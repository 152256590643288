<template>
  <div>
    <slot></slot>
    <base-dialog
      :show="!!error"
      title="Er trad een fout op!"
      @close="handleError"
    >
      <p>{{ error }}</p>
    </base-dialog>
    <section>
      <presence-filter
        :moments="sportsCampMoments"
        title = "Selecteer moment"
        @set-moment="setSportsCampMoment"
      ></presence-filter>
      <sports-camp-planning-filter @change-filter="setFilter" v-if="isMomentSelected">
      </sports-camp-planning-filter>
    </section>
    <div v-if="isLoading || isMomentSelected">
      <section>
        <base-card>
          <div v-if="isLoading">
            <base-spinner></base-spinner>
          </div>
          <div v-if="isMomentSelected" v-html="info">
          </div>
        </base-card>
      </section>
    </div>
  </div>
</template>

<script>
import PresenceFilter from '../../components/presences/PresenceFilter.vue';
import SportsCampPlanningFilter from '../../components/sportscamps/SportsCampPlanningFilter.vue';

export default {
  components: {
    PresenceFilter,
    SportsCampPlanningFilter
  },
  data() {
    return {
      isLoading: false,
      error: null,
      sportsCampId: null,
      campDate: null,
      ampm: null,
      selection: '3',
      planning: null,
      theme: null,
      material: null,
      trainers: null
    };
  },
  computed: {
    sportsCampMoments() {
      const momentsList = this.$store.getters['sportscamps/sportsCampMoments'];
      const moments = [{ id: -1, name: 'Selecteer moment' }];
      momentsList.forEach(moment => {
        moments.push({ id: moment.id, name: moment.campTitleAndPeriod });
      });

      return moments;
    },
    isMomentSelected() {
      if (this.sportsCampId && this.sportsCampId != null && this.sportsCampId > 0) {
        return true;
      }

      return false;
    },
    info() {
      if (this.selection == '1') {
        return this.theme;
      }
      if (this.selection == '2') {
        return this.material;
      }
      if (this.selection == '3') {
        return this.planning;
      }
      if (this.selection == '4') {
        return this.trainers;
      }

      return '';
    },
  },
  methods: {
    async loadSportsCampMoments() {
      this.isLoading = true;

      try {
        const trainerId = this.$store.getters.trainerId;
        await this.$store.dispatch('sportscamps/loadSportsCampMoments', {
          trainerId: trainerId,
          forPlanning: true
        });
      } catch (err) {
        this.error =
          err.message ||
          'Er trad een probleem op bij het ophalen van de sportkampmomenten!';
      }

      this.isLoading = false;

      // Close navigation
      if (this.$store.getters.isNavOpen) {
        this.$store.dispatch('toggleNav');
      }
    },
    async loadPlanning() {
      this.isLoading = true;

      this.planning = null;

      try {
        await this.$store.dispatch('sportscamps/loadPlanning', {
          sportsCampId: this.sportsCampId,
          campDate: this.campDate,
          ampm: this.ampm
        });
      } catch (err) {
        this.error =
          err.message ||
          'Er trad een probleem op bij het ophalen van de planning!';
      }

      this.planning = this.$store.getters['sportscamps/planning'];
      this.theme = this.$store.getters['sportscamps/theme'];
      this.material = this.$store.getters['sportscamps/material'];
      this.trainers = this.$store.getters['sportscamps/trainers'];

      this.isLoading = false;
    },
    setSportsCampMoment(selectedMoment) {
      this.sportsCampId = null;
      this.campDate = null;
      this.ampm = null;
      if (selectedMoment) {
        const momentsList = this.$store.getters['sportscamps/sportsCampMoments'];
        momentsList.forEach(moment => {
          if (moment.id == selectedMoment.id) {
            this.sportsCampId = moment.sportsCampId;
            this.campDate = moment.campDate;
            this.ampm = moment.ampm;
          }
        })
      }

      if (this.sportsCampId && this.sportsCampId != null && this.sportsCampId > 0) {
        this.loadPlanning();
      } else {
        this.$store.dispatch('sportscamps/clearPlanning');
      }
    },
    setFilter(filter) {
      this.selection = filter.selection;
    },
    handleError() {
      this.error = null;
    }
  },
  created() {
    this.loadSportsCampMoments();
  }
};
</script>

<style scoped>
ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.nrofsubscriptions {
  font-size: 24px;
  font-weight: bold;
}

.controls {
  display: flex;
  justify-content: space-between;
}
</style>
