<template>
  <div>
    <slot></slot>
    <base-dialog
      :show="!!error"
      title="Er trad een fout op bij het ophalen van de materiaallijst!"
      @close="handleError"
    >
      <p>{{ error }}</p>
    </base-dialog>
    <section>
      <material-filter @change-filter="setFilter"></material-filter>
    </section>
    <section>
      <base-card>
        <div v-if="isLoading">
          <base-spinner></base-spinner>
        </div>
        <ul v-else-if="hasMaterialList">
          <material-item
            v-for="materialList in filteredMaterialList"
            :key="materialList.id"
            :id="materialList.id"
            :description="materialList.description"
            :camp="materialList.camp"
            :stock="materialList.stock"
            :available="materialList.available"
            :reserved="materialList.reserved"
            :details="materialList.details"
            :photo="materialList.photo"
          ></material-item>
        </ul>
        <h3 v-else>Geen materiaallijst</h3>
        <div class="controls">
          <base-button mode="flat" @click="loadMaterialList(true)"
            >Vernieuwen</base-button
          >
        </div>
      </base-card>
    </section>
  </div>
</template>

<script>
import MaterialItem from '../../components/sportscamps/MaterialItem.vue';
import MaterialFilter from '../../components/sportscamps/MaterialFilter.vue';

export default {
  components: {
    MaterialItem,
    MaterialFilter
  },
  data() {
    return {
      isLoading: false,
      error: null,
      filter: {
        searchTerm: '',
        today: false
      }
    };
  },
  computed: {
    filteredMaterialList() {
      const materialList = this.$store.getters['sportscamps/materialList'];
      const currentDate = new Date().toLocaleDateString('nl-BE');

      return materialList.filter(material => {
        let campDate = '';
        if (material.campDate) {
          campDate = new Date(material.campDate).toLocaleDateString('nl-BE');
        }

        if (this.filter.today && currentDate != campDate) {
          return false;
        } else {
          if (this.filter.searchTerm) {
            return (
              material.description
                .toLowerCase()
                .includes(this.filter.searchTerm.toLowerCase()) ||
              (material.camp &&
                material.camp
                  .toLowerCase()
                  .includes(this.filter.searchTerm.toLowerCase())) ||
              (material.details != null &&
                material.details
                  .toLowerCase()
                  .includes(this.filter.searchTerm.toLowerCase()))
            );
          } else {
            return true;
          }
        }
      });
    },
    hasMaterialList() {
      const materialList = this.filteredMaterialList;
      if (materialList.length > 0) {
        return true;
      } else {
        return false;
      }
    }
  },
  methods: {
    async loadMaterialList(refresh = false) {
      this.isLoading = true;

      try {
        await this.$store.dispatch('sportscamps/loadMaterialList', {
          forceRefresh: refresh
        });
      } catch (err) {
        this.error =
          err.message ||
          'Er trad een probleem op bij het ophalen van de materiaallijst!';
      }

      this.isLoading = false;

      // Close navigation
      if (this.$store.getters.isNavOpen) {
        this.$store.dispatch('toggleNav');
      }
    },
    handleError() {
      this.error = null;
    },
    setFilter(updatedFilter) {
      this.filter = updatedFilter;
    }
  },
  created() {
    this.loadMaterialList(false);
  }
};
</script>

<style scoped>
ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.controls {
  display: flex;
  justify-content: space-between;
}
</style>
