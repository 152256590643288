export default {
  async loadSterktelijsten(context) {

    let url = context.rootState.apiUrl + 'sterktelijst_list.php';

    const token = context.rootState.token;
    const response = await fetch(url, {
      method: 'POST',
      body: JSON.stringify({
        jwt: token
      })
    });

    const responseData = await response.json();
    // console.log(responseData);

    if (!response.ok) {
      const error = new Error(responseData.message || 'Fout bij ophalen van de sterktelijsten!');
      throw error;
    }

    const sterktelijsten = [];

    for (const key in responseData) {
      const sterktelijst = {
        id: key,
        discipline: responseData[key].discipline,
        listNumber: responseData[key].listnumber,
        indexNumber: responseData[key].indexnumber,
        memberId: responseData[key].member_id,
        memberName: responseData[key].membername,
        email: responseData[key].email,
        telephone: responseData[key].telephone,
        mobile: responseData[key].mobile,
        ranking: responseData[key].ranking,
        associationId: responseData[key].association_id,
        url: responseData[key].url
      };
      sterktelijsten.push(sterktelijst);
    }

    context.commit('setSterktelijsten', sterktelijsten);
  }
};