<template>
  <li>
    <h5>
      <i class="fas fa-mars" v-if="isMale"></i>
      <i class="fas fa-venus" v-if="isFemale"></i>
      {{ name }}
      <span v-if="isMember">
        <i class="fas fa-people-arrows"></i>
      </span>
      <span :class="gdprClass">
        <i class="fas fa-camera-retro"></i>
        <span class="gdprtooltiptext" v-if="!gdprAllowed"
          >GEEN toestemming tot het nemen van foto's</span
        >
      </span>
      <span v-if="!!extrainfo" class="extra">
        <i class="fas fa-info-circle"></i>
        <span class="extratooltiptext">{{ extrainfo }}</span>
      </span>
      <span v-if="!!medication" class="medication">
        <i class="fas fa-capsules"></i>
        <span class="medicationtooltiptext">{{ medication }}</span>
      </span>
    </h5>
    <hr />
    <div v-if="isBirthdayToday" class="birthday">
      <i class="fa fa-birthday-cake"></i><i class="fa fa-birthday-cake"></i
      ><i class="fa fa-birthday-cake"></i><span>JARIG VANDAAG</span
      ><i class="fa fa-birthday-cake"></i><i class="fa fa-birthday-cake"></i
      ><i class="fa fa-birthday-cake"></i>
    </div>
    <div v-if="!!address">
      <i class="fas fa-map-marker-alt"></i>&nbsp;<strong>{{ address }}</strong>
    </div>
    <div v-if="validEmail(email) || validEmail(email2)">
      <i class="fas fa-envelope-square" v-if="validEmail(email)"></i>&nbsp;<a
        :href="mailto(email)"
        ><strong>{{ email }}</strong></a
      >
      <span v-if="validEmail(email) && validEmail(email2)">of&nbsp;</span>
      <span v-if="validEmail(email2)">
        <a :href="mailto(email2)"
          ><strong>{{ email2 }}</strong></a
        >
      </span>
    </div>
    <div v-if="!!telephone || !!telephone2">
      <i class="fas fa-phone" v-if="!!telephone"></i>&nbsp;<a
        :href="phoneto(telephone)"
        ><strong>{{ telephone }}</strong></a
      >
      <span v-if="!!telephone && !!telephone2">of&nbsp;</span>
      <span v-if="!!telephone2">
        <a :href="phoneto(telephone2)"
          ><strong>{{ telephone2 }}</strong></a
        >
      </span>
    </div>
    <div v-if="!!parents">
      <i class="fas fa-user-shield"></i>&nbsp;<strong>{{ parents }}</strong>
    </div>
    <div v-if="!!sportscamp">
      <i class="fas fa-users"></i>&nbsp;<strong>{{ sportscamp }}</strong>
    </div>
  </li>
</template>

<script>
export default {
  props: [
    'id',
    'name',
    'gender',
    'birthdate',
    'address',
    'email',
    'email2',
    'telephone',
    'telephone2',
    'isbirthdaytoday',
    'medication',
    'experience',
    'extrainfo',
    'ismember',
    'parents',
    'sportscamp',
    'gdpr'
  ],
  computed: {
    isMale() {
      if (this.gender === 'M') {
        return true;
      }

      return false;
    },
    isFemale() {
      if (this.gender === 'F') {
        return true;
      }

      return false;
    },
    isMember() {
      if (this.ismember == '1') {
        return true;
      }

      return false;
    },
    gdprAllowed() {
      if (this.gdpr == '1') {
        return true;
      }

      return false;
    },
    gdprClass() {
      if (this.gdpr == '1') {
        return 'gdprallowed';
      }

      return 'gdprnotallowed';
    },
    isBirthdayToday() {
      return this.isbirthdaytoday == 1 ? true : false;
    }
  },
  methods: {
    validEmail(emailAddress) {
      if (emailAddress.includes('@')) {
        return true;
      }

      return false;
    },
    mailto(emailAddress) {
      return 'mailto:' + emailAddress;
    },
    phoneto(telephoneNumber) {
      var nr = telephoneNumber;
      nr = nr.replace(/\D/g, '');
      return 'tel:' + nr;
    }
  }
};
</script>

<style scoped>
li {
  margin: 1rem 0;
  border: 1px solid #424242;
  border-radius: 12px;
  padding: 1rem;
}

h3 {
  font-size: 1.5rem;
}

h3,
h4 {
  margin: 0.5rem 0;
}

div {
  margin: 0rem 0;
}

.gdprallowed {
  color: green;
  margin-left: 0.5rem;
}

.gdprnotallowed {
  color: red;
  margin-left: 0.5rem;
  position: relative;
  display: inline-block;
}

.gdprnotallowed .gdprtooltiptext {
  visibility: hidden;
  width: 250px;
  color: #f00;
  background-color: var(--var-button-background);
  font-size: 18px;
  padding: 5px 0;
  border: 2px solid #424242;
  border-radius: 6px;

  position: absolute;
  z-index: 1;
}

.gdprnotallowed:hover .gdprtooltiptext {
  visibility: visible;
}

.extra {
  color: #7c7b79;
  margin-left: 0.5rem;
  position: relative;
  display: inline-block;
}

.extra .extratooltiptext {
  visibility: hidden;
  width: 250px;
  color: #7c7b79;
  background-color: var(--var-button-background);
  font-size: 18px;
  padding: 5px 0;
  border: 2px solid #424242;
  border-radius: 6px;

  position: absolute;
  z-index: 1;
}

.extra:hover .extratooltiptext {
  visibility: visible;
}

.medication {
  color: red;
  margin-left: 0.5rem;
  position: relative;
  display: inline-block;
}

.medication .medicationtooltiptext {
  visibility: hidden;
  width: 250px;
  color: #f00;
  background-color: var(--var-button-background);
  font-size: 18px;
  padding: 5px 0;
  border: 2px solid #424242;
  border-radius: 6px;

  position: absolute;
  z-index: 1;
}

.medication:hover .medicationtooltiptext {
  visibility: visible;
}
.birthday {
  color: rgb(207, 135, 58);
  font-size: 32px;
  font-weight: bold;
  margin-bottom: 10px;
}
.birthday i {
  margin-right: 10px;
}
.birthday span {
  margin-right: 10px;
}
</style>
