export default {
  async loadMembers(context, payload) {
    if (!payload.forceRefresh && !context.getters.shouldUpdate) {
      return;
    }

    let url = context.rootState.apiUrl + 'member_list.php';

    const token = context.rootState.token;
    const response = await fetch(url, {
      method: 'POST',
      body: JSON.stringify({
        jwt: token,
        allmembers: true
      })
    });

    const responseData = await response.json();
    // console.log(responseData);

    if (!response.ok) {
      const error = new Error(
        responseData.message || 'Fout bij ophalen van de ledenfiches!'
      );
      throw error;
    }

    const members = [];

    for (const key in responseData) {
      const member = {
        id: key,
        memberId: responseData[key].id,
        memberName: responseData[key].membername,
        address: responseData[key].address,
        email: responseData[key].email,
        telephone: responseData[key].telephone,
        mobile: responseData[key].mobile,
        birthDate: responseData[key].birthdate,
        gender: responseData[key].gender,
        ranking: responseData[key].ranking,
        associationId: responseData[key].association_id,
        memberState: +responseData[key].memberstate,
        traineeState: +responseData[key].traineestate,
        traineeInteralState: +responseData[key].traineeinternalstate,
        traineeInteralColor: responseData[key].traineeinternalcolor,
        trainingGroups: responseData[key].traininggroups,
        traineeRemark: responseData[key].traineeremark,
        traineeRemarkColor: responseData[key].traineeremarkcolor,
        achievedObjectiveListId: +responseData[key].achievedobjectivelist_id,
        achievedObjectiveList: responseData[key].achievedobjectivelist,
        achievedObjectiveListColor:
          responseData[key].achievedobjectivelistcolor,
        currentObjectiveListId: +responseData[key].currentobjectivelist_id,
        currentObjectiveList: responseData[key].currentobjectivelist,
        currentObjectiveListColor: responseData[key].currentobjectivelistcolor
        // photo: responseData[key].photo
      };
      members.push(member);
    }

    context.commit('setMembers', members);
    context.commit('setFetchTimestamp');
  },

  async loadAbsenceReasons(context) {
    const absenceReasons = context.getters.absenceReasons;

    // Only load absencereasones once
    if (absenceReasons && absenceReasons.length > 0) {
      return;
    }

    let url = context.rootState.apiUrl + 'absencereason_list.php';

    const token = context.rootState.token;
    const response = await fetch(url, {
      method: 'POST',
      body: JSON.stringify({
        jwt: token
      })
    });

    const responseData = await response.json();
    // console.log(responseData);

    if (!response.ok) {
      const error = new Error(
        responseData.message ||
          'Fout bij het ophalen van de afwezigheidsredenen!'
      );

      throw error;
    }

    for (const key in responseData) {
      const absenceReason = {
        id: responseData[key].id,
        description: responseData[key].description
      };

      absenceReasons.push(absenceReason);
    }

    context.commit('setAbsenceReasons', absenceReasons);
  },

  async loadAbsences(context) {
    let url = context.rootState.apiUrl + 'absence_list.php';

    const token = context.rootState.token;
    const response = await fetch(url, {
      method: 'POST',
      body: JSON.stringify({
        jwt: token
      })
    });

    const responseData = await response.json();

    if (!response.ok) {
      const error = new Error(
        responseData.message || 'Fout bij het ophalen van de afwezigheden!'
      );
      throw error;
    }

    const memberAbsences = [];

    for (const key in responseData) {
      const memberAbsence = {
        id: responseData[key].id,
        dateFrom: responseData[key].datefrom,
        dateTill: responseData[key].datetill,
        confirmationOption: responseData[key].confirmationoption,
        remark: responseData[key].remark,
        teamCalendarId: responseData[key].teamcalendar_id
      };
      memberAbsences.push(memberAbsence);
    }

    context.commit('setMemberAbsences', memberAbsences);
  }
};
