export default {
  async loadSportsCampMoments(context, payload) {
    let url = context.rootState.apiUrl + 'sportscamp_moments_presence.php';
    if (payload.forPlanning) {
      url = context.rootState.apiUrl + 'sportscamp_moments_planning.php';
    }

    let trainerId = payload.trainerId;

    const token = context.rootState.token;
    const response = await fetch(url, {
      method: 'POST',
      body: JSON.stringify({
        jwt: token,
        trainer_id: trainerId
      })
    });

    const responseData = await response.json();
    // console.log(responseData);

    if (!response.ok) {
      const error = new Error(
        responseData.message || 'Fout bij ophalen van de kampmomenten!'
      );
      throw error;
    }

    const sportsCampMoments = [];

    for (const key in responseData) {
      const sportsCampMoment = {
        id: key,
        eventId: responseData[key].event_id,
        campDate: responseData[key].campdate,
        ampm: responseData[key].ampm,
        sportsCampCampaignId: responseData[key].sportscampcampaign_id,
        sportsCampPlanId: responseData[key].sportscampplan_id,
        sportsCampId: responseData[key].sportscamp_id,
        campTitleAndPeriod: responseData[key].camptitleandperiod,
        info: responseData[key].info
      };

      sportsCampMoments.push(sportsCampMoment);
    }

    context.commit('setSportsCampMoments', sportsCampMoments);
  },
  async loadSubscriptions(context) {
    let url = context.rootState.apiUrl + 'sportscamp_subscription_list.php';

    const token = context.rootState.token;
    const response = await fetch(url, {
      method: 'POST',
      body: JSON.stringify({
        jwt: token
      })
    });

    const responseData = await response.json();
    // console.log(responseData);

    if (!response.ok) {
      const error = new Error(
        responseData.message || 'Fout bij ophalen van de inschrijvingslijst!'
      );
      throw error;
    }

    const subscriptions = [];

    for (const key in responseData) {
      const subscription = {
        id: key,
        subscriptionId: responseData[key].id,
        name: responseData[key].name,
        gender: responseData[key].gender,
        birthdate: responseData[key].birthdate,
        isBirthdayToday: responseData[key].isbirthdaytoday,
        address: responseData[key].address,
        email: responseData[key].email,
        email2: responseData[key].email2,
        telephone: responseData[key].telephone,
        telephone2: responseData[key].telephone2,
        medication: responseData[key].medication,
        experience: responseData[key].experience,
        extraInfo: responseData[key].extrainfo,
        isMember: responseData[key].ismember,
        memberId: responseData[key].member_id,
        parents: responseData[key].parents,
        gdpr: responseData[key].gdprallowance,
        remark: responseData[key].remark,
        sportsCampId: responseData[key].sportscamp_id,
        sportsCamp: responseData[key].sportscamp
      };

      subscriptions.push(subscription);
    }

    context.commit('setSubscriptions', subscriptions);
  },
  clearSubscriptions(context) {
    const subscriptions = [];
    context.commit('setSubscriptions', subscriptions);
  },
  async loadPresences(context, payload) {
    let url = context.rootState.apiUrl + 'sportscamp_presence_list.php';

    const token = context.rootState.token;
    const response = await fetch(url, {
      method: 'POST',
      body: JSON.stringify({
        jwt: token,
        event_id: payload.eventId,
        forcheckout: payload.checkOut
      })
    });

    const responseData = await response.json();
    // console.log(responseData);

    if (!response.ok) {
      const error = new Error(
        responseData.message || 'Fout bij ophalen van de aanwezigheidslijst!'
      );
      throw error;
    }

    const presences = [];

    for (const key in responseData) {
      const presence = {
        id: key,
        eventSubscriptionId: responseData[key].eventsubscription_id,
        subscriptionId: responseData[key].subscription_id,
        subscriptionName: responseData[key].subscriptionname,
        gender: responseData[key].gender,
        birthdate: responseData[key].birthdate,
        isBirthdayToday: responseData[key].isbirthdaytoday,
        email: responseData[key].email,
        email2: responseData[key].email2,
        telephone: responseData[key].telephone,
        telephone2: responseData[key].telephone2,
        parents: responseData[key].parents,
        address: responseData[key].address,
        presence: responseData[key].presence,
        checkedOut: responseData[key].checkedout
      };
      presences.push(presence);
    }

    context.commit('setPresences', presences);
  },
  clearPresences(context) {
    const presences = [];
    context.commit('setPresences', presences);
  },
  async updateCheckIn(context, payload) {
    let url = context.rootState.apiUrl + 'sportscamp_presence_update.php';
    let presence = '1';
    if (payload.present === false) {
      presence = '0';
    }

    const token = context.rootState.token;
    const response = await fetch(url, {
      method: 'POST',
      body: JSON.stringify({
        jwt: token,
        event_id: payload.eventId,
        subscription_id: payload.subscriptionId,
        presence: presence
      })
    });

    const responseData = await response.json();
    // console.log(responseData);

    if (!response.ok) {
      const error = new Error(
        responseData.message || 'Fout bij het aanmelden!'
      );
      throw error;
    }

    // Update presences-item in array
    const presences = context.state.presences;

    presences.forEach(pres => {
      if (pres.subscriptionId === payload.subscriptionId) {
        pres.presence = presence;
      }
    });

    context.commit('setPresences', presences);
  },
  async updateCheckOut(context, payload) {
    let url = context.rootState.apiUrl + 'sportscamp_checkout_update.php';
    let checkout = '1';
    if (payload.checkedOut === false) {
      checkout = '0';
    }

    const token = context.rootState.token;
    const response = await fetch(url, {
      method: 'POST',
      body: JSON.stringify({
        jwt: token,
        event_id: payload.eventId,
        subscription_id: payload.subscriptionId,
        checkout: checkout
      })
    });

    const responseData = await response.json();
    // console.log(responseData);

    if (!response.ok) {
      const error = new Error(responseData.message || 'Fout bij het afmelden!');
      throw error;
    }

    // Update presences-item in array
    const presences = context.state.presences;

    presences.forEach(pres => {
      if (pres.subscriptionId === payload.subscriptionId) {
        pres.checkedOut = checkout;
      }
    });

    context.commit('setPresences', presences);
  },
  async loadPlanning(context, payload) {
    let url = context.rootState.apiUrl + 'sportscamp_planning.php';

    const token = context.rootState.token;
    const response = await fetch(url, {
      method: 'POST',
      body: JSON.stringify({
        jwt: token,
        sportscamp_id: payload.sportsCampId,
        campdate: payload.campDate,
        ampm: payload.ampm
      })
    });

    const responseData = await response.json();
    // console.log(responseData);

    if (!response.ok) {
      const error = new Error(
        responseData.message || 'Fout bij ophalen van de planning!'
      );
      throw error;
    }

    context.commit('setTheme', responseData[0].theme);
    context.commit('setMaterial', responseData[0].material);
    context.commit('setPlanning', responseData[0].themeandmaterial);
    context.commit('setTrainers', responseData[0].trainers);
  },
  clearPlanning(context) {
    context.commit('setTheme', '');
    context.commit('setMaterial', '');
    context.commit('setPlanning', '');
    context.commit('setTrainers', '');
  },
  async loadTrainerDayPlanning(context, payload) {
    let url = context.rootState.apiUrl + 'sportscamp_trainer_dayplanning.php';

    const token = context.rootState.token;
    const response = await fetch(url, {
      method: 'POST',
      body: JSON.stringify({
        jwt: token,
        trainer_id: payload.trainerId
      })
    });

    const responseData = await response.json();
    // console.log(responseData);

    if (!response.ok) {
      const error = new Error(
        responseData.message || 'Fout bij ophalen van de trainers dagplanning!'
      );
      throw error;
    }

    context.commit('setTrainerDayPlanning', responseData[0].dayplanning);
  },
  async loadMaterialList(context, payload) {
    if (!payload.forceRefresh && !context.getters.materialList) {
      return;
    }

    let url = context.rootState.apiUrl + 'sportscamp_materiallist.php';

    const token = context.rootState.token;
    const response = await fetch(url, {
      method: 'POST',
      body: JSON.stringify({
        jwt: token
      })
    });

    const responseData = await response.json();

    if (!response.ok) {
      const error = new Error(
        responseData.message || 'Fout bij ophalen van de materiaallijst!'
      );
      throw error;
    }

    const materialList = [];

    for (const key in responseData) {
      const materialEntry = {
        id: key,
        materialId: responseData[key].id,
        description: responseData[key].description,
        camp: responseData[key].camp,
        campDate: responseData[key].campdate,
        stock: responseData[key].stock,
        reserved: responseData[key].reserved,
        available: responseData[key].available,
        details: responseData[key].details,
        photo: responseData[key].photo
      };

      materialList.push(materialEntry);
    }

    // console.log(materialList);
    context.commit('setMaterialList', materialList);
  },

  async loadTrainerPresences(context, payload) {
    let url = context.rootState.apiUrl + 'sportscamp_trainer_presence_list.php';

    const token = context.rootState.token;
    const response = await fetch(url, {
      method: 'POST',
      body: JSON.stringify({
        jwt: token,
        trainer_id: payload.trainerId
      })
    });

    const responseData = await response.json();
    // console.log(responseData);

    if (!response.ok) {
      const error = new Error(
        responseData.message || 'Fout bij ophalen van de aanwezigheidslijst!'
      );
      throw error;
    }

    const trainerPresences = [];

    for (const key in responseData) {
      const trainerPresence = {
        id: key,
        campDate: responseData[key].campDate,
        ids: responseData[key].ids,
        presence: responseData[key].presence,
        transport: responseData[key].transport,
        remarks: responseData[key].remarks,
        registrationDate: responseData[key].registrationDate,
        title: responseData[key].title,
        responsable: responseData[key].responsable,
        location: responseData[key].location
      };

      trainerPresences.push(trainerPresence);
    }

    context.commit('setTrainerPresences', trainerPresences);
  },

  async setTrainerPresence(context, payload) {
    let url =
      context.rootState.apiUrl + 'sportscamp_trainer_presence_update.php';

    const token = context.rootState.token;
    const response = await fetch(url, {
      method: 'POST',
      body: JSON.stringify({
        jwt: token,
        ids: payload.ids,
        presence: payload.presence,
        transport: payload.transport,
        remarks: payload.remarks
      })
    });

    const responseData = await response.json();
    // console.log(responseData);

    if (!response.ok) {
      const error = new Error(
        responseData.message || 'Fout bij het registreren van je aanwezigheid!'
      );
      throw error;
    }

    // Update entrie(s) in actual trainerPresences
    let trainerPresences = context.state.trainerPresences;
    trainerPresences = trainerPresences.map(presence => {
      if (presence.ids == payload.ids) {
        presence.presence = payload.presence;
        presence.transport = payload.transport;
        presence.remarks = payload.remarks;

        // console.log(presence);
        return presence;
      } else {
        return presence;
      }
    });

    context.commit('setTrainerPresences', trainerPresences);
  }
};
