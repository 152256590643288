<template>
  <div id="signin">
    <base-dialog
      :show="!!error"
      title="Fout bij authenticatie..."
      @close="handleError"
    >
      <p>{{ error }}</p>
    </base-dialog>
    <base-dialog :show="isLoading" title="Authenticeren..." fixed>
      <base-spinner></base-spinner>
    </base-dialog>
    <div class="signin-form">
      <base-card>
        <form @submit.prevent="onSubmit">
          <div class="input">
            <label for="email">E-mail</label>
            <input
              type="email"
              id="email"
              placeholder="Uw e-mailadres"
              v-model="user.email"
              @blur="clearValidity"
            />
          </div>
          <div class="input">
            <label for="password">Wachtwoord</label>
            <input
              type="password"
              id="password"
              placeholder="Uw wachtwoord"
              v-model="user.password"
              @blur="clearValidity"
            />
          </div>
          <p></p>
          <div v-if="!formIsValid">
            <p>Breng e-mail en wachtwoord in a.u.b.!</p>
          </div>
          <div class="submit">
            <base-button type="submit">Aanmelden</base-button>
          </div>
        </form>
      </base-card>
      <base-card>
        <div>
          Klik
          <a :href="passwordUrl" v-if="!loggedIn" target="_blank">hier</a> als
          je jouw aanmeldgegevens vergeten bent, of om je wachtwoord in te
          stellen.
        </div>
      </base-card>
    </div>
  </div>
</template>

<script>
import { reactive, ref, computed } from 'vue';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';

export default {
  setup() {
    const store = useStore();
    const router = useRouter();

    let user = reactive({
      email: '',
      password: ''
    });

    if (process.env.NODE_ENV !== 'production') {
      console.log('Local environment - preset login');
      user.email = 'glenn.detollenaere@gmail.com';
      user.password = 'Master8!';
    }

    let error = ref('');
    let isLoading = ref(false);
    let formIsValid = ref(true);

    const loggedIn = computed(function() {
      return store.getters.loggedIn;
    });

    async function onSubmit() {
      formIsValid.value = true;

      const isLicensed = store.getters.isLicensed;
      // console.log(isLicensed);

      if (!isLicensed) {
        formIsValid.value = false;
      } else if (user.email === '' || user.password === '') {
        formIsValid.value = false;
      } else {
        isLoading.value = true;
        const formData = {
          email: user.email,
          password: user.password
        };

        try {
          await store.dispatch('login', formData);
          await store.dispatch('loadMyMembers');
          await store.dispatch('loadTeamConfirmationOptions');

          if (store.getters.isTableTennis) {
            // Homepage?
            const homePage = store.getters.homePage;
            // console.log(homePage);
            // NULL/0 = geen => general homepage
            // 1 = weekdigest
            // 2 = Ploegopstellingen
            // 3 = Mijn resultaten
            // 4 = Ploegenoverzicht
            if (homePage == 1) {
              router.push('/weekdigest');
            } else if (homePage == 2) {
              router.push('/teams');
            } else if (homePage == 3) {
              router.push('/results/' + store.getters.memberId);
            } else if (homePage == 4) {
              router.push('/statistics');
            } else {
              router.push('/home');
            }
          } else {
            router.push('/home');
          }
        } catch (err) {
          error.value =
            err.message || 'Fout bij aanmelden. Probeer later opnieuw.';
        }

        isLoading.value = false;
      }
    }

    function clearValidity() {
      formIsValid.value = true;
    }

    function handleError() {
      error.value = null;
    }

    // Close navigation
    if (store.getters.isNavOpen) {
      store.dispatch('toggleNav');
    }

    const passwordUrl = computed(function() {
      return store.getters.passwordUrl;
    });

    return {
      user,
      loggedIn,
      formIsValid,
      isLoading,
      clearValidity,
      error,
      onSubmit,
      handleError,
      passwordUrl
    };
  }
};
</script>

<style scoped>
.signin-form {
  background-color: var(--var-button-background);
  width: 800px;
  margin: 30px auto;
  border: 2px solid var(--var-font-color);
  padding: 20px;
  box-shadow: 0 2px 3px #ccc;
}

.input {
  margin: 10px auto;
}

.input label {
  display: block;
  color: var(--var-font-color);
  margin-bottom: 6px;
}

.input input {
  font: inherit;
  width: 100%;
  padding: 6px 12px;
  box-sizing: border-box;
  border: 1px solid #ccc;
}

.input input:focus {
  outline: none;
  border: 1px solid #521751;
  background-color: #eee;
}
</style>
