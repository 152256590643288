<template>
  <section>
    <base-dialog :show="subscriptionFormIsVisible" :fixed="true">
      <template v-slot:header>
        <h2>
          <i class="fas fa-pen-nib"></i>
          Ik wens me in te schrijven
        </h2>
      </template>
      <template v-slot:default>
        <subscribe-form
          @close-subscription-form="setSubscriptionFormIsVisible(false)"
          @save-subscription="saveSubscription"
        ></subscribe-form>
        <div v-if="isUpdating">
          <base-spinner></base-spinner>
        </div>
      </template>
    </base-dialog>
    <base-card>
      <div v-if="loggedIn && hasSportsCamp && hasPlanning">
        <h2>Mijn kampdag</h2>
        <hr v-if="trainerId > 0" />
        <div class="trainerpresences" v-if="trainerId > 0">
          <i class="far fa-calendar-check"></i>&nbsp;<router-link
            to="/trainerpresence"
            >Mijn aanwezigheden</router-link
          >
        </div>
        <hr />
        <div v-html="planningInfo"></div>
      </div>
      <div v-else>
        <h2>{{ appName }}</h2>
        <p v-html="appDescription"></p>
        <div v-if="loggedIn && hasLeague">
          <div class="leagueselection">
            <h3>League</h3>
            <league-filter @change-league="setSelectedLeague"></league-filter>
          </div>
          <div class="leaguesubscription" v-if="!isSubscribedForTheLeague">
            <div @click="setSubscriptionFormIsVisible(true)">
              Je bent nog niet ingeschreven voor deze league? Klik hier om je in
              te schrijven!
            </div>
          </div>
        </div>
        <div v-if="loggedIn && teamCompositionModuleActive">
          <h3>Interclub</h3>
          <base-button
            mode="smallbold"
            :link="true"
            :to="resultRoute"
            v-if="!!memberId && isTableTennis"
            >Mijn resultaten</base-button
          >
          <base-button mode="smallbold" :link="true" to="/statistics"
            >Ploegen</base-button
          >
          <base-button mode="smallbold" :link="true" to="/teams"
            >Opstellingen</base-button
          >
          <base-button mode="smallbold" :link="true" to="/weekdigest"
            >Weekdigest</base-button
          >
        </div>
      </div>
      <div v-if="!loggedIn">
        <hr />
        <p>
          Klik <router-link to="/login" v-if="!loggedIn">hier</router-link> om
          je aan te melden.
        </p>
        <p>
          Klik
          <a :href="passwordUrl" v-if="!loggedIn" target="_blank">hier</a> als
          je jouw aanmeldgegevens vergeten bent, of om je wachtwoord in te
          stellen.
        </p>
      </div>
      <div v-if="loggedIn">
        <hr />
        <p>
          Klik
          <a
            href="https://ledenfiches.be/wp-content/plugins/pdf-poster/pdfjs/web/viewer.html?file=https://ledenfiches.be/wp-content/uploads/2023/03/MobileAppShortcut.pdf&download=true&print=true&openfile=false"
            target="_blank"
            >hier</a
          >
          voor wat uitleg voor het aanmaken van een snelkoppeling op je mobiel
          device naar deze mobile web(app).
        </p>
      </div>
    </base-card>
  </section>
</template>

<script>
import { ref, computed } from 'vue';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
import LeagueFilter from './../components/leagues/LeagueFilter.vue';
import SubscribeForm from './../components/leagues/LeagueSubscribeForm.vue';

export default {
  components: {
    LeagueFilter,
    SubscribeForm
  },
  setup() {
    const store = useStore();
    const router = useRouter();

    let planning = ref('');
    let subscriptionForm = ref(false);
    let isUpdating = ref(false);

    const loggedIn = computed(function() {
      return store.getters.loggedIn;
    });

    const appName = computed(function() {
      return store.getters.appName;
    });

    const appDescription = computed(function() {
      return store.getters.appDescription;
    });

    const passwordUrl = computed(function() {
      return store.getters.passwordUrl;
    });

    const hasSportsCamp = computed(function() {
      return store.getters.hasSportsCamp;
    });

    const hasPlanning = computed(function() {
      if (planning.value && planning.value != '') {
        return true;
      }

      return false;
    });

    const hasLeague = computed(function() {
      return store.getters.hasLeague;
    });

    const isSubscribedForTheLeague = computed(function() {
      return store.getters.isSubscribedForTheLeague;
    });

    const planningInfo = computed(function() {
      return planning.value;
    });

    const trainerId = computed(function() {
      let id = store.getters.trainerId;
      if (!id || id <= 0) id = 0;
      return id;
    });

    async function loadTrainerDayPlanning() {
      // Trainer?
      const trainerId = store.getters.trainerId;
      if (!trainerId || trainerId <= 0) return;

      try {
        await store.dispatch('sportscamps/loadTrainerDayPlanning', {
          trainerId: trainerId
        });

        planning.value = store.getters['sportscamps/trainerDayPlanning'];
      } catch (err) {
        console.log(
          err.message ||
            'Er trad een probleem op bij het ophalen van de dagplanning!'
        );
      }
    }

    // Sportkampen - dagplanning?
    if (store.getters.hasSportsCamp) {
      loadTrainerDayPlanning();
    }

    const leagues = computed(function() {
      // console.log(store.getters['leagues/leagues']);
      return store.getters['leagues/leagues'];
    });

    // Weekdigest present?
    let digests = ref([]);
    const weekDigest = computed(function() {
      return digests.value && digests.value.length > 0;
    });

    async function loadWeekDigests() {
      try {
        await store.dispatch('statistics/loadWeekDigests', {}).then(() => {
          digests.value = store.getters['statistics/weekDigests'];
        });
      } catch (err) {
        console.log(
          err.message ||
            'Er trad een probleem op bij het ophalen van de weekdigest!'
        );
      }
    }

    async function loadLeagues() {
      try {
        await store.dispatch('leagues/loadLeagues', {});
      } catch (err) {
        console.log(
          err.message ||
            'Er trad een probleem op bij het ophalen van de leagues!'
        );
      }
    }

    if (store.getters.hasLeague) {
      loadLeagues();
    }

    function setSelectedLeague(selectedLeague) {
      // console.log(selectedLeague);

      const whom = store.getters['memberId'];

      store.dispatch('leagues/setLeagueId', selectedLeague);
      router.push('/league/' + whom);
    }

    const subscriptionFormIsVisible = computed(function() {
      return subscriptionForm.value;
    });

    function setSubscriptionFormIsVisible(value) {
      subscriptionForm.value = value;
    }

    async function saveSubscription(formData) {
      isUpdating.value = true;

      try {
        const leagueId = store.getters.hasLeague;
        const memberId = store.getters['memberId'];

        store
          .dispatch('leagues/subscribeToLeague', {
            leagueId: leagueId,
            memberId: memberId,
            remarks: formData.remarks
          })
          .then(() => {
            store.dispatch('setSubscribedForTheLeague', leagueId);
          });
      } catch (err) {
        console.error(this.error);
      }

      isUpdating.value = false;
    }

    const teamCompositionModuleActive = computed(function() {
      // console.log(store.getters.teamCompositionModuleActive);
      return store.getters.teamCompositionModuleActive == 1 ? true : false;
    });

    const isTableTennis = computed(function() {
      return store.getters.isTableTennis;
    });

    if (store.getters.teamCompositionModuleActive == 1) {
      loadWeekDigests();
    }

    const memberId = computed(function() {
      const memberId = store.getters['memberId'];
      return memberId;
    });

    const resultRoute = computed(function() {
      const memberId = store.getters['memberId'];
      return '/results/' + memberId;
    });

    // Close navigation
    if (store.getters.isNavOpen) {
      store.dispatch('toggleNav');
    }

    return {
      loggedIn,
      appName,
      appDescription,
      passwordUrl,
      trainerId,
      hasSportsCamp,
      hasPlanning,
      planningInfo,
      teamCompositionModuleActive,
      weekDigest,
      memberId,
      resultRoute,
      hasLeague,
      isSubscribedForTheLeague,
      subscriptionFormIsVisible,
      setSubscriptionFormIsVisible,
      saveSubscription,
      leagues,
      setSelectedLeague,
      isUpdating,
      isTableTennis
    };
  }
};
</script>

<style scoped>
.leagueselection {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.leaguesubscription {
  margin-top: 1rem;
  margin-bottom: 1rem;
  font-size: 14px;
  font-style: italic;
  cursor: pointer;
}

li {
  list-style: none;
  margin: 0;
  padding: 0;
}

h3 {
  width: 100%;
  text-align: left;
  font-size: 16px;
  border-bottom: 1px solid #aaa;
  line-height: 0.8em;
  margin: 10px 0 15px;
  color: #aaa;
}

h3 span {
  background: #fff;
  padding: 0 10px;
}
.teamtitle {
  font-size: 22px;
}

.teamposition {
  font-size: 18px;
}

.teamcomposition {
  font-size: 14px;
  font-style: italic;
}

.trainerpresences {
  display: block;
  width: 100%;
  text-decoration: none;
  font: inherit;
  background-color: var(--var-button-background);
  color: var(--var-font-color);
  cursor: pointer;
  border-radius: 0px;
  font-size: 24px;
  font-weight: bold;
}
</style>
