<template>
  <li>
    <h3>
      <img
        v-bind:src="'data:image/gif;base64,' + photoAsBase64"
        height="100"
        v-if="photoPresent"
      />
      {{ description }}
    </h3>

    <h6>{{ camp }}</h6>
    <div class="details">Beschikbaar: {{ available }} / {{ stock }}</div>
    <div v-html="details" class="details"></div>
  </li>
</template>

<script>
export default {
  props: [
    'id',
    'description',
    'camp',
    'details',
    'stock',
    'reserved',
    'available',
    'photo'
  ],
  computed: {
    photoAsBase64() {
      return this.photo;
    },
    photoPresent() {
      return this.photo;
    }
  }
};
</script>

<style scoped>
li {
  margin: 1rem 0;
  border: 1px solid #424242;
  border-radius: 12px;
  padding: 1rem;
}

h3 {
  font-size: 1.5rem;
  padding-bottom: 1rem;
}

h3,
div {
  margin: 0rem 0;
}

.details {
  font-size: 14px;
}
</style>
