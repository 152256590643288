<template>
  <div>
    <li @click="setPresence">
      <span class="present"
        ><i class="fas fa-user-check" v-if="isPresent"></i
      ></span>
      <span class="notpresent"
        ><i class="fas fa-user-slash" v-if="!isPresent"></i
      ></span>
      <span v-if="isOccasional"
        >&nbsp;
        <i class="fas fa-user-plus"></i>
      </span>
      &nbsp;{{ membername }}
    </li>
  </div>
</template>

<script>
export default {
  props: ['id', 'memberid', 'membername', 'presence', 'occasional'],
  emits: ['set-presence'],
  data() {
    return {
      present: false,
      occasionalPlayer: false
    };
  },
  computed: {
    isPresent() {
      return this.present;
    },
    isOccasional() {
      return this.occasionalPlayer;
    }
  },
  methods: {
    setPresence() {
      this.present = !this.present;
      this.$emit('set-presence', {
        memberId: this.memberid,
        present: this.present
      });
    }
  },
  mounted() {
    if (this.presence == '1') {
      this.present = true;
    }
    if (this.occasional == '1') {
      this.occasionalPlayer = true;
    }
  }
};
</script>

<style scoped>
li {
  list-style: none;
  font-size: 32px;
  font-weight: bold;
  margin: 0.5rem 0;
  border: 1px solid var(--var-font-color);
  border-radius: 12px;
  padding: 1rem;
  cursor: pointer;
}

.present {
  color: var(--var-selected);
}

.notpresent {
  color: var(--var-unselected);
}
</style>
