<template>
  <base-popup
    :show="canBeCancelledFormVisible"
    title="Vervanging ongedaan maken"
    awesomeicon="far fa-undo"
  >
    <template v-slot:default>
      <p>Deze spelersvervanging ongedaan maken?</p>
    </template>
    <template v-slot:actions>
      <base-button @click="registerCancellation" mode="smallbold"
        >Vervanging ongedaan maken</base-button
      >
      <base-button @click="hideCancelForm" mode="smallbold">Sluit</base-button>
    </template>
  </base-popup>
  <div>
    <section>
      <div>
        <div class="playerselection">
          <div class="inputlabel">
            Te vervangen speler
          </div>
          <multiselect
            v-model="selectedPlayer"
            :options="playerSelection"
            :searchable="true"
            placeholder="Selecteer speler"
            ref="selectedPlayer"
            @change="setSelectedPlayer"
          ></multiselect>
        </div>
        <hr v-if="selectedPlayer" />
        <div v-if="selectedPlayer" class="replacement">
          <div class="inputlabel">Werd vervangen door</div>
          <multiselect
            v-model="replacedBy"
            :options="memberSelection"
            :searchable="true"
            placeholder="Selecteer vervanger"
            ref="replacedBy"
            @change="setReplacedBy"
          ></multiselect>
        </div>
        <div v-if="selectedPlayer" class="replacement">
          <div class="inputlabel">Reden</div>
          <multiselect
            v-model="reason"
            :options="reasonSelection"
            :searchable="true"
            placeholder="Reden vervanging"
            ref="reason"
            @change="setReason"
          ></multiselect>
        </div>
        <div v-if="selectedPlayer" class="remarks">
          <div class="inputlabel">Opmerkingen</div>
          <div class="inputfield">
            <textarea id="remarks" v-model.trim="remarks" rows="3" />
          </div>
        </div>
        <div
          v-if="selectedPlayer && unsubscriptionPossible"
          class="form-check form-switch check"
        >
          <label for="signOutFromLeague" class="checkboxtext"
            ><strong
              >Vervangen speler geeft aan niet verder deel te (kunnen) nemen aan
              de League</strong
            ></label
          >
          <input
            class="form-check-input"
            type="checkbox"
            id="signOutFromLeague"
            :checked="signOutFromLeague"
            @change="setSignOutFromLeague"
          />
          <div v-if="signOutFromLeague" class="signedout">
            <i class="fas fa-exclamation-triangle"></i>&nbsp;Dit wordt zo
            doorgegeven aan de Leagueverantwoordelijke! De speler wordt door dit
            aan te geven definitief uitgeschreven en wordt in de volgende ronde
            vervangen door een speler vanop de wachtlijst!
          </div>
        </div>
      </div>
      <div>
        <hr />
        <p>
          <i class="fas fa-exclamation-triangle"></i>&nbsp; OPGELET: Spelers die
          werden vervangen komen onderaan het klassement van de poule! Indien er
          meerdere vervangingen zijn binnen eenzelfde poule zal loting beslissen
          wie van de vervangde deelnemers daalt.
        </p>
      </div>
      <menu>
        <base-button
          @click="showCancelForm"
          mode="smallbold"
          v-if="canBeCancelled"
          ><i class="fas fa-undo"></i>&nbsp;Maak deze vervanging
          ongedaan</base-button
        >
        <base-button @click="registerReplacement" mode="smallbold"
          ><i class="far fa-save"></i>&nbsp;Registreer</base-button
        >
        <base-button @click="closeForm" mode="smallbold">Sluit</base-button>
      </menu>
    </section>
  </div>
</template>

<script>
import Multiselect from '@vueform/multiselect';

export default {
  components: { Multiselect },
  props: ['id'],
  emits: ['close-replacement', 'register-replacement'],
  data() {
    return {
      selectedPlayer: null,
      players: [],
      replacedBy: null,
      reason: null,
      signOutFromLeague: false,
      remarks: null,
      canBeCancelledFormVisible: false,
      canBeCancelled: false
    };
  },
  computed: {
    playerSelection() {
      const players = [];

      for (let i = 0; i < this.players.length; i++) {
        const player = {
          value: this.players[i].poulePlayerId,
          label: this.players[i].memberName
        };

        players.push(player);
      }

      return players;
    },
    reasonSelection() {
      const reasonsStore = this.$store.getters['members/absenceReasons'];

      const reasons = [];
      for (let i = 0; i < reasonsStore.length; i++) {
        const reason = {
          value: reasonsStore[i].id,
          label: reasonsStore[i].description
        };

        reasons.push(reason);
      }

      return reasons;
    },
    memberSelection() {
      const membersStore = this.$store.getters['members/members'];

      const members = [];
      for (let i = 0; i < membersStore.length; i++) {
        const member = {
          value: membersStore[i].memberId,
          label: membersStore[i].memberName
        };

        members.push(member);
      }

      return members;
    },
    unsubscriptionPossible() {
      const canUnsubscribeFromLeague = this.$store.getters[
        'canUnsubscribeFromLeague'
      ];

      return canUnsubscribeFromLeague;
    }
  },
  methods: {
    async loadAbsenceReasons() {
      this.isLoading = true;

      try {
        await this.$store.dispatch('members/loadAbsenceReasons');
      } catch (err) {
        this.error =
          err.message ||
          'Er trad een probleem op bij het ophalen van de afwezigheidsredenen!';
      }

      this.isLoading = false;
    },
    async loadMembers() {
      this.isLoading = true;

      try {
        await this.$store.dispatch('members/loadMembers', {
          forceRefresh: false
        });
      } catch (err) {
        this.error =
          err.message ||
          'Er trad een probleem op bij het ophalen van de ledenfiches!';
      }

      this.isLoading = false;
    },
    async loadPouleInformation() {
      this.isLoading = true;

      try {
        await this.$store
          .dispatch('leagues/loadPouleInformation', { pouleId: this.id })
          .then(() => {
            this.players = this.$store.getters['leagues/poulePlayers'];
          });
      } catch (err) {
        this.error =
          err.message ||
          'Er trad een probleem op bij het ophalen van de poule-informatie!';
      }

      this.isLoading = false;
    },
    setSelectedPlayer(selectedPlayer) {
      this.selectedPlayer = selectedPlayer;

      for (let i = 0; i < this.players.length; i++) {
        if (selectedPlayer == this.players[i].poulePlayerId) {
          // console.log(this.players[i]);

          this.replacedBy = this.players[i].replacementId;
          this.reason = this.players[i].absenceReasonId;
          this.remarks = this.players[i].poulePlayerRemarks;
          this.signOutFromLeague = this.players[i].status == 9 ? true : false;

          this.canBeCancelled = !!this.players[i].replacementId;
        }
      }
    },
    setReplacedBy(selectedMember) {
      this.replacedBy = selectedMember;
    },
    setReason(selectedReason) {
      this.reason = selectedReason;
    },
    setSignOutFromLeague(event) {
      this.signOutFromLeague = event.target.checked;
    },
    closeForm() {
      this.$emit('close-replacement');
    },
    showCancelForm() {
      this.canBeCancelledFormVisible = true;
    },
    hideCancelForm() {
      this.canBeCancelledFormVisible = false;
    },
    registerReplacement() {
      if (this.selectedPlayer && this.replacedBy) {
        const formData = {
          poulePlayerId: this.selectedPlayer,
          replace: 1,
          replacedBy: this.replacedBy,
          reasonId: this.reason,
          remarks: this.remarks,
          signOutFromLeague: this.signOutFromLeague
        };

        this.$emit('register-replacement', formData);
      }
    },
    registerCancellation() {
      if (this.selectedPlayer) {
        const formData = {
          poulePlayerId: this.selectedPlayer,
          replace: 0,
          replacedBy: null,
          reasonId: null,
          remarks: null,
          signOutFromLeague: false
        };

        this.$emit('register-replacement', formData);
      }
    }
  },
  mounted() {
    this.loadPouleInformation();
    this.loadMembers();
    this.loadAbsenceReasons();
  }
};
</script>

<style src="@vueform/multiselect/themes/default.css"></style>
<style>
:root {
  --ms-font-size: 0.9rem;
  --ms-line-height: 0.85;

  --ms-option-bg-selected: #4dabf7;
  --ms-option-bg-selected-pointed: #4dabf7;
  --ms-option-font-size: 0.9rem;
}
</style>

<style scoped>
textarea {
  width: 100%;
  border-color: lightgray;
}
.inputlabel {
  width: 150px;
  font-size: 16px;
  font-weight: bold;
  padding-top: 5px;
}

.inputfield {
  width: 100%;
  display: flex;
}
.playerselection {
  display: flex;
  align-items: center;
  gap: 10px;
}
.replacement {
  display: flex;
  align-items: center;
  gap: 10px;
}
.remarks {
  display: flex;
  align-items: center;
  gap: 10px;
  font-size: 16px;
  padding-top: 10px;
}

.check {
  align-items: center;
  gap: 10px;
  font-size: 16px;
  padding-top: 10px;
}

.signedout {
  font-size: 12px;
  font-style: italic;
  color: var(--var-warning);
}

menu {
  padding-top: 1rem;
  display: flex;
  justify-content: flex-end;
  margin: 0;
}
</style>
