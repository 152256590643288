const trainingMomentObject = function(data) {
  return {
    id: +data.id,
    eventId: +data.id,
    trainingId: +data.training_id,
    trainingPlanId: +data.trainingplan_id,
    startTime: data.starttime,
    endTime: data.endtime,
    title: data.title,
    moment: data.moment,
    calendarTitle: data.calendartitle,
    trainerId: +data.trainer_id,
    nrOfAssistents: +data.nrofassistents,
    assistentId: +data.assistent_id,
    assistent2Id: +data.assistent2_id,
    assistent3Id: +data.assistent3_id,
    assistent4Id: +data.assistent4_id,
    assistent5Id: +data.assistent5_id,
    description: data.description,
    eventColor: data.event_color,
    trainingStatus: +data.trainingstatus,
    trainingStatusDescription: data.trainingstatusdescription,
    trainingMergeId: data.trainingmerge_id,
    trainingRemark: data.trainingremark,
    toBeConfirmed: +data.tobeconfirmed
  };
};

const traineeTrainingMomentObject = function(data) {
  return {
    id: +data.id,
    eventId: +data.id,
    trainingId: +data.training_id,
    trainingPlanId: +data.trainingplan_id,
    startTime: data.starttime,
    endTime: data.endtime,
    title: data.title,
    moment: data.moment,
    calendarTitle: data.calendartitle,
    description: data.description,
    reserved: +data.reserved,
    presence: +data.presence,
    trainingStatus: +data.trainingstatus,
    trainingStatusDescription: data.trainingstatusdescription,
    remark: data.remark
  };
};

export default {
  async loadTrainers(context) {
    const trainers = context.getters['trainers'];
    if (trainers.length === 0) {
      const url = context.rootState.apiUrl + 'trainer_list.php';

      const token = context.rootState.token;
      const response = await fetch(url, {
        method: 'POST',
        body: JSON.stringify({
          jwt: token
        })
      });

      const responseData = await response.json();
      // console.log(responseData);

      if (!response.ok) {
        const error = new Error(
          responseData.message || 'Fout bij het ophalen van de trainers!'
        );
        throw error;
      }

      const trainers = [];

      for (const key in responseData) {
        const trainer = {
          id: responseData[key].id,
          name: responseData[key].name,
          email: responseData[key].email
        };
        trainers.push(trainer);
      }

      // console.log(trainers);
      context.commit('setTrainers', trainers);
    }
  },
  async loadTrainingMoments(context) {
    const url = context.rootState.apiUrl + 'training_moment_list.php';

    const token = context.rootState.token;
    const response = await fetch(url, {
      method: 'POST',
      body: JSON.stringify({
        jwt: token
      })
    });

    const responseData = await response.json();
    // console.log(responseData);

    if (!response.ok) {
      const error = new Error(
        responseData.message || 'Fout bij het ophalen van de trainingsmomenten!'
      );
      throw error;
    }

    const trainingMoments = [];

    for (const key in responseData) {
      trainingMoments.push(trainingMomentObject(responseData[key]));
    }

    // console.log(trainingMoments);
    context.commit('setTrainingMoments', trainingMoments);
  },
  async loadMergeableMoments(context, payload) {
    const url = context.rootState.apiUrl + 'training_mergemoment_list.php';
    // console.log(payload);

    const token = context.rootState.token;
    const response = await fetch(url, {
      method: 'POST',
      body: JSON.stringify({
        jwt: token,
        training_id: payload.trainingId,
        event_id: payload.eventId
      })
    });

    const responseData = await response.json();
    // console.log(responseData);

    if (!response.ok) {
      const error = new Error(
        responseData.message ||
          'Fout bij het ophalen van de samenvoegbare trainingsmomenten!'
      );
      throw error;
    }

    const trainingMoments = [];

    for (const key in responseData) {
      trainingMoments.push(trainingMomentObject(responseData[key]));
    }

    // console.log(trainingMoments);
    context.commit('setMergeableMoments', trainingMoments);
  },
  async updateTraining(context, payload) {
    const url = context.rootState.apiUrl + 'training_update.php';
    // console.log(payload);

    const token = context.rootState.token;
    const response = await fetch(url, {
      method: 'POST',
      body: JSON.stringify({
        jwt: token,
        event_id: payload.eventId,
        status: payload.status,
        remark: payload.remark,
        ...(payload.trainerId !== undefined && {
          trainer_id: payload.trainerId
        }),
        ...(payload.assistentId !== undefined && {
          assistent_id: payload.assistentId
        }),
        ...(payload.assistent2Id !== undefined && {
          assistent2_id: payload.assistent2Id
        }),
        ...(payload.assistent3Id !== undefined && {
          assistent3_id: payload.assistent3Id
        }),
        ...(payload.assistent4Id !== undefined && {
          assistent4_id: payload.assistent4Id
        }),
        ...(payload.assistent5Id !== undefined && {
          assistent5_id: payload.assistent5Id
        }),
        ...(payload.trainingMergeId !== undefined && {
          trainingmerge_id: payload.trainingMergeId
        })
      })
    });

    const responseData = await response.json();
    // console.log(responseData);

    if (!response.ok) {
      const error = new Error(
        responseData.message ||
          'Fout bij het aanpassen van de trainingsgegevens!'
      );
      throw error;
    } else {
      // Update moment-item in array
      let trainingMoments = context.state.trainingMoments;
      trainingMoments = trainingMoments.map(moment => {
        if (moment.eventId == payload.eventId) {
          // console.log(moment);
          // console.log(responseData[0]);
          return trainingMomentObject(responseData[0]);
        } else {
          return moment;
        }
      });

      context.commit('setTrainingMoments', trainingMoments);
    }
  },
  async loadPresences(context, payload) {
    const url = context.rootState.apiUrl + 'training_presences_list.php';

    const token = context.rootState.token;
    const response = await fetch(url, {
      method: 'POST',
      body: JSON.stringify({
        jwt: token,
        event_id: payload.eventId
      })
    });

    const responseData = await response.json();
    // console.log(responseData);

    if (!response.ok) {
      const error = new Error(
        responseData.message || 'Fout bij het ophalen van de aanwezigheden!'
      );
      throw error;
    }

    const presences = [];

    for (const key in responseData) {
      const presence = {
        id: key,
        eventId: responseData[key].event_id,
        memberId: responseData[key].member_id,
        memberName: responseData[key].traineename,
        presence: +responseData[key].presence,
        trainingGroup: responseData[key].traininggroupshortcut,
        nrOfEventsAgo: +responseData[key].nrofeventsago
      };

      presences.push(presence);
    }

    // console.log(presences);
    context.commit('setPresences', presences);
  },
  clearPresences(context) {
    const presences = [];
    context.commit('setPresences', presences);
  },
  async updatePresence(context, payload) {
    const url = context.rootState.apiUrl + 'training_presence_update.php';

    const token = context.rootState.token;
    const response = await fetch(url, {
      method: 'POST',
      body: JSON.stringify({
        jwt: token,
        event_id: payload.eventId,
        member_id: payload.memberId,
        presence: payload.presence
      })
    });

    const responseData = await response.json();
    // console.log(responseData);

    if (!response.ok) {
      const error = new Error(
        responseData.message || 'Fout bij het aanpassen van de aanwezigheid!'
      );
      throw error;
    } else {
      // Update presences-item in array
      const presences = context.state.presences;
      presences.forEach(pres => {
        if (pres.memberId === payload.memberId) {
          pres.presence = payload.presence;
        }
      });

      context.commit('setPresences', presences);
    }
  },
  async loadTraineeTrainingMoments(context, payload) {
    const url = context.rootState.apiUrl + 'training_traineemoment_list.php';

    const token = context.rootState.token;
    const response = await fetch(url, {
      method: 'POST',
      body: JSON.stringify({
        jwt: token,
        trainee_id: payload.traineeId
      })
    });

    const responseData = await response.json();
    // console.log(responseData);

    if (!response.ok) {
      const error = new Error(
        responseData.message || 'Fout bij het ophalen van de trainingsmomenten!'
      );
      throw error;
    }

    const traineeTrainingMoments = [];

    for (const key in responseData) {
      traineeTrainingMoments.push(
        traineeTrainingMomentObject(responseData[key])
      );
    }

    // console.log(traineeTrainingMoments);
    context.commit('setTraineeTrainingMoments', traineeTrainingMoments);
  },
  async updateReserved(context, payload) {
    const url = context.rootState.apiUrl + 'training_reserved_update.php';

    // console.log(payload);

    const token = context.rootState.token;
    const response = await fetch(url, {
      method: 'POST',
      body: JSON.stringify({
        jwt: token,
        event_id: payload.eventId,
        member_id: payload.memberId,
        reserved: payload.reserved,
        ...(payload.remark !== undefined && {
          remark: payload.remark
        })
      })
    });

    const responseData = await response.json();
    // console.log(responseData);

    if (!response.ok) {
      const error = new Error(
        responseData.message ||
          'Fout bij het doorvoeren van de reservatie/annulatie!'
      );
      throw error;
    } else {
      // Update presences-item in array
      const traineeTrainingMoments = context.state.traineeTrainingMoments;
      traineeTrainingMoments.forEach(pres => {
        if (pres.eventId === payload.eventId) {
          pres.reserved = payload.reserved;
          if (payload.remark !== undefined) {
            pres.remark = payload.remark;
          }
        }
      });

      context.commit('setTraineeTrainingMoments', traineeTrainingMoments);
    }
  }
};
