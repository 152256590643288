export default {
  trainers(state) {
    return state.trainers;
  },
  hasTrainingMoments(state) {
    return state.trainingMoments && state.trainingMoments.length > 0;
  },
  trainingMoments(state) {
    return state.trainingMoments;
  },
  mergeableMoments(state) {
    return state.mergeableMoments;
  },
  presences(state) {
    return state.presences;
  },
  traineeTrainingMoments(state) {
    return state.traineeTrainingMoments;
  }
};
