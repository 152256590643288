<template>
  <button v-if="!link" :class="mode">
    <slot></slot>
  </button>
  <router-link v-else :to="to" :class="mode">
    <slot></slot>
  </router-link>
</template>

<script>
export default {
  props: {
    mode: {
      type: String,
      required: false,
      default: null
    },
    link: {
      type: Boolean,
      required: false,
      default: false
    },
    to: {
      type: String,
      required: false,
      default: '/'
    }
  },
  data() {
    return {
      textColor: 'red'
    };
  }
};
</script>

<style scoped>
button,
a {
  text-decoration: none;
  padding: 0.75rem 1.5rem;
  background-color: var(--var-button-background);
  border: 1px solid var(--var-font-color);
  color: var(--var-font-color);
  cursor: pointer;
  border-radius: 0px;
  margin-right: 0.5rem;
  display: inline-block;
  font-size: 32px;
  font-weight: bold;
}

a:hover,
a:active,
button:hover,
button:active {
  background-color: var(--var-button-background-focused);
  border-color: var(--var-button-background);
  border: 1px solid var(--var-font-color);
}

.flat {
  font: inherit;
  background-color: var(--var-button-background);
  color: var(--var-font-color);
  border: 1px solid var(--var-font-color);
  border-radius: 0px;
}

.small {
  font: inherit;
  background-color: var(--var-button-background);
  color: var(--var-font-color);
  padding: 0.25rem 0.5rem;
  border: 0.3px solid var(--var-font-color);
  border-radius: 0px;
  font-size: 18px;
  font-weight: normal;
}

.smallbold {
  font: inherit;
  background-color: var(--var-button-background);
  color: var(--var-font-color);
  padding: 0.25rem 0.5rem;
  border: 0.3px solid var(--var-font-color);
  border-radius: 0px;
  font-size: 18px;
  font-weight: bold;
}

.badge,
.badge:hover {
  color: var(--var-font-color);
  padding: 0.5rem 1.5rem;
  border: 0px;
  border-radius: 30px;
  display: inline-block;
  font-size: 16px;
  font-weight: bold;
  margin-right: 0.5rem;
}

.outline {
  font: inherit;
  background-color: var(--var-button-background);
  border: 1px solid var(--var-font-color);
  color: var(--var-font-color);
}

.flat:hover,
.flat:active,
.outline:hover,
.outline:active {
  background-color: var(--var-button-background-focused);
}
</style>
