import { createRouter, createWebHistory } from 'vue-router';

import HomePage from './pages/HomePage.vue';
import NotFound from './pages/NotFound.vue';
import LoginPage from './pages/auth/login.vue';
import LogoutPage from './pages/auth/logout.vue';
import TeamList from './pages/teams/TeamList.vue';
import MemberList from './pages/members/MemberList.vue';
import Gdpr from './pages/members/Gdpr.vue';
import PersonalInformation from './pages/members/PersonalInformation.vue';
import FrenoyResults from './pages/members/FrenoyResults.vue';
import Statistics from './pages/statistics/Statistics.vue';
import WeekDigest from './pages/statistics/WeekDigest.vue';
import PresencesList from './pages/presences/PresenceList.vue';
import ReservationEntry from './pages/reservations/ReservationEntry.vue';
import ReservationList from './pages/reservations/ReservationList.vue';
import ReservationDone from './pages/reservations/ReservationDone.vue';
import SterktelijstList from './pages/sterktelijsten/SterktelijstList.vue';
import TrainerPresenceList from './pages/sportscamps/TrainerPresenceList.vue';
import TrainingMomentList from './pages/training/TrainingMomentList.vue';
import TrainingPresenceList from './pages/training/TrainingPresenceList.vue';
import TraineeTrainingMomentList from './pages/training/TraineeTrainingMomentList.vue';
import SubscriptionList from './pages/sportscamps/SubscriptionList.vue';
import SportsCampPlanningList from './pages/sportscamps/SportsCampPlanningList.vue';
import SportsCampCheckInList from './pages/sportscamps/SportsCampCheckInList.vue';
import SportsCampCheckOutList from './pages/sportscamps/SportsCampCheckOutList.vue';
import SportsCampTrainerPresence from './pages/sportscamps/SportsCampCheckOutList.vue';
import MaterialList from './pages/sportscamps/MaterialList.vue';
import LeaguePage from './pages/leagues/LeaguePage.vue';
import LeagueSubscriptionList from './pages/leagues/LeagueSubscriptionList.vue';
import LeagueRegulations from './pages/leagues/LeagueRegulations.vue';
import store from './store/index.js';

const router = createRouter({
  history: createWebHistory(),
  routes: [
    { path: '/', component: HomePage },
    { path: '/home', component: HomePage },
    { path: '/login', component: LoginPage },
    { path: '/logout', component: LogoutPage },
    { path: '/teams', component: TeamList, meta: { requiresAuth: true } },
    {
      path: '/members/:which',
      component: MemberList,
      meta: { requiresAuth: true }
    },
    { path: '/gdpr', component: Gdpr },
    { path: '/personalinformation', component: PersonalInformation },
    { path: '/results/:refresh', component: FrenoyResults },
    { path: '/statistics', component: Statistics },
    { path: '/weekdigest', component: WeekDigest },
    {
      path: '/presences',
      component: PresencesList,
      meta: { requiresAuth: true }
    },
    {
      path: '/reservation',
      component: ReservationEntry,
      meta: { requiresAuth: true }
    },
    {
      path: '/reservations',
      component: ReservationList,
      meta: { requiresAuth: true }
    },
    { path: '/reserved', component: ReservationDone },
    {
      path: '/sterktelijsten',
      component: SterktelijstList,
      meta: { requiresAuth: true }
    },
    {
      path: '/trainingmoments/:status',
      component: TrainingMomentList,
      meta: { requiresAuth: true }
    },
    {
      path: '/trainingpresences/:id',
      component: TrainingPresenceList,
      meta: { requiresAuth: true }
    },
    {
      path: '/traineetrainingmoments/:status',
      component: TraineeTrainingMomentList,
      meta: { requiresAuth: true }
    },
    {
      path: '/subscriptions',
      component: SubscriptionList,
      meta: { requiresAuth: true }
    },
    {
      path: '/sportscampplanning',
      component: SportsCampPlanningList,
      meta: { requiresAuth: true }
    },
    {
      path: '/trainerpresence',
      component: TrainerPresenceList,
      meta: { requiresAuth: true }
    },
    {
      path: '/sportscampcheckin',
      component: SportsCampCheckInList,
      meta: { requiresAuth: true }
    },
    {
      path: '/sportscampcheckout',
      component: SportsCampCheckOutList,
      meta: { requiresAuth: true }
    },
    {
      path: '/sportscamptrainerpresence',
      component: SportsCampTrainerPresence,
      meta: { requiresAuth: true }
    },
    {
      path: '/materiallist',
      component: MaterialList,
      meta: { requiresAuth: true }
    },
    {
      path: '/league/:whom',
      component: LeaguePage,
      meta: { requiresAuth: true }
    },
    {
      path: '/leaguesubscriptions',
      component: LeagueSubscriptionList,
      meta: { requiresAuth: true }
    },
    {
      path: '/leagueregulations',
      component: LeagueRegulations,
      meta: { requiresAuth: true }
    },

    { path: '/mygymnetmobile', component: HomePage },
    { path: '/ledenbeheer/mytabnetmobile', component: HomePage },
    { path: '/ledenbeheer/vttl/mytabnetmobile', component: HomePage },
    { path: '/ledenbeheer/sporta/mytabnetmobile', component: HomePage },
    { path: '/sporta/mytabnet', component: HomePage },
    { path: '/mytabnet', component: HomePage },
    { path: '/mytabnettest', component: HomePage },
    { path: '/mytabnetmobile', component: HomePage },
    { path: '/mytabnetmobile/vttl', component: HomePage },
    { path: '/mytabnetmobile/sporta', component: HomePage },
    { path: '/ledenbeheer/app', component: HomePage },

    { path: '/:notFound(.*)', component: NotFound }
  ]
});

router.beforeEach((to, from, next) => {
  // console.log(to, from);
  if (to.matched.some(route => route.meta.requiresAuth)) {
    if (store.state.loginId) {
      next();
    } else {
      next('/');
    }
  } else {
    next();
  }
});

export default router;
