export default {
  async loadPlayerMoments(context, payload) {
    const url = context.rootState.apiUrl + 'playermoment_list.php';
    let memberId = payload.memberId;
    if (!memberId || memberId == 0) {
      memberId = -1;
    }

    const token = context.rootState.token;
    const response = await fetch(url, {
      method: 'POST',
      body: JSON.stringify({
        jwt: token,
        member_id: memberId,
        limit: 5
      })
    });

    const responseData = await response.json();
    // console.log(responseData);

    if (!response.ok) {
      const error = new Error(
        responseData.message || 'Fout bij het ophalen van de speelmomenten!'
      );
      throw error;
    }

    const playerMoments = [];

    for (const key in responseData) {
      const playerMoment = {
        id: key,
        eventId: responseData[key].id,
        playDate: responseData[key].playdate,
        moment: responseData[key].description,
        responsableId: responseData[key].responsable_id,
        capacity: responseData[key].capacity,
        nrOfReservations: responseData[key].nrofreservations
      };

      playerMoments.push(playerMoment);
    }

    const showPlayerMomentsInTheFuture =
      context.rootState.showPlayerMomentsInTheFuture;
    if (showPlayerMomentsInTheFuture) {
      const response = await fetch(url, {
        method: 'POST',
        body: JSON.stringify({
          jwt: token,
          member_id: memberId,
          infuture: true,
          limit: 5
        })
      });

      const responseData = await response.json();
      // console.log(responseData);

      if (!response.ok) {
        const error = new Error(
          responseData.message ||
            'Fout bij ophalen van de (toekomstige) speelmomenten!'
        );
        throw error;
      }

      for (const key in responseData) {
        const playerMoment = {
          id: key,
          eventId: responseData[key].id,
          playDate: responseData[key].playdate,
          moment: responseData[key].description,
          responsableId: responseData[key].responsable_id,
          capacity: responseData[key].capacity,
          nrOfReservations: responseData[key].nrofreservations
        };

        playerMoments.push(playerMoment);
      }
    }

    context.commit('setPlayerMoments', playerMoments);
  },
  async loadPlayerMomentsForReservation(context) {
    let url = context.rootState.apiUrl + 'playermoment_list.php';

    const token = context.rootState.token;
    const response = await fetch(url, {
      method: 'POST',
      body: JSON.stringify({
        jwt: token,
        infuture: true,
        limit: 5
      })
    });

    const responseData = await response.json();
    // console.log(responseData);

    if (!response.ok) {
      const error = new Error(
        responseData.message || 'Fout bij ophalen van de speelmomenten!'
      );
      throw error;
    }

    const playerMoments = [];

    for (const key in responseData) {
      const playerMoment = {
        id: key,
        eventId: responseData[key].id,
        playDate: responseData[key].playdate,
        moment: responseData[key].description,
        responsableId: responseData[key].responsable_id,
        capacity: responseData[key].capacity,
        nrOfReservations: responseData[key].nrofreservations
      };

      playerMoments.push(playerMoment);
    }

    context.commit('setPlayerMoments', playerMoments);
  }
};
