<template>
  <div>
    <slot></slot>
    <base-dialog
      :show="!!error"
      title="Er trad een fout op!"
      @close="handleError"
    >
      <p>{{ error }}</p>
    </base-dialog>
    <div>
      <section>
        <base-card>
          <div v-if="isLoading || isUpdating">
            <base-spinner></base-spinner>
          </div>
          <div v-if="hasReservations">
            <reservation-item
              v-for="reservation in filteredReservations"
              :key="reservation.id"
              :eventid="reservation.eventId"
              :memberid="reservation.memberId"
              :membername="reservation.memberName"
              :description="reservation.description"
              :remark="reservation.remark"
              :reservationdate="reservation.reservationDate"
              @cancel-reservation="cancelReservation"
            ></reservation-item>
          </div>
          <base-button @click="addReservation" v-if="hasReservations"><i class="fas fa-plus"></i>&nbsp;Nog een speelmoment reserveren</base-button>
          <base-button @click="addReservation" v-else><i class="fas fa-plus"></i>&nbsp;Een speelmoment reserveren</base-button>
        </base-card>
      </section>
    </div>
  </div>
</template>

<script>
import ReservationItem from '../../components/reservations/ReservationItem.vue';

export default {
  components: {
    ReservationItem
  },
  data() {
    return {
      isLoading: false,
      isUpdating: false,
      error: null,
      eventId: 0,
      selectedMember: null
    };
  },
  computed: {
    hasReservations() {
      const ReservationsList = this.$store.getters['reservations/reservations'];

      return !this.isLoading && ReservationsList && ReservationsList.length > 0;
    },
    filteredReservations() {
      const ReservationsList = this.$store.getters['reservations/reservations'];

      return ReservationsList;
    }
  },
  methods: {
    async loadReservations() {
      this.isLoading = true;

      try {
        await this.$store.dispatch('reservations/loadReservations', {
          forceRefresh: false,
          memberIds: this.$store.getters['memberIds']
        });
      } catch (err) {
        this.error =
          err.message ||
          'Er trad een probleem op bij het ophalen van de reeds geboekte reservaties!';
      }

      this.isLoading = false;

      // Close navigation
      if (this.$store.getters.isNavOpen) {
        this.$store.dispatch('toggleNav');
      }
    },
    async cancelReservation(payload) {
      this.isUpdating = true;

      try {
        await this.$store.dispatch('reservations/cancelReservation', {
          eventId: payload.eventId,
          memberId: payload.memberId,
        });
      } catch (err) {
        this.error =
          err.message ||
          'Er trad een probleem op bij het annuleren van de reservatie!';
      }

      this.isUpdating = false;

      // Get list of reservations
      try {
        await this.$store.dispatch('reservations/loadReservations', {
          forceRefresh: true,
          memberIds: this.$store.getters['memberIds']
        });

        const reservations = this.$store.getters['reservations/reservations'];
        if (!reservations || reservations.length <= 0) {
          this.$router.push('reserved');
        }
      } catch (err) {
        this.error =
          err.message ||
          'Er trad een probleem op bij het ophalen van de gewijzigde reservatie(s)!';
      }
    },
    addReservation() {
      this.$router.push('reservation');
    },
    handleError() {
      this.error = null;
    }
  },
  created() {
    this.loadReservations();
  }
};
</script>

<style scoped>
ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.controls {
  display: flex;
  justify-content: space-between;
}
</style>
