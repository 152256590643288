export default {
  setReservations(state, payload) {
    state.reservations = payload;
  },
  setReservationRemark(state, payload) {
    state.reservationRemark = payload.remark;
  },
  setFetchTimestamp(state) {
    state.lastFetch = new Date().getTime();
  },
  resetFetchTimestamp(state) {
    state.lastFetch = null;
  }
};
