import mutations from './mutations.js';
import actions from './actions.js';
import getters from './getters.js';

export default {
  namespaced: true,
  state() {
    return {
      leagues: [],
      leagueId: null,
      description: null,
      dateFrom: null,
      dateTill: null,
      onlineSubscription: null,
      subscriptionDateFrom: null,
      subscriptionDateTill: null,
      maxNrOfSubscription: null,
      currentSerie: {
        id: null,
        dateFrom: null,
        dateTill: null,
        period: null
      },
      levels: [],
      series: [],
      poules: [],
      rankings: [],
      participations: [],
      lastSubscriptionFetch: null,
      subscriptions: [],
      playerResults: [],
      pouleInformation: {
        pouleId: null,
        levelId: null,
        level: null,
        levelCode: null,
        serieId: null,
        serie: null,
        serieDateFrom: null,
        serieDateTill: null,
        serieStatus: null,
        serieStatusDescription: null,
        poule: null,
        nrOfPlayers: null,
        pouleStatus: null,
        pouleStatusDescription: null
      },
      poulePlayers: [],
      pouleMatches: [],
      leagueStatistics: []
    };
  },
  mutations,
  actions,
  getters
};
