import { render } from "./TrainingDetailForm.vue?vue&type=template&id=94b45d02&scoped=true"
import script from "./TrainingDetailForm.vue?vue&type=script&lang=js"
export * from "./TrainingDetailForm.vue?vue&type=script&lang=js"

import "@vueform/multiselect/themes/default.css?vue&type=style&index=0&lang=css"
import "./TrainingDetailForm.vue?vue&type=style&index=1&id=94b45d02&lang=css"
import "./TrainingDetailForm.vue?vue&type=style&index=2&id=94b45d02&scoped=true&lang=css"
script.render = render
script.__scopeId = "data-v-94b45d02"

export default script