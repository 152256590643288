i<template>
  <base-popup
    :show="!!error"
    title="Fout/waarschuwing"
    awesomeicon="fas fa-exclamation-triangle"
    @close="handleErrorAndClose"
  >
    <p class="error">{{ error }}</p>
  </base-popup>
  <div v-if="isLoading">
    <base-spinner></base-spinner>
  </div>
  <base-button
    v-for="player in players"
    :key="player.id"
    @click="selectPlayer(player.id)"
    :class="selectedPlayerClass(player.id)"
    mode="badge"
  >
    <i class="fas fa-trophy"></i>&nbsp;
    {{ player.memberFirstName }}
  </base-button>
  <hr />
  <form @submit.prevent="closeForm">
    <div class="boxed results">
      <table cellspacing="0" cellpadding="0" border="0" width="700">
        <tr>
          <td width="50px"></td>
          <td width="110px" style="text-align:left">
            <b>Periode</b>
          </td>
          <td width="50px" style="text-align:center">
            <b>Poule</b>
          </td>
          <td width="200px" style="text-align:left"></td>
          <td width="25px"></td>
          <td width="200px" style="text-align:left"></td>
          <td width="60px" style="text-align:center">
            <b>Uitslag</b>
          </td>
          <td width="25px"></td>
        </tr>
        <tr
          v-for="selectedPlayerResult in selectedPlayerResults()"
          :key="selectedPlayerResult.id"
        >
          <td width="50px" style="text-align:center">
            <b>{{ selectedPlayerResult.matchIndex }}</b>
          </td>
          <td width="110px" style="text-align:left">
            <strong>({{ selectedPlayerResult.serie }})</strong>&nbsp;
            <small>
              <em>{{ selectedPlayerResult.period }}</em></small
            >
          </td>
          <td width="50px" style="text-align:center">
            {{ selectedPlayerResult.poule }}
          </td>
          <td
            width="200px"
            style="text-align:left"
            v-html="poulePlayers(selectedPlayerResult)"
          ></td>
          <td width="25px">vs</td>
          <td
            width="200px"
            style="text-align:left"
            v-html="counterPlayers(selectedPlayerResult)"
          ></td>
          <td width="60px" style="text-align:center">
            <strong>{{ selectedPlayerResult.games }}</strong>
          </td>
          <td
            width="20px"
            :class="
              resultClass(
                selectedPlayerResult.won,
                selectedPlayerResult.lost,
                selectedPlayerResult.draw
              )
            "
          >
            <div v-if="selectedPlayerResult.won == 1">
              <i class="far fa-smile"></i>
            </div>
            <div v-if="selectedPlayerResult.lost == 1">
              <i class="far fa-frown"></i>
            </div>
            <div v-if="selectedPlayerResult.draw == 1">
              <i class="far fa-meh"></i>
            </div>
          </td>
          <td width="25px" class="replacement">
            <i
              class="fas fa-exclamation-triangle showhint"
              v-if="isReplacement(selectedPlayerResult)"
            ></i>
          </td>
        </tr>
      </table>
    </div>
    <hr />
    <div class="buttons">
      <base-button @click="closeForm" mode="smallbold">Sluit</base-button>
    </div>
  </form>
</template>

<script>
export default {
  props: ['id'],
  emits: ['close-results'],
  data() {
    return {
      error: null,
      isLoading: false,
      players: [],
      selectedPlayer: null
    };
  },
  computed: {},
  methods: {
    handleError() {
      this.error = null;
    },
    handleErrorAndClose() {
      this.error = null;
      this.$emit('close-results');
    },
    selectedPlayerClass(id) {
      if (this.selectedPlayer == id) {
        return 'selected';
      }

      return 'notselected';
    },
    async selectPlayer(id) {
      this.selectedPlayer = id;
      // console.log(this.selectedPlayer);

      if (!this.players[this.selectedPlayer - 1].playerResults) {
        try {
          const leagueId = this.$store.getters['leagues/leagueId'];
          const memberId = this.players[this.selectedPlayer - 1].memberId;

          await this.$store
            .dispatch('leagues/loadPlayerResults', {
              leagueId: leagueId,
              memberId: memberId
            })
            .then(() => {
              const playerResults = this.$store.getters[
                'leagues/playerResults'
              ];
              // console.log(playerResults);

              this.players[this.selectedPlayer - 1] = {
                ...this.players[this.selectedPlayer - 1],
                playerResults
              };

              // console.log(this.players[this.selectedPlayer - 1]);
            });
        } catch (err) {
          this.error =
            err.message ||
            'Er trad een probleem op bij het opvragen van de spelersresultaten!';
        }
      }
    },
    selectedPlayerResults() {
      if (
        this.players &&
        this.selectedPlayer &&
        this.players.length >= this.selectedPlayer &&
        this.players[this.selectedPlayer - 1].playerResults
      ) {
        // console.log(this.players[this.selectedPlayer - 1].playerResults);
        return this.players[this.selectedPlayer - 1].playerResults;
      }

      return [];
    },
    poulePlayers(playerResult) {
      let result = '';

      if (playerResult.replacement1) {
        result = '<s><em>' + playerResult.player1 + '</s></em>';
      } else {
        result = '<strong>' + playerResult.player1 + '</strong>';
      }

      if (playerResult.player2) {
        if (playerResult.replacement2) {
          result = result + '/<s><em>' + playerResult.player2 + '</s></em>';
        } else {
          result = result + '/<strong>' + playerResult.player2 + '</strong>';
        }
      }

      if (playerResult.replacement1) {
        result =
          result +
          '<br>&#x21b9;&nbsp;<em><strong>' +
          playerResult.replacement1 +
          '</em></strong>';
      }
      if (playerResult.replacement2) {
        result =
          result +
          '<br>&#x21b9;&nbsp;<em><strong>' +
          playerResult.replacement2 +
          '</em></strong>';
      }

      return result;
    },
    counterPlayers(playerResult) {
      let result = '';

      if (playerResult.replacement3) {
        result = '<s><em>' + playerResult.player3 + '</s></em>';
      } else {
        result = '<strong>' + playerResult.player3 + '</strong>';
      }

      if (playerResult.player4) {
        if (playerResult.replacement4) {
          result = result + '/<s><em>' + playerResult.player4 + '</s></em>';
        } else {
          result = result + '/<strong>' + playerResult.player4 + '</strong>';
        }
      }

      if (playerResult.replacement3) {
        result =
          result +
          '<br>&#x21b9;&nbsp;<strong><em>' +
          playerResult.replacement3 +
          '</em></strong>';
      }
      if (playerResult.replacement4) {
        result =
          result +
          '<br>&#x21b9;&nbsp;<em><strong>' +
          playerResult.replacement4 +
          '</em></strong>';
      }

      return result;
    },
    resultClass(won, lost, draw) {
      if (won == 1) {
        return 'won';
      }

      if (lost == 1) {
        return 'lost';
      }

      if (draw == 1) {
        return 'draw';
      }

      return '';
    },
    isReplacement(playerResult) {
      return (
        !!playerResult.replacement1 ||
        !!playerResult.replacement2 ||
        !!playerResult.replacement3 ||
        !!playerResult.replacement4
      );
    },
    replacements(playerResult) {
      let repl = '';
      if (playerResult.replacement1) {
        repl = playerResult.player1 + ' &#x21b9; ' + playerResult.replacement1;
      }
      if (playerResult.replacement2) {
        if (repl) {
          repl = repl + '<br>';
        }
        repl =
          repl +
          playerResult.player2 +
          ' &#x21b9; ' +
          playerResult.replacement2;
      }
      if (playerResult.replacement3) {
        if (repl) {
          repl = repl + '<br>';
        }
        repl =
          repl +
          playerResult.player3 +
          ' &#x21b9; ' +
          playerResult.replacement3;
      }
      if (playerResult.replacement4) {
        if (repl) {
          repl = repl + '<br>';
        }
        repl =
          repl +
          playerResult.player4 +
          ' &#x21b9; ' +
          playerResult.replacement4;
      }
      return repl;
    },
    closeForm() {
      this.$emit('close-results');
    },
    async loadPoulePlayers() {
      this.isLoading = true;

      try {
        await this.$store
          .dispatch('leagues/loadPouleInformation', { pouleId: this.id })
          .then(() => {
            this.players = this.$store.getters['leagues/poulePlayers'];
            // console.log(this.players);
          })
          .then(() => {
            if (this.players && this.players.length > 0) {
              this.selectPlayer(1);
            }
          });
      } catch (err) {
        this.error =
          err.message ||
          'Er trad een probleem op bij het ophalen van de individuele resultaten!';
      }

      this.isLoading = false;
    }
  },
  mounted() {
    // Get individual results
    this.loadPoulePlayers();
  }
};
</script>

<style scoped>
form {
  height: 400px;
  font-size: 0.8rem;
}

.form-control {
  margin: 0.5rem 0;
}

label {
  font-weight: bold;
  display: block;
  margin-bottom: 0.5rem;
}

h3 {
  margin: 0.5rem 0;
  font-size: 1rem;
}

ul {
  list-style-type: none;
  padding-left: 0px;
}

.buttons {
  margin-top: 10px;
}

.error {
  color: var(--var-error);
  font-weight: bold;
}

.boxed {
  overflow: scroll;
  overflow-x: hidden;
  height: 300px;
  margin-left: 5px;
}

.results {
  font-size: 14px;
}

.selected {
  background-color: var(--var-button-background-focused);
}

.notselected {
  background-color: var(--var-button-background);
}
.won {
  color: var(--var-won);
  font-weight: bold;
}
.lost {
  color: var(--var-lost);
  font-weight: bold;
}
.draw {
  color: var(--var-draw);
  font-weight: bold;
}
.replacement {
  color: var(--var-warning);
  font-weight: bold;
}
</style>
