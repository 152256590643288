<template>
  <base-card>
    <h3>Zoek</h3>
    <base-search
      @search="updateSearch"
      :search-term="searchTerm"
    ></base-search>
  </base-card>
</template>

<script>
export default {
  emits: ['change-filter'],
  data() {
    return {
      searchTerm: ''
    };
  },
  methods: {
    updateSearch(updatedSearchTerm) {
      this.searchTerm = updatedSearchTerm;
      this.$emit('change-filter', this.searchTerm);
    }
  }
};
</script>

<style scoped>
h2 {
  margin: 0.5rem 0;
}

.checkboxtext {
  /* Checkbox text */
  font-size: 130%;
  display: inline;
  padding: 5px;
}
</style>
