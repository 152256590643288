export default {
  async loadLeagues(context) {
    let url = context.rootState.apiUrl + 'league_list.php';

    const token = context.rootState.token;
    const response = await fetch(url, {
      method: 'POST',
      body: JSON.stringify({
        jwt: token
      })
    });

    const responseData = await response.json();
    // console.log(responseData);

    if (!response.ok) {
      const error = new Error(
        responseData.message || 'Fout bij ophalen van de leagues!'
      );
      throw error;
    }

    const leagues = [];

    for (const key in responseData) {
      const league = {
        id: key,
        leagueId: responseData[key].id,
        description: responseData[key].description,
        information: responseData[key].information,
        dateFrom: responseData[key].dateFrom,
        dateTill: responseData[key].dateTill,
        subscriptionDateTill: responseData[key].subscriptionDateTill,
        maxNrOfSubscriptions: responseData[key].maxNrOfSubscriptions,
        status: responseData[key].status,
        onlineSubscriptions: responseData[key].onlineSubscriptions,
        resultsEntry: responseData[key].resultsEntry
      };

      leagues.push(league);
    }

    // console.log(leagues);

    context.commit('setLeagues', leagues);
  },

  setLeagueId(context, payload) {
    context.commit('setLeagueId', payload);
  },

  async loadLeagueInformation(context, payload) {
    let url = context.rootState.apiUrl + 'league_information.php';
    // console.log(payload);

    const token = context.rootState.token;
    const response = await fetch(url, {
      method: 'POST',
      body: JSON.stringify({
        jwt: token,
        leagueid: payload.leagueId,
        memberid: payload.memberId
      })
    });

    const responseData = await response.json();
    //console.log(responseData);

    if (!response.ok) {
      const error = new Error(
        responseData.message || 'Fout bij ophalen van de league-informatie!'
      );
      throw error;
    }

    const levels = [];

    const arrayOfLevels = responseData.levels;
    for (const key in arrayOfLevels) {
      const level = {
        id: key,
        levelId: arrayOfLevels[key].levelId,
        level: arrayOfLevels[key].level,
        description: arrayOfLevels[key].description,
        nrofpoules: arrayOfLevels[key].nrofpoules,
        nrofplayersperpoule: arrayOfLevels[key].nrofplayersperpoule
      };

      levels.push(level);
    }

    context.commit('setLevels', levels);

    const series = [];

    const arrayOfSeries = responseData.series;
    for (const key in arrayOfSeries) {
      const serie = {
        id: key,
        serieId: arrayOfSeries[key].serieId,
        serie: arrayOfSeries[key].serie,
        dateFrom: arrayOfSeries[key].dateFrom,
        dateTill: arrayOfSeries[key].dateTill,
        period: arrayOfSeries[key].period,
        status: arrayOfSeries[key].status,
        statusDescription: arrayOfSeries[key].statusDescription,
        toBePlayed: arrayOfSeries[key].toBePlayed,
        remarks: arrayOfSeries[key].remarks,
        isFirstSerie: arrayOfSeries[key].isFirstSerie,
        isLastSerie: arrayOfSeries[key].isLastSerie
      };

      series.push(serie);
    }

    // console.log(series);
    context.commit('setSeries', series);

    const participations = [];

    const arrayOfParticipations = responseData.participations;
    for (const key in arrayOfParticipations) {
      const participation = {
        id: key,
        serieId: arrayOfParticipations[key].serieId,
        serie: arrayOfParticipations[key].serie,
        period: arrayOfParticipations[key].period,
        serieStatus: arrayOfParticipations[key].serieStatus,
        level: arrayOfParticipations[key].level,
        levelId: arrayOfParticipations[key].levelId,
        levelCode: arrayOfParticipations[key].levelCode,
        pouleId: arrayOfParticipations[key].pouleId,
        poule: arrayOfParticipations[key].poule,
        pouleStatus: arrayOfParticipations[key].pouleStatus,
        pouleStatusDescription:
          arrayOfParticipations[key].pouleStatusDescription,
        poulePlayerId: arrayOfParticipations[key].poulePlayerId,
        poulePlayerIndex: arrayOfParticipations[key].poulePlayerIndex,
        subscriptionId: arrayOfParticipations[key].subscriptionId,
        poulePlayers: arrayOfParticipations[key].poulePlayers,
        pouleResults: arrayOfParticipations[key].pouleResults,
        pouleMatches: arrayOfParticipations[key].pouleMatches,
        poulePlayerStatus: arrayOfParticipations[key].poulePlayerStatus,
        seriePlayerStatus: arrayOfParticipations[key].seriePlayerStatus,
        unSubscribed:
          arrayOfParticipations[key].unSubscribed == 1 ? true : false
      };

      participations.push(participation);
    }

    // console.log(participations);

    context.commit('setParticipations', participations);
  },

  async loadLeagueSubscriptions(context, payload) {
    if (!payload.forceRefresh && !context.getters.shouldUpdateSubscriptions) {
      return;
    }

    let url = context.rootState.apiUrl + 'league_subscriptions.php';

    const token = context.rootState.token;
    const response = await fetch(url, {
      method: 'POST',
      body: JSON.stringify({
        jwt: token,
        leagueid: payload.leagueId
      })
    });

    const responseData = await response.json();
    // console.log(responseData);

    if (!response.ok) {
      const error = new Error(
        responseData.message || 'Fout bij ophalen van de inschrijvingsgegevens!'
      );
      throw error;
    }

    const subscriptions = [];

    for (const key in responseData) {
      const subscription = {
        id: key,
        subscriptionId: responseData[key].subscriptionId,
        memberId: responseData[key].memberId,
        memberName: responseData[key].memberName,
        gender: responseData[key].gender,
        address: responseData[key].address,
        email: responseData[key].email,
        telephone: responseData[key].telephone,
        mobile: responseData[key].mobile,
        birthdate: responseData[key].birthdate,
        ranking: responseData[key].ranking,
        actualLevel: responseData[key].actualLevel,
        actualPoule: responseData[key].actualPoule,
        subscriptionDate: responseData[key].subscriptionDate,
        status: responseData[key].status,
        statusDescription: responseData[key].statusDescription
      };

      subscriptions.push(subscription);
    }

    // console.log(subscriptions);

    context.commit('setSubscriptions', subscriptions);
  },

  async loadPoules(context, payload) {
    let url = context.rootState.apiUrl + 'league_seriepoules.php';

    const token = context.rootState.token;
    const response = await fetch(url, {
      method: 'POST',
      body: JSON.stringify({
        jwt: token,
        leagueserieid: payload.serieId
      })
    });

    const responseData = await response.json();
    // console.log(responseData);

    if (!response.ok) {
      const error = new Error(
        responseData.message || 'Fout bij ophalen van de poules!'
      );
      throw error;
    }

    const poules = [];

    for (const key in responseData) {
      const poule = {
        id: key,
        level: responseData[key].level,
        levelId: responseData[key].levelId,
        levelCode: responseData[key].levelCode,
        pouleId: responseData[key].pouleId,
        poule: responseData[key].poule,
        pouleStatus: responseData[key].pouleStatus,
        pouleStatusDescription: responseData[key].pouleStatusDescription,
        poulePlayerNames: responseData[key].poulePlayerNames,
        poulePlayers: responseData[key].poulePlayers,
        pouleResults: responseData[key].pouleResults,
        pouleMatches: responseData[key].pouleMatches,
        playDate: responseData[key].playDate,
        playTime: responseData[key].playTime,
        playTerrain: responseData[key].playTerrain,
        playRemarks: responseData[key].playRemarks,
        playInformation: responseData[key].playInformation
      };

      poules.push(poule);
    }

    // console.log(poules);

    context.commit('setPoules', poules);
  },

  async loadRankings(context, payload) {
    let url = context.rootState.apiUrl + 'league_serieranking.php';

    const token = context.rootState.token;
    const response = await fetch(url, {
      method: 'POST',
      body: JSON.stringify({
        jwt: token,
        leagueserieid: payload.serieId
      })
    });

    const responseData = await response.json();
    // console.log(responseData);

    if (!response.ok) {
      const error = new Error(
        responseData.message || 'Fout bij ophalen van de rangschikking!'
      );
      throw error;
    }

    const rankings = [];

    for (const key in responseData) {
      const ranking = {
        id: key,
        leagueSerieSubscriptionId: responseData[key].leagueSerieSubscriptionId,
        leagueSubscriptionId: responseData[key].leagueSubscriptionId,
        memberId: responseData[key].memberId,
        memberName: responseData[key].memberName,
        leagueLevelId: responseData[key].leagueLevelId,
        levelCode: responseData[key].levelCode,
        ranked: responseData[key].ranked,
        globalRanked: responseData[key].globalRanked,
        status: responseData[key].status,
        statusDescription: responseData[key].statusDescription,
        communication: responseData[key].communication,
        ranking: responseData[key].ranking,
        subscriptionDate: responseData[key].subscriptionDate,
        remarks: responseData[key].remarks
      };

      rankings.push(ranking);
    }

    // console.log(rankings);

    context.commit('setRankings', rankings);
  },

  async loadPlayerResults(context, payload) {
    let url = context.rootState.apiUrl + 'league_playerresults.php';
    const token = context.rootState.token;
    const response = await fetch(url, {
      method: 'POST',
      body: JSON.stringify({
        jwt: token,
        leagueid: payload.leagueId,
        memberid: payload.memberId
      })
    });

    const responseData = await response.json();
    // console.log(responseData);

    if (!response.ok) {
      const error = new Error(
        responseData.message || 'Fout bij ophalen van de spelersresultaten!'
      );
      throw error;
    }

    const results = [];
    let matchIndex = 0;

    for (const key in responseData) {
      matchIndex += 1;

      const result = {
        id: key,
        matchIndex: matchIndex,
        leaguePouleId: responseData[key].leaguePouleId,
        serieId: responseData[key].serieId,
        serie: responseData[key].serie,
        period: responseData[key].period,
        poule: responseData[key].poule,
        player1Id: responseData[key].player1Id,
        player1: responseData[key].player1,
        ranking1: responseData[key].ranking1,
        replacement1Id: responseData[key].replacement1Id,
        replacement1: responseData[key].replacement1,
        player2Id: responseData[key].player2Id,
        player2: responseData[key].player2,
        ranking2: responseData[key].ranking2,
        replacement2Id: responseData[key].replacement2Id,
        replacement2: responseData[key].replacement2,
        player3Id: responseData[key].player3Id,
        player3: responseData[key].player3,
        ranking3: responseData[key].ranking3,
        replacement3Id: responseData[key].replacement3Id,
        replacement3: responseData[key].replacement3,
        player4Id: responseData[key].player4Id,
        player4: responseData[key].player4,
        ranking4: responseData[key].ranking4,
        replacement4Id: responseData[key].replacement4Id,
        replacement4: responseData[key].replacement4,
        games1: responseData[key].games1,
        games2: responseData[key].games2,
        games: responseData[key].games,
        draw: responseData[key].draw,
        won: responseData[key].won,
        lost: responseData[key].lost,
        remarks: responseData[key].remarks
      };

      results.push(result);
    }

    // console.log(results);

    context.commit('setPlayerResults', results);
  },

  async loadPouleInformation(context, payload) {
    let url = context.rootState.apiUrl + 'league_pouleinformation.php';
    const token = context.rootState.token;
    const response = await fetch(url, {
      method: 'POST',
      body: JSON.stringify({
        jwt: token,
        pouleid: payload.pouleId
      })
    });

    const responseData = await response.json();
    // console.log(responseData);

    if (!response.ok) {
      const error = new Error(
        responseData.message || 'Fout bij ophalen van de poule-informatie!'
      );

      throw error;
    }

    const pouleInformation = {
      levelId: responseData.levelId,
      level: responseData.level,
      levelCode: responseData.levelCode,
      serieId: responseData.serieId,
      serie: responseData.serie,
      serieDateFrom: responseData.serieDateFrom,
      serieDateTill: responseData.serieDateTill,
      serieStatus: responseData.serieStatus,
      serieStatusDescription: responseData.serieStatusDescription,
      poule: responseData.poule,
      nrOfPlayers: responseData.nrOfPlayers,
      pouleStatus: responseData.pouleStatus,
      pouleStatusDescription: responseData.pouleStatusDescription
    };

    // console.log(pouleInformation);

    context.commit('setPouleInformation', pouleInformation);

    const players = [];

    const arrayOfPlayers = responseData.players;
    for (const key in arrayOfPlayers) {
      const player = {
        id: key,
        poulePlayerId: arrayOfPlayers[key].poulePlayerId,
        player: arrayOfPlayers[key].player,
        subscriptionId: arrayOfPlayers[key].subscriptionId,
        subscriptionDate: arrayOfPlayers[key].subscriptionDate,
        subscriptionStatus: arrayOfPlayers[key].subscriptionStatus,
        subscriptionStatusDescription:
          arrayOfPlayers[key].subscriptionStatusDescription,
        memberId: arrayOfPlayers[key].memberId,
        memberFirstName: arrayOfPlayers[key].memberFirstName,
        memberLastName: arrayOfPlayers[key].memberLastName,
        memberName: arrayOfPlayers[key].memberName,
        memberRanking: arrayOfPlayers[key].memberRanking,
        replacementId: arrayOfPlayers[key].replacementId,
        replacementName: arrayOfPlayers[key].replacementName,
        absenceReasonId: arrayOfPlayers[key].absenceReasonId,
        absenceReason: arrayOfPlayers[key].absenceReason,
        matchesWon: arrayOfPlayers[key].matchesWon,
        matchesLost: arrayOfPlayers[key].matchesLost,
        gamesWon: arrayOfPlayers[key].gamesWon,
        gamesLost: arrayOfPlayers[key].gamesLost,
        saldo: arrayOfPlayers[key].saldo,
        ranking: arrayOfPlayers[key].ranking,
        poulePlayerStatus: arrayOfPlayers[key].poulePlayerStatus,
        seriePlayerStatus: arrayOfPlayers[key].seriePlayerStatus,
        status: arrayOfPlayers[key].status,
        statusDescription: arrayOfPlayers[key].statusDescription,
        subscriptionRemarks: arrayOfPlayers[key].subscriptionRemarks,
        poulePlayerRemarks: arrayOfPlayers[key].poulePlayerRemarks
      };

      players.push(player);
    }

    // console.log(players);
    context.commit('setPoulePlayers', players);

    const matches = [];

    const arrayOfMatches = responseData.matches;
    for (const key in arrayOfMatches) {
      const match = {
        id: key,
        pouleGameId: arrayOfMatches[key].pouleGameId,
        matchIndex: arrayOfMatches[key].matchIndex,
        playerId1: arrayOfMatches[key].playerId1,
        playerName1: arrayOfMatches[key].playerName1,
        playerId2: arrayOfMatches[key].playerId2,
        playerName2: arrayOfMatches[key].playerName2,
        team1: arrayOfMatches[key].team1,
        playerId3: arrayOfMatches[key].playerId3,
        playerName3: arrayOfMatches[key].playerName3,
        playerId4: arrayOfMatches[key].playerId4,
        playerName4: arrayOfMatches[key].playerName4,
        team2: arrayOfMatches[key].team2,
        games1: arrayOfMatches[key].games1,
        games2: arrayOfMatches[key].games2,
        remarks: arrayOfMatches[key].remarks
      };

      matches.push(match);
    }

    // console.log(players);
    context.commit('setPouleMatches', matches);
  },

  async subscribeToLeague(context, payload) {
    let url = context.rootState.apiUrl + 'league_subscribe.php';

    const token = context.rootState.token;
    const response = await fetch(url, {
      method: 'POST',
      body: JSON.stringify({
        jwt: token,
        leagueid: payload.leagueId,
        memberid: payload.memberId,
        remarks: payload.remarks
      })
    });

    const responseData = await response.json();
    // console.log(responseData);

    if (!response.ok) {
      const error = new Error(
        responseData.message || 'Fout bij het doorsturen van je inschrijving!'
      );

      throw error;
    }
  },

  async sendPouleMail(context, payload) {
    let url = context.rootState.apiUrl + 'league_mailpoule.php';

    const token = context.rootState.token;
    const response = await fetch(url, {
      method: 'POST',
      body: JSON.stringify({
        jwt: token,
        pouleid: payload.pouleId,
        memberid: payload.memberId
      })
    });

    const responseData = await response.json();
    // console.log(responseData);

    if (!response.ok) {
      const error = new Error(
        responseData.message || 'Fout bij het uitsturen van het e-mailbericht!'
      );

      throw error;
    }
  },

  async replacePlayer(context, payload) {
    let url = context.rootState.apiUrl + 'league_replacement.php';

    // console.log(payload);
    const token = context.rootState.token;
    const quitsLeague = payload.signOutFromLeague ? 1 : 0;
    const response = await fetch(url, {
      method: 'POST',
      body: JSON.stringify({
        jwt: token,
        pouleplayerid: payload.poulePlayerId,
        replace: payload.replace,
        replacementid: payload.replacedBy,
        absencereasonid: payload.reasonId,
        remarks: payload.remarks,
        quitsleague: quitsLeague
      })
    });

    const responseData = await response.json();
    // console.log(responseData);

    if (!response.ok) {
      const error = new Error(
        responseData.message || 'Fout bij het registreren van deze vervanging!'
      );

      throw error;
    }
  },

  async registerScore(context, payload) {
    let url = context.rootState.apiUrl + 'league_poulescore.php';

    // console.log(payload);
    const token = context.rootState.token;
    const response = await fetch(url, {
      method: 'POST',
      body: JSON.stringify({
        jwt: token,
        pouleid: payload.pouleId,
        matches: payload.matches
      })
    });

    const responseData = await response.json();
    // console.log(responseData);

    if (!response.ok) {
      const error = new Error(
        responseData.message ||
          'Fout bij het registreren van de pouleresultaten!'
      );

      throw error;
    }
  },

  async registerPlayDate(context, payload) {
    let url = context.rootState.apiUrl + 'league_pouleplaydate.php';

    // console.log(payload);
    const token = context.rootState.token;
    const response = await fetch(url, {
      method: 'POST',
      body: JSON.stringify({
        jwt: token,
        pouleid: payload.pouleId,
        playdate: payload.playDate,
        playtime: payload.playTime,
        terrain: payload.terrain,
        remarks: payload.remarks
      })
    });

    const responseData = await response.json();
    // console.log(responseData);

    if (!response.ok) {
      const error = new Error(
        responseData.message ||
          'Fout bij het registreren van de geplande speeldatum!'
      );

      throw error;
    }
  },

  async unSubscribe(context, payload) {
    let url = context.rootState.apiUrl + 'league_unsubscribe.php';

    // console.log(payload);
    const token = context.rootState.token;
    const response = await fetch(url, {
      method: 'POST',
      body: JSON.stringify({
        jwt: token,
        subscriptionid: payload.subscriptionId,
        absencereasonid: payload.reasonId,
        remarks: payload.remarks
      })
    });

    const responseData = await response.json();
    // console.log(responseData);

    if (!response.ok) {
      const error = new Error(
        responseData.message || 'Fout bij het registreren van de uitschrijving!'
      );

      throw error;
    }
  },

  async undoUnSubscribe(context, payload) {
    let url = context.rootState.apiUrl + 'league_undounsubscribe.php';

    // console.log(payload);
    const token = context.rootState.token;
    const response = await fetch(url, {
      method: 'POST',
      body: JSON.stringify({
        jwt: token,
        subscriptionid: payload.subscriptionId
      })
    });

    const responseData = await response.json();
    // console.log(responseData);

    if (!response.ok) {
      const error = new Error(
        responseData.message || 'Fout bij het annuleren van je uitschrijving!'
      );

      throw error;
    }
  },

  async loadLeagueStatistics(context, payload) {
    let url = context.rootState.apiUrl + 'league_statistics.php';

    const token = context.rootState.token;
    const response = await fetch(url, {
      method: 'POST',
      body: JSON.stringify({
        jwt: token,
        leagueid: payload.leagueId
      })
    });

    const responseData = await response.json();
    console.log(responseData);

    if (!response.ok) {
      const error = new Error(
        responseData.message || 'Fout bij ophalen van de leaguestatistieken!'
      );
      throw error;
    }

    const leagueStatistics = [];

    for (const key in responseData) {
      const leagueStatistic = {
        id: key,
        serie: responseData[key].serie,
        period: responseData[key].period,
        nrOfMatches: responseData[key].nrOfMatches,
        nrOf60Matches: responseData[key].nrOf60Matches,
        nrOf61Matches: responseData[key].nrOf61Matches,
        nrOf62Matches: responseData[key].nrOf62Matches,
        nrOf63Matches: responseData[key].nrOf63Matches,
        nrOf64Matches: responseData[key].nrOf64Matches,
        nrOf75Matches: responseData[key].nrOf75Matches,
        nrOf76Matches: responseData[key].nrOf76Matches,
        nrOfReplacements: responseData[key].nrOfReplacements,
        nrOfUnFinishedMatches: responseData[key].nrOfUnFinishedMatches
      };

      leagueStatistics.push(leagueStatistic);
    }

    // console.log(leagues);

    context.commit('setLeagueStatistics', leagueStatistics);
  }
};
