<template>
  <section>
    <base-card>
      <h2>{{ appName }}</h2>
      <p>
        Je werd afgemeld.
      </p>
      <p v-if="!loggedIn">
        Klik <router-link to="/login" v-if="!loggedIn">hier</router-link> om je terug aan te melden.
      </p>
    </base-card>
  </section>
</template>

<script>
import { computed } from 'vue';
import { useStore } from 'vuex';

export default {
  setup() {
    const store= useStore();

    const loggedIn = computed(function() {
      return store.getters.loggedIn;
    })

    const appName = computed(function() {
      return store.getters.appName;
    });

    store.dispatch('logout');
    if (store.getters.isNavOpen) {
      store.dispatch('toggleNav');
    }

    return { 
        loggedIn,
        appName
    }
  },
}
</script>
