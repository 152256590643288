export default {
  hasWeekDigests(state) {
    return state.weekDigests && state.weekDigests.length > 0;
  },
  weekDigests(state) {
    return state.weekDigests;
  },
  hasRankings(state) {
    return state.rankings && state.rankings.length > 0;
  },
  rankings(state) {
    return state.rankings;
  },
  hasTeamPlayers(state) {
    return state.teamPlayers && state.teamPlayers.length > 0;
  },
  teamPlayers(state) {
    return state.teamPlayers;
  },
  hasTeamCalendars(state) {
    return state.teamCalendars && state.teamCalendars.length > 0;
  },
  teamCalendars(state) {
    return state.teamCalendars;
  },
  hasWeekResults(state) {
    return state.weekResults && state.weekResults.length > 0;
  },
  weekResults(state) {
    return state.weekResults;
  },
  hasTeamConfirmations(state) {
    return state.teamConfirmations && state.teamConfirmations.length > 0;
  },
  teamConfirmations(state) {
    return state.teamConfirmations;
  }
};
