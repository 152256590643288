<template>
  <form @submit.prevent="closeForm">
    <div class="form-control">
      <div class="info" v-if="trainingstatus >= 2">
        <i class="fas fa-exclamation-triangle"></i>&nbsp;Dit trainingsmoment kan
        niet meer geannuleerd worden!
      </div>
      <div class="info" v-else>
        <i class="fas fa-exclamation-triangle"></i>&nbsp;Ben je zeker dat dit
        trainingsmoment mag geannuleerd worden?
      </div>
      <label for="remark">Opmerking(en)</label>
      <textarea id="remark" v-model.trim="remark" rows="3" />
    </div>
    <div>
      <base-button @click="submitForm" mode="smallbold"
        >Trainingsmoment annuleren</base-button
      >
      <base-button @click="closeForm" mode="smallbold">Sluit</base-button>
    </div>
  </form>
</template>

<script>
export default {
  props: ['id', 'trainingstatus', 'training_remark'],
  emits: ['save-cancellation', 'close-cancellation'],
  data() {
    return {
      remark: ''
    };
  },
  methods: {
    closeForm() {
      this.$emit('close-cancellation');
    },
    submitForm(e) {
      e.preventDefault();
      if (confirm('Dit trainingsmoment annuleren?')) {
        const formData = {
          eventId: this.id,
          status: 9,
          remark: this.remark
        };

        this.$emit('save-cancellation', formData);
      }
    }
  },
  mounted() {
    this.remark = this.training_remark;
  }
};
</script>

<style scoped>
.form-control {
  margin: 0.5rem 0;
}

label {
  font-weight: bold;
  display: block;
  margin-bottom: 0.5rem;
}

input,
textarea {
  display: block;
  width: 100%;
  border: 1px solid var(--var-edit-bordercolor);
  font: inherit;
}

input:focus,
textarea:focus {
  background-color: var(--var-edit-color-focused);
  outline: none;
  border-color: var(--var-edit-bordercolor-focused);
}

.invalid label {
  color: red;
}

.invalid input,
.invalid textarea {
  border: 1px solid red;
}

.info {
  padding-bottom: 15px;
  font-style: italic;
}
</style>
