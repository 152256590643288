<template>
  <base-card>
    <div>
      <h3>{{ momentTitle }}</h3>
      <base-combo
        :options="momentsList"
        :default="defaultMoment"
        @input="selectMoment"
      >
      </base-combo>
    </div>
  </base-card>
</template>

<script>
export default {
  props: ['moments', 'title'],
  emits: ['set-moment'],
  computed: {
    momentsList() {
      if (this.moments) {
        return this.moments;
      }

      return [];
    },
    defaultMoment() {
      if (this.moments) {
        return this.moments[0];
      }

      return {id:0, name:'Selecteer ' + this.title.toLowerCase() };
    },
    momentTitle() {
      return this.title;
    }
  },
  methods: {
    selectMoment(selectedItem) {
      this.$emit('set-moment', selectedItem);
    }
  }
};
</script>
