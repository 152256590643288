<template>
  <div>
    <slot></slot>
    <base-popup
      :show="!!error"
      title="Er trad een fout op bij het ophalen van de trainingsmomenten!"
      @close="handleError"
    >
      <p>{{ error }}</p>
    </base-popup>
    <section v-if="!isLoading">
      <trainee-training-filter
        @change-filter="setFilter"
      ></trainee-training-filter>
    </section>
    <section>
      <base-card>
        <div v-if="isLoading">
          <base-spinner></base-spinner>
        </div>
        <ul v-else-if="hasTrainingMoments">
          <trainee-training-item
            v-for="trainingMoment in filteredTrainingMoments"
            :key="trainingMoment.id"
            :which="which"
            :eventid="trainingMoment.eventId"
            :title="trainingMoment.title"
            :moment="trainingMoment.moment"
            :starttime="trainingMoment.startTime"
            :endtime="trainingMoment.endTime"
            :trainingid="trainingMoment.trainingId"
            :description="trainingMoment.description"
            :trainingstatus="trainingMoment.trainingStatus"
            :trainingstatusdescription="
              trainingMoment.trainingStatusDescription
            "
            :presence="trainingMoment.presence"
            :reserved="trainingMoment.reserved"
            :remark="trainingMoment.remark"
            @set-reserved="setReserved"
          ></trainee-training-item>
        </ul>
        <h3 v-else>Geen trainingsmomenten</h3>
      </base-card>
    </section>
  </div>
</template>

<script>
import TraineeTrainingItem from '../../components/training/TraineeTrainingItem.vue';
import TraineeTrainingFilter from '../../components/training/TraineeTrainingFilter.vue';

export default {
  components: {
    TraineeTrainingItem,
    TraineeTrainingFilter
  },
  data() {
    return {
      which: 'open',
      isLoading: false,
      searchTerm: '',
      error: null
    };
  },
  computed: {
    filteredTrainingMoments() {
      const trainingMoments = this.$store.getters[
        'training/traineeTrainingMoments'
      ];

      // return trainingMoments;

      const currentDate = new Date();

      return trainingMoments.filter(trainingMoment => {
        const trainingDate = new Date(trainingMoment.endTime);
        if (this.which === 'reservation' && trainingDate < currentDate) {
          return false;
        }

        if (this.which === 'participated' && trainingDate > currentDate) {
          return false;
        }

        if (this.which === 'participated' && trainingMoment.presence != 1) {
          return false;
        }

        if (this.searchTerm) {
          return (
            trainingMoment.calendarTitle
              .toLowerCase()
              .includes(this.searchTerm.toLowerCase()) ||
            trainingMoment.description
              .toLowerCase()
              .includes(this.searchTerm.toLowerCase())
          );
        }

        return true;
      });
    },
    hasTrainingMoments() {
      const trainingMoments = this.filteredTrainingMoments;
      return !this.isLoading && trainingMoments.length > 0;
    }
  },
  methods: {
    async loadTraineeTrainingMoments() {
      this.isLoading = true;

      const traineeId = this.$store.getters['memberId'];

      try {
        await this.$store.dispatch('training/loadTraineeTrainingMoments', {
          traineeId: traineeId
        });
      } catch (err) {
        this.error =
          err.message ||
          'Er trad een probleem op bij het ophalen van de trainingsmomenten!';
      }

      this.isLoading = false;

      // Close navigation
      if (this.$store.getters.isNavOpen) {
        this.$store.dispatch('toggleNav');
      }
    },
    async setReserved(presenceData) {
      const traineeId = this.$store.getters['memberId'];
      try {
        await this.$store.dispatch('training/updateReserved', {
          eventId: presenceData.eventId,
          memberId: traineeId,
          reserved: presenceData.reserved,
          ...(presenceData.remark !== undefined && {
            remark: presenceData.remark
          })
        });
      } catch (err) {
        this.error =
          err.message ||
          'Er trad een probleem op bij het doorvoeren van de reservatie/annulatie!';
      }
    },
    setFilter(updatedSearchTerm) {
      this.searchTerm = updatedSearchTerm;
    },
    handleError() {
      this.error = null;
    }
  },
  created() {
    this.loadTraineeTrainingMoments();
  },
  mounted() {
    if (this.$route.params.status) {
      this.which = this.$route.params.status;
    }
  }
};
</script>

<style scoped>
ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.controls {
  display: flex;
  justify-content: space-between;
}
</style>
