export default {
  setAppConfig(state, payload) {
    state.appName = payload.title;
    state.appDescription = payload.description;
    state.apiUrl = payload.apiUrl;
    state.passwordUrl = payload.passwordUrl;
  },
  setUser(state, payload) {
    state.token = payload.token;
    state.clubName = payload.clubName;
    state.clubCode = payload.clubCode;
    state.association = payload.association;
    state.disciplines = payload.disciplines;
    state.loginId = payload.loginId;
    state.userId = payload.userId;
    state.firstName = payload.firstName;
    state.lastName = payload.lastName;
    state.email = payload.email;
    state.memberId = payload.memberId;
    state.memberIds = payload.memberIds;
    state.trainingModuleActive = payload.trainingModuleActive;
    state.sportsCampModuleActive = payload.sportsCampModuleActive;
    state.trainerId = payload.trainerId;
    state.trainerprofile = payload.trainerProfile;
    state.additionalTraineeSelection = payload.additionalTraineeSelection;
    state.frenoyUrl = payload.frenoyUrl;
    state.frenoyPublicUrl = payload.frenoyPublicUrl;
    state.compositionsNeedRelease = payload.compositionsNeedRelease;
    state.myTeams = payload.myTeams;
    state.playerMoments = payload.playerMoments;
    state.showPlayerMomentsInTheFuture = payload.showPlayerMomentsInTheFuture;
    state.hasSportsCamp = payload.hasSportsCamp;
    state.composeAsCaptain = payload.composeAsCaptain;
    state.composeAnyTeam = payload.composeAnyTeam;
    state.enterAnyLeagueResult = payload.enterAnyLeagueResult;
    state.homePage = payload.homePage;
    state.messageOfTheDay = payload.messageOfTheDay;
    state.teamCompositionModuleActive = payload.teamCompositionModuleActive;
    state.hasLeague = payload.hasLeague;
    state.leaguePlayerMomentInfo = payload.leaguePlayerMomentInfo;
    state.isSubscribedForTheLeague = payload.isSubscribedForTheLeague;
    state.canUnsubscribeFromLeague = payload.canUnsubscribeFromLeague;

    state.didAutoLogout = false;
  },
  setTeamConfirmationOptions(state, payload) {
    state.teamConfirmationOptions = payload;
  },
  setGdpr(state, payload) {
    state.gdprExplicitAllowance = payload.gdprExplicitAllowance;
    state.gdprMailings = payload.gdprMailings;
    state.gdprSmses = payload.gdprSmses;
    state.gdprBirthday = payload.gdprBirthday;
    state.gdprPhoto = payload.gdprPhoto;
  },
  setPersonalInformation(state, payload) {
    state.personalInformation = payload;
  },
  setFrenoyResults(state, payload) {
    state.frenoyResults = payload;
  },
  setMemberId(state, payload) {
    state.memberId = payload;
  },
  setAutoLogout(state) {
    state.didAutoLogout = true;
  },
  setIsNavOpen(state, payload) {
    state.isNavOpen = payload;
  },
  setMyMembers(state, payload) {
    state.myMembers = payload;
  },
  setSubscribedForTheLeague(state, payload) {
    state.isSubscribedForTheLeague = payload;
  },
  setTransportTypes(state, payload) {
    state.transportTypes = payload;
  }
};
