<template>
  <form @submit.prevent="closeForm">
    <div class="form-control">
      <label for="remarks">Heb je aan- of opmerkingen</label>
      <textarea id="remarks" v-model.trim="remarks" rows="3" />
    </div>
    <div>
      <base-button @click="submitForm" mode="smallbold"
        >Bevestig mijn inschrijving</base-button
      >
      <base-button @click="closeForm" mode="smallbold">Sluit</base-button>
    </div>
  </form>
</template>

<script>
export default {
  props: ['id'],
  emits: ['save-subscription', 'close-subscription-form'],
  data() {
    return {
      remarks: ''
    };
  },
  methods: {
    closeForm() {
      this.$emit('close-subscription-form');
    },
    submitForm() {
      // const currentMember = this.$store.getters['memberId'];
      const formData = {
        remarks: this.remarks
      };

      this.$emit('save-subscription', formData);
    }
  }
};
</script>

<style scoped>
.form-control {
  margin: 0.5rem 0;
}

label {
  font-weight: bold;
  display: block;
  margin-bottom: 0.5rem;
}

input[type='checkbox'] + label {
  font-weight: normal;
  display: inline;
  margin: 0 0 0 0.5rem;
}

input,
textarea {
  display: block;
  width: 100%;
  border: 1px solid var(--var-edit-bordercolor);
  font: inherit;
}

input:focus,
textarea:focus {
  background-color: var(--var-edit-color-focused);
  outline: none;
  border-color: var(--var-edit-bordercolor-focused);
}

input[type='checkbox'] {
  display: inline;
  width: auto;
  border: none;
}

input[type='checkbox']:focus {
  outline: var(--var-edit-bordercolor-focused) solid 1px;
}

h3 {
  margin: 0.5rem 0;
  font-size: 1rem;
}

.invalid label {
  color: red;
}

.invalid input,
.invalid textarea {
  border: 1px solid red;
}
</style>
