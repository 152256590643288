<template>
  <base-popup :show="!!error" title="Er trad een fout op!" @close="handleError">
    <p>{{ error }}</p>
  </base-popup>

  <!-- BEVESTIGEN -->
  <base-dialog
    :show="confirmationIsVisible"
    :fixed="true"
    :headerStyle="'background-color: var(--var-training-confirmed);'"
  >
    <template v-slot:header>
      <div class="title">
        <div class="titleicon">
          <h3><i class="fas fa-running"></i></h3>
        </div>
        <div class="titletext titledialog">
          <h3>Details training {{ title }}</h3>
          <h5>{{ moment }}</h5>
        </div>
      </div>
    </template>
    <template v-slot:default>
      <training-confirmation-form
        :id="eventid"
        :training_remark="trainingremark"
        :tobeconfirmed="tobeconfirmed"
        @close-confirmation="hideConfirmation"
        @save-confirmation="saveConfirmation"
      ></training-confirmation-form>
      <div v-if="isUpdating">
        <base-spinner></base-spinner>
      </div>
    </template>
  </base-dialog>

  <!-- ANNULEREN -->
  <base-dialog
    :show="cancellationIsVisible"
    :fixed="true"
    :headerStyle="'background-color: var(--var-training-cancelled);'"
  >
    <template v-slot:header>
      <div class="title">
        <div class="titleicon">
          <h3><i class="fas fa-running"></i></h3>
        </div>
        <div class="titletext titledialog">
          <h3>Training {{ title }} annuleren</h3>
          <h5>{{ moment }}</h5>
        </div>
      </div>
    </template>
    <template v-slot:default>
      <training-cancel-form
        :id="eventid"
        :trainingstatus="trainingstatus"
        :training_remark="trainingremark"
        @close-cancellation="hideCancellation"
        @save-cancellation="saveCancellation"
      ></training-cancel-form>
      <div v-if="isUpdating">
        <base-spinner></base-spinner>
      </div>
    </template>
  </base-dialog>

  <!-- DETAILS -->
  <base-dialog
    :show="detailIsVisible"
    :fixed="true"
    :headerStyle="detailHeaderStyle"
  >
    <template v-slot:header>
      <div class="title">
        <div class="titleicon">
          <h3><i class="fas fa-running"></i></h3>
        </div>
        <div class="titletext titledialog">
          <h3>Details training {{ title }}</h3>
          <h5>{{ moment }}</h5>
        </div>
      </div>
    </template>
    <template v-slot:default>
      <training-detail-form
        :id="eventid"
        :starttime="starttime"
        :trainerid="trainerid"
        :nrofassistents="nrofassistents"
        :assistentid="assistentid"
        :assistent2id="assistent2id"
        :assistent3id="assistent3id"
        :assistent4id="assistent4id"
        :assistent5id="assistent5id"
        :trainingid="trainingid"
        :trainingstatus="trainingstatus"
        :trainingmergeid="trainingmergeid"
        :training_remark="trainingremark"
        @close-training="hideDetail"
        @save-training="saveDetail"
      ></training-detail-form>
      <div v-if="isUpdating">
        <base-spinner></base-spinner>
      </div>
    </template>
  </base-dialog>

  <!-- LIST -->
  <div :class="trainingClass">
    <li>
      <div class="title">
        <div class="titleicon">
          <h3><i class="fas fa-running"></i></h3>
        </div>
        <div class="titletext">
          <h3>{{ title }}</h3>
          <h5>{{ moment }}</h5>
        </div>
      </div>
      <hr />
      <div v-html="description"></div>
      <div class="remark" v-if="trainingremark" v-html="remarks"></div>
      <div v-if="trainingstatus > 2" class="status">
        <strong>{{ trainingstatusdescription.toUpperCase() }}</strong>
      </div>
      <hr />
      <nav>
        <base-button
          class="smallbold"
          v-if="trainingstatus != 9 && trainingIsPast"
          @click="trainingPresences"
          ><i class="fas fa-user-check"></i>&nbsp;Aanwezigheden</base-button
        >
        <base-button
          class="smallbold"
          v-if="trainingstatus < 2 && trainingIsPast && tobeconfirmed === 0"
          @click="showConfirmation"
          ><i class="far fa-calendar-check"></i>&nbsp;Training
          bevestigen</base-button
        >
        <base-button class="smallbold" @click="showDetail"
          ><i class="fas fa-info"></i>&nbsp;Details</base-button
        >
        <base-button
          class="smallbold"
          v-if="trainingstatus < 2 && tobeconfirmed !== 0"
          @click="showCancellation"
          ><i class="fas fa-info"></i>&nbsp;Training annuleren</base-button
        >
      </nav>
    </li>
  </div>
</template>

<script>
import TrainingConfirmationForm from '../../components/training/TrainingConfirmationForm.vue';
import TrainingCancelForm from '../../components/training/TrainingCancelForm.vue';
import TrainingDetailForm from '../../components/training/TrainingDetailForm.vue';

export default {
  props: [
    'which',
    'eventid',
    'title',
    'moment',
    'description',
    'starttime',
    'trainerid',
    'nrofassistents',
    'assistentid',
    'assistent2id',
    'assistent3id',
    'assistent4id',
    'assistent5id',
    'trainingid',
    'trainingstatus',
    'trainingstatusdescription',
    'trainingmergeid',
    'trainingremark',
    'tobeconfirmed'
  ],
  components: {
    TrainingConfirmationForm,
    TrainingCancelForm,
    TrainingDetailForm
  },
  data() {
    return {
      confirmationIsVisible: false,
      detailIsVisible: false,
      cancellationIsVisible: false,
      error: null,
      isUpdating: false
    };
  },
  computed: {
    trainingClass() {
      if (this.trainingstatus == 8) {
        return 'merged';
      }
      if (this.trainingstatus == 9) {
        return 'cancelled';
      }
      if (this.trainingstatus >= 2) {
        return 'confirmed';
      }
      if (this.trainingIsPast) {
        return 'tobeconfirmed';
      }

      return '';
    },
    trainingIsPast() {
      const currentDate = new Date();
      const trainingDate = new Date(this.starttime);
      // console.log(currentDate);
      // console.log(trainingDate);

      if (trainingDate === currentDate) {
        const currentTime = new Date().toLocaleTimeString('nl-BE');
        return this.starttime <= currentTime;
      }

      return trainingDate < currentDate;
    },
    remarks() {
      return this.trainingremark.replace(/\n/g, '<br>');
    },
    detailHeaderStyle() {
      if (this.trainingstatus == 8) {
        return 'background-color: var(--var-training-merged);';
      }
      if (this.trainingstatus == 9) {
        return 'background-color: var(--var-training-cancelled);';
      }
      if (this.trainingstatus >= 2) {
        return 'background-color: var(--var-training-confirmed);';
      }
      if (this.trainingIsPast) {
        return 'background-color: var(--var-training-tobeconfirmed);';
      }

      return '';
    }
  },
  methods: {
    trainingPresences() {
      this.$router.push('/trainingpresences/' + this.eventid);
    },
    showConfirmation() {
      this.confirmationIsVisible = true;
    },
    hideConfirmation() {
      this.confirmationIsVisible = false;
    },
    async saveConfirmation(payload) {
      // console.log(payload);
      this.isUpdating = true;

      try {
        await this.$store.dispatch('training/updateTraining', payload);
      } catch (err) {
        this.error =
          err.message ||
          'Er trad een probleem op bij het bevestigen van deze training!';
      }

      this.isUpdating = false;
      this.confirmationIsVisible = false;
    },
    handleError() {
      this.error = '';
    },
    showDetail() {
      this.detailIsVisible = true;
    },
    hideDetail() {
      this.detailIsVisible = false;
    },
    async saveDetail(payload) {
      // console.log(payload);
      this.isUpdating = true;

      try {
        await this.$store.dispatch('training/updateTraining', payload);
      } catch (err) {
        this.error =
          err.message ||
          'Er trad een probleem op bij het aanpassen van de trainingsgegevens!';
      }

      this.isUpdating = false;
      this.detailIsVisible = false;
    },
    showCancellation() {
      this.cancellationIsVisible = true;
    },
    hideCancellation() {
      this.cancellationIsVisible = false;
    },
    async saveCancellation(payload) {
      // console.log(payload);
      this.isUpdating = true;

      try {
        await this.$store.dispatch('training/updateTraining', payload);
      } catch (err) {
        this.error =
          err.message ||
          'Er trad een probleem op bij het annuleren van deze training!';
      }

      this.isUpdating = false;
      this.cancellationIsVisible = false;
    }
  }
};
</script>

<style scoped>
li {
  list-style: none;
  margin: 1rem 0;
  border: 1px solid #424242;
  border-radius: 12px;
  padding: 1rem;
  line-height: 1.2;
}

nav {
  margin-top: 10px;
}

.title {
  display: flex;
}

.titleicon {
  margin-right: 10px;
}

.titletext {
  margin-bottom: -20px;
}

.titletext h3 {
  margin-top: -5px;
  margin-bottom: 0px;
}

.titledialog h5 {
  margin-bottom: 25px;
}

.confirmed {
  background-color: var(--var-training-confirmed);
}

.merged {
  background-color: var(--var-training-merged);
}

.cancelled {
  background-color: var(--var-training-cancelled);
}

.tobeconfirmed {
  background-color: var(--var-training-tobeconfirmed);
}

.status {
  margin-top: 10px;
}

.remark {
  padding-top: 10px;
  font-size: 14px;
  font-style: italic;
}
</style>
