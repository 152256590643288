<template>
  <div>
    <slot></slot>
    <base-dialog
      :show="!!error"
      title="Er trad een fout op bij het ophalen van de inschrijvingslijst!"
      @close="handleError"
    >
      <p>{{ error }}</p>
    </base-dialog>
    <section v-if="!isLoading">
      <league-subscription-filter
        @change-filter="setFilter"
      ></league-subscription-filter>
    </section>
    <section>
      <base-card>
        <div v-if="isLoading">
          <base-spinner></base-spinner>
        </div>
        <ul v-else-if="hasSubscriptions">
          <league-subscription-item
            v-for="subscription in filteredSubscriptions"
            :key="subscription.id"
            :id="subscription.subscriptionId"
            :memberid="subscription.memberId"
            :membername="subscription.memberName"
            :gender="subscription.gender"
            :address="subscription.address"
            :telephone="subscription.telephone"
            :mobile="subscription.mobile"
            :email="subscription.email"
            :birthdate="subscription.birthdate"
            :ranking="subscription.ranking"
            :actuallevel="subscription.actualLevel"
            :actualpoule="subscription.actualPoule"
            :subscriptiondate="subscription.subscriptionDate"
            :status="subscription.status"
            :statusdescription="subscription.statusDescription"
          ></league-subscription-item>
        </ul>
        <div v-else>
          Geen inschrijvingen
        </div>
        <hr />
        <div class="controls">
          <base-button mode="small" @click="loadSubscriptions(true)"
            >Vernieuwen</base-button
          >
        </div>
      </base-card>
    </section>
  </div>
</template>

<script>
import LeagueSubscriptionItem from '../../components/leagues/LeagueSubscriptionItem.vue';
import LeagueSubscriptionFilter from '../../components/leagues/LeagueSubscriptionFilter.vue';

export default {
  components: {
    LeagueSubscriptionItem,
    LeagueSubscriptionFilter
  },
  data() {
    return {
      which: 'all',
      isLoading: false,
      error: null,
      searchTerm: ''
    };
  },
  computed: {
    filteredSubscriptions() {
      const subscriptionList = this.$store.getters['leagues/subscriptions'];

      return subscriptionList.filter(subscription => {
        if (this.searchTerm) {
          return (
            subscription.memberName
              .toLowerCase()
              .includes(this.searchTerm.toLowerCase()) ||
            (subscription.email != null &&
              subscription.email
                .toLowerCase()
                .includes(this.searchTerm.toLowerCase()))
          );
        }

        return true;
      });
    },
    hasSubscriptions() {
      const subscriptionList = this.filteredSubscriptions;
      return subscriptionList.length > 0 ? true : false;
    }
  },
  methods: {
    async loadLeagueSubscriptions(refresh = false) {
      this.isLoading = true;

      try {
        const leagueId = this.$store.getters['leagues/leagueId'];

        await this.$store.dispatch('leagues/loadLeagueSubscriptions', {
          leagueId: leagueId,
          forceRefresh: refresh
        });
      } catch (err) {
        this.error =
          err.message ||
          'Er trad een probleem op bij het ophalen van de inschrijvingen!';
      }

      this.isLoading = false;

      // Close navigation
      if (this.$store.getters.isNavOpen) {
        this.$store.dispatch('toggleNav');
      }
    },
    handleError() {
      this.error = null;
    },
    setFilter(updatedSearchTerm) {
      this.searchTerm = updatedSearchTerm;
    }
  },
  created() {
    this.loadLeagueSubscriptions(true);
  }
};
</script>

<style scoped>
ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.controls {
  display: flex;
  justify-content: space-between;
}
</style>
