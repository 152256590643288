<template>
  <base-button mode="smallbold" @click="setLeague" :class="selectedClass">
    {{ description }}
  </base-button>
</template>

<script>
export default {
  props: ['league', 'description', 'selectedleague'],
  emits: ['set-league'],
  computed: {
    selectedClass() {
      if (this.selectedleague && this.selectedleague == this.league) {
        return 'selected';
      } else {
        return 'unselected';
      }
    }
  },
  methods: {
    setLeague() {
      this.$emit('set-league', {
        league: this.league
      });
    }
  }
};
</script>

<style scoped>
.unselected {
  background-color: var(--var-button-background);
  color: var(--var-font-color);
  padding: 0rem 0.1rem;
  border: 0.3px solid var(--var-font-color);
  border-radius: 0px;
  font-size: 18px;
  font-weight: normal;
}

.selected {
  background-color: var(--var-button-background-focused);
  color: var(--var-font-color);
  padding: 0rem 0.1rem;
  border: 0.3px solid var(--var-font-color);
  border-radius: 0px;
  font-size: 18px;
  font-weight: normal;
}
</style>
