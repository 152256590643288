export default {
  setTrainers(state, payload) {
    state.trainers = payload;
  },
  setTrainingMoments(state, payload) {
    state.trainingMoments = payload;
  },
  setMergeableMoments(state, payload) {
    state.mergeableMoments = payload;
  },
  setPresences(state, payload) {
    state.presences = payload;
  },
  setTraineeTrainingMoments(state, payload) {
    state.traineeTrainingMoments = payload;
  }
};
