export default {
  setWeekDigests(state, payload) {
    state.weekDigests = payload;
  },
  setRankings(state, payload) {
    state.rankings = payload;
  },
  setTeamPlayers(state, payload) {
    state.teamPlayers = payload;
  },
  setTeamCalendars(state, payload) {
    state.teamCalendars = payload;
  },
  setWeekResults(state, payload) {
    state.weekResults = payload;
  },
  setTeamConfirmations(state, payload) {
    state.teamConfirmations = payload;
  }
};
