<template>
  <league-selection
    v-for="league in leagues"
    :key="league.id"
    :league="league.leagueId"
    :description="league.description"
    :selectedleague="selectedLeague"
    @set-league="selectLeague"
  >
  </league-selection>
</template>

<script>
import LeagueSelection from './LeagueSelection.vue';

export default {
  components: {
    LeagueSelection
  },
  emits: ['change-league'],
  data() {
    return {
      selectedLeague: '',
      leagues: []
    };
  },
  computed: {
    hasLeagues() {
      return this.leagues.length > 0 ? true : false;
    }
  },
  methods: {
    async loadLeagues() {
      this.leagues = this.$store.getters['leagues/leagues'];
      if (this.leagues.length == 0) {
        try {
          await this.$store.dispatch('leagues/loadLeagues').then(() => {
            const leagues = this.$store.getters['leagues/leagues'];
            if (leagues && leagues.length > 0) {
              this.selectedLeague = leagues[0].league;
            }

            this.leagues = leagues;
          });
        } catch (err) {
          this.error =
            err.message ||
            'Er trad een probleem op bij het ophalen van de leagues!';
        }
      }
    },
    selectLeague(payload) {
      this.selectedLeague = payload.league;
      this.$emit('change-league', this.selectedLeague);
    }
  },
  created() {
    this.loadLeagues();
  }
};
</script>

<style scoped>
h2 {
  margin: 0.5rem 0;
}
</style>
