<template>
  <base-popup
    :show="!!error"
    title="Fout/waarschuwing"
    awesomeicon="fas fa-exclamation-triangle"
    @close="handleErrorAndClose"
  >
    <p class="error">{{ error }}</p>
  </base-popup>
  <div>
    <section>
      <div class="scores">
        <table width="100%">
          <tr v-if="nrOfMatches >= 1">
            <th class="inputlabel" width="44%">
              <strong>Team 1</strong>
            </th>
            <th width="5%"></th>
            <th width="2%"></th>
            <th width="5%"></th>
            <th class="inputlabel" width="44%" style="text-align: right">
              <strong>Team 2</strong>
            </th>
          </tr>
          <tr v-if="nrOfMatches >= 1">
            <td v-html="team1(1)" class="inputlabel"></td>
            <td align="center">
              <input
                class="inputfield"
                type="number"
                id="score1a"
                name="score1a"
                min="0"
                max="99"
                v-model="score1a"
                onkeypress="return event.keyCode != 13;"
              />
            </td>
            <td align="center">-</td>
            <td align="center">
              <input
                class="inputfield"
                type="number"
                id="score1b"
                name="score1b"
                min="0"
                max="99"
                v-model="score1b"
                onkeypress="return event.keyCode != 13;"
              />
            </td>
            <td v-html="team2(1)" class="inputlabel" align="right"></td>
          </tr>
          <tr v-if="nrOfMatches >= 2">
            <td v-html="team1(2)" class="inputlabel"></td>
            <td align="center">
              <input
                class="inputfield"
                type="number"
                id="score2a"
                name="score2a"
                min="0"
                max="99"
                v-model="score2a"
                onkeypress="return event.keyCode != 13;"
              />
            </td>
            <td align="center">-</td>
            <td align="center">
              <input
                class="inputfield"
                type="number"
                id="score2b"
                name="score2b"
                min="0"
                max="99"
                v-model="score2b"
                onkeypress="return event.keyCode != 13;"
              />
            </td>
            <td v-html="team2(2)" class="inputlabel" align="right"></td>
          </tr>
          <tr v-if="nrOfMatches >= 3">
            <td v-html="team1(3)" class="inputlabel"></td>
            <td align="center">
              <input
                class="inputfield"
                type="number"
                id="score3a"
                name="score3a"
                min="0"
                max="99"
                v-model="score3a"
                onkeypress="return event.keyCode != 13;"
              />
            </td>
            <td align="center">-</td>
            <td align="center">
              <input
                class="inputfield"
                type="number"
                id="score3b"
                name="score3b"
                min="0"
                max="99"
                v-model="score3b"
                onkeypress="return event.keyCode != 13;"
              />
            </td>
            <td v-html="team2(3)" class="inputlabel" align="right"></td>
          </tr>
          <tr v-if="nrOfMatches >= 4">
            <td v-html="team1(4)" class="inputlabel"></td>
            <td align="center">
              <input
                class="inputfield"
                type="number"
                id="score4a"
                name="score4a"
                min="0"
                max="99"
                v-model="score4a"
                onkeypress="return event.keyCode != 13;"
              />
            </td>
            <td align="center">-</td>
            <td align="center">
              <input
                class="inputfield"
                type="number"
                id="score4b"
                name="score4b"
                min="0"
                max="99"
                v-model="score4b"
                onkeypress="return event.keyCode != 13;"
              />
            </td>
            <td v-html="team2(4)" class="inputlabel" align="right"></td>
          </tr>
          <tr v-if="nrOfMatches >= 5">
            <td v-html="team1(5)" class="inputlabel"></td>
            <td align="center">
              <input
                class="inputfield"
                type="number"
                id="score5a"
                name="score5a"
                min="0"
                max="99"
                v-model="score5a"
                onkeypress="return event.keyCode != 13;"
              />
            </td>
            <td align="center">-</td>
            <td align="center">
              <input
                class="inputfield"
                type="number"
                id="score5b"
                name="score5b"
                min="0"
                max="99"
                v-model="score5b"
                onkeypress="return event.keyCode != 13;"
              />
            </td>
            <td v-html="team2(5)" class="inputlabel" align="right"></td>
          </tr>
        </table>
      </div>
      <menu>
        <base-button
          @click="submitForm"
          mode="smallbold"
          v-if="nrOfMatches >= 1"
          ><i class="far fa-save"></i>&nbsp;Registreer</base-button
        >
        <base-button @click="closeForm" mode="smallbold">Sluit</base-button>
      </menu>
    </section>
  </div>
</template>

<script>
export default {
  components: {},
  props: ['id'],
  emits: ['close-score', 'register-score'],
  data() {
    return {
      matches: [],
      score1a: null,
      score1b: null,
      score2a: null,
      score2b: null,
      score3a: null,
      score3b: null,
      score4a: null,
      score4b: null,
      score5a: null,
      score5b: null,
      error: null
    };
  },
  computed: {
    nrOfMatches() {
      return this.matches.length;
    }
  },
  methods: {
    handleError() {
      this.error = null;
    },
    handleErrorAndClose() {
      this.error = null;
      this.$emit('close-score');
    },
    async loadPouleInformation() {
      this.isLoading = true;

      try {
        await this.$store
          .dispatch('leagues/loadPouleInformation', { pouleId: this.id })
          .then(() => {
            this.matches = this.$store.getters['leagues/pouleMatches'];
            // console.log(this.matches);

            if (this.matches.length >= 1) {
              this.score1a = this.matches[0].games1;
              this.score1b = this.matches[0].games2;
            }
            if (this.matches.length >= 2) {
              this.score2a = this.matches[1].games1;
              this.score2b = this.matches[1].games2;
            }
            if (this.matches.length >= 3) {
              this.score3a = this.matches[2].games1;
              this.score3b = this.matches[2].games2;
            }
            if (this.matches.length >= 4) {
              this.score4a = this.matches[3].games1;
              this.score4b = this.matches[3].games2;
            }
            if (this.matches.length >= 5) {
              this.score5a = this.matches[4].games1;
              this.score5b = this.matches[4].games2;
            }
          });
      } catch (err) {
        this.error =
          err.message ||
          'Er trad een probleem op bij het ophalen van de poule-wedstrijden!';
      }

      this.isLoading = false;
    },
    team1(matchIndex) {
      if (this.matches.length >= matchIndex) {
        return this.matches[matchIndex - 1].team1.replace(' / ', '<br />');
      } else {
        return '';
      }
    },
    team2(matchIndex) {
      if (this.matches.length >= matchIndex) {
        return this.matches[matchIndex - 1].team2.replace(' / ', '<br />');
      } else {
        return '';
      }
    },
    closeForm() {
      this.$emit('close-score');
    },
    submitForm() {
      // console.log(this.matches[0]);
      let formData = {
        pouleId: this.id,
        matches: {}
      };
      if (this.matches.length >= 1) {
        const match = {
          id: this.matches[0].pouleGameId,
          games1: this.score1a,
          games2: this.score1b
        };
        formData.matches = {
          ...formData.matches,
          match1: match
        };
      }
      if (this.matches.length >= 2) {
        const match = {
          id: this.matches[1].pouleGameId,
          games1: this.score2a,
          games2: this.score2b
        };
        formData.matches = {
          ...formData.matches,
          match2: match
        };
      }
      if (this.matches.length >= 3) {
        const match = {
          id: this.matches[2].pouleGameId,
          games1: this.score3a,
          games2: this.score3b
        };
        formData.matches = {
          ...formData.matches,
          match3: match
        };
      }
      if (this.matches.length >= 4) {
        const match = {
          id: this.matches[3].pouleGameId,
          games1: this.score4a,
          games2: this.score4b
        };
        formData.matches = {
          ...formData.matches,
          match3: match
        };
      }
      if (this.matches.length >= 5) {
        const match = {
          id: this.matches[4].pouleGameId,
          games1: this.score5a,
          games2: this.score5b
        };
        formData.matches = {
          ...formData.matches,
          match4: match
        };
      }

      this.$emit('register-score', formData);
    }
  },
  mounted() {
    this.loadPouleInformation();
  }
};
</script>

<style scoped>
textarea {
  width: 100%;
}
.inputlabel {
  font-size: 24px;
  font-weight: bold;
  padding-top: 16px;
  padding-bottom: 16px;
}

input[type='number']::-webkit-outer-spin-button,
input[type='number']::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type='number'] {
  -moz-appearance: textfield; /* Firefox */
}
.inputfield {
  width: 50px;
  display: flex;
  font-size: 24px;
  font-weight: bolder;
  text-align: center;
}
.scores {
  display: flex;
  align-items: center;
  gap: 10px;
}

menu {
  padding-top: 1rem;
  display: flex;
  justify-content: flex-end;
  margin: 0;
}

table,
th,
td {
  border: none;
  border-collapse: collapse;
}
</style>
