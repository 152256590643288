<template>
  <div>
    <li @click="selectMember" :class="selectedClass">
      <div v-if="selected">
        <i class="fas fa-user-check"></i>&nbsp;{{ membername }}
      </div>
      <div v-else><i class="fas fa-user"></i>&nbsp;{{ membername }}</div>
    </li>
  </div>
</template>

<script>
export default {
  props: ['memberid', 'membername', 'selected'],
  emits: ['set-member'],
  data() {
    return {};
  },
  computed: {
    isSelected() {
      return !!this.selected;
    },
    selectedClass() {
      if (this.isSelected) {
        return 'selected';
      }

      return 'notselected';
    }
  },
  methods: {
    selectMember() {
      this.$emit('set-member', {
        memberId: this.memberid
      });
    }
  }
};
</script>

<style scoped>
li {
  list-style: none;
  font-size: 20px;
  color: var(--var-font-color);
  border: 1px solid var(--var-font-color);
  text-decoration: none;
  display: flex;
  padding: 0.75rem 1.5rem;
  margin-left: 10px;
  margin-right: 10px;
  margin-bottom: 5px;
  cursor: pointer;
}

li:hover {
  background-color: var(--var-button-background-focused);
}

.selected {
  background-color: var(--var-button-background-focused);
}

.notselected {
  background-color: var(--var-button-background);
}
</style>
