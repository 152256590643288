export default {
  hasMembers(state) {
    return state.members && state.members.length > 0;
  },
  members(state) {
    return state.members;
  },
  absenceReasons(state) {
    return state.absenceReasons;
  },
  memberAbsences(state) {
    return state.memberAbsences;
  },
  shouldUpdate(state) {
    const lastFetch = state.lastFetch;
    if (!lastFetch) {
      return true;
    }
    const currentTimeStamp = new Date().getTime();
    return (currentTimeStamp - lastFetch) / 1000 > 60;
  }
};
