<template>
  <div>
    <slot></slot>
    <base-dialog
      :show="!!error"
      title="Er trad een fout op!"
      @close="handleError"
    >
      <p>{{ error }}</p>
    </base-dialog>
    <section>
      <base-card>
        <div v-if="isLoading">
          <base-spinner></base-spinner>
        </div>
        <div v-if="!isLoading" class="training">
          <div class="title">
            <div class="title">
              <div class="titleicon">
                <h3><i class="fas fa-running"></i></h3>
              </div>
              <div class="titlesub">
                <div>
                  <h3>{{ title }}</h3>
                </div>
                <div>
                  <h5>{{ moment }}</h5>
                </div>
                <div>Totaal aantal trainees: {{ nrOfPresences }}</div>
                <div v-if="trainingStatus >= 2">
                  Wijzigingen NIET meer toegelaten (training werd geconfirmeerd
                  of samengevoegd)
                </div>
              </div>
            </div>
            <div>
              <base-button class="smallbold" @click="returnToList"
                ><i class="fas fa-long-arrow-alt-left"></i
                >&nbsp;Terug</base-button
              >
            </div>
          </div>
          <div
            class="occasional"
            v-if="trainingStatus < 2 && additionalTraineeSelection > 0"
          >
            Traint mee
            <multiselect
              v-model="memberToAdd"
              :options="memberSelection"
              :searchable="true"
              placeholder="Zoek speler"
              ref="member"
            ></multiselect>
            <base-button
              @click="addMember"
              class="smallbold addbutton"
              v-if="memberToAdd"
              >Voeg toe</base-button
            >
          </div>
        </div>
        <div v-if="hasPresences">
          <training-presence-item
            v-for="presence in filteredPresences"
            :key="presence.id"
            :memberid="presence.memberId"
            :membername="presence.memberName"
            :presence="presence.presence"
            :trainingstatus="trainingStatus"
            :traininggroup="presence.trainingGroup"
            :nrofeventsago="presence.nrOfEventsAgo"
            @set-presence="setPresence"
          ></training-presence-item>
          <div v-if="!isLoading" class="training">
            <div class="title">
              <div class="title titlebottom">
                <div class="titleicon">
                  <h3><i class="fas fa-running"></i></h3>
                </div>
                <div class="titlesub">
                  <div>
                    <h3>{{ title }}</h3>
                  </div>
                  <div>
                    <h5>{{ moment }}</h5>
                  </div>
                  <div>Totaal aantal trainees: {{ nrOfPresences }}</div>
                </div>
              </div>
              <div>
                <base-button class="smallbold" @click="returnToList"
                  ><i class="fas fa-long-arrow-alt-left"></i
                  >&nbsp;Terug</base-button
                >
              </div>
            </div>
          </div>
          <!--          <div class="controls">
            <base-button mode="flat" @click="loadPresences"
              >Vernieuwen</base-button
            >
          </div> -->
        </div>
      </base-card>
    </section>
  </div>
</template>

<script>
import TrainingPresenceItem from '../../components/training/TrainingPresenceItem.vue';
import Multiselect from '@vueform/multiselect';

export default {
  components: {
    TrainingPresenceItem,
    Multiselect
  },
  data() {
    return {
      isLoading: false,
      error: null,
      eventId: null,
      title: '',
      moment: '',
      trainingStatus: null,
      memberToAdd: null,
      additionalTraineeSelection: 0
    };
  },
  computed: {
    hasPresences() {
      const presencesList = this.$store.getters['training/presences'];
      return !this.isLoading && presencesList && presencesList.length > 0;
    },
    filteredPresences() {
      const presencesList = this.$store.getters['training/presences'];
      return presencesList;
    },
    nrOfPresences() {
      let nrOfPresent = 0;
      const presencesList = this.$store.getters['training/presences'];

      presencesList.forEach(presence => {
        if (presence.presence == 1) {
          nrOfPresent++;
        }
      });

      return nrOfPresent;
    },
    memberSelection() {
      const membersStore = this.$store.getters['members/members'];

      const members = [];
      for (let i = 0; i < membersStore.length; i++) {
        const mbr = membersStore[i];

        if (this.additionalTraineeSelection == 1 && mbr.traineeState != 1) {
          continue;
        }
        if (
          this.additionalTraineeSelection == 2 &&
          mbr.traineeState != 1 &&
          mbr.memberState != 1
        ) {
          continue;
        }
        if (
          this.additionalTraineeSelection == 3 &&
          mbr.traineeState != 1 &&
          mbr.memberState != 1 &&
          mbr.memberState != 2
        ) {
          continue;
        }

        const member = {
          value: mbr.memberId,
          label: mbr.memberName
        };

        members.push(member);
      }

      return members;
    }
  },
  methods: {
    async loadMembers() {
      this.isLoading = true;

      try {
        await this.$store.dispatch('members/loadMembers', {
          forceRefresh: false
        });
      } catch (err) {
        this.error =
          err.message ||
          'Er trad een probleem op bij het ophalen van de ledenfiches!';
      }

      this.isLoading = false;
    },
    async loadPresences() {
      this.isLoading = true;

      try {
        await this.$store.dispatch('training/loadPresences', {
          eventId: this.eventId
        });
      } catch (err) {
        this.error =
          err.message ||
          'Er trad een probleem op bij het ophalen van de aanwezigheidslijst!';
      }

      this.isLoading = false;
    },
    async setPresence(presenceData) {
      try {
        await this.$store.dispatch('training/updatePresence', {
          eventId: this.eventId,
          memberId: presenceData.memberId,
          presence: presenceData.presence
        });
      } catch (err) {
        this.error =
          err.message ||
          'Er trad een probleem op bij het aanpassen van de aanwezigheid!';
      }
    },
    async addMember() {
      if (this.memberToAdd > 0) {
        try {
          await this.$store.dispatch('training/updatePresence', {
            eventId: this.eventId,
            memberId: this.memberToAdd,
            presence: 1
          });
        } catch (err) {
          this.error =
            err.message ||
            'Er trad een probleem op bij het aanpassen van de aanwezigheid!';
        }

        this.memberToAdd = null;
        this.loadPresences();
      }
    },
    returnToList() {
      this.$router.back();
    },
    handleError() {
      this.error = null;
    }
  },
  mounted() {
    this.additionalTraineeSelection = this.$store.getters[
      'additionalTraineeSelection'
    ];

    this.$store.dispatch('training/clearPresences');
    if (this.$route.params.id) {
      this.eventId = this.$route.params.id;

      const moments = this.$store.getters['training/trainingMoments'];
      moments.forEach(moment => {
        if (moment.eventId == this.eventId) {
          this.title = moment.title;
          this.moment = moment.moment;
          this.trainingStatus = moment.trainingStatus;
        }
      });
    }

    this.loadPresences();
    this.loadMembers();
  }
};
</script>

<style src="@vueform/multiselect/themes/default.css"></style>
<style>
:root {
  --ms-font-size: 0.9rem;
  --ms-line-height: 0.85;

  --ms-option-bg-selected: #4dabf7;
  --ms-option-bg-selected-pointed: #4dabf7;
  --ms-option-font-size: 0.9rem;
}
</style>

<style scoped>
ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.controls {
  display: flex;
  justify-content: space-between;
}

.training {
  width: 100%;
}

.title {
  display: flex;
  justify-content: space-between;
}

.titleicon {
  padding-right: 10px;
}

.titlesub h5 {
  line-height: 0.5;
  margin-bottom: 15px;
}

.titlesub {
  line-height: 1.2;
}

.titlebottom {
  padding-top: 20px;
  padding-bottom: 0px;
}

.occasional {
  line-height: 1.2;
  display: flex;
}

.addbutton {
  margin-left: 10px;
}
</style>
