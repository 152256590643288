<template>
  <base-button @click="setDiscipline" :class="selectedClass">
    {{ discipline }}
  </base-button>
</template>

<script>
export default {
  props: ['id', 'discipline', 'selecteddiscipline'],
  emits: ['set-discipline'],
  computed: {
    selectedClass() {
      if (this.selecteddiscipline && this.selecteddiscipline == this.id) {
        return 'selected';
      } else {
        return 'unselected';
      }
    }
  },
  methods: {
    setDiscipline() {
      this.$emit('set-discipline', {
        discipline: this.id
      });
    }
  }
};
</script>

<style scoped>
.unselected {
  background-color: var(--var-button-background);
  color: var(--var-font-color);
  padding: 0rem 0.1rem;
  border: 0.3px solid var(--var-font-color);
  border-radius: 0px;
  font-size: 18px;
  font-weight: normal;
}

.selected {
  background-color: var(--var-button-background-focused);
  color: var(--var-font-color);
  padding: 0rem 0.1rem;
  border: 0.3px solid var(--var-font-color);
  border-radius: 0px;
  font-size: 18px;
  font-weight: normal;
}
</style>
