<template>
  <base-popup :show="!!error" title="Er trad een fout op!" @close="handleError">
    <p>{{ error }}</p>
  </base-popup>

  <div :class="trainingClass">
    <base-dialog :show="absentFormIsVisible" :fixed="true">
      <template v-slot:header>
        <h2>
          <i class="fas fa-user-alt-slash"></i>
          Ik kan NIET aanwezig zijn
        </h2>
      </template>
      <template v-slot:default>
        <absent-form
          :absence_remarks="remark"
          @close-absence="closeAbsentForm"
          @save-absence="saveCancellation"
        ></absent-form>
        <div v-if="isUpdating">
          <base-spinner></base-spinner>
        </div>
      </template>
    </base-dialog>

    <li>
      <div class="title">
        <div class="titleicon">
          <h3><i class="fas fa-running"></i></h3>
        </div>
        <div class="titletext">
          <h3>{{ title }}</h3>
          <h5>{{ moment }}</h5>
        </div>
      </div>
      <hr />
      <div v-if="!trainingIsPast">
        <div v-if="reserved == -1" class="title">
          <div class="titleicon">
            <h3><i class="fas fa-times-circle"></i></h3>
          </div>
          <div>
            <div>
              <strong>JE HEBT AANGEGEVEN NIET AANWEZIG TE ZULLEN ZIJN!</strong>
              <br />
              <em><div v-html="absenceRemark" v-if="remark"></div></em>
            </div>
            <div @click="setReserved(0)" class="undo">
              Klik hier om dit ongedaan te maken.
            </div>
          </div>
        </div>
        <div v-if="reserved == 1" class="title">
          <div class="titleicon">
            <h3><i class="fas fa-check-circle"></i></h3>
          </div>
          <div>
            <div>
              <strong>JE HEBT AANGEGEVEN AANWEZIG TE ZULLEN ZIJN!</strong>
            </div>
            <div @click="setReserved(0)" class="undo">
              Klik hier om dit ongedaan te maken.
            </div>
          </div>
        </div>
        <hr v-if="reserved != 0" />
      </div>
      <div v-html="description"></div>
      <div v-if="!trainingIsPast && reserved === 0">
        <hr />
        <nav>
          <base-button class="smallbold" @click="setReserved(1)"
            ><i class="fas fa-user-check"></i>&nbsp;Ik zal aanwezig
            zijn</base-button
          >
          <base-button class="smallbold" @click="showAbsentForm"
            ><i class="fas fa-user-alt-slash"></i>&nbsp;Ik zal niet aanwezig
            zijn</base-button
          >
        </nav>
      </div>
    </li>
  </div>
</template>

<script>
import AbsentForm from '../../components/training/TraineeTrainingAbsentForm.vue';

export default {
  props: [
    'which',
    'eventid',
    'title',
    'moment',
    'description',
    'starttime',
    'endtime',
    'trainingid',
    'trainingstatus',
    'trainingstatusdescription',
    'presence',
    'reserved',
    'remark'
  ],
  emits: ['set-reserved'],
  components: {
    AbsentForm
  },
  data() {
    return {
      error: null,
      isUpdating: false,
      absentFormIsVisible: false
    };
  },
  computed: {
    trainingClass() {
      if (this.presence == 1) {
        return 'present';
      }
      if (this.reserved == 1) {
        return 'reserved';
      }
      if (this.reserved == -1) {
        return 'cancelled';
      }

      return '';
    },
    trainingIsPast() {
      const currentDate = new Date();
      const trainingDate = new Date(this.endtime);
      // console.log(currentDate);
      // console.log(trainingDate);

      return trainingDate < currentDate;
    },
    absenceRemark() {
      return this.remark.replace(/\n/g, '<br>');
    }
  },
  methods: {
    setReserved(reserved) {
      if (reserved === 0) {
        if (!confirm('Je aangegeven aan- of afwezigheid ongedaan maken?'))
          return;
      }

      this.$emit('set-reserved', {
        eventId: this.eventid,
        reserved: reserved
      });
    },
    showAbsentForm() {
      this.absentFormIsVisible = true;
    },
    closeAbsentForm() {
      this.absentFormIsVisible = false;
    },
    saveCancellation(formData) {
      this.$emit('set-reserved', {
        eventId: this.eventid,
        reserved: -1,
        remark: formData.remarks
      });

      this.absentFormIsVisible = false;
    },
    handleError() {
      this.error = '';
    }
  }
};
</script>

<style scoped>
li {
  list-style: none;
  margin: 1rem 0;
  border: 1px solid #424242;
  border-radius: 12px;
  padding: 1rem;
  line-height: 1.2;
}

nav {
  margin-top: 10px;
}

.title {
  display: flex;
}

.titleicon {
  margin-right: 10px;
}

.titletext {
  margin-bottom: -20px;
}

.titletext h3 {
  margin-top: -5px;
  margin-bottom: 0px;
}

.present {
  background-color: var(--var-training-confirmed);
}

.reserved {
  background-color: var(--var-training-confirmed);
}

.cancelled {
  background-color: var(--var-training-cancelled);
}

.undo {
  cursor: pointer;
}
</style>
