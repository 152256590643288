<template>
  <div>
    <li @click="setPresence">
      <span :class="presentclass"
        ><i class="fas fa-user-check" v-if="isPresent"></i
      ></span>
      <span class="notpresent"
        ><i class="fas fa-user-slash" v-if="!isPresent"></i
      ></span>
      <span class="checkedout"
        ><i class="fas fa-sign-out-alt" v-if="isCheckedOut"></i
      ></span>
      <div class="subscription">
        <div v-if="isBirthdayToday" class="birthday">
          <i class="fa fa-birthday-cake"></i><i class="fa fa-birthday-cake"></i
          ><i class="fa fa-birthday-cake"></i><span>JARIG VANDAAG</span
          ><i class="fa fa-birthday-cake"></i><i class="fa fa-birthday-cake"></i
          ><i class="fa fa-birthday-cake"></i>
        </div>
        <div>
          <i class="fas fa-mars" v-if="isMale"></i>
          <i class="fas fa-venus" v-if="isFemale"></i>
          {{ subscriptionname }}
        </div>
        <div class="email" v-if="validEmail(email)">
          <i class="fas fa-envelope-square"></i>&nbsp;<a :href="mailto(email)"
            ><strong>{{ email }}</strong></a
          >
          <span v-if="validEmail(email2)">
            of
            <a :href="mailto(email2)"
              ><strong>{{ email2 }}</strong></a
            >
          </span>
        </div>
        <div class="telephone" v-if="!!telephone">
          <i class="fas fa-phone"></i>&nbsp;<a :href="phoneto(telephone)"
            ><strong>{{ telephone }}</strong></a
          >
          <span v-if="!!telephone2">
            of
            <a :href="phoneto(telephone2)"
              ><strong>{{ telephone2 }}</strong></a
            >
          </span>
        </div>
        <div v-if="!!parents" class="parents">
          <i class="fas fa-user-shield"></i>&nbsp;<strong>{{ parents }}</strong>
        </div>
      </div>
    </li>
  </div>
</template>

<script>
export default {
  props: [
    'checkinout',
    'id',
    'subscriptionid',
    'subscriptionname',
    'gender',
    'email',
    'email2',
    'telephone',
    'telephone2',
    'isbirthdaytoday',
    'parents',
    'presence',
    'checkedout'
  ],
  emits: ['set-presence', 'set-checkout'],
  data() {
    return {
      present: false,
      checkedOut: false
    };
  },
  computed: {
    isPresent() {
      return this.present;
    },
    isCheckedOut() {
      return this.checkedOut;
    },
    isMale() {
      if (this.gender === 'M') {
        return true;
      }

      return false;
    },
    isFemale() {
      if (this.gender === 'F') {
        return true;
      }

      return false;
    },
    isBirthdayToday() {
      return this.isbirthdaytoday == 1 ? true : false;
    },
    presentclass() {
      if (this.checkinout == 0 || this.checkedOut != 1) {
        return 'present';
      }

      return 'checkedout';
    }
  },
  methods: {
    validEmail(emailAddress) {
      if (emailAddress && emailAddress != null && emailAddress.includes('@')) {
        return true;
      }

      return false;
    },
    mailto(emailAddress) {
      return 'mailto:' + emailAddress;
    },
    phoneto(telephoneNumber) {
      var nr = telephoneNumber;
      nr = nr.replace(/\D/g, '');
      return 'tel:' + nr;
    },
    setPresence() {
      // Checkin/out
      if (this.checkinout == 0) {
        if (!this.checkedOut) {
          this.present = !this.present;
          this.$emit('set-presence', {
            subscriptionId: this.subscriptionid,
            present: this.present
          });
        }
      } else {
        this.checkedOut = !this.checkedOut;
        this.$emit('set-checkout', {
          subscriptionId: this.subscriptionid,
          checkedOut: this.checkedOut
        });
      }
    }
  },
  mounted() {
    if (this.presence == '1') {
      this.present = true;
    }
    if (this.checkedout == '1') {
      this.checkedOut = true;
    }
  }
};
</script>

<style scoped>
li {
  list-style: none;
  font-size: 32px;
  font-weight: bold;
  margin: 0.5rem 0;
  border: 1px solid #424242;
  border-radius: 12px;
  padding: 1rem;
  cursor: pointer;
  display: flex;
}

.present {
  color: rgb(65, 209, 65);
}

.checkedout {
  color: rgb(207, 58, 58);
  margin-left: 0.5rem;
}

.notpresent {
  color: rgb(207, 58, 58);
}

.subscription {
  padding-left: 0.7rem;
}

.email {
  font-size: 18px;
}

.telephone {
  font-size: 18px;
}

.parents {
  font-size: 18px;
}

.birthday {
  color: rgb(207, 135, 58);
  font-weight: bold;
  margin-bottom: 10px;
}
.birthday i {
  margin-right: 10px;
}
.birthday span {
  margin-right: 10px;
}
</style>
