export default {
  leagues(state) {
    return state.leagues;
  },
  leagueId(state) {
    return state.leagueId;
  },
  leagueDescription(state) {
    let description = '';

    if (state.leagueId) {
      const leagueId = state.leagueId;
      const leagues = state.leagues;
      leagues.forEach(l => {
        if (l.leagueId == leagueId) {
          description = l.description;
        }
      });
    }

    return description;
  },
  levels(state) {
    return state.levels;
  },
  series(state) {
    return state.series;
  },
  poules(state) {
    return state.poules;
  },
  rankings(state) {
    return state.rankings;
  },
  participations(state) {
    return state.participations;
  },
  shouldUpdateSubscriptions(state) {
    const lastSubscriptionFetch = state.lastSubscriptionFetch;
    if (!lastSubscriptionFetch) {
      return true;
    }
    const currentTimeStamp = new Date().getTime();
    return (currentTimeStamp - lastSubscriptionFetch) / 1000 > 60;
  },
  subscriptions(state) {
    return state.subscriptions;
  },
  playerResults(state) {
    return state.playerResults;
  },
  pouleInformation(state) {
    return state.pouleInformation;
  },
  poulePlayers(state) {
    return state.poulePlayers;
  },
  pouleMatches(state) {
    return state.pouleMatches;
  },
  leagueStatistics(state) {
    return state.leagueStatistics;
  }
};
