<template>
  <div>
    <slot></slot>
    <base-dialog
      :show="!!error"
      title="Er trad een fout op!"
      @close="handleError"
    >
      <p>{{ error }}</p>
    </base-dialog>
    <base-dialog
      :show="confirmReservation"
      :fixed="true"
      title="Confirmeer je reservatie"
      @close="closeConfirmation"
    >
      <h3>Graag nog je aandacht!</h3>
      <p>Respecteer de geldende CORONA-maatregelen aub!</p>
      <!-- <p>
        1.hou steeds voldoende afstand!<br />
        2.draag steeds een mondmasker bij het betreden van de zaal, alsook bij
        het verlaten van de zaal. Idem bij het doorschuiven of als je naar het
        toilet gaat.<br />
        3.volg de (door)looprichting die staat aangegeven op de website.<br />
        4.ontsmet je handen bij het betreden van de zaal en aarzel niet dat ook
        te doen tussen de wedstrijden of als je naar het toilet gaat.<br />
        5.er zijn geen kleedkamers beschikbaar!<br />
        6.wie zich niet wenst te registreren, komt er niet in...<br />
        7.na de sport keer je huiswaarts, dat eigen bakje bier drink je dus
        thuis uit!<br />
      </p> -->
      <base-button @click="saveReservation">Ok</base-button>
      <base-button @click="closeConfirmation">Sluit</base-button>
    </base-dialog>
    <div>
      <section>
        <base-card>
          <div v-if="isLoading || isUpdating">
            <base-spinner></base-spinner>
          </div>

          <div class="playermoment">
            <h3><i class="far fa-calendar-check"></i>&nbsp;Speelmoment</h3>
            <base-combo
              :options="playerMoments"
              :default="defaultMoment"
              @input="selectPlayerMoment"
            >
            </base-combo>
          </div>
          <div v-if="showReservationCount" :class="reservationClass">
            Actueel aantal reservaties: {{ nrOfReservations }} / {{ capacity }}
            <div v-if="overCapacity">
              Je kan NIET meer reserveren voor dit speelmoment!
            </div>
          </div>
          <p></p>

          <div v-if="moreThanOneMember">
            <h3>Reserveren voor</h3>
            <reservation-member
              v-for="member in membersList"
              :key="member.id"
              :memberid="member.memberId"
              :membername="member.memberName"
              :selected="member.selected"
              @set-member="selectMember"
            ></reservation-member>
          </div>

          <h3><i class="far fa-file-alt"></i>&nbsp;Op- of aanmerkingen?</h3>
          <textarea id="textarea" rows="3" v-model="remark" />
          <p></p>
          <base-button
            @click="showConfirmation"
            v-if="!showReservationCount || !overCapacity"
            ><i class="fas fa-check"></i>&nbsp;Reserveer dit moment</base-button
          >
          <p></p>
          <base-button @click="backToOverview"
            ><i class="far fa-list-alt"></i>&nbsp;Terug naar mijn
            reservaties-overzicht</base-button
          >
        </base-card>
      </section>
    </div>
  </div>
</template>

<script>
import ReservationMember from '../../components/reservations/ReservationMember.vue';

export default {
  components: {
    ReservationMember
  },
  data() {
    return {
      isLoading: false,
      isUpdating: false,
      error: null,
      eventId: 0,
      selectedMember: 0,
      remark: '',
      confirmReservation: false
    };
  },
  computed: {
    playerMoments() {
      const momentsList = this.$store.getters['playermoments/playerMoments'];
      const moments = [{ id: 0, name: 'Selecteer speelmoment' }];

      const today = new Date();
      today.setHours(0, 0, 0, 0);
      momentsList.forEach(moment => {
        const playDate = new Date(moment.playDate);
        playDate.setHours(0, 0, 0, 0);
        if (playDate >= today) {
          moments.push({ id: moment.eventId, name: moment.moment });
        }
      });

      return moments;
    },
    defaultMoment() {
      return { id: 0, name: 'Selecteer speelmoment' };
    },
    isEventSelected() {
      if (this.eventId > 0) {
        return true;
      }

      return false;
    },
    membersList() {
      const members = this.$store.getters['myMembers'];
      return members;
    },
    moreThanOneMember() {
      const members = this.$store.getters['myMembers'];
      if (members && members.length > 1) {
        return true;
      }

      return false;
    },
    capacity() {
      let cap = 0;
      const momentsList = this.$store.getters['playermoments/playerMoments'];
      momentsList.forEach(moment => {
        if (moment.eventId == this.eventId) {
          cap = moment.capacity;
        }
      });

      return cap;
    },
    nrOfReservations() {
      let res = 0;
      const momentsList = this.$store.getters['playermoments/playerMoments'];
      momentsList.forEach(moment => {
        if (moment.eventId == this.eventId) {
          res = moment.nrOfReservations;
        }
      });

      return res;
    },
    showReservationCount() {
      if (
        this.eventId &&
        this.eventId > 0 &&
        this.nrOfReservations > 0 &&
        this.capacity > 0
      ) {
        return true;
      }

      return false;
    },
    overCapacity() {
      if (parseInt(this.nrOfReservations) >= parseInt(this.capacity)) {
        return true;
      } else {
        return false;
      }
    },
    reservationClass() {
      if (this.overCapacity) {
        return 'overcapacity';
      } else {
        return 'reservation';
      }
    }
  },
  methods: {
    async loadPlayerMoments() {
      this.isLoading = true;

      try {
        await this.$store.dispatch(
          'playermoments/loadPlayerMomentsForReservation'
        );
      } catch (err) {
        this.error =
          err.message ||
          'Er trad een probleem op bij het ophalen van de speelmomenten!';
      }

      this.isLoading = false;

      // Close navigation
      if (this.$store.getters.isNavOpen) {
        this.$store.dispatch('toggleNav');
      }
    },
    async loadMyMembers() {
      this.isLoading = true;

      try {
        await this.$store.dispatch('loadMyMembers');
      } catch (err) {
        this.error =
          err.message ||
          'Er trad een probleem op bij het ophalen van de fiches waartoe je toegang hebt!';
      }

      const members = this.$store.getters['myMembers'];

      if (members && members.length === 1) {
        this.selectedMember = members[0].memberId;
      }

      this.isLoading = false;
    },
    async loadReservations() {
      this.isLoading = true;

      try {
        await this.$store.dispatch('reservations/loadReservations', {
          forceRefresh: false,
          memberIds: this.$store.getters['memberIds']
        });
      } catch (err) {
        this.error =
          err.message ||
          'Er trad een probleem op bij het ophalen van de reeds geboekte reservaties!';
      }

      this.isLoading = false;
    },
    async selectPlayerMoment(moment) {
      if (moment) {
        this.eventId = moment.id;

        if (
          this.eventId &&
          this.eventId > 0 &&
          this.selectedMember &&
          this.selectedMember > 0
        ) {
          const reservations = this.$store.getters['reservations/reservations'];
          reservations.forEach(reservation => {
            if (
              reservation.eventId == this.eventId &&
              reservation.memberId == this.selectedMember
            ) {
              this.remark = reservation.remark;
            }
          });
        }
      } else {
        this.eventId = 0;
      }
    },
    async selectMember(selectedMember) {
      this.selectedMember = selectedMember.memberId;
      this.$store.dispatch('setMemberId', this.selectedMember);

      if (
        this.eventId &&
        this.eventId > 0 &&
        this.selectedMember &&
        this.selectedMember > 0
      ) {
        const reservations = this.$store.getters['reservations/reservations'];
        if (reservations) {
          reservations.forEach(reservation => {
            if (
              reservation.eventId == this.eventId &&
              reservation.memberId == this.selectedMember
            ) {
              this.remark = reservation.remark;
            }
          });
        }
      }
    },
    async saveReservation() {
      this.isUpdating = true;

      // console.log(this.eventId);
      // console.log(this.selectedMember);
      if (
        this.eventId &&
        this.eventId > 0 &&
        this.selectedMember &&
        this.selectedMember > 0
      ) {
        try {
          await this.$store.dispatch('reservations/updateReservation', {
            eventId: this.eventId,
            memberId: this.selectedMember,
            remark: this.remark
          });

          this.eventId = 0;
          this.selectedMember = 0;

          const members = this.membersList;
          members.forEach(member => {
            member.selected = false;
          });

          this.$store.dispatch('reservations/resetFetchTime');
          this.$router.push('reservations');
        } catch (err) {
          this.error =
            err.message ||
            'Er trad een probleem op bij het boeken van de reservatie!';
        }
      }

      this.isUpdating = false;
    },
    closeConfirmation() {
      this.confirmReservation = false;
    },
    showConfirmation() {
      // console.log(this.selectedMember);
      if (
        this.eventId &&
        this.eventId > 0 &&
        this.selectedMember &&
        this.selectedMember > 0
      ) {
        this.confirmReservation = true;
      }
    },
    handleError() {
      this.error = null;
    },
    backToOverview() {
      this.$router.push('/reservations');
    }
  },
  created() {
    this.loadPlayerMoments();
    this.loadMyMembers();
    this.loadReservations();
  }
};
</script>

<style scoped>
ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.nrofplayers {
  font-size: 24px;
  font-weight: bold;
}

.controls {
  display: flex;
  justify-content: space-between;
}

.discipline {
  width: 100%;
}

.reservation {
  font-size: 24px;
  font-weight: bold;
  border-radius: 12px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);
  padding: 0.5rem;
  margin-top: 10px;
  width: 100%;
}

.overcapacity {
  font-size: 24px;
  font-weight: bold;
  border-radius: 12px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);
  padding: 0.5rem;
  margin-top: 10px;
  width: 100%;
  background-color: red;
}
</style>
