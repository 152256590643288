<template>
  <div class="custom-select" v-if="options">
    <!-- Dropdown Input -->
    <input
      class="selected"
      :name="name"
      @focus="showOptions()"
      @blur="exit()"
      @keyup="keyMonitor"
      @keypress="keyMonitor"
      v-model="searchFilter"
      :disabled="disabled"
      :placeholder="placeholder"
    />

    <!-- Dropdown Menu -->
    <div class="items" v-show="optionsShown">
      <div
        @mousedown="selectOption(option)"
        v-for="(option, index) in filteredOptions"
        :key="index"
      >
        {{ option.name || option.id || '-' }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Dropdown',
  template: 'Dropdown',
  props: {
    name: {
      type: String,
      required: false,
      default: 'dropdown',
      note: 'Input name'
    },
    options: {
      type: Array,
      required: true,
      note: 'Options of dropdown. An array of options with id and name'
    },
    placeholder: {
      type: String,
      required: false,
      default: 'Selecteer optie',
      note: 'Placeholder of dropdown'
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
      note: 'Disable the dropdown'
    },
    maxItem: {
      type: Number,
      required: false,
      default: 6,
      note: 'Max items showing'
    },
    preSelect: {
      type: String,
      required: false,
      default: ''
    },
    clearOnFocus: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  emits: ['selected', 'filter'],
  data() {
    return {
      selected: {},
      optionsShown: false,
      searchFilter: ''
    };
  },
  created() {
    if (this.preSelect != '') {
      this.selected = this.preSelect;
      this.searchFilter = this.preSelect;
    }
    this.$emit('selected', this.selected);
  },
  computed: {
    filteredOptions() {
      const filtered = [];
      const regOption = new RegExp(this.searchFilter, 'ig');
      for (const option of this.options) {
        if (this.searchFilter.length < 1 || option.name.match(regOption)) {
          if (filtered.length < this.maxItem) filtered.push(option);
        }
      }
      return filtered;
    }
  },
  methods: {
    selectOption(option) {
      this.selected = option;
      this.optionsShown = false;
      this.searchFilter = this.selected.name;
      this.$emit('selected', this.selected);
    },
    showOptions() {
      if (!this.disabled) {
        if (this.clearOnFocus) {
          this.searchFilter = '';
        } else {
          this.searchFilter = this.searchFilter.substring(0, 3);
        }
        this.optionsShown = true;
      }
    },
    exit() {
      if (!this.selected.id) {
        this.selected = {};
        this.searchFilter = '';
      } else {
        this.searchFilter = this.selected.name;
      }
      this.$emit('selected', this.selected);
      this.optionsShown = false;
    },
    // Selecting when pressing Enter
    keyMonitor: function(event) {
      if (event.key === 'Enter' && this.filteredOptions[0])
        this.selectOption(this.filteredOptions[0]);
    }
  },
  watch: {
    searchFilter() {
      if (this.filteredOptions.length === 0) {
        this.selected = {};
      } else {
        this.selected = this.filteredOptions[0];
      }
      this.$emit('filter', this.searchFilter);
    }
  }
};
</script>

<style scoped>
.selected {
  position: relative;
  width: 100%;
  color: var(--var-font-color);
  text-align: left;
  outline: none;
  height: 32px;
  line-height: 32px;
}

.custom-select {
  position: relative;
  width: 100%;
  text-align: left;
  outline: none;
  height: 32px;
  line-height: 32px;
}

.custom-select .selected {
  font-size: 18px;
  font-weight: bold;
  background-color: var(--var-button-background);
  border-radius: 2px;
  border: 1px solid var(--var-font-color);
  color: var(--var-font-color);
  padding-left: 1em;
  cursor: pointer;
  user-select: none;
}

.custom-select .selected.open {
  border: 1px solid var(--var-button-background-focused);
  border-radius: 6px 6px 0px 0px;
}

.custom-select .selected:after {
  position: absolute;
  content: '';
  top: 22px;
  right: 1em;
  width: 0;
  height: 0;
  border: 5px solid transparent;
  border-color: #fff transparent transparent transparent;
}

.custom-select .items {
  color: var(--var-font-color);
  border-radius: 0px 0px 6px 6px;
  overflow: hidden;
  border: 1px solid var(--var-font-color);
  position: absolute;
  background-color: var(--var-button-background);
  left: 0;
  right: 0;
  z-index: 1;
}

.custom-select .items div {
  font-size: 18px;
  font-weight: bold;
  color: var(--var-font-color);
  padding-left: 1em;
  cursor: pointer;
  user-select: none;
}

.custom-select .items div:hover {
  background-color: var(--var-button-background-focused);
}
</style>
