export default {
  async loadWeekDigests(context) {
    let url = context.rootState.apiUrl + 'weekdigest.php';

    const token = context.rootState.token;
    const response = await fetch(url, {
      method: 'POST',
      body: JSON.stringify({
        jwt: token
      })
    });

    const responseData = await response.json();
    // console.log(responseData);

    if (!response.ok) {
      const error = new Error(
        responseData.message || 'Fout bij ophalen van de weekdigest!'
      );
      throw error;
    }

    const digests = [];

    for (const key in responseData) {
      let result = 'Gelijkspel';
      if (responseData[key].Won == '1') {
        result = 'Gewonnen';
      }
      if (responseData[key].Won == '-1') {
        result = 'Verloren';
      }
      const digest = {
        id: key,
        team: responseData[key].Team,
        week: responseData[key].Week,
        playDate: responseData[key].PlayDate,
        playTime: responseData[key].PlayTime,
        matchId: responseData[key].MatchId,
        division: responseData[key].Division,
        meeting: responseData[key].Meeting,
        matchUrl: responseData[key].MatchUrl,
        won: responseData[key].Won,
        result: result,
        score: responseData[key].Score,
        position: responseData[key].Position,
        points: responseData[key].Points,
        composition: responseData[key].Composition
      };

      digests.push(digest);
    }

    context.commit('setWeekDigests', digests);
  },
  async loadRankings(context) {
    let url = context.rootState.apiUrl + 'teamrankings.php';

    const token = context.rootState.token;
    const response = await fetch(url, {
      method: 'POST',
      body: JSON.stringify({
        jwt: token
      })
    });

    const responseData = await response.json();
    // console.log(responseData);

    if (!response.ok) {
      const error = new Error(
        responseData.message || 'Fout bij ophalen van de weekdigest!'
      );
      throw error;
    }

    const rankings = [];

    for (const key in responseData) {
      const ranking = {
        id: key,
        teamId: responseData[key].TeamId,
        team: responseData[key].Team,
        position: responseData[key].Position,
        club: responseData[key].Club,
        teamDescription: responseData[key].TeamDescription,
        gamesPlayed: responseData[key].GamesPlayed,
        gamesWon: responseData[key].GamesWon,
        gamesLost: responseData[key].GamesLost,
        gamesDraw: responseData[key].GamesDraw,
        individualMatchesWon: responseData[key].IndividualMatchesWon,
        individualMatchesLost: responseData[key].IndividualMatchesLost,
        individualSetsWon: responseData[key].IndividualSetsWon,
        individualSetsLost: responseData[key].IndividualSetsLost,
        points: responseData[key].Points
      };

      rankings.push(ranking);
    }

    context.commit('setRankings', rankings);
  },
  async loadTeamPlayers(context) {
    let url = context.rootState.apiUrl + 'teamplayers.php';

    const token = context.rootState.token;
    const response = await fetch(url, {
      method: 'POST',
      body: JSON.stringify({
        jwt: token
      })
    });

    const responseData = await response.json();
    // console.log(responseData);

    if (!response.ok) {
      const error = new Error(
        responseData.message || 'Fout bij ophalen van de ploegsamenstellingen!'
      );
      throw error;
    }

    const teamPlayers = [];

    for (const key in responseData) {
      const teamPlayer = {
        id: key,
        team: responseData[key].team,
        rank: responseData[key].rank,
        memberName: responseData[key].name,
        ranking: responseData[key].ranking,
        telephone: responseData[key].telephone,
        email: responseData[key].email,
        captain: responseData[key].captain == 1 ? true : false
      };

      teamPlayers.push(teamPlayer);
    }

    context.commit('setTeamPlayers', teamPlayers);
  },
  async loadTeamCalendars(context) {
    let url = context.rootState.apiUrl + 'teamcalendar.php';

    const token = context.rootState.token;
    const response = await fetch(url, {
      method: 'POST',
      body: JSON.stringify({
        jwt: token
      })
    });

    const responseData = await response.json();
    // console.log(responseData);

    if (!response.ok) {
      const error = new Error(
        responseData.message || 'Fout bij ophalen van de ploegkalenders!'
      );
      throw error;
    }

    const teamCalendars = [];

    for (const key in responseData) {
      const teamCalendar = {
        id: key,
        team: responseData[key].team,
        week: responseData[key].week,
        playId: responseData[key].playid,
        matchId: responseData[key].matchid,
        matchUrl: responseData[key].matchurl,
        playDate: responseData[key].playdate,
        homeTeamCode: responseData[key].hometeamcode,
        homeTeam: responseData[key].hometeam,
        outTeamCode: responseData[key].outteamcode,
        outTeam: responseData[key].outteam,
        score: responseData[key].score,
        won: responseData[key].won
      };

      teamCalendars.push(teamCalendar);
    }
    // console.log(teamCalendars);
    context.commit('setTeamCalendars', teamCalendars);
  },
  async loadWeekResults(context) {
    let url = context.rootState.apiUrl + 'weekoverview.php';

    const token = context.rootState.token;
    const response = await fetch(url, {
      method: 'POST',
      body: JSON.stringify({
        jwt: token
      })
    });

    const responseData = await response.json();
    // console.log(responseData);

    if (!response.ok) {
      const error = new Error(
        responseData.message || 'Fout bij ophalen van de weekresultaten!'
      );
      throw error;
    }

    const weekResults = [];

    for (const key in responseData) {
      const weekResult = {
        id: key,
        team: responseData[key].team,
        listNumber: responseData[key].listnumber,
        memberName: responseData[key].membername,
        ranking: responseData[key].ranking,
        w01: responseData[key].week01,
        w02: responseData[key].week02,
        w03: responseData[key].week03,
        w04: responseData[key].week04,
        w05: responseData[key].week05,
        w06: responseData[key].week06,
        w07: responseData[key].week07,
        w08: responseData[key].week08,
        w09: responseData[key].week09,
        w10: responseData[key].week10,
        w11: responseData[key].week11,
        w12: responseData[key].week12,
        w13: responseData[key].week13,
        w14: responseData[key].week14,
        w15: responseData[key].week15,
        w16: responseData[key].week16,
        w17: responseData[key].week17,
        w18: responseData[key].week18,
        w19: responseData[key].week19,
        w20: responseData[key].week20,
        w21: responseData[key].week21,
        w22: responseData[key].week22,
        entries: responseData[key].entries,
        saldo: responseData[key].saldo,
        percentage: responseData[key].percentage,
        percentageString: responseData[key].percentagedesc,
        prognose: responseData[key].residu
      };

      weekResults.push(weekResult);
    }

    context.commit('setWeekResults', weekResults);
  },
  async loadTeamConfirmations(context, payload) {
    let url = context.rootState.apiUrl + 'teamconfirmations.php';
    const token = context.rootState.token;
    const response = await fetch(url, {
      method: 'POST',
      body: JSON.stringify({
        jwt: token,
        teamId: payload.teamId
      })
    });

    const responseData = await response.json();
    // console.log(responseData);

    if (!response.ok) {
      const error = new Error(
        responseData.message || 'Fout bij ophalen van de spelersconfirmaties!'
      );
      throw error;
    }

    const teamConfirmations = [];

    for (const key in responseData) {
      const teamConfirmation = {
        id: key,
        teamCalendarId: responseData[key].teamCalendarId,
        week: responseData[key].week,
        meeting: responseData[key].meeting,
        meetingDate: responseData[key].meetingDate,
        isHome: responseData[key].isHome,
        isPast: responseData[key].isPast,
        isTeamPlayer: responseData[key].isTeamPlayer,
        captain: responseData[key].captain,
        playerId: responseData[key].playerId,
        playerName: responseData[key].playerName,
        ranking: responseData[key].ranking,
        confirmationOption: responseData[key].confirmationOption,
        confirmationVariant: responseData[key].confirmationVariant,
        confirmationDescription: responseData[key].confirmationDescription,
        canPlay: responseData[key].canPlay,
        canDrive: responseData[key].canDrive,
        remarks: responseData[key].remarks,
        hasBeenSetup: responseData[key].hasBeenSetup,
        status: responseData[key].status,
        statusInformation: responseData[key].statusInformation,
        victoryCount: responseData[key].victoryCount,
        nrOfParticipations: responseData[key].nrOfParticipations
      };

      teamConfirmations.push(teamConfirmation);
    }

    // console.log(teamConfirmations);
    context.commit('setTeamConfirmations', teamConfirmations);
  }
};
