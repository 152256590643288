<template>
  <base-button mode="badge" @click="setId" :class="selectedClass">
    {{ description }}
  </base-button>
</template>

<script>
export default {
  props: ['typeid', 'description', 'selectedid'],
  emits: ['set-id'],
  computed: {
    selectedClass() {
      if (this.selectedid && this.selectedid == this.typeid) {
        return 'selected';
      } else {
        return 'unselected';
      }
    }
  },
  methods: {
    setId() {
      this.$emit('set-id', this.typeid);
    }
  }
};
</script>

<style scoped>
.unselected {
  background-color: var(--var-badge);
}

.selected {
  background-color: var(--var-selected);
}
</style>
