<template>
  <tr>
    <td>
      {{ listnumber }}
    </td>
    <td v-if="isTableTennis">
      {{ indexnumber }}
    </td>
    <td v-if="isTableTennis">
      <strong
        ><a :href="url" target="_blank">{{ associationid }}</a></strong
      >
    </td>
    <td>
      <strong>{{ membername }}</strong>
    </td>
    <td v-if="!isTableTennis">
      <div v-if="validEmail" class="small">
        <a :href="mailto"
          ><strong>{{ email }}</strong></a
        >
      </div>
      <!--    <div v-if="!!address">Adres: <strong>{{ address }}</strong></div>
    <div v-if="!!telephone">Tel: <a :href="phoneto"><strong>{{ telephone }}</strong></a></div>
    <div v-if="!!mobile">GSM: <a :href="mobileto"><strong>{{ mobile }}</strong></a></div>
-->
    </td>
    <td>
      <strong>{{ ranking }}</strong>
    </td>
  </tr>
</template>

<script>
export default {
  props: [
    'id',
    'listnumber',
    'indexnumber',
    'membername',
    'address',
    'email',
    'telephone',
    'mobile',
    'ranking',
    'associationid',
    'url'
  ],
  computed: {
    isTableTennis() {
      return this.$store.getters['isTableTennis'];
    },
    validEmail() {
      if (this.email.includes('@')) {
        return true;
      }

      return false;
    },
    mailto() {
      return 'mailto:' + this.email;
    },
    phoneto() {
      var nr = this.telephone;
      nr = nr.replace(/\D/g, '');
      return 'tel:' + nr;
    },
    mobileto() {
      var nr = this.mobile;
      nr = nr.replace(/\D/g, '');
      return 'tel:' + nr;
    }
  }
};
</script>

<style scoped>
li {
  border: none;
}

h3 {
  font-size: 1.5rem;
}

h3,
h4 {
  margin: 0.5rem 0;
}

div {
  margin: 0rem 0;
}

.small {
  font-size: small;
}
</style>
