<template>
  <div>
    <base-dialog
      :show="showAnnulation"
      :fixed="true"
      title="Reservatie annuleren"
      @close="closeAnnulation"
    >
      <p><strong>Mag de reservatie</strong></p>

      <h3>{{ description }}</h3>
      <i class="fas fa-file-signature"></i>&nbsp;Op naam van: {{ membername }}<br />
      <i class="far fa-calendar-check"></i>&nbsp;Gereserveerd op: {{ reservationdate }}<br />
      <div v-if="!!remark"><i class="far fa-file-alt"></i>&nbsp;Opmerkingen: {{ remark }}</div>
      <p></p>
      <p><strong>geannuleerd worden?</strong></p>
      <base-button @click="cancelReservation">Reservatie annuleren</base-button>
      <base-button @click="closeAnnulation">Sluit</base-button>
    </base-dialog>
    <li>
      <h3>
        <span class="delete"
          ><i class="far fa-trash-alt" @click="setAnnulation"></i
        ></span>
        &nbsp;{{ description }}
      </h3>
      <br />
      <i class="fas fa-file-signature"></i>&nbsp;Op naam van: {{ membername }}<br />
      <i class="far fa-calendar-check"></i>&nbsp;Gereserveerd op: {{ reservationdate }}<br />
      <div v-if="!!remark"><i class="far fa-file-alt"></i>&nbsp;Opmerkingen: {{ remark }}</div>
    </li>
  </div>
</template>

<script>
export default {
  props: [
    'id',
    'eventid',
    'memberid',
    'membername',
    'description',
    'remark',
    'reservationdate'
  ],
  emits: ['cancel-reservation'],
  data() {
    return {
      showAnnulation: false
    };
  },
  computed: {},
  methods: {
    setAnnulation() {
      this.showAnnulation = true;
    },
    closeAnnulation() {
      this.showAnnulation = false;
    },
    cancelReservation() {
      this.$emit('cancel-reservation', {
        eventId: this.eventid,
        memberId: this.memberid
      });
      this.showAnnulation = false;
    }
  }
};
</script>

<style scoped>
li {
  list-style: none;
  font-weight: bold;
  margin: 1rem 0;
  border: 1px solid #424242;
  border-radius: 12px;
  padding: 1rem;
}

.delete {
  cursor: pointer;
}
</style>
