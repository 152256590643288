<template>
  <sterktelijst-selection
    v-for="discipline in disciplines"
    :key="discipline.id"
    :id="discipline.id"
    :discipline="discipline.description"
    :selecteddiscipline="selectedDiscipline"
    @set-discipline="selectDiscipline"
  >
  </sterktelijst-selection>
</template>

<script>
import SterktelijstSelection from './SterktelijstSelection.vue';

export default {
  components: {
    SterktelijstSelection
  },
  emits: ['change-discipline'],
  data() {
    return {
      disciplines: [],
      selectedDiscipline: 1
    };
  },
  methods: {
    async loadDisciplines() {
      this.disciplines = this.$store.getters['disciplines'];
    },
    selectDiscipline(payload) {
      this.selectedDiscipline = payload.discipline;
      this.$emit('change-discipline', {
        discipline: this.selectedDiscipline
      });
    }
  },
  created() {
    this.loadDisciplines();
  }
};
</script>

<style scoped>
h2 {
  margin: 0.5rem 0;
}
</style>
