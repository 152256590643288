export default {
  hasSportsCampMoments(state) {
    return state.sportsCampMoments && state.sportsCampMoments.length > 0;
  },
  subscriptions(state) {
    return state.subscriptions;
  },
  sportsCampMoments(state) {
    return state.sportsCampMoments;
  },
  presences(state) {
    return state.presences;
  },
  materialList(state) {
    return state.materialList;
  },
  theme(state) {
    return state.theme;
  },
  material(state) {
    return state.material;
  },
  planning(state) {
    return state.planning;
  },
  trainers(state) {
    return state.trainers;
  },
  trainerDayPlanning(state) {
    return state.trainerDayPlanning;
  },
  trainerPresences(state) {
    return state.trainerPresences;
  }
};
