<template>
  <div>
    <slot></slot>
    <base-dialog
      :show="!!error"
      title="Er trad een fout op bij het ophalen van de sterktelijsten!"
      @close="handleError"
    >
      <p>{{ error }}</p>
    </base-dialog>
    <base-card>
      <section>
        <sterktelijst-filter
          @change-discipline="setFilter"
        ></sterktelijst-filter>
      </section>
    </base-card>
    <section>
      <base-card>
        <div v-if="isLoading">
          <base-spinner></base-spinner>
        </div>
        <div v-else-if="hasSterktelijst">
          <table style="width:100%">
            <sterktelijst-item
              v-for="sterktelijst in filteredSterktelijst"
              :key="sterktelijst.id"
              :id="sterktelijst.id"
              :discipline="sterktelijst.discipline"
              :listnumber="sterktelijst.listNumber"
              :indexnumber="sterktelijst.indexNumber"
              :memberid="sterktelijst.memberId"
              :membername="sterktelijst.memberName"
              :email="sterktelijst.email"
              :telephone="sterktelijst.telephone"
              :mobile="sterktelijst.mobile"
              :ranking="sterktelijst.ranking"
              :associationid="sterktelijst.associationId"
              :url="sterktelijst.url"
            ></sterktelijst-item>
          </table>
        </div>
        <h3 v-else>(Nog) geen sterktelijst(en) beschikbaar</h3>
        <!--        <div class="controls">
          <base-button mode="flat" @click="loadSterktelijsten(true)"
            >Vernieuwen</base-button
          >
        </div>
-->
      </base-card>
    </section>
  </div>
</template>

<script>
import SterktelijstItem from '../../components/sterktelijsten/SterktelijstItem.vue';
import SterktelijstFilter from '../../components/sterktelijsten/SterktelijstFilter.vue';

export default {
  components: {
    SterktelijstItem,
    SterktelijstFilter
  },
  data() {
    return {
      isLoading: false,
      error: null,
      discipline: '1'
    };
  },
  computed: {
    hasSterktelijst() {
      const sterktelijstList = this.$store.getters[
        'sterktelijsten/sterktelijsten'
      ];

      return (
        !this.isLoading &&
        sterktelijstList &&
        sterktelijstList.filter(sterktelijst => {
          if (this.discipline != sterktelijst.discipline) {
            return false;
          }

          return true;
        }).length > 0
      );
    },
    filteredSterktelijst() {
      const sterktelijstList = this.$store.getters[
        'sterktelijsten/sterktelijsten'
      ];

      return sterktelijstList.filter(sterktelijst => {
        if (this.discipline != sterktelijst.discipline) {
          return false;
        }

        return true;
      });
    }
  },
  methods: {
    async loadSterktelijsten(refresh = false) {
      this.isLoading = true;

      try {
        await this.$store.dispatch('sterktelijsten/loadSterktelijsten', {
          forceRefresh: refresh
        });
      } catch (err) {
        this.error =
          err.message ||
          'Er trad een probleem op bij het ophalen van de sterktelijsten!';
      }

      this.isLoading = false;

      // Close navigation
      if (this.$store.getters.isNavOpen) {
        this.$store.dispatch('toggleNav');
      }
    },
    handleError() {
      this.error = null;
    },
    setFilter(filter) {
      this.discipline = filter.discipline;
    }
  },
  created() {
    this.loadSterktelijsten(true);
  }
};
</script>

<style scoped>
ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.controls {
  display: flex;
  justify-content: space-between;
}
</style>
