<template>
  <teleport to="body">
    <div v-if="show" @click="tryClose" class="backdrop"></div>
    <transition name="dialog">
      <dialog open v-if="show">
        <header>
          <slot name="header">
            <h2 v-html="getHeaderTitle"></h2>
          </slot>
        </header>
        <section>
          <slot></slot>
        </section>
        <menu v-if="!fixed">
          <slot name="actions">
            <base-button @click="tryClose" mode="smallbold"
              ><i class="fas fa-check"></i>&nbsp;Sluit</base-button
            >
          </slot>
        </menu>
      </dialog>
    </transition>
  </teleport>
</template>

<script>
export default {
  props: {
    show: {
      type: Boolean,
      required: true
    },
    awesomeicon: {
      type: String,
      required: false
    },
    title: {
      type: String,
      required: false
    },
    fixed: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  emits: ['close'],
  computed: {
    getHeaderTitle() {
      let headerTitle = '';
      if (this.awesomeicon) {
        headerTitle = '<i class="' + this.awesomeicon + '"></i>&nbsp;';
      }

      headerTitle = headerTitle + this.title;
      // console.log(headerTitle);

      return headerTitle;
    }
  },
  methods: {
    tryClose() {
      if (this.fixed) {
        return;
      }
      this.$emit('close');
    }
  }
};
</script>

<style scoped>
.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.75);
  z-index: 10;
}

dialog {
  position: fixed;
  top: 30vh;
  left: 10%;
  width: 60%;
  z-index: 100;
  border-radius: 12px;
  border: none;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);
  padding: 0;
  margin: 0;
  overflow: hidden;
  background-color: white;
}

header {
  background-color: var(--var-button-background);
  color: var(--var-font-color);
  width: 100%;
  padding: 0.5rem;
}

header h2 {
  margin: 0;
  font-size: 28px;
}

section {
  padding: 0.5rem;
}

menu {
  padding: 1rem;
  display: flex;
  justify-content: flex-end;
  margin: 0;
}

.dialog-enter-from,
.dialog-leave-to {
  opacity: 0;
  transform: scale(0.8);
}

.dialog-enter-active {
  transition: all 0.3s ease-out;
}

.dialog-leave-active {
  transition: all 0.3s ease-in;
}

.dialog-enter-to,
.dialog-leave-from {
  opacity: 1;
  transform: scale(1);
}

@media (min-width: 500px) {
  dialog {
    left: calc(50% - 15rem);
    width: 30rem;
  }
}
</style>
