export default {
  setMembers(state, payload) {
    state.members = payload;
  },
  setAbsenceReasons(state, payload) {
    state.absenceReasons = payload;
  },
  setMemberAbsences(state, payload) {
    state.memberAbsences = payload;
  },
  setFetchTimestamp(state) {
    state.lastFetch = new Date().getTime();
  }
};
