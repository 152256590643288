<template>
  <form @submit.prevent="closeForm">
    <div>
      <base-button-select
        optionId="confirmation"
        :optionList="confirmationList"
        :awesomeIconList="confirmationIconList"
        :badgeList="confirmationColorList"
        useAwesomeIcon="true"
        bold="false"
        :defaultOption="selectedConfirmation"
        @selection-change="setSelectedConfirmation"
      ></base-button-select>
    </div>
    <hr />
    <div class="form-control">
      <label for="remarks"
        ><div v-if="participate == 1"><strong>Opmerkingen / info</strong></div>
        <div v-else><strong>Reden niet-deelname</strong></div></label
      >
      <textarea id="remarks" v-model.trim="remarks" rows="2" />
      <span
        class="form-check form-switch membercandrive"
        v-if="!isHome && participate == 1"
      >
        <label for="membercandrive">Ik kan eventueel wel rijden</label>
        <input
          class="form-check-input"
          type="checkbox"
          id="membercandrive"
          :checked="canDrive"
          @change="setCanDrive"
        />
        <p v-if="canDrive" class="candriveinfo">
          Opmerking: hiermee geef je <u>niet</u> aan dat je effectief zal rijden
          maar wel dat je daartoe in de mogelijkheid bent. Je ploeggenoten zien
          dit dan ook meteen en kunnen dan eventueel beroep op je doen.
        </p>
      </span>
      <div class="participateremark" v-if="participate == 1">
        <strong
          ><i class="fas fa-exclamation-triangle"></i>&nbsp;Zorg er ook voor dat
          je tijdig aanwezig bent, aangeraden wordt om een halfuur voor aanvang
          ter plaatse te zijn!</strong
        >
      </div>
      <div class="participateremark" v-else>
        <strong
          ><i class="fas fa-exclamation-triangle"></i>&nbsp;Geef aan de
          interclubleider mee waarom je niet kan deelnemen</strong
        >
      </div>
      <div>
        <base-button
          @click="submitForm"
          mode="smallbold"
          v-html="confirmationButtonCaption"
        ></base-button>
        <base-button @click="closeForm" mode="smallbold">SLUIT</base-button>
      </div>
    </div>
  </form>
</template>

<script>
export default {
  props: [
    'id',
    'confirmationoption',
    'confirmationremarks',
    'candrive',
    'ishome'
  ],
  emits: ['save-confirmation', 'close-confirmation'],
  data() {
    return {
      selectedConfirmation: '',
      participate: 1,
      overwriteRemarkAllowed: true,
      remarks: '',
      canDrive: false,
      isHome: false
    };
  },
  computed: {
    confirmationList() {
      const options = this.$store.getters['teamConfirmationOptions'];
      let a = [];
      options.forEach(option => {
        a.push(option.description);
      });
      return a;
    },
    confirmationIconList() {
      const options = this.$store.getters['teamConfirmationOptions'];
      let a = [];
      options.forEach(option => {
        if (option.participate == 1) {
          a.push('fas fa-check');
        } else {
          a.push('fas fa-ban');
        }
      });
      return a;
    },
    confirmationColorList() {
      const options = this.$store.getters['teamConfirmationOptions'];
      let a = [];
      options.forEach(option => {
        if (option.variant) {
          a.push(option.variant);
        } else {
          a.push('');
        }
      });
      return a;
    },
    confirmationButtonCaption() {
      return this.selectedConfirmation;
    }
  },
  methods: {
    setSelectedConfirmation(selection) {
      this.selectedConfirmation = selection;

      // Get details
      const options = this.$store.getters['teamConfirmationOptions'];
      options.forEach(option => {
        if (option.description == selection) {
          this.participate = option.participate;
          if (option.remarks && this.overwriteRemarkAllowed) {
            this.remarks = option.remarks;
          }
        }
      });
    },
    setCanDrive() {
      this.canDrive = !this.canDrive;
    },
    closeForm() {
      this.$emit('close-confirmation');
    },
    submitForm() {
      // const currentMember = this.$store.getters['memberId'];
      let confirmationOption = 1;
      const options = this.$store.getters['teamConfirmationOptions'];
      options.forEach(option => {
        if (option.description == this.selectedConfirmation) {
          confirmationOption = option.teamConfirmationOptionId;
        }
      });

      const formData = {
        id: this.id,
        participate: this.participate,
        confirmationOption: confirmationOption,
        remarks: this.remarks,
        canDrive: this.canDrive
      };

      this.$emit('save-confirmation', formData);
    }
  },
  created() {
    // Get details
    const options = this.$store.getters['teamConfirmationOptions'];
    options.forEach(option => {
      if (option.teamConfirmationOptionId == this.confirmationoption) {
        this.selectedConfirmation = option.description;
      }
    });

    if (this.selectedConfirmation == '' && options && options.length > 0) {
      this.selectedConfirmation = options[0].description;
    }
  },
  mounted() {
    this.remarks = this.confirmationremarks;
    if (this.remarks) {
      this.overwriteRemarkAllowed = false;
    }

    this.isHome = this.ishome;
    this.canDrive = this.candrive;
  }
};
</script>

<style scoped>
textarea {
  display: block;
  width: 100%;
  border: 1px solid var(--var-edit-bordercolor);
  font: inherit;
}

textarea:focus {
  background-color: var(--var-edit-color-focused);
  outline: none;
  border-color: var(--var-edit-bordercolor-focused);
}

.participateremark {
  margin-top: 1rem;
  margin-bottom: 1rem;
  font-size: 15px;
}

.membercandrive {
  /* Checkbox text */
  font-size: 15px;
  font-weight: bold;
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.candriveinfo {
  color: var(--var-candriveinfo);
}
</style>
