import mutations from './mutations.js';
import actions from './actions.js';
import getters from './getters.js';

export default {
  namespaced: true,
  state() {
    return {
      sportsCampMoments: [],
      subscriptions: [],
      presences: [],
      materialList: [],
      theme: '',
      material: '',
      planning: '',
      trainers: '',
      trainerDayPlanning: '',
      trainerPresences: []
    };
  },
  mutations,
  actions,
  getters
};
