<template>
  <section>
    <base-card>
      <div v-if="!!weekDigest">
        <h2>Weekdigest</h2>
        <li v-for="digest in digests" :key="digest.team">
          <div class="teamtitle">
            <a :href="digest.matchUrl" target="_blank"
              >{{ digest.team }} week {{ digest.week }}: {{ digest.meeting }}
            </a>
          </div>
          <div :class="digestResult(digest.won)" class="teamposition">
            {{ digest.result }}: {{ digest.score }}, {{ digest.position }}
          </div>
          <div class="teamcomposition" v-html="digest.composition"></div>
          <hr />
        </li>
      </div>
      <div v-else>
        <h2>{{ appName }}</h2>
        <p v-html="appDescription"></p>
      </div>
    </base-card>
  </section>
</template>

<script>
import { ref, computed } from 'vue';
import { useStore } from 'vuex';

export default {
  setup() {
    const store = useStore();

    let digests = ref([]);

    const appName = computed(function() {
      return store.getters.appName;
    });

    const appDescription = computed(function() {
      return store.getters.appDescription;
    });

    const weekDigest = computed(function() {
      return digests.value && digests.value.length > 0;
    });

    const digestResult = computed(function() {
      return won => {
        if (won == '1') {
          return 'teamwon';
        }
        if (won == '-1') {
          return 'teamlost';
        }

        return 'teamequal';
      };
    });

    async function loadWeekDigests() {
      try {
        await store.dispatch('statistics/loadWeekDigests', {}).then(() => {
          digests.value = store.getters['statistics/weekDigests'];
        });
      } catch (err) {
        console.log(
          err.message ||
            'Er trad een probleem op bij het ophalen van de weekdigest!'
        );
      }
    }

    loadWeekDigests();

    // Close navigation
    if (store.getters.isNavOpen) {
      store.dispatch('toggleNav');
    }

    return {
      appName,
      appDescription,
      weekDigest,
      digests,
      digestResult
    };
  }
};
</script>

<style scoped>
li {
  list-style: none;
  margin: 0;
  padding: 0;
}

.teamtitle {
  font-size: 22px;
}

.teamposition {
  font-size: 18px;
}

.teamcomposition {
  font-size: 14px;
  font-style: italic;
}

.teamwon {
  color: var(--var-won);
}

.teamlost {
  color: var(--var-lost);
}

.teamequal {
  color: var(--var--draw);
}
</style>
