export default {
  async loadReservations(context, payload) {
    if (!payload.forceRefresh && !context.getters.shouldUpdate) {
      return;
    }

    let url = context.rootState.apiUrl + 'reservation_list.php';

    const token = context.rootState.token;
    const response = await fetch(url, {
      method: 'POST',
      body: JSON.stringify({
        jwt: token,
        member_ids: payload.memberIds
      })
    });

    const responseData = await response.json();

    if (!response.ok) {
      const error = new Error(
        responseData.message || 'Fout bij ophalen van de reservaties!'
      );
      throw error;
    }

    const reservations = [];

    for (const key in responseData) {
      const reservation = {
        id: key,
        eventReservationId: responseData[key].eventreservation_id,
        eventId: responseData[key].event_id,
        description: responseData[key].description,
        memberId: responseData[key].member_id,
        memberName: responseData[key].membername,
        email: responseData[key].email,
        telephone: responseData[key].telephone,
        mobile: responseData[key].mobile,
        birthDate: responseData[key].birthdate,
        remark: responseData[key].remark,
        reservationDate: responseData[key].reservationdate
      };
      reservations.push(reservation);
    }

    context.commit('setReservations', reservations);
    context.commit('setFetchTimestamp');
  },
  clearReservations(context) {
    const reservations = [];
    context.commit('setReservations', reservations);
  },
  async updateReservation(context, payload) {
    let url = context.rootState.apiUrl + 'reservation_update.php';

    const token = context.rootState.token;
    const response = await fetch(url, {
      method: 'POST',
      body: JSON.stringify({
        jwt: token,
        event_id: payload.eventId,
        member_id: payload.memberId,
        remark: payload.remark
      })
    });

    const responseData = await response.json();
    // console.log(responseData);

    if (!response.ok) {
      const error = new Error(
        responseData.message || 'Fout bij het registreren van de reservatie!'
      );
      throw error;
    }
  },
  async cancelReservation(context, payload) {
    let url = context.rootState.apiUrl + 'reservation_delete.php';

    const token = context.rootState.token;
    const response = await fetch(url, {
      method: 'POST',
      body: JSON.stringify({
        jwt: token,
        event_id: payload.eventId,
        member_id: payload.memberId
      })
    });

    const responseData = await response.json();
    // console.log(responseData);

    if (!response.ok) {
      const error = new Error(
        responseData.message || 'Fout bij het annuleren van de reservatie!'
      );
      throw error;
    }
  },
  resetFetchTime(context) {
    context.commit('resetFetchTimestamp');
  }
};
