<template>
  <div>
    <slot></slot>
    <base-popup
      :show="!!error"
      title="Er trad een fout op bij het ophalen van de trainingsmomenten!"
      @close="handleError"
    >
      <p>{{ error }}</p>
    </base-popup>
    <section v-if="!isLoading">
      <training-filter @change-filter="setFilter"></training-filter>
    </section>
    <section>
      <base-card>
        <div v-if="isLoading">
          <base-spinner></base-spinner>
        </div>
        <ul v-else-if="hasTrainingMoments">
          <training-item
            v-for="trainingMoment in filteredTrainingMoments"
            :key="trainingMoment.id"
            :which="which"
            :eventid="trainingMoment.eventId"
            :title="trainingMoment.title"
            :moment="trainingMoment.moment"
            :starttime="trainingMoment.startTime"
            :trainerid="trainingMoment.trainerId"
            :nrofassistents="trainingMoment.nrOfAssistents"
            :assistentid="trainingMoment.assistentId"
            :assistent2id="trainingMoment.assistent2Id"
            :assistent3id="trainingMoment.assistent3Id"
            :assistent4id="trainingMoment.assistent4Id"
            :assistent5id="trainingMoment.assistent5Id"
            :trainingid="trainingMoment.trainingId"
            :description="trainingMoment.description"
            :trainingstatus="trainingMoment.trainingStatus"
            :trainingstatusdescription="
              trainingMoment.trainingStatusDescription
            "
            :trainingmergeid="trainingMoment.trainingMergeId"
            :trainingremark="trainingMoment.trainingRemark"
            :tobeconfirmed="trainingMoment.toBeConfirmed"
          ></training-item>
        </ul>
        <h3 v-else>Geen trainingsmomenten</h3>
      </base-card>
    </section>
  </div>
</template>

<script>
import TrainingItem from '../../components/training/TrainingItem.vue';
import TrainingFilter from '../../components/training/TrainingFilter.vue';

export default {
  components: {
    TrainingItem,
    TrainingFilter
  },
  data() {
    return {
      which: 'open',
      isLoading: false,
      searchTerm: '',
      error: null
    };
  },
  computed: {
    filteredTrainingMoments() {
      const trainingMoments = this.$store.getters['training/trainingMoments'];

      return trainingMoments.filter(trainingMoment => {
        if (this.which === 'open' && trainingMoment.trainingStatus >= '2') {
          return false;
        }

        if (this.searchTerm) {
          return (
            trainingMoment.calendarTitle
              .toLowerCase()
              .includes(this.searchTerm.toLowerCase()) ||
            trainingMoment.description
              .toLowerCase()
              .includes(this.searchTerm.toLowerCase())
          );
        }

        return true;
      });
    },
    hasTrainingMoments() {
      const trainingMoments = this.filteredTrainingMoments;
      return !this.isLoading && trainingMoments.length > 0;
    }
  },
  methods: {
    async loadTrainingMoments() {
      this.isLoading = true;

      try {
        await this.$store.dispatch('training/loadTrainingMoments');
      } catch (err) {
        this.error =
          err.message ||
          'Er trad een probleem op bij het ophalen van de trainingsmomenten!';
      }

      this.isLoading = false;

      // Close navigation
      if (this.$store.getters.isNavOpen) {
        this.$store.dispatch('toggleNav');
      }
    },
    setFilter(updatedSearchTerm) {
      this.searchTerm = updatedSearchTerm;
    },
    handleError() {
      this.error = null;
    }
  },
  created() {
    this.loadTrainingMoments();
  },
  mounted() {
    if (this.$route.params.status) {
      this.which = this.$route.params.status;
    }
  }
};
</script>

<style scoped>
ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.controls {
  display: flex;
  justify-content: space-between;
}
</style>
