<template>
  <div>
    <slot></slot>
    <base-dialog
      :show="!!error"
      title="Er trad een fout op bij het ophalen van de inschrijvingslijst!"
      @close="handleError"
    >
      <p>{{ error }}</p>
    </base-dialog>
    <section>
      <subscription-filter @change-filter="setFilter"></subscription-filter>
    </section>
    <section>
      <base-card>
        <div v-if="isLoading">
          <base-spinner></base-spinner>
        </div>
        <ul v-else-if="hasSubscriptions">
          <subscription-item
            v-for="subscription in filteredSubscriptions"
            :key="subscription.id"
            :id="subscription.id"
            :name="subscription.name"
            :gender="subscription.gender"
            :birthdate="subscription.birthdate"
            :isbirthdaytoday="subscription.isBirthdayToday"
            :address="subscription.address"
            :email="subscription.email"
            :email2="subscription.email2"
            :telephone="subscription.telephone"
            :telephone2="subscription.telephone2"
            :medication="subscription.medication"
            :experience="subscription.experience"
            :extrainfo="subscription.extraInfo"
            :ismember="subscription.isMember"
            :parents="subscription.parents"
            :sportscamp="subscription.sportsCamp"
            :gdpr="subscription.gdpr"
          ></subscription-item>
        </ul>
        <h3 v-else>Geen inschrijvingen</h3>
        <div class="controls">
          <base-button mode="flat" @click="loadSubscriptions(true)"
            >Vernieuwen</base-button
          >
        </div>
      </base-card>
    </section>
  </div>
</template>

<script>
import SubscriptionItem from '../../components/sportscamps/SubscriptionItem.vue';
import SubscriptionFilter from '../../components/sportscamps/SubscriptionFilter.vue';

export default {
  components: {
    SubscriptionItem,
    SubscriptionFilter
  },
  data() {
    return {
      isLoading: false,
      error: null,
      searchTerm: ''
    };
  },
  computed: {
    hasSubscriptions() {
      const subscriptionList = this.$store.getters['sportscamps/subscriptions'];

      return (
        !this.isLoading &&
        subscriptionList &&
        subscriptionList.filter(subscription => {
          if (this.searchTerm) {
            return (
              subscription.name
                .toLowerCase()
                .includes(this.searchTerm.toLowerCase()) ||
              (subscription.email != null &&
                subscription.email
                  .toLowerCase()
                  .includes(this.searchTerm.toLowerCase())) ||
              (subscription.address != null &&
                subscription.address
                  .toLowerCase()
                  .includes(this.searchTerm.toLowerCase()))
            );
          } else {
            return true;
          }
        }).length > 0
      );
    },
    filteredSubscriptions() {
      const subscriptionList = this.$store.getters['sportscamps/subscriptions'];

      return subscriptionList.filter(subscription => {
        if (this.searchTerm) {
          return (
            subscription.name
              .toLowerCase()
              .includes(this.searchTerm.toLowerCase()) ||
            (subscription.email != null &&
              subscription.email
                .toLowerCase()
                .includes(this.searchTerm.toLowerCase())) ||
            (subscription.address != null &&
              subscription.address
                .toLowerCase()
                .includes(this.searchTerm.toLowerCase()))
          );
        } else {
          return true;
        }
      });
    }
  },
  methods: {
    async loadSubscriptions(refresh = false) {
      this.isLoading = true;

      try {
        await this.$store.dispatch('sportscamps/loadSubscriptions', {
          forceRefresh: refresh
        });
      } catch (err) {
        this.error =
          err.message ||
          'Er trad een probleem op bij het ophalen van de inschrijvingslijst!';
      }

      this.isLoading = false;

      // Close navigation
      if (this.$store.getters.isNavOpen) {
        this.$store.dispatch('toggleNav');
      }
    },
    handleError() {
      this.error = null;
    },
    setFilter(updatedSearchTerm) {
      this.searchTerm = updatedSearchTerm;
    }
  },
  created() {
    this.loadSubscriptions(true);
  }
};
</script>

<style scoped>
ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.controls {
  display: flex;
  justify-content: space-between;
}
</style>
