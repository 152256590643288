<template>
  <div>
    <slot></slot>
    <base-dialog
      :show="!!error"
      title="Er trad een fout op bij het ophalen van de aanwezigheidslijst!"
      @close="handleError"
    >
      <p>{{ error }}</p>
    </base-dialog>
    <section>
      <base-card>
        <div v-if="isLoading">
          <base-spinner></base-spinner>
        </div>
        <ul v-else-if="hasPresences">
          <h3>Mijn aanwezigheden als trainer</h3>
          <hr />
          <trainer-presence-item
            v-for="presence in filteredPresences"
            :key="presence.id"
            :ids="presence.ids"
            :campdate="presence.campDate"
            :title="presence.title"
            :responsable="presence.responsable"
            :presence="presence.presence"
            :transport="presence.transport"
            :remarks="presence.remarks"
            :registrationdate="presence.registrationDate"
          ></trainer-presence-item>
        </ul>
        <h3 v-else>Geen aanwezigheidslijst</h3>
      </base-card>
    </section>
  </div>
</template>

<script>
import TrainerPresenceItem from '../../components/sportscamps/TrainerPresenceItem.vue';

export default {
  components: {
    TrainerPresenceItem
  },
  data() {
    return {
      isLoading: false,
      error: null,
      trainerId: null
    };
  },
  computed: {
    hasPresences() {
      const presenceList = this.$store.getters['sportscamps/trainerPresences'];
      // console.log(presenceList);

      return !this.isLoading && presenceList && presenceList.length > 0;
    },
    filteredPresences() {
      const presenceList = this.$store.getters['sportscamps/trainerPresences'];

      return presenceList;
    }
  },
  methods: {
    async loadPresences() {
      this.isLoading = true;

      try {
        await this.$store.dispatch('sportscamps/loadTrainerPresences', {
          trainerId: this.trainerId
        });
      } catch (err) {
        this.error =
          err.message ||
          'Er trad een probleem op bij het ophalen van de aanwezigheidslijst!';
      }

      this.isLoading = false;

      // Close navigation
      if (this.$store.getters.isNavOpen) {
        this.$store.dispatch('toggleNav');
      }
    },
    async loadTransportTypes() {
      try {
        await this.$store.dispatch('loadTransportTypes');
      } catch (err) {
        this.error =
          err.message ||
          'Er trad een probleem op bij het ophalen van de transportwijzes!';
      }
    },
    handleError() {
      this.error = null;
    }
  },
  created() {
    this.trainerId = this.$store.getters.trainerId;
    // console.log(this.trainerId);
    this.loadTransportTypes();
    this.loadPresences();
  }
};
</script>

<style scoped>
ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.controls {
  display: flex;
  justify-content: space-between;
}
</style>
