<template>
  <div>
    <section>
      <div class="form-control">
        <div class="titleinfo">
          <div>Kampgroep(en):</div>
          <div>{{ title }}</div>
        </div>
        <hr />
        <div><label>Heb je training gegeven?</label></div>
        <div class="option" @click="togglePresence">
          <div>
            <base-badge :title="presentText" :type="presentType"></base-badge>
          </div>
        </div>
        <div v-if="presenceInput == 1">
          <label>Hoe heb je je verplaatst?</label>
        </div>
        <div class="option" v-if="presenceInput == 1">
          <div>
            <transport-type-selection
              v-for="transportType in transportTypes"
              :key="transportType.typeId"
              :typeid="transportType.typeId"
              :description="transportType.description"
              :selectedid="transportInput"
              @set-id="setTransportType"
            >
            </transport-type-selection>
          </div>
        </div>
        <div v-if="presenceInput == 1">
          <label for="remarks">Heb je aan- of opmerkingen?</label>
        </div>
        <textarea
          id="remarks"
          name="remarks"
          v-model.trim="remarksInput"
          rows="3"
          onkeypress="return event.keyCode != 13;"
          v-if="presenceInput == 1"
        />
      </div>

      <menu>
        <base-button @click="submitForm" mode="smallbold"
          ><i class="far fa-save"></i>&nbsp;Registreer</base-button
        >
        <base-button @click="closeForm" mode="smallbold">Sluit</base-button>
      </menu>
    </section>
  </div>
</template>

<script>
import BaseBadge from '../ui/BaseBadge.vue';
import TransportTypeSelection from '../general/TransportTypeSelection.vue';

export default {
  components: { BaseBadge, TransportTypeSelection },
  props: ['ids', 'campdate', 'title', 'presence', 'transport', 'remarks'],
  emits: ['close-presence', 'register-presence'],
  data() {
    return {
      presenceInput: null,
      transportInput: null,
      remarksInput: null,

      transportTypes: []
    };
  },
  computed: {
    presentText() {
      if (this.presenceInput == 1) {
        return '<i class="fas fa-check"></i>&nbsp;JA';
      } else {
        return 'NEEN';
      }
    },
    presentType() {
      if (this.presenceInput == 1) {
        return 'checked';
      } else {
        return 'unchecked';
      }
    }
  },
  methods: {
    togglePresence() {
      if (this.presenceInput == 1) {
        this.presenceInput = 0;
      } else {
        this.presenceInput = 1;
      }
    },
    setTransportType(selectedId) {
      this.transportInput = selectedId;
    },
    closeForm() {
      this.$emit('close-presence');
    },
    submitForm() {
      // console.log(this.matches[0]);
      let formData = {
        ids: this.ids,
        presence: this.presenceInput,
        transport: this.transportInput,
        remarks: this.remarksInput
      };

      this.$emit('register-presence', formData);
    }
  },
  mounted() {
    this.presenceInput = this.presence;
    this.transportInput = this.transport;
    this.remarksInput = this.remarks;

    this.transportTypes = this.$store.getters['transportTypes'];
  }
};
</script>

<style scoped>
textarea {
  width: 100%;
}

.titleinfo {
  display: flex;
  gap: 5px;
}

.option {
  display: flex;
  cursor: pointer;
}

.checked {
  background-color: var(--var-selected);
}

.unchecked {
  background-color: var(--var-unselected);
}
.form-control {
  margin: 0.5rem 0;
}

menu {
  padding-top: 1rem;
  display: flex;
  justify-content: flex-end;
  margin: 0;
}
</style>
