<template>
  <div>
    <base-popup :show="isLoading" :fixed="true" title="Even geduld aub...">
      <div>
        <base-spinner></base-spinner>
      </div>
    </base-popup>
    <base-popup
      :show="!!itemError"
      title="Er trad een fout op!"
      @close="handleError"
    >
      <p>{{ itemError }}</p>
    </base-popup>
    <base-dialog :show="driverFormIsVisible" :fixed="true">
      <template v-slot:header>
        <h2>
          <i class="fas fa-car"></i>
          Chauffeur
        </h2>
        <h4>
          {{ driver }}
        </h4>
      </template>
      <template v-slot:default>
        <driver-form
          :id="teamCompositionId"
          :meetingIsPast="meetingIsPast"
          :driver_id="teamDriverId"
          :driver_remarks="driverRemarks"
          @close-driver="closeDriverForm"
          @save-driver="saveDriver"
        ></driver-form>
        <div v-if="isUpdating">
          <base-spinner></base-spinner>
        </div>
      </template>
    </base-dialog>
    <base-dialog :show="removeDriverFormIsVisible" :fixed="false">
      <template v-slot:header>
        <h2>
          <i class="fas fa-car"></i>
          Chauffeur
        </h2>
        <h4>
          {{ driver }}
        </h4>
      </template>
      <template v-slot:default>
        <p>
          <strong
            >Klik '{{ driveText1 }}' aan om aan te geven dat je niet hebt
            gereden of zal rijden voor deze ontmoeting.</strong
          >
        </p>
        <p>
          Je krijgt dit dialoog omdat je eerder hebt aangegeven gereden te
          hebben of aangegeven te hebben dat je hebt gereden. Met deze optie
          annuleer je deze eerdere ingave.
        </p>
      </template>
      <template v-slot:actions>
        <base-button @click="removeDriver" mode="smallbold">{{
          driveText1
        }}</base-button>
        <base-button @click="closeRemoveDriverForm" mode="smallbold"
          >Sluit</base-button
        >
        <div v-if="isUpdating">
          <base-spinner></base-spinner>
        </div>
      </template>
    </base-dialog>
    <base-dialog :show="confirmationFormIsVisible" :fixed="true">
      <template v-slot:header>
        <h2>
          <i class="far fa-handshake"></i>
          Geef aan of je kan deelnemen
        </h2>
      </template>
      <template v-slot:default>
        <team-confirmation-form
          :id="teamCompositionId"
          :confirmationoption="confirmationOption"
          :confirmationremarks="memberRemark"
          :ishome="isHome"
          :candrive="canDriveIsPresent"
          @close-confirmation="closeConfirmationForm"
          @save-confirmation="saveConfirmation"
        ></team-confirmation-form>
        <div v-if="isUpdating">
          <base-spinner></base-spinner>
        </div>
      </template>
    </base-dialog>
    <base-dialog :show="intermediateScoreIsVisible" :fixed="true">
      <template v-slot:header>
        <h2>
          <i class="fas fa-keyboard"></i>
          Tussentijdse score
        </h2>
      </template>
      <template v-slot:default>
        <p>
          <strong
            >Laat iedereen weten wat de tussenstand is in deze
            wedstrijd!</strong
          >
        </p>
        <p>
          <strong
            >Geef desgewenst ook individuele prestaties mee, bijvoorbeeld Jan:
            1/2, ...</strong
          >
        </p>
        <div>
          <label for="score">Tussentijdse score&nbsp;</label>
          <input
            type="text"
            id="score"
            size="30"
            maxlength="80"
            v-model="intermediateScoreEntered"
            onkeypress="return event.keyCode != 13;"
          />
        </div>
        <base-button @click="updateIntermediateScore" mode="smallbold"
          >Opslaan</base-button
        >
        <base-button @click="toggleIntermediateScore" mode="smallbold"
          >Sluit</base-button
        >
        <div v-if="isUpdating">
          <base-spinner></base-spinner>
        </div>
      </template>
    </base-dialog>
    <base-dialog :show="compositionFormIsVisible" :fixed="true">
      <template v-slot:header>
        <h2>
          <i class="fas fa-user-edit"></i>
          {{ teamdescription }}
        </h2>
      </template>
      <template v-slot:default>
        <composition-form
          :id="teamCompositionId"
          :team="currentTeamComposition.team"
          :nrofplayersperteam="nrofplayersperteam"
          :startplayerindex="startplayerindex"
          :availableteamplayers="availableTeamPlayers"
          :teamplayers="currentTeamComposition.members"
          :teamreserves="currentTeamComposition.reserves"
          :confirmed="currentTeamComposition.confirmed"
          :denied="currentTeamComposition.denied"
          @close-composition="closeCompositionForm"
          @save-composition="saveComposition"
        ></composition-form>
        <div v-if="isUpdating">
          <base-spinner></base-spinner>
        </div>
      </template>
    </base-dialog>
    <base-dialog :show="iCalFormIsVisible" :fixed="false" @close="hideICalForm">
      <template v-slot:header>
        <h2>
          <i class="fas fa-mail"></i>
          {{ teamdescription }}
        </h2>
      </template>
      <template v-slot:default>
        <team-ical-form
          :id="teamCompositionId"
          :teamid="teamid"
          :teamdescription="teamdescription"
          :weekdescription="weekdescription"
          :datedescription="datedescription"
          @close-form="hideICalForm"
        ></team-ical-form>
        <div v-if="isUpdating">
          <base-spinner></base-spinner>
        </div>
      </template>
    </base-dialog>

    <!-- MAIN ITEM -->
    <div>
      <li :class="memberClass">
        <h3>
          <span class="error" v-if="errorInComposition">
            <span class="errortooltiptext">{{ error }}</span>
            <i class="fas fa-exclamation-triangle"></i>&nbsp;
          </span>
          <span class="report" v-if="reportPresent" @click="showReport">
            <span class="reporttooltiptext"
              >Klik hier om het verslag te lezen...</span
            >
            <i class="far fa-comments"></i>&nbsp;&nbsp;
          </span>
          <i class="fas fa-table-tennis" v-if="isTableTennis"></i>
          <i class="fas fa-trophy" v-if="isPadel"></i>
          &nbsp;
          {{ teamdescription }} - {{ weekdescription }}
        </h3>

        <div v-if="reportVisible">
          <hr />
          <div v-html="report"></div>
          <hr />
        </div>

        <div class="calendar">
          <div v-html="datedescription"></div>
          <div class="ical" v-if="!meetingIsPast" @click="showICalForm">
            <span class="icaltooltiptext"
              >Stuur mij een e-mailbericht met afspraakgegevens voor deze
              ontmoeting.</span
            >
            <i class="fas fa-mail-bulk"></i>
          </div>
        </div>

        <h4>
          <a :href="frenoyurl" target="_blank">
            {{ meeting }}
          </a>
        </h4>

        <div v-html="composition"></div>

        <div v-if="canCompose">
          <base-button @click="showCompositionForm" mode="smallbold">
            <i class="fas fa-user-edit"></i>&nbsp;Deze opstelling aanpassen
          </base-button>
        </div>

        <base-card v-if="!score && !!frenoyScore" class="busy">
          <!-- FOR TESTING PURPOSES: <base-card v-if="!!frenoyScore" class="busy"> -->
          <h5>
            <i class="fab fa-creative-commons-sampling"></i>&nbsp;<a
              :href="frenoyLiveUrl"
              target="_blank"
              >LIVE OP FRENOY: {{ frenoyScore }}</a
            >
          </h5>
          <div v-html="frenoyDetailResults" class="frenoydetails"></div>
        </base-card>

        <h3 v-if="meetingWasWon" class="won">
          <i class="far fa-smile"></i>&nbsp; {{ score }}
        </h3>
        <h3 v-else-if="meetingWasLost" class="lost">
          <i class="far fa-sad-tear"></i>&nbsp; {{ score }}
        </h3>
        <h3 v-else-if="!!score">{{ score }}</h3>
        <div
          class="intermediate"
          v-else-if="meetingIsBusy || !!intermediatescore"
          @click="toggleIntermediateScore"
        >
          <h3 v-if="!!intermediatescore">
            <i class="fas fa-star"></i>
            &nbsp;TUSSENTIJDS: {{ intermediatescore }}
          </h3>
          <h3 v-else>
            <i class="fas fa-keyboard"></i>&nbsp;Tussentijdse score?
          </h3>
        </div>

        <div v-if="!score && !!address && !isHome">
          <i v-if="!!googlemapsurl" class="fas fa-map-marker-alt"></i>&nbsp;
          <a :href="googlemapsurl" target="_blank">
            {{ address }}
          </a>
        </div>

        <div
          class="candrivetext"
          v-if="!isHome && canDriveIsPresent && !meetingIsPast"
          v-html="canDriveText"
        ></div>
        <div class="driver" v-if="!isHome">
          <div @click="showDriverForm">
            <i class="fas fa-car"></i>
          </div>
          <div class="drivertext">
            <div v-if="memberDrove && meetingIsPast">
              <span @click="showDriverForm"
                >Je hebt aangegeven gereden te hebben&nbsp;</span
              >
              <span @click="showRemoveDriverForm"
                ><i class="far fa-trash-alt"></i
              ></span>
            </div>
            <div
              v-else-if="memberDrove && !meetingIsPast"
              @click="showDriverForm"
            >
              <span @click="showDriverForm"
                >Je hebt aangegeven te zullen rijden&nbsp;</span
              >
              <span @click="showRemoveDriverForm"
                ><i class="far fa-trash-alt"></i
              ></span>
            </div>
            <div v-else-if="!!driver" @click="showDriverForm">
              {{ driver }}
            </div>
            <div v-else-if="meetingIsPast" @click="showDriverForm">
              Geef aan of je gereden hebt
            </div>
            <div v-else @click="showDriverForm">
              Geef aan of je zal rijden
            </div>
            <div v-if="driverRemarkPresent" @click="showDriverForm">
              {{ driverremarks }}
            </div>
          </div>
        </div>

        <!-- DRIVER, IF NOT A HOMEGAME -->
        <div v-if="memberIsAbsent" class="absentblock">
          <div @click="showNonParticipateForm">
            <i class="fas fa-ban"></i>&nbsp;Je hebt aangegeven niet te kunnen
            aantreden
            <span v-if="!!memberRemark">: {{ memberRemark }}</span>
          </div>
        </div>
        <div
          v-if="(memberIsPartOfTeam || memberIsParticipating) && !meetingIsPast"
          class="participate"
        >
          <base-button @click="showConfirmationForm" mode="smallbold"
            ><i class="far fa-handshake"></i>&nbsp;Geef aan of je kan
            deelnemen</base-button
          >
        </div>
      </li>
    </div>
  </div>
</template>

<script>
import DriverForm from '../../components/teams/TeamDriverForm.vue';
import TeamConfirmationForm from '../../components/teams/TeamConfirmationForm.vue';
import CompositionForm from '../../components/teams/TeamComposition.vue';
import TeamIcalForm from '../../components/teams/TeamIcalForm.vue';

export default {
  props: [
    'id',
    'playid',
    'playdate',
    'playtime',
    'datedescription',
    'weekdescription',
    'teamid',
    'team',
    'teamdescription',
    'meeting',
    'division',
    'nrofplayersperteam',
    'startplayerindex',
    'captain',
    'composition',
    'error',
    'members',
    'reserves',
    'intermediatescore',
    'score',
    'won',
    'homeout',
    'address',
    'frenoyurl',
    'googlemapsurl',
    'driverid',
    'driver',
    'driverremarks',
    'confirmedmembers',
    'candrive',
    'report'
  ],
  components: {
    DriverForm,
    TeamConfirmationForm,
    CompositionForm,
    TeamIcalForm
  },
  data() {
    return {
      isLoading: false,
      driverFormIsVisible: false,
      removeDriverFormIsVisible: false,
      confirmationFormIsVisible: false,
      intermediateScoreIsVisible: false,
      intermediateScoreEntered: '',
      isUpdating: false,
      itemError: '',
      reportVisible: false,
      frenoyScore: '',
      frenoyDetailResults: '',
      frenoyMatchUniqueId: '',
      compositionFormIsVisible: false,
      currentTeamComposition: null,
      availableTeamPlayers: [],
      iCalFormIsVisible: false
    };
  },
  computed: {
    meetingWasWon() {
      return !!this.score && this.won === 'Y';
    },
    meetingWasLost() {
      return !!this.score && this.won === 'N';
    },
    meetingIsPast() {
      const currentDate = new Date();
      const compositionDate = new Date(this.playdate);
      const isToday = this.meetingIsToday;

      return !isToday && compositionDate < currentDate;
    },
    driveText1() {
      if (this.meetingIsPast) {
        return 'Ik heb niet gereden';
      } else {
        return 'Ik zal niet rijden';
      }
    },
    meetingIsToday() {
      const currentDate = new Date().toLocaleDateString('nl-BE');
      const compositiondate = new Date(this.playdate).toLocaleDateString(
        'nl-BE'
      );

      return compositiondate === currentDate;
    },
    meetingIsBusy() {
      const currentDate = new Date().toLocaleDateString('nl-BE');
      const compositiondate = new Date(this.playdate).toLocaleDateString(
        'nl-BE'
      );

      if (compositiondate === currentDate) {
        const currentTime = new Date().toLocaleTimeString('nl-BE');
        return this.playtime <= currentTime;
      }

      return false;
    },
    isTableTennis() {
      return this.$store.getters['isTableTennis'];
    },
    isPadel() {
      return this.$store.getters['isPadel'];
    },
    doLoadFrenoyScore() {
      const isTableTennis = this.$store.getters['isTableTennis'];
      if (!isTableTennis) {
        return false;
      }

      // If definitive score is already known, no Frenoy-score is needed
      if (this.score && this.score != '') {
        return false;
      }

      const currentDate = new Date().toLocaleDateString('nl-BE');
      const yesterDay = new Date(
        new Date().setDate(new Date().getDate() - 1)
      ).toLocaleDateString('nl-BE');
      const compositiondate = new Date(this.playdate).toLocaleDateString(
        'nl-BE'
      );

      if (compositiondate === yesterDay) {
        return true;
      }

      if (compositiondate === currentDate) {
        const currentTime = new Date().toLocaleTimeString('nl-BE');
        return this.playtime <= currentTime;
      }

      return false;
    },
    frenoyLiveUrl() {
      const liveUrl =
        this.$store.getters['frenoyUrl'] +
        'wedstrijd/' +
        this.frenoyMatchUniqueId;
      return liveUrl;
    },
    memberHasConfirmed() {
      const currentDate = new Date();
      const compositionDate = new Date(this.playdate);

      const currentMember = this.$store.getters['memberId'];
      const members = ',' + this.confirmedmembers + ',';

      return (
        compositionDate >= currentDate && members.indexOf(currentMember) > 0
      );
    },
    memberIsParticipating() {
      const currentMember = ',' + this.$store.getters['memberId'] + ',';
      // console.log(currentMember);
      const members = ',' + this.members + ',';
      // console.log(members);

      return members.indexOf(currentMember) >= 0;
    },
    memberIsPartOfTeam() {
      const myTeams = this.$store.getters['myTeams'];
      // console.log(myTeams);

      if (!myTeams || myTeams.indexOf(this.teamdescription) < 0) {
        return false;
      }

      return true;
    },
    memberIsAbsent() {
      const memberAbsences = this.$store.getters['members/memberAbsences'];
      const index = memberAbsences.findIndex(
        absence => absence.dateFrom === this.playdate
      );

      if (index >= 0) {
        return true;
      }

      return false;
    },
    memberRemark() {
      const memberAbsences = this.$store.getters['members/memberAbsences'];
      const index = memberAbsences.findIndex(
        absence => absence.dateFrom === this.playdate
      );

      if (index >= 0) {
        return memberAbsences[index].remark;
      }

      return '';
    },
    confirmationOption() {
      const memberAbsences = this.$store.getters['members/memberAbsences'];
      const index = memberAbsences.findIndex(
        absence => absence.dateFrom === this.playdate
      );

      if (index >= 0) {
        return memberAbsences[index].confirmationOption;
      }

      return 1;
    },
    memberClass() {
      if (this.memberIsAbsent) {
        return 'absent';
      }
      if (this.memberIsParticipating || this.memberHasConfirmed) {
        return 'participating';
      }

      return 'notparticipating';
    },
    isHome() {
      if (this.homeout === 'home') {
        return true;
      } else {
        return false;
      }
    },
    memberDrove() {
      const currentMember = this.$store.getters['memberId'];
      return currentMember === this.driverid;
    },
    teamCompositionId() {
      return this.id;
    },
    teamDriverId() {
      return this.driverid;
    },
    driverRemarks() {
      return this.driverremarks;
    },
    driverRemarkPresent() {
      if (this.driverremarks === '') {
        return false;
      } else {
        return true;
      }
    },
    canDriveIsPresent() {
      if (this.candrive === '') {
        return false;
      } else {
        return true;
      }
    },
    canDriveText() {
      return (
        '<em>Volgende speler(s) hebben aangegeven eventueel te kunnen rijden<br>' +
        '<strong>' +
        this.candrive +
        '</strong>' +
        '</em>'
      );
    },
    reportPresent() {
      return !!this.report;
    },
    canCompose() {
      if (this.meetingIsPast) {
        return false;
      }

      const canComposeAnyTeam = this.$store.getters['canComposeAnyTeam'];
      if (canComposeAnyTeam === true) {
        return true;
      }

      if (this.captain && this.captain > 0) {
        const canComposeAsCaptain = this.$store.getters['canComposeAsCaptain'];
        if (canComposeAsCaptain === true) {
          const currentMember = this.$store.getters['memberId'];
          return currentMember === this.captain;
        }
      }

      return false;
    },
    errorInComposition() {
      return (
        this.canCompose &&
        !!this.error &&
        this.error !== 'Correcte teamopstelling'
      );
    },
    email() {
      return this.$store.getters['email'];
    }
  },
  methods: {
    showDriverForm() {
      this.driverFormIsVisible = true;
    },
    closeDriverForm() {
      this.driverFormIsVisible = false;
    },
    showRemoveDriverForm() {
      this.removeDriverFormIsVisible = true;
    },
    closeRemoveDriverForm() {
      this.removeDriverFormIsVisible = false;
    },
    showConfirmationForm() {
      this.confirmationFormIsVisible = true;
    },
    closeConfirmationForm() {
      this.confirmationFormIsVisible = false;
    },
    async saveDriver(formData) {
      this.isUpdating = true;
      const payload = {
        id: this.id,
        member_id: formData.driverId,
        remarks: formData.driverRemarks
      };

      try {
        await this.$store.dispatch('teams/setDriver', payload);
      } catch (err) {
        this.itemError =
          err.message ||
          'Er trad een probleem op bij het aanpassen van de chauffeur!';
      }

      this.isUpdating = false;
      this.driverFormIsVisible = false;
    },
    async removeDriver() {
      this.isUpdating = true;
      const payload = {
        id: this.id,
        member_id: this.driverid
      };

      try {
        await this.$store.dispatch('teams/deleteDriver', payload);
      } catch (err) {
        this.itemError =
          err.message ||
          'Er trad een probleem op bij het verwijderen van de chauffeur!';
      }

      this.isUpdating = false;
      this.removeDriverFormIsVisible = false;
    },
    async saveConfirmation(formData) {
      // console.log(formData);
      this.isUpdating = true;

      const payload = {
        id: formData.id,
        confirmationOption: formData.confirmationOption,
        remarks: formData.remarks,
        canDrive: formData.canDrive
      };

      if (formData.participate == 1) {
        try {
          await this.$store.dispatch('teams/participateMeeting', payload);
        } catch (err) {
          this.itemError =
            err.message ||
            'Er trad een probleem op bij het aangeven dat je kan deelnemen!';
        }
      } else {
        try {
          // console.log('denyMeeting');
          await this.$store.dispatch('teams/denyMeeting', payload);
        } catch (err) {
          this.itemError =
            err.message ||
            'Er trad een probleem op bij het aangeven dat je niet kan deelnemen!';
        }
      }

      await this.$store.dispatch('members/loadAbsences');
      this.isUpdating = false;

      this.confirmationFormIsVisible = false;
    },
    toggleIntermediateScore() {
      this.intermediateScoreIsVisible = !this.intermediateScoreIsVisible;
    },
    async updateIntermediateScore() {
      this.isUpdating = true;
      const payload = {
        id: this.id,
        intermediateScore: this.intermediateScoreEntered
      };

      try {
        await this.$store.dispatch('teams/setIntermediateScore', payload);
      } catch (err) {
        this.itemError =
          err.message ||
          'Er trad een probleem op bij het doorvoeren van de tussentijdse score!';
      }

      this.isUpdating = false;
      this.intermediateScoreIsVisible = false;
    },
    handleError() {
      this.itemError = null;
    },
    showReport() {
      this.reportVisible = !this.reportVisible;
    },
    async getFrenoyScore() {
      try {
        const url = this.$store.getters['apiUrl'] + 'frenoy_matchresult.php';
        const token = this.$store.getters['token'];
        const response = await fetch(url, {
          method: 'POST',
          body: JSON.stringify({
            jwt: token,
            match_id: this.playid
          })
        });

        const responseData = await response.json();
        for (const key in responseData) {
          if (key == 'Score') {
            this.frenoyScore = responseData[key];
          }
          if (key == 'PlayerResults') {
            this.frenoyDetailResults = responseData[key];
          }
          if (key == 'MatchUniqueId') {
            this.frenoyMatchUniqueId = responseData[key];
          }
        }
      } catch (err) {
        console.log(err.message);
      }
    },
    async showCompositionForm() {
      try {
        this.isLoading = true;

        // Get/update current composition
        await this.$store.dispatch('teams/loadTeamCompositions', {
          id: this.id
        });

        // Get available players
        await this.$store.dispatch('teams/loadAvailableTeamPlayers', {
          id: this.id
        });

        const teamCompositions = this.$store.getters['teams/teamCompositions'];
        this.currentTeamComposition = teamCompositions.find(
          teamComposition => teamComposition.id === this.id
        );

        // console.log(this.currentTeamComposition);

        if (this.currentTeamComposition) {
          this.availableTeamPlayers = this.$store.getters[
            'teams/availableTeamPlayers'
          ];

          this.compositionFormIsVisible = true;
        }
      } catch (err) {
        this.itemError =
          err.message ||
          'Er trad een probleem op bij het ophalen van de beschikbare spelers!';
      }

      this.isLoading = false;
    },
    closeCompositionForm() {
      this.compositionFormIsVisible = false;
    },
    saveComposition() {
      this.compositionFormIsVisible = false;
    },
    showICalForm() {
      this.iCalFormIsVisible = true;
    },
    hideICalForm() {
      this.iCalFormIsVisible = false;
    }
  },
  mounted() {
    this.intermediateScoreEntered = this.intermediatescore;

    // FOR TESTING PURPOSES, get a specific score (do not get all scores -> CORS-error)
    // if (this.playid == 'POVLH08/041') {
    //   this.getFrenoyScore();
    // }

    // Ophalen van de score bij Frenoy
    if (this.doLoadFrenoyScore) {
      this.getFrenoyScore();

      const that = this;
      this.frenoyTimer = setInterval(function() {
        that.getFrenoyScore();
      }, 300000);
    }
  },
  beforeUnmount() {
    if (this.frenoyTimer) {
      clearInterval(this.frenoyTimer);
    }
  }
};
</script>

<style scoped>
li {
  margin: 1rem 0;
  border: 1px solid #424242;
  border-radius: 12px;
  padding: 1rem;
}

h3 {
  font-size: 1.5rem;
}

h3,
h4 {
  margin: 0.5rem 0;
}

div {
  margin: 0.5rem 0;
}

.busy {
  color: rgb(0, 0, 0);
}

.busy a {
  color: rgb(0, 0, 0);
}

.frenoydetails {
  line-height: 100%;
}

.won {
  color: var(--var-won);
}

.lost {
  color: var(--var-lost);
}

.intermediate {
  color: var(--var-intermediatescore);
  cursor: pointer;
}

.absent {
  background-color: var(--var-isabsent);
}

.absentblock {
  font-weight: bold;
  color: var(--var-isabsent-text);
  display: flex;
  cursor: pointer;
}

.participating {
  background-color: var(--var-isplaying);
}

.notparticipating {
  color: inherit;
}

.driver {
  font-weight: bold;
  font-size: 32px;
  display: flex;
  cursor: pointer;
}

.drivertext {
  padding-left: 20px;
  font-size: 20px;
  cursor: pointer;
  width: 100%;
}

.participate {
  display: flex;
}

.candrivetext {
  font-weight: bold;
  font-size: 16px;
  color: var(--var-candrive);
}

.report {
  cursor: pointer;
}

.report .reporttooltiptext {
  visibility: hidden;
  width: 350px;
  background-color: var(--var-button-background);
  font-size: 18px;
  padding: 5px 0;
  margin-left: 40px;
  border: 2px solid var(--var-font-color);
  border-radius: 6px;

  position: absolute;
  z-index: 1;
}

.report:hover .reporttooltiptext {
  visibility: visible;
}

.error {
  cursor: pointer;
  color: var(--var-error);
}

.error .errortooltiptext {
  visibility: hidden;
  width: 350px;
  color: var(--var-font-color);
  background-color: var(--var-error);
  font-size: 18px;
  padding: 5px 0;
  margin-left: 40px;
  border: 2px solid var(--var-font-color);
  border-radius: 6px;

  position: absolute;
  z-index: 1;
}

.error:hover .errortooltiptext {
  visibility: visible;
}

.calendar {
  display: flex;
}

.ical {
  margin-left: 10px;
  cursor: pointer;
}

.ical .icaltooltiptext {
  visibility: hidden;
  width: 350px;
  color: var(--var-font-color);
  font-size: 18px;
  padding: 5px 0;
  margin-left: 30px;
  border: 2px solid var(--var-font-color);
  border-radius: 6px;

  position: absolute;
  z-index: 1;
}

.ical:hover .icaltooltiptext {
  visibility: visible;
}
</style>
