<template>
  <team-selection
    v-for="team in teams"
    :key="team.id"
    :team="team.team"
    :selectedteam="selectedTeam"
    @set-team="selectTeam"
  >
  </team-selection>
</template>

<script>
import TeamSelection from './TeamSelection.vue';

export default {
  components: {
    TeamSelection
  },
  props: ['defaultteam'],
  emits: ['change-team'],
  data() {
    return {
      selectedTeam: '',
      teams: []
    };
  },
  methods: {
    async loadTeams() {
      const teams = this.$store.getters['teams/teams'];
      if (teams && teams.length > 0) {
        this.teams = teams;
      } else {
        try {
          await this.$store.dispatch('teams/loadTeams').then(() => {
            const teams = this.$store.getters['teams/teams'];
            if (teams && teams.length > 0) {
              this.selectedTeam = teams[0].team;
            }

            this.teams = teams;
          });
        } catch (err) {
          this.error =
            err.message ||
            'Er trad een probleem op bij het ophalen van de ploegen!';
        }
      }
    },
    selectTeam(payload) {
      this.selectedTeam = payload.team;
      this.$emit('change-team', this.selectedTeam);
    }
  },
  created() {
    this.loadTeams();

    this.selectedTeam = this.defaultteam;
  }
};
</script>

<style scoped>
h2 {
  margin: 0.5rem 0;
}
</style>
