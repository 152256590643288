<template>
  <div>
    <section>
      <div>
        <div class="unsubscription">
          <div class="inputlabel">Reden</div>
          <multiselect
            v-model="reason"
            :options="reasonSelection"
            :searchable="true"
            placeholder="Reden van je uitschrijving"
            ref="reason"
            @change="setReason"
          ></multiselect>
        </div>
        <div v-if="errorRemark" class="warning">
          <i class="fas fa-exclamation-triangle"></i>&nbsp;Geef aan waarom je je
          wenst uit te schrijven!
        </div>
        <div class="remarks">
          <div class="inputlabel">Opmerkingen</div>
          <div class="inputfield">
            <textarea id="remarks" v-model.trim="remarks" rows="6" />
          </div>
        </div>
        <div class="warning">
          <i class="fas fa-exclamation-triangle"></i>&nbsp; Door je uit te
          schrijven zal je niet meer worden opgenomen in de komende
          speelperiodes!
        </div>
        <div class="warning">
          <i class="fas fa-exclamation-triangle"></i>&nbsp; Indien je je in jouw
          poule laten vervangen hebt, dient de kapitein dit afzonderlijk nog aan
          te geven! Gelieve dit met de kapitein van jouw poule af te stemmen
          indien dit het geval is!
        </div>
      </div>
      <menu>
        <base-button @click="registerUnsubscription" mode="smallbold"
          ><i class="fas fa-sign-out-alt"></i>&nbsp;Uitschrijven</base-button
        >
        <base-button @click="closeForm" mode="smallbold">Sluit</base-button>
      </menu>
    </section>
  </div>
</template>

<script>
import Multiselect from '@vueform/multiselect';

export default {
  components: { Multiselect },
  props: ['id'],
  emits: ['close-unsubscribe', 'register-unsubscribe'],
  data() {
    return {
      reason: null,
      remarks: null,
      errorRemark: false
    };
  },
  computed: {
    reasonSelection() {
      const reasonsStore = this.$store.getters['members/absenceReasons'];

      const reasons = [];
      for (let i = 0; i < reasonsStore.length; i++) {
        const reason = {
          value: reasonsStore[i].id,
          label: reasonsStore[i].description
        };

        reasons.push(reason);
      }

      return reasons;
    }
  },
  methods: {
    async loadAbsenceReasons() {
      this.isLoading = true;

      try {
        await this.$store.dispatch('members/loadAbsenceReasons');
      } catch (err) {
        this.error =
          err.message ||
          'Er trad een probleem op bij het ophalen van de afwezigheidsredenen!';
      }

      this.isLoading = false;
    },
    setReason(selectedReason) {
      this.reason = selectedReason;
    },
    closeForm() {
      this.$emit('close-unsubscribe');
    },
    registerUnsubscription() {
      this.errorRemark = false;
      if (this.remarks) {
        const formData = {
          reasonId: this.reason,
          remarks: this.remarks
        };

        this.$emit('register-unsubscribe', formData);
      } else {
        this.errorRemark = true;
      }
    }
  },
  mounted() {
    this.loadAbsenceReasons();
  }
};
</script>

<style src="@vueform/multiselect/themes/default.css"></style>
<style>
:root {
  --ms-font-size: 0.9rem;
  --ms-line-height: 0.85;

  --ms-option-bg-selected: #4dabf7;
  --ms-option-bg-selected-pointed: #4dabf7;
  --ms-option-font-size: 0.9rem;
}
</style>

<style scoped>
textarea {
  width: 100%;
  border-color: lightgray;
}
.inputlabel {
  width: 150px;
  font-size: 16px;
  font-weight: bold;
  padding-top: 5px;
}

.inputfield {
  width: 100%;
  display: flex;
}
.unsubscription {
  display: flex;
  align-items: center;
  gap: 10px;
}
.remarks {
  display: flex;
  align-items: center;
  gap: 10px;
  font-size: 16px;
  padding-top: 10px;
}

.warning {
  padding-top: 12px;
  font-size: 16px;
  font-style: italic;
  color: var(--var-warning);
}

menu {
  padding-top: 1rem;
  display: flex;
  justify-content: flex-end;
  margin: 0;
}
</style>
