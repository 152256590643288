<template>
  <base-card>
    <h3>Zoek</h3>
    <base-search @search="updateSearch" :search-term="searchTerm">
    </base-search>
    <span class="form-check form-switch">
      <label for="onlytoday" class="checkboxtext"
        >Enkel materiaal vandaag in gebruik</label
      >
      <input
        class="form-check-input"
        type="checkbox"
        id="onlytoday"
        :checked="onlyToday"
        @change="setToday"
      />
    </span>
  </base-card>
</template>

<script>
export default {
  emits: ['change-filter'],
  data() {
    return {
      filters: {
        searchTerm: '',
        today: false
      }
    };
  },
  computed: {
    searchTerm() {
      return this.filters.searchTerm;
    },
    onlyToday() {
      return this.filters.today;
    }
  },
  methods: {
    updateSearch(updatedSearchTerm) {
      const updatedFilters = {
        ...this.filters,
        searchTerm: updatedSearchTerm
      };
      this.filters = updatedFilters;
      this.$emit('change-filter', updatedFilters);
    },
    setToday(event) {
      const isActive = event.target.checked;

      const updatedFilters = {
        ...this.filters,
        today: isActive
      };
      this.filters = updatedFilters;
      this.$emit('change-filter', updatedFilters);
    }
  }
};
</script>

<style scoped>
h2 {
  margin: 0.5rem 0;
}
.checkboxtext {
  /* Checkbox text */
  font-size: 18px;
  display: inline;
  padding: 3px;
}
</style>
