import { render } from "./TeamComposition.vue?vue&type=template&id=a89948ca&scoped=true"
import script from "./TeamComposition.vue?vue&type=script&lang=js"
export * from "./TeamComposition.vue?vue&type=script&lang=js"

import "@vueform/multiselect/themes/default.css?vue&type=style&index=0&lang=css"
import "./TeamComposition.vue?vue&type=style&index=1&id=a89948ca&lang=css"
import "./TeamComposition.vue?vue&type=style&index=2&id=a89948ca&scoped=true&lang=css"
script.render = render
script.__scopeId = "data-v-a89948ca"

export default script