<template>
  <div>
    <base-popup
      :show="!!error"
      title="Fout/waarschuwing"
      awesomeicon="fas fa-exclamation-triangle"
      @close="handleErrorAndClose"
    >
      <p class="error">{{ error }}</p>
    </base-popup>
    <base-popup
      :show="mailSent"
      title="Het e-mailbericht werd verstuurd"
      awesomeicon="far fa-envelope"
      @close="closeMailSentForm"
    >
      <template v-slot:default>
        <div>
          Het e-mailbericht werd verstuurd aan <strong>{{ memberEmail }}</strong
          >.
        </div>
        <div class="mailinfo">
          <em
            >Eventjes geduld wel, het kan even duren alvorens het bericht in je
            mailbox toekomt!<br />
            Vergeet ook niet even bij je spamberichten te zien!</em
          >
        </div>
      </template>
    </base-popup>
    <base-popup
      :show="resultRegisteredIsVisible"
      title="Resultaat geregistreerd"
      awesomeicon="fas fa-dice"
      @close="closeResultRegisteredForm"
    >
      <div class="registered">
        Het pouleresultaat werd correct geregistreerd.
      </div>
      <div class="registered">
        <em>
          Er werd zowel aan alle poulespelers alsook aan de
          leagueverantwoordelijke(n) een informatief e-mailbericht
          uitgestuurd.</em
        >
      </div>
    </base-popup>
    <base-dialog :show="resultFormIsVisible" :fixed="true">
      <template v-slot:header>
        <h4>
          <i class="fas fa-dice"></i>
          Resultaten poule {{ poule }}
        </h4>
      </template>
      <template v-slot:default>
        <league-results-form
          :id="pouleId"
          @close-score="closeResultForm"
          @register-score="registerResult"
        ></league-results-form>
      </template>
    </base-dialog>
    <base-popup
      :show="replacementRegisteredIsVisible"
      title="Resultaat geregistreerd"
      awesomeicon="fas fa-people-carry"
      @close="closeReplacementRegisteredForm"
    >
      <div class="registered">De vervanging werd correct geregistreerd.</div>
      <div class="registered">
        <em>
          Er werd zowel aan de vervangen poulespeler alsook aan de
          leagueverantwoordelijke(n) een informatief e-mailbericht
          uitgestuurd.</em
        >
      </div>
    </base-popup>
    <base-dialog :show="replacementFormIsVisible" :fixed="true">
      <template v-slot:header>
        <h4>
          <i class="fas fa-people-carry"></i>
          Spelersvervanging poule {{ poule }}
        </h4>
      </template>
      <template v-slot:default>
        <league-replacement-form
          :id="pouleId"
          @close-replacement="closeReplacementForm"
          @register-replacement="registerReplacement"
        ></league-replacement-form>
      </template>
    </base-dialog>
    <base-popup
      :show="unSubscribeRegisteredIsVisible"
      title="Uitschrijving geregistreerd"
      awesomeicon="fas fa-sign-out-alt"
      @close="closeUnSubscribeRegisteredForm"
    >
      <div class="registered">
        Je uitschrijving werd geregistreerd!
      </div>
      <div class="registered">
        <em>
          Er werd aan de leagueverantwoordelijke(n) een informatief
          e-mailbericht uitgestuurd.</em
        >
      </div>
      <div class="warning">
        <i class="fas fa-exclamation-triangle"></i>
        Je zal niet meer worden opgenomen in de komende speelperiodes!
      </div>
      <div class="warning">
        <i class="fas fa-exclamation-triangle"></i>
        Breng eventueel de kapitein van je poule op de hoogte als je je laat
        vervangen, dit moet immers afzonderlijk nog aangegeven worden! Gelieve
        dit met de kapitein van jouw poule af te stemmen indien dit het geval
        is!
      </div>
    </base-popup>
    <base-dialog :show="unSubscribeFormIsVisible" :fixed="true">
      <template v-slot:header>
        <h4>
          <i class="fas fa-sign-out-alt"></i>
          Uitschrijving league
        </h4>
      </template>
      <template v-slot:default>
        <league-unsubscribe-form
          :id="pouleId"
          @close-unsubscribe="closeUnsubscribeForm"
          @register-unsubscribe="registerUnsubscribe"
        ></league-unsubscribe-form>
      </template>
    </base-dialog>
    <base-popup
      :show="undoUnSubscribeRegisteredIsVisible"
      title="Annulatie uitschrijving"
      awesomeicon="fas fa-sign-out-alt"
      @close="closeUndoUnSubscribeRegisteredForm"
    >
      <div class="registered">
        Je annulatie van je eerdere uitschrijving werd geregistreerd!
      </div>
      <div class="registered">
        <em>
          Er werd aan de leagueverantwoordelijke(n) een informatief
          e-mailbericht uitgestuurd.</em
        >
      </div>
      <div class="warning">
        <i class="fas fa-exclamation-triangle"></i>
        Breng eventueel de kapitein van je poule op de hoogte als je je hebt
        laten vervangen, dit werd mogelijks afzonderlijk nog aangegeven! Gelieve
        dit met de kapitein van jouw poule af te stemmen indien dit het geval is
        zodat hij ook daar de eventuele uitschrijving van jou (uit de league)
        als speler kan aanpassen!
      </div>
    </base-popup>
    <base-dialog :show="undoUnSubscribeFormIsVisible" :fixed="false">
      <template v-slot:header>
        <h4>
          <i class="fas fa-undo"></i>
          Uitschrijving ongedaan maken
        </h4>
      </template>
      <template v-slot:default>
        <div>
          Wens je je eerdere uitschrijving uit de league ongedaan te maken?
        </div>
      </template>
      <template v-slot:actions>
        <base-button @click="registerUndoUnsubscribe" mode="smallbold"
          ><i class="fas fa-undo"></i>&nbsp;Ongedaan maken</base-button
        >
        <base-button @click="closeUndoUnsubscribeForm" mode="smallbold"
          >Sluit</base-button
        >
      </template>
    </base-dialog>
    <base-dialog :show="playDateFormIsVisible" :fixed="true">
      <template v-slot:header>
        <h4>
          <i class="fas fa-calendar"></i>&nbsp; (Geplande) speeldatum poule
          {{ poule }}
        </h4>
      </template>
      <template v-slot:default>
        <league-play-date-form
          :id="pouleId"
          @close-playdate-form="closePlayDateForm"
          @save-playdate="registerPlayDate"
        ></league-play-date-form>
      </template>
    </base-dialog>
    <base-dialog :show="individualResultsVisible" :fixed="true">
      <template v-slot:header>
        <h2>
          <div class="newbadge">NIEUW</div>
          <i class="fas fa-trophy"></i>
          Individuele resultaten
        </h2>
        <h4>Poulespelers {{ poule }}</h4>
      </template>
      <template v-slot:default>
        <league-individual-results-form
          :id="pouleId"
          @close-results="closeIndividualResults"
        ></league-individual-results-form>
      </template>
    </base-dialog>
    <section>
      <base-card>
        <div v-if="isLoading">
          <base-spinner></base-spinner>
        </div>
        <div v-else>
          <h3>{{ leagueDescription }}</h3>
          <div class="periodselection" v-if="leagueSeriePresent">
            <i class="far fa-calendar-alt"></i>Periode
            <multiselect
              v-model="selectedSerie"
              :options="serieSelection"
              :searchable="true"
              placeholder="Selecteer periode"
              ref="selectedSerie"
              @change="setSelectedSerie"
            ></multiselect>
          </div>
          <hr />
          <div v-if="!leagueSeriePresent">
            Deze league werd nog niet gestart en de inschrijvingen lopen
            momenteel binnen. Van zodra de eerste poules werden aangemaakt zal
            je hier alle informatie kunnen opvragen!
          </div>
          <base-tabs
            :tabList="tabList"
            :awesomeIconList="tabIconList"
            :tabCaptionType="tabCaptionType"
            fontsize="small"
            bold="false"
            v-if="leagueSeriePresent"
            @tab-change="changeTab"
          >
            <template v-slot:tabPanel-1>
              <div v-if="!!myPoule" class="boxed">
                <div>
                  <h4><i class="fas fa-boxes"></i>&nbsp;Poule {{ poule }}</h4>
                  <div v-html="pouleResults" @click="switchResultsView()"></div>
                  <div
                    class="playdate"
                    v-if="playerMomentInfoEnabled()"
                    @click="showPlayDateForm"
                  >
                    <i class="fas fa-calendar"></i>&nbsp;
                    <div v-html="myPoule.playInformation"></div>
                  </div>
                  <div
                    class="individualresults"
                    @click="showIndividualResults()"
                  >
                    <div class="newbadge">NIEUW</div>
                    <i class="fas fa-trophy"></i>&nbsp;Klik hier voor de
                    individuele wedstrijdresultaten van deze poulespelers
                  </div>
                  <div
                    class="buttons"
                    v-if="canEnterAnyLeagueResult || (isCaptain && !isClosed)"
                  >
                    <base-button
                      @click="showResultForm"
                      mode="smallbold"
                      v-if="serieOngoing"
                      ><i class="fas fa-dice"></i>&nbsp;Inbrengen van de
                      resultaten</base-button
                    >
                    <base-button
                      @click="showReplacementForm"
                      mode="smallbold"
                      v-if="serieOngoing"
                      ><i class="fas fa-people-carry"></i
                      >&nbsp;Spelersvervanging</base-button
                    >
                  </div>
                </div>
              </div>
              <div v-else>
                Je bent niet (meer) opgenomen in een poule voor deze periode.
              </div>
            </template>
            <template v-slot:tabPanel-2>
              <div class="boxed results">
                <table cellspacing="0" cellpadding="0" border="0" width="740">
                  <tr>
                    <td width="50px"></td>
                    <td width="110px" style="text-align:left">
                      <b>Periode</b>
                    </td>
                    <td width="50px" style="text-align:center">
                      <b>Poule</b>
                    </td>
                    <td width="200px" style="text-align:left"></td>
                    <td width="25px"></td>
                    <td width="200px" style="text-align:left"></td>
                    <td width="60px" style="text-align:center">
                      <b>Uitslag</b>
                    </td>
                    <td width="25px"></td>
                  </tr>
                  <tr
                    v-for="playerResult in playerResults"
                    :key="playerResult.id"
                  >
                    <td width="50px" style="text-align:center">
                      <b>{{ playerResult.matchIndex }}</b>
                    </td>
                    <td width="110px" style="text-align:left">
                      <strong>({{ playerResult.serie }})</strong>&nbsp;
                      <small>
                        <em>{{ playerResult.period }}</em></small
                      >
                    </td>
                    <td width="50px" style="text-align:center">
                      {{ playerResult.poule }}
                    </td>
                    <td
                      width="200px"
                      style="text-align:left"
                      v-html="players(playerResult)"
                    ></td>
                    <td width="25px">vs</td>
                    <td
                      width="200px"
                      style="text-align:left"
                      v-html="counterPlayers(playerResult)"
                    ></td>
                    <td width="60px" style="text-align:center">
                      <strong>{{ playerResult.games }}</strong>
                    </td>
                    <td
                      width="20px"
                      :class="
                        resultClass(
                          playerResult.won,
                          playerResult.lost,
                          playerResult.draw
                        )
                      "
                    >
                      <div v-if="playerResult.won == 1">
                        <i class="far fa-smile"></i>
                      </div>
                      <div v-if="playerResult.lost == 1">
                        <i class="far fa-frown"></i>
                      </div>
                      <div v-if="playerResult.draw == 1">
                        <i class="far fa-meh"></i>
                      </div>
                    </td>
                    <td width="25px" class="replacement">
                      <i
                        class="fas fa-exclamation-triangle showhint"
                        v-if="isReplacement(playerResult)"
                      ></i>
                      <div
                        class="replacementhint"
                        v-html="replacements(playerResult)"
                      ></div>
                    </td>
                  </tr>
                </table>
              </div>
            </template>
            <template v-slot:tabPanel-3>
              <div>
                <table width="100%">
                  <tr v-if="selectedSerieStatus == 2">
                    <td width="15%">
                      <i class="fas fa-percent"></i>&nbsp;Poules
                    </td>
                    <td width="85%">
                      <div class="statusselection">
                        <input
                          type="radio"
                          id="poulestatusall"
                          name="poulestatus"
                          value="Alle"
                          v-model="pouleStatusSelection"
                        />
                        <label for="poulestatusall">{{ pouleCount(2) }}</label>
                        <input
                          type="radio"
                          id="poulestatusplayed"
                          name="poulestatus"
                          value="Gespeeld"
                          v-model="pouleStatusSelection"
                        />
                        <label for="poulestatusplayed">{{
                          pouleCount(1)
                        }}</label>
                        <input
                          type="radio"
                          id="poulestatusnotplayed"
                          name="poulestatus"
                          value="Te spelen"
                          v-model="pouleStatusSelection"
                        />
                        <label for="poulestatusnotplayed">{{
                          pouleCount(0)
                        }}</label>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td width="15%">
                      <i class="fas fa-chart-line"></i>&nbsp;Niveau
                    </td>
                    <td width="85%">
                      <div class="levelselection">
                        <multiselect
                          v-model="selectedLevel"
                          :options="levelSelection"
                          :searchable="true"
                          placeholder="Selecteer niveau"
                          ref="selectedLevel"
                          @change="setSelectedLevel"
                        ></multiselect>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td width="15%"><i class="fas fa-user"></i>&nbsp;Speler</td>
                    <td width="85%">
                      <div class="playerselection">
                        <base-search
                          @search="updatePlayerSelection"
                          :search-term="playerSelection"
                        ></base-search>
                      </div>
                    </td>
                  </tr>
                </table>
              </div>
              <hr />
              <div class="boxed">
                <league-poule-item
                  v-for="poule in selectedPoules"
                  :key="poule.id"
                  :pouleid="poule.pouleId"
                  :levelcode="poule.levelCode"
                  :poule="poule.poule"
                  :poulestatus="poule.pouleStatus"
                  :poulestatusdescription="poule.pouleStatusDescription"
                  :pouleresults="poule.pouleResults"
                  :poulematches="poule.pouleMatches"
                ></league-poule-item>
              </div>
            </template>
            <template v-slot:tabPanel-4>
              <div class="boxed statistics">
                <div>
                  <div>
                    <i class="fas fa-info-circle"></i>&nbsp; Dit
                    statistiekoverzicht toont je enkele totaalcijfertjes, zoals
                    totaal aantal wedstrijden (#), aantal wedstrijden 6-0, 6-1,
                    enz..., het aantal vervangingen (Vrv) en het aantal
                    onafgewerkte wedstrijden (Ng), en dit per speelperiode.
                  </div>
                </div>
                <hr />
                <table cellspacing="0" cellpadding="0" border="0" width="740">
                  <tr style="font-weight: bold">
                    <td width="200px">Periode</td>
                    <td width="25px" style="text-align:center">#</td>
                    <td width="25px" style="text-align:center">6-0</td>
                    <td width="25px" style="text-align:center">6-1</td>
                    <td width="25px" style="text-align:center">6-2</td>
                    <td width="25px" style="text-align:center">6-3</td>
                    <td width="25px" style="text-align:center">6-4</td>
                    <td width="25px" style="text-align:center">7-5</td>
                    <td width="25px" style="text-align:center">7-6</td>
                    <td width="25px" style="text-align:center">Vrv</td>
                    <td width="25px" style="text-align:center">Ng</td>
                  </tr>
                  <tr
                    v-for="leagueStatistic in leagueStatistics"
                    :key="leagueStatistic.id"
                    :style="statisticStyle(leagueStatistic.serie)"
                  >
                    <td width="200px">
                      <span v-if="leagueStatistic.serie != 0">
                        ({{ leagueStatistic.serie }})
                      </span>
                      <strong> {{ leagueStatistic.period }}</strong>
                    </td>
                    <td width="25px" style="text-align:center">
                      {{ leagueStatistic.nrOfMatches }}
                    </td>
                    <td width="25px" style="text-align:center">
                      {{ leagueStatistic.nrOf60Matches }}
                    </td>
                    <td width="25px" style="text-align:center">
                      {{ leagueStatistic.nrOf61Matches }}
                    </td>
                    <td width="25px" style="text-align:center">
                      {{ leagueStatistic.nrOf62Matches }}
                    </td>
                    <td width="25px" style="text-align:center">
                      {{ leagueStatistic.nrOf63Matches }}
                    </td>
                    <td width="25px" style="text-align:center">
                      {{ leagueStatistic.nrOf64Matches }}
                    </td>
                    <td width="25px" style="text-align:center">
                      {{ leagueStatistic.nrOf75Matches }}
                    </td>
                    <td width="25px" style="text-align:center">
                      {{ leagueStatistic.nrOf76Matches }}
                    </td>
                    <td width="25px" style="text-align:center">
                      {{ leagueStatistic.nrOfReplacements }}
                    </td>
                    <td width="25px" style="text-align:center">
                      {{ leagueStatistic.nrOfUnFinishedMatches }}
                    </td>
                  </tr>
                </table>
              </div>
            </template>
            <template v-slot:tabPanel-5>
              <div class="boxed results">
                <table cellspacing="0" cellpadding="0" border="0" width="740">
                  <tr>
                    <td width="25px"></td>
                    <td width="25px" style="text-align:center"></td>
                    <td width="50px" style="text-align:center"></td>
                    <td width="400px">
                      <b>Deelnemer</b>
                    </td>
                    <td width="50px" style="text-align:center">
                      <b>Kl.</b>
                    </td>
                  </tr>
                  <tr v-for="ranking in rankings" :key="ranking.id">
                    <td width="25px">
                      <i
                        class="fas fa-trophy gold"
                        v-if="ranking.ranked == 1"
                      ></i>
                      <i
                        class="fas fa-trophy silver"
                        v-if="ranking.ranked == 2"
                      ></i>
                      <i
                        class="fas fa-trophy bronze"
                        v-if="ranking.ranked == 3"
                      ></i>
                    </td>
                    <td width="25px">
                      <div v-if="showLevelCodeInRanking(ranking.id)">
                        <strong>{{ ranking.levelCode }}</strong> /
                      </div>
                    </td>
                    <td width="50px">
                      <div>{{ ranking.ranked }}</div>
                    </td>
                    <td width="550px">
                      <b>{{ ranking.memberName }}</b>
                    </td>
                    <td width="50px" style="text-align:center">
                      <b>{{ ranking.ranking }}</b>
                    </td>
                  </tr>
                </table>
              </div>
            </template>
          </base-tabs>
          <hr />
          <div>
            <div @click="openHelp">
              <a
                href="https://ledenfiches.be/wp-content/plugins/pdf-poster/pdfjs/web/viewer.html?file=https://ledenfiches.be/wp-content/uploads/2023/03/MobileAppLeague.pdf&download=true&print=true&openfile=false"
                target="_blank"
              >
                <i class="fas fa-info-circle"></i>&nbsp;Wat kan ik allemaal in
                deze mobile (web)app doen?</a
              >
            </div>
          </div>
          <div>
            <div @click="sendEmail" class="email" v-if="!!myPoule">
              <i class="far fa-envelope"></i>&nbsp;Stuur me een e-mail met
              {{ pouleVan }}
            </div>
            <div
              @click="unsubscribe"
              class="unsubscribe"
              v-if="unsubscriptionPossible()"
            >
              <i class="fas fa-sign-out-alt"></i>&nbsp;Ik wens me uit te
              schrijven uit de league
            </div>
            <div
              @click="undoUnsubscribe"
              class="unsubscribe"
              v-if="
                isOwnLeagueInfo &&
                  hasBeenUnsubscribed &&
                  selectedSerieIsLastSerie
              "
            >
              <i class="fas fa-undo"></i>&nbsp;Ik wens mijn eerdere
              uitschrijving ongedaan te maken
            </div>
          </div>
        </div>
      </base-card>
    </section>
  </div>
</template>

<script>
import Multiselect from '@vueform/multiselect';
import LeaguePouleItem from '../../components/leagues/LeaguePouleItem.vue';
import LeagueResultsForm from '../../components/leagues/LeagueResultsForm.vue';
import LeagueReplacementForm from '../../components/leagues/LeagueReplacementForm.vue';
import LeagueUnsubscribeForm from '../../components/leagues/LeagueUnsubscribeForm.vue';
import LeaguePlayDateForm from '../../components/leagues/LeaguePlayDateForm.vue';
import LeagueIndividualResultsForm from '../../components/leagues/LeagueIndividualResultsForm.vue';

export default {
  components: {
    Multiselect,
    LeaguePouleItem,
    LeagueResultsForm,
    LeagueReplacementForm,
    LeagueUnsubscribeForm,
    LeaguePlayDateForm,
    LeagueIndividualResultsForm
  },
  data() {
    return {
      isLoading: false,
      resultFormIsVisible: false,
      resultRegisteredIsVisible: false,
      replacementFormIsVisible: false,
      replacementRegisteredIsVisible: false,
      individualResultsVisible: false,
      whom: null,
      activeSeries: [],
      selectedSerie: null,
      selectedSerieIsLastSerie: false,
      selectedSerieStatus: null,

      activeLevels: [],
      selectedLevel: null,

      myPoule: null,
      showMatches: false,
      poules: [],
      rankings: [],
      captain: null,
      hasBeenUnsubscribed: false,
      playerResults: [],
      error: null,
      mailSent: false,

      playerSelection: '',
      pouleStatusSelection: 'Alle',

      unSubscribeFormIsVisible: false,
      unSubscribeRegisteredIsVisible: false,

      undoUnSubscribeFormIsVisible: false,
      undoUnSubscribeRegisteredIsVisible: false,

      playDateFormIsVisible: false,

      selectedTeam: '',

      leagueStatistics: []
    };
  },
  computed: {
    tabList() {
      let tabs = ['Mijn poule', 'Mijn wedstrijden'];

      if (this.whom != this.$store.getters['memberId']) {
        const subscriptions = this.$store.getters['leagues/subscriptions'];
        // console.log(this.whom);
        // console.log(subscriptions);
        let memberName = '';
        for (let i = 0; i < subscriptions.length; i++) {
          if (subscriptions[i].memberId == this.whom) {
            memberName = subscriptions[i].memberName;
          }
        }

        tabs = [
          'Poule van<br>' + memberName,
          'Wedstrijden van<br>' + memberName
        ];
      }

      tabs.push('Alle poules');
      tabs.push('Statistiekjes');
      if (this.selectedSerieStatus == 9) {
        tabs.push('Rangschikking');
      }

      return tabs;
    },
    tabIconList() {
      let icons = [
        'fas fa-user',
        'fas fa-trophy',
        'fas fa-boxes',
        'fas fa-balance-scale-right'
      ];

      if (this.selectedSerieStatus == 9) {
        icons.push('far fa-list-alt');
      }

      return icons;
    },
    tabCaptionType() {
      if (window.innerWidth < 540) {
        return 'icons';
      }

      return 'iconsandtext';
    },
    leagueDescription() {
      const description = this.$store.getters['leagues/leagueDescription'];
      return description;
    },
    leagueSeriePresent() {
      const series = [];
      for (let i = 0; i < this.activeSeries.length; i++) {
        if (this.activeSeries[i].status >= 2) {
          const serie = {
            value: this.activeSeries[i].serieId,
            label: this.activeSeries[i].period
          };

          series.push(serie);
        }
      }

      return series.length > 0 ? true : false;
    },
    serieSelection() {
      // console.log(this.activeSeries);
      const series = [];
      for (let i = 0; i < this.activeSeries.length; i++) {
        if (this.activeSeries[i].status >= 2) {
          const serie = {
            value: this.activeSeries[i].serieId,
            label: this.activeSeries[i].period
          };

          series.push(serie);
        }
      }

      return series;
    },
    levelSelection() {
      const levels = [];
      for (let i = 0; i < this.activeLevels.length; i++) {
        const level = {
          value: this.activeLevels[i].levelId,
          label: this.activeLevels[i].description
        };

        levels.push(level);
      }

      return levels;
    },
    pouleId() {
      if (this.myPoule) {
        return this.myPoule.pouleId;
      }

      return null;
    },
    pouleVan() {
      let van = 'mijn hier getoonde poule-informatie';

      if (this.whom != this.$store.getters['memberId']) {
        const subscriptions = this.$store.getters['leagues/subscriptions'];
        // console.log(this.whom);
        // console.log(subscriptions);
        let memberName = '';
        for (let i = 0; i < subscriptions.length; i++) {
          if (subscriptions[i].memberId == this.whom) {
            memberName = subscriptions[i].memberName;
          }
        }

        van = 'de hier getoonde poule-informatie van ' + memberName;
      }

      return van;
    },
    memberEmail() {
      return this.$store.getters['email'];
    },
    poule() {
      return this.myPoule.levelCode + '/' + this.myPoule.poule;
    },
    pouleResults() {
      if (this.showMatches) {
        return this.myPoule.pouleMatches;
      } else {
        return this.myPoule.pouleResults;
      }
    },
    canEnterAnyLeagueResult() {
      return this.$store.getters['canEnterAnyLeagueResult'];
    },
    isCaptain() {
      return this.captain;
    },
    isClosed() {
      return this.myPoule.pouleStatus == 9;
    },
    isOnGoing() {
      return this.myPoule.pouleStatus == 2;
    },
    isOwnLeagueInfo() {
      return this.whom == this.$store.getters['memberId'] ? true : false;
    },
    selectedPoules() {
      const poules = this.poules;
      // console.log(poules);

      return poules.filter(poule => {
        let selected = true;
        if (this.selectedLevel) {
          selected = poule.levelId == this.selectedLevel;
        }

        if (this.playerSelection && this.playerSelection.length >= 3) {
          selected =
            selected &&
            poule.poulePlayerNames
              .toLowerCase()
              .includes(this.playerSelection.toLowerCase());
        }

        selected =
          selected &&
          (this.pouleStatusSelection == 'Alle' ||
            (this.pouleStatusSelection == 'Gespeeld' &&
              poule.pouleStatus >= 3) ||
            (this.pouleStatusSelection == 'Te spelen' &&
              poule.pouleStatus < 3));

        return selected;
      });
    },
    serieOngoing() {
      return this.selectedSerieStatus == 2 ? true : false;
    }
  },
  methods: {
    async setSelectedSerie(selectedSerie) {
      this.selectedSerie = selectedSerie;

      this.poules = [];

      this.selectedSerieIsLastSerie = false;
      this.selectedSerieStatus = null;

      if (this.selectedSerie) {
        for (let i = 0; i < this.activeSeries.length; i++) {
          if (this.activeSeries[i].serieId == selectedSerie) {
            this.selectedSerieIsLastSerie =
              this.activeSeries[i].isLastSerie == 1 ? true : false;
            this.selectedSerieStatus = this.activeSeries[i].status;
          }
        }
        // console.log(this.selectedSerieIsLastSerie);

        try {
          await this.$store
            .dispatch('leagues/loadPoules', {
              serieId: selectedSerie
            })
            .then(() => {
              this.poules = this.$store.getters['leagues/poules'];
            });
        } catch (err) {
          this.error =
            err.message ||
            'Er trad een probleem op bij het opvragen van de poules!';
        }

        try {
          await this.$store
            .dispatch('leagues/loadRankings', {
              serieId: selectedSerie
            })
            .then(() => {
              this.rankings = this.$store.getters['leagues/rankings'];
            });
        } catch (err) {
          this.error =
            err.message ||
            'Er trad een probleem op bij het opvragen van de rangschikking!';
        }
      }

      this.myPoule = null;
      this.captain = null;
      this.hasBeenUnsubscribed = false;

      const participations = this.$store.getters['leagues/participations'];
      for (let i = 0; i < participations.length; i++) {
        if (participations[i].serieId == selectedSerie) {
          for (let j = 0; j < this.poules.length; j++) {
            if (this.poules[j].pouleId == participations[i].pouleId) {
              this.myPoule = this.poules[j];
            }
          }

          this.hasBeenUnsubscribed = participations[i].unSubscribed;
          this.captain =
            this.whom == this.$store.getters['memberId'] &&
            participations[i].poulePlayerIndex == 1;
        }
      }
    },
    async loadInformation() {
      this.isLoading = true;

      const leagueId = this.$store.getters['leagues/leagueId'];
      const memberId = this.whom;
      // console.log(memberId);

      try {
        await this.$store
          .dispatch('leagues/loadLeagueInformation', {
            leagueId: leagueId,
            memberId: memberId
          })
          .then(() => {
            this.activeSeries = this.$store.getters['leagues/series'];
            this.activeLevels = this.$store.getters['leagues/levels'];
            // console.log(this.activeLevels);

            const participations = this.$store.getters[
              'leagues/participations'
            ];
            if (participations && participations.length > 0) {
              if (!this.selectedSerie) {
                this.setSelectedSerie(participations[0].serieId);
              } else {
                this.setSelectedSerie(this.selectedSerie);
              }
            } else {
              if (this.activeSeries.length > 0) {
                let lastSerie = null;
                for (let i = 0; i < this.activeSeries.length; i++) {
                  if (this.activeSeries[i].isLastSerie == 1) {
                    lastSerie = this.activeSeries[0].serieId;
                  }
                }

                this.setSelectedSerie(lastSerie);
              } else {
                this.setSelectedSerie(null);
              }

              this.myPoule = null;
              this.captain = null;
            }
          });
      } catch (err) {
        this.error =
          err.message ||
          'Er trad een probleem op bij het opvragen van de league-informatie!';
      }

      try {
        await this.$store
          .dispatch('leagues/loadPlayerResults', {
            leagueId: leagueId,
            memberId: memberId
          })
          .then(() => {
            this.playerResults = this.$store.getters['leagues/playerResults'];
            // console.log(this.playerResults);
          });
      } catch (err) {
        this.error =
          err.message ||
          'Er trad een probleem op bij het opvragen van de spelersresultaten!';
      }

      this.isLoading = false;

      // Close navigation
      if (this.$store.getters.isNavOpen) {
        this.$store.dispatch('toggleNav');
      }
    },
    setSelectedLevel(selectedLevel) {
      // console.log(selectedLevel);
      this.selectedLevel = selectedLevel;
    },
    updatePlayerSelection(updatedSearchTerm) {
      this.playerSelection = updatedSearchTerm;
    },
    switchResultsView() {
      this.showMatches = !this.showMatches;
    },
    showIndividualResults() {
      this.individualResultsVisible = true;
    },
    closeIndividualResults() {
      this.individualResultsVisible = false;
    },
    resultClass(won, lost, draw) {
      if (won == 1) {
        return 'won';
      }

      if (lost == 1) {
        return 'lost';
      }

      if (draw == 1) {
        return 'draw';
      }

      return '';
    },
    pouleCount(kind) {
      let description = 'Te spelen';
      if (kind == 2) {
        description = 'Alle';
      } else if (kind == 1) {
        description = 'Gespeeld';
      }

      const poules = this.poules;
      let pouleCount = 0;

      poules.forEach(poule => {
        // console.log(poule);
        if (
          kind == 2 ||
          (kind == 1 && poule.pouleStatus >= 3) ||
          (kind == 0 && poule.pouleStatus < 3)
        ) {
          ++pouleCount;
        }
      });

      return description + ' (' + pouleCount + ')';
    },
    isReplacement(playerResult) {
      return (
        !!playerResult.replacement1 ||
        !!playerResult.replacement2 ||
        !!playerResult.replacement3 ||
        !!playerResult.replacement4
      );
    },
    replacements(playerResult) {
      let repl = '';
      if (playerResult.replacement1) {
        repl = playerResult.player1 + ' &#x21b9; ' + playerResult.replacement1;
      }
      if (playerResult.replacement2) {
        if (repl) {
          repl = repl + '<br>';
        }
        repl =
          repl +
          playerResult.player2 +
          ' &#x21b9; ' +
          playerResult.replacement2;
      }
      if (playerResult.replacement3) {
        if (repl) {
          repl = repl + '<br>';
        }
        repl =
          repl +
          playerResult.player3 +
          ' &#x21b9; ' +
          playerResult.replacement3;
      }
      if (playerResult.replacement4) {
        if (repl) {
          repl = repl + '<br>';
        }
        repl =
          repl +
          playerResult.player4 +
          ' &#x21b9; ' +
          playerResult.replacement4;
      }
      return repl;
    },
    players(playerResult) {
      let result = '';

      if (playerResult.replacement1) {
        result = '<s><em>' + playerResult.player1 + '</s></em>';
      } else {
        result = '<strong>' + playerResult.player1 + '</strong>';
      }

      if (playerResult.player2) {
        if (playerResult.replacement2) {
          result = result + '/<s><em>' + playerResult.player2 + '</s></em>';
        } else {
          result = result + '/<strong>' + playerResult.player2 + '</strong>';
        }
      }

      if (playerResult.replacement1) {
        result =
          result +
          '<br>&#x21b9;&nbsp;<em><strong>' +
          playerResult.replacement1 +
          '</em></strong>';
      }
      if (playerResult.replacement2) {
        result =
          result +
          '<br>&#x21b9;&nbsp;<em><strong>' +
          playerResult.replacement2 +
          '</em></strong>';
      }

      return result;
    },
    counterPlayers(playerResult) {
      let result = '';

      if (playerResult.replacement3) {
        result = '<s><em>' + playerResult.player3 + '</s></em>';
      } else {
        result = '<strong>' + playerResult.player3 + '</strong>';
      }

      if (playerResult.player4) {
        if (playerResult.replacement4) {
          result = result + '/<s><em>' + playerResult.player4 + '</s></em>';
        } else {
          result = result + '/<strong>' + playerResult.player4 + '</strong>';
        }
      }

      if (playerResult.replacement3) {
        result =
          result +
          '<br>&#x21b9;&nbsp;<strong><em>' +
          playerResult.replacement3 +
          '</em></strong>';
      }
      if (playerResult.replacement4) {
        result =
          result +
          '<br>&#x21b9;&nbsp;<em><strong>' +
          playerResult.replacement4 +
          '</em></strong>';
      }

      return result;
    },
    handleError() {
      this.error = null;
    },
    handleErrorAndClose() {
      this.error = null;
    },
    async changeTab(payload) {
      // Statistics
      if (payload.tabIndex == 4) {
        this.leagueStatistics = this.$store.getters['leagues/leagueStatistics'];
        if (!this.leagueStatistics || this.leagueStatistics.length == 0) {
          // console.log('Get statistics');
          const leagueId = this.$store.getters['leagues/leagueId'];
          await this.$store
            .dispatch('leagues/loadLeagueStatistics', {
              leagueId: leagueId
            })
            .then(() => {
              this.leagueStatistics = this.$store.getters[
                'leagues/leagueStatistics'
              ];

              // console.log(this.leagueStatistics);
            });
        }
      }
    },
    async sendEmail() {
      let van = 'jouw poule-informatie';

      if (this.whom != this.$store.getters['memberId']) {
        const subscriptions = this.$store.getters['leagues/subscriptions'];
        // console.log(this.whom);
        // console.log(subscriptions);
        let memberName = '';
        for (let i = 0; i < subscriptions.length; i++) {
          if (subscriptions[i].memberId == this.whom) {
            memberName = subscriptions[i].memberName;
          }
        }

        van = 'de poule-informatie van ' + memberName;
      }

      if (confirm(`Wens je een e-mailbericht met ` + van + '?')) {
        try {
          this.isLoading = true;

          const memberId = this.$store.getters['memberId'];

          // Send email
          await this.$store.dispatch('leagues/sendPouleMail', {
            pouleId: this.myPoule.pouleId,
            memberId: memberId
          });

          this.mailSent = true;
        } catch (err) {
          this.error =
            err.message ||
            'Er trad een probleem op bij het versturen van het e-mailbericht!';
        }

        this.isLoading = false;
      }
    },
    closeMailSentForm() {
      this.mailSent = false;
    },
    showResultForm() {
      this.resultFormIsVisible = true;
    },
    closeResultForm() {
      this.resultFormIsVisible = false;
    },
    async registerResult(formData) {
      // console.log(formData);

      try {
        await this.$store.dispatch('leagues/registerScore', formData);
      } catch (err) {
        this.error =
          err.message ||
          'Er trad een probleem op bij het registreren van de resultaten voor deze poule!';
      }

      this.loadInformation();

      this.resultFormIsVisible = false;
      this.resultRegisteredIsVisible = true;
    },
    closeResultRegisteredForm() {
      this.resultRegisteredIsVisible = false;
    },
    showReplacementForm() {
      this.replacementFormIsVisible = true;
    },
    closeReplacementForm() {
      this.replacementFormIsVisible = false;
    },
    async registerReplacement(formData) {
      // console.log(formData);
      try {
        await this.$store.dispatch('leagues/replacePlayer', formData);
      } catch (err) {
        this.error =
          err.message ||
          'Er trad een probleem op bij het registreren van deze vervanging!';
      }

      this.loadInformation();

      this.replacementFormIsVisible = false;
      this.replacementRegisteredIsVisible = true;
    },
    closeReplacementRegisteredForm() {
      this.replacementRegisteredIsVisible = false;
    },
    unsubscriptionPossible() {
      const canUnsubscribeFromLeague = this.$store.getters[
        'canUnsubscribeFromLeague'
      ];
      // console.log(canUnsubscribeFromLeague);
      return (
        canUnsubscribeFromLeague &&
        this.isOwnLeagueInfo &&
        !this.hasBeenUnsubscribed &&
        this.pouleId &&
        this.selectedSerieIsLastSerie &&
        this.selectedSerieStatus == 2
      );
    },
    unsubscribe() {
      this.unSubscribeFormIsVisible = true;
    },
    closeUnsubscribeForm() {
      this.unSubscribeFormIsVisible = false;
    },
    async registerUnsubscribe(formData) {
      const subscriptionId = this.$store.getters['isSubscribedForTheLeague'];

      if (subscriptionId) {
        try {
          await this.$store.dispatch('leagues/unSubscribe', {
            subscriptionId: subscriptionId,
            reasonId: formData.reasonId,
            remarks: formData.remarks
          });
        } catch (err) {
          this.error =
            err.message ||
            'Er trad een probleem op bij het registreren van je uitschrijving!';
        }

        this.loadInformation();

        this.unSubscribeFormIsVisible = false;
        this.unSubscribeRegisteredIsVisible = true;
      }
    },
    closeUnSubscribeRegisteredForm() {
      this.unSubscribeRegisteredIsVisible = false;
    },
    undoUnsubscribe() {
      this.undoUnSubscribeFormIsVisible = true;
    },
    closeUndoUnsubscribeForm() {
      this.undoUnSubscribeFormIsVisible = false;
    },
    async registerUndoUnsubscribe() {
      const subscriptionId = this.$store.getters['isSubscribedForTheLeague'];
      // console.log(subscriptionId);

      if (subscriptionId) {
        try {
          await this.$store.dispatch('leagues/undoUnSubscribe', {
            subscriptionId: subscriptionId
          });
        } catch (err) {
          this.error =
            err.message ||
            'Er trad een probleem op bij het annuleren van je uitschrijving!';
        }

        this.loadInformation();

        this.undoUnSubscribeFormIsVisible = false;
        this.undoUnSubscribeRegisteredIsVisible = true;
      }
    },
    closeUndoUnSubscribeRegisteredForm() {
      this.undoUnSubscribeRegisteredIsVisible = false;
    },
    showLevelCodeInRanking(rankingId) {
      if (rankingId > 0) {
        const previousLevel = this.rankings[rankingId - 1].levelCode;
        const currentLevel = this.rankings[rankingId].levelCode;
        return previousLevel != currentLevel;
      }

      return true;
    },
    playerMomentInfoEnabled() {
      const leaguePlayerMomentInfo = this.$store.getters[
        'leaguePlayerMomentInfo'
      ];
      // console.log(leaguePlayerMomentInfo);
      return (
        leaguePlayerMomentInfo &&
        this.isOwnLeagueInfo &&
        this.pouleId &&
        this.selectedSerieIsLastSerie &&
        this.selectedSerieStatus == 2
      );
    },
    showPlayDateForm() {
      this.playDateFormIsVisible = true;
    },
    async registerPlayDate(formData) {
      // console.log(formData);

      try {
        await this.$store.dispatch('leagues/registerPlayDate', formData);
      } catch (err) {
        this.error =
          err.message ||
          'Er trad een probleem op bij het registreren van de (geplande) speeldatum voor deze poule!';
      }

      this.loadInformation();

      this.playDateFormIsVisible = false;
    },
    closePlayDateForm() {
      this.playDateFormIsVisible = false;
    },
    statisticStyle(serie) {
      if (serie != 0) {
        return '';
      }

      return 'font-weight: bold';
    }
  },
  mounted() {
    if (this.$route.params.whom) {
      this.whom = this.$route.params.whom;
    } else {
      this.whom = this.$store.getters['memberId'];
    }

    this.loadInformation();
  }
};
</script>

<style src="@vueform/multiselect/themes/default.css"></style>
<style>
:root {
  --ms-font-size: 0.9rem;
  --ms-line-height: 0.85;

  --ms-option-bg-selected: #4dabf7;
  --ms-option-bg-selected-pointed: #4dabf7;
  --ms-option-font-size: 0.9rem;
}
</style>

<style scoped>
.buttons {
  margin-top: 10px;
}
.mailinfo {
  padding-top: 12px;
  font-size: 16px;
}
.periodselection {
  display: flex;
  align-items: center;
  gap: 10px;
  font-size: 18px;
}
.levelselection {
  display: flex;
  align-items: center;
  gap: 7px;
  font-size: 18px;
}
.playerselection {
  display: flex;
  align-items: center;
  gap: 7px;
  font-size: 18px;
}
.statusselection {
  display: flex;
  gap: 7px;
  font-size: 16px;
}
.boxed {
  width: 100%;
  overflow: scroll;
  height: 400px;
  margin-left: 5px;
  font-size: 16px;
}
.playdate {
  display: flex;
  margin-top: 5px;
  font-size: 12px;
  cursor: pointer;
}
.results {
  font-size: 14px;
}

.statistics {
  font-size: 14px;
}

a {
  width: 100%;
  font-size: 14px;
  cursor: pointer;
}
.email {
  width: 100%;
  font-size: 14px;
  font-weight: bold;
  cursor: pointer;
}
.unsubscribe {
  width: 100%;
  font-size: 14px;
  font-style: italic;
  cursor: pointer;
}

.won {
  color: var(--var-won);
  font-weight: bold;
}
.lost {
  color: var(--var-lost);
  font-weight: bold;
}
.draw {
  color: var(--var-draw);
  font-weight: bold;
}
.replacement {
  color: var(--var-warning);
  font-weight: bold;
}

.replacementhint {
  position: absolute;
  top: 200px;
  right: 10px;
  width: 400px;
  height: 25px;
  text-align: center;
  background-color: var(--var-warning);
  display: none;
}

.registered {
  padding-bottom: 10px;
}

.showhint:hover + .replacementhint {
  display: block;
  color: black;
}
.warning {
  padding-top: 12px;
  font-size: 16px;
  font-style: italic;
  color: var(--var-warning);
}
.gold {
  color: var(--var-gold);
}

.silver {
  color: var(--var-silver);
}
.bronze {
  color: var(--var-bronze);
}
.individualresults {
  font-size: 12px;
  font-weight: bold;
  cursor: pointer;
}

.newbadge,
.newbadge:hover {
  color: var(--var-font-color);
  background-color: var(--var-gold);
  padding: 3px 3px;
  border: 0px;
  border-radius: 30px;
  display: inline-block;
  font-size: 16px;
  font-weight: bold;
  margin-right: 0.5rem;
}
</style>
