<template>
  <base-card>
    <div class="buttons">
      <base-button @click="refresh" class="smallbold" v-if="!isVisible"
        ><i class="fas fa-sync-alt"></i
      ></base-button>
      <base-button @click="setVisible" v-if="!isVisible" class="smallbold"
        ><i class="fas fa-angle-down"></i>&nbsp;Maak selecties</base-button
      >
    </div>
    <div v-if="isVisible">
      <span class="form-check form-switch" v-if="!!myTeams">
        <label for="onlymyteams" class="checkboxtext"
          >Enkel de ploegen ({{ myTeams }}) waarin ik normaal opgesteld
          sta</label
        >
        <input
          class="form-check-input"
          type="checkbox"
          id="onlymyteams"
          :checked="onlyMyTeamsIsSelected"
          @change="setOnlyMyTeams"
        />
      </span>
      <div
        v-if="hasTeams && (!myTeams || !onlyMyTeamsIsSelected)"
        class="teamselection"
      >
        <team-selection
          v-for="team in teams"
          :key="team.id"
          :team="team.team"
          :selectedteam="filters.team"
          @set-team="selectTeam"
        >
        </team-selection>
        <span v-if="!!filters.team">
          <i class="far fa-trash-alt" @click="selectTeam('')"></i>
        </span>
      </div>
      <span class="form-check form-switch">
        <label for="coming" class="checkboxtext">Komende ontmoetingen</label>
        <input
          class="form-check-input"
          type="checkbox"
          id="coming"
          :checked="comingIsSelected"
          @change="setFilter"
        />
      </span>
      <span class="form-check form-switch">
        <label for="done" class="checkboxtext">Afgelopen ontmoetingen</label>
        <input
          class="form-check-input"
          type="checkbox"
          id="done"
          :checked="doneIsSelected"
          @change="setFilter"
        />
      </span>
      <span class="form-check form-switch">
        <label for="mine" class="mymeetingstext"
          >Enkel ontmoetingen waar ik sta opgesteld</label
        >
        <input
          class="form-check-input"
          type="checkbox"
          id="mine"
          :checked="mineIsSelected"
          @change="setFilter"
        />
      </span>
    </div>
    <p v-if="isVisible"></p>
    <div class="buttons">
      <base-button @click="refresh" class="smallbold" v-if="isVisible"
        ><i class="fas fa-sync-alt"></i
      ></base-button>
      <base-button @click="setVisible" v-if="isVisible" class="smallbold"
        ><i class="fas fa-angle-up"></i>&nbsp;Verberg selecties</base-button
      >
    </div>
  </base-card>
</template>

<script>
import TeamSelection from './TeamSelection.vue';

export default {
  components: {
    TeamSelection
  },
  emits: ['change-filter', 'refresh'],
  data() {
    return {
      isVisible: false,
      filters: {
        coming: true,
        done: false,
        mine: false,
        onlyMyTeams: false,
        team: ''
      },
      teams: []
    };
  },
  computed: {
    doneIsSelected() {
      return this.filters.done;
    },
    comingIsSelected() {
      return this.filters.coming;
    },
    mineIsSelected() {
      return this.filters.mine;
    },
    myTeams() {
      return this.$store.getters['myTeams'];
    },
    onlyMyTeamsIsSelected() {
      return this.filters.onlyMyTeams;
    },
    hasTeams() {
      return this.teams.length > 0 ? true : false;
    }
  },
  methods: {
    async loadTeams() {
      this.teams = this.$store.getters['teams/teams'];
      if (this.teams.length == 0) {
        try {
          await this.$store.dispatch('teams/loadTeams');

          this.teams = this.$store.getters['teams/teams'];
        } catch (err) {
          this.error =
            err.message ||
            'Er trad een probleem op bij het ophalen van de ploegen!';
        }
      }
    },
    setFilter(event) {
      const inputId = event.target.id;
      const isActive = event.target.checked;
      const updatedFilters = {
        ...this.filters,
        [inputId]: isActive
      };
      this.filters = updatedFilters;
      this.$emit('change-filter', updatedFilters);
    },
    setOnlyMyTeams(event) {
      const isActive = event.target.checked;

      const updatedFilters = {
        ...this.filters,
        onlyMyTeams: isActive
      };
      this.filters = updatedFilters;
      this.$emit('change-filter', updatedFilters);
    },
    selectTeam(payload) {
      let selectedTeam = '';
      if (this.filters.team && this.filters.team == payload.team) {
        selectedTeam = '';
      } else {
        selectedTeam = payload.team;
      }

      const updatedFilters = {
        ...this.filters,
        team: selectedTeam
      };
      this.filters = updatedFilters;
      this.$emit('change-filter', updatedFilters);
    },
    setVisible() {
      this.isVisible = !this.isVisible;
    },
    refresh() {
      this.$emit('refresh');
    }
  },
  created() {
    this.loadTeams();
  }
};
</script>

<style scoped>
h2 {
  margin: 0.5rem 0;
}

.checkboxtext {
  /* Checkbox text */
  font-size: 18px;
  display: inline;
  padding: 3px;
}

.mymeetingstext {
  font-size: 18px;
  background-color: var(--var-isplaying);
  display: inline;
  padding: 3px;
}

.teamselection {
  margin-top: 1rem;
  margin-bottom: 1rem;
}
</style>
