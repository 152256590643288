<template>
  <form @submit.prevent="closeForm">
    <div class="form-control">
      <div v-if="isLoading">
        <base-spinner></base-spinner>
      </div>
      <div
        v-if="!isLoading && trainingStatus == 2"
        class="status confirmed clickable"
        @click="deleteConfirmation"
      >
        Training werd bevestigd! Klik hier om de bevestiging ongedaan te maken.
      </div>
      <div v-if="!isLoading && trainingStatus == 8" class="status merged">
        Training werd samengevoegd met <strong>{{ mergeTitle }}</strong>
        <div v-html="mergeDescription"></div>
        <div @click="deleteMerge" class="clickable">
          Klik hier om deze samenvoeging ongedaan te maken.
        </div>
      </div>
      <div
        v-if="!isLoading && trainingStatus == 9"
        class="status cancelled clickable"
        @click="deleteCancellation"
      >
        Training werd geannuleerd! Klik hier om deze annulatie ongedaan te
        maken.
      </div>
      <base-tabs
        v-if="!isLoading"
        :tabList="tabList"
        @tab-change="tabChange"
        fontsize="small"
        bold="false"
      >
        <template v-slot:tabPanel-1>
          <div class="boxed">
            <multiselect
              v-model="trainerId"
              :options="trainerSelection"
              :searchable="true"
              placeholder="Selecteer trainer"
              ref="trainer"
            ></multiselect>
            <multiselect
              v-model="assistentId"
              :options="trainerSelection"
              :searchable="true"
              placeholder="Selecteer assistent"
              ref="assistent"
              v-if="nrofassistents >= 1"
            ></multiselect>
            <multiselect
              v-model="assistent2Id"
              :options="trainerSelection"
              :searchable="true"
              placeholder="Selecteer assistent (2)"
              ref="assistent2"
              v-if="nrofassistents >= 2"
            ></multiselect>
            <multiselect
              v-model="assistent3Id"
              :options="trainerSelection"
              :searchable="true"
              placeholder="Selecteer assistent (3)"
              ref="assistent3"
              v-if="nrofassistents >= 3"
            ></multiselect>
            <multiselect
              v-model="assistent4Id"
              :options="trainerSelection"
              :searchable="true"
              placeholder="Selecteer assistent (4)"
              ref="assistent4"
              v-if="nrofassistents >= 4"
            ></multiselect>
            <multiselect
              v-model="assistent5Id"
              :options="trainerSelection"
              :searchable="true"
              placeholder="Selecteer assistent (5)"
              ref="assistent5"
              v-if="nrofassistents >= 5"
            ></multiselect>
          </div>
        </template>
        <template v-slot:tabPanel-2>
          <div class="boxed">
            <textarea id="remark" v-model.trim="remark" />
          </div>
        </template>
        <template v-slot:tabPanel-3>
          <div class="boxed">
            <div class="merge">
              De training samenvoegen met
            </div>
            <multiselect
              v-model="mergeId"
              :options="mergeMomentSelection"
              :searchable="true"
              placeholder="Selecteer training"
              ref="merge"
              class="merge"
            ></multiselect>
            <div
              v-html="mergeDescription"
              v-if="mergeDescription"
              class="mergeinfo"
            ></div>
          </div>
        </template>
      </base-tabs>
      <hr />
      <div>
        <base-button @click="submitForm" mode="smallbold">Opslaan</base-button>
        <base-button @click="closeForm" mode="smallbold">Sluit</base-button>
      </div>
    </div>
  </form>
</template>

<script>
import Multiselect from '@vueform/multiselect';

export default {
  props: [
    'id',
    'starttime',
    'trainingid',
    'trainerid',
    'nrofassistents',
    'assistentid',
    'assistent2id',
    'assistent3id',
    'assistent4id',
    'assistent5id',
    'trainingstatus',
    'trainingmergeid',
    'training_remark'
  ],
  emits: ['save-training', 'close-training'],
  components: {
    Multiselect
  },
  data() {
    return {
      isLoading: false,
      trainerId: null,
      assistentId: null,
      assistent2Id: null,
      assistent3Id: null,
      assistent4Id: null,
      assistent5Id: null,
      trainingStatus: 0,
      mergeId: null,
      mergeTitle: null,
      mergeDescription: null,
      remark: '',
      tabIndex: 1
    };
  },
  computed: {
    trainerSelection() {
      const trainersStore = this.$store.getters['training/trainers'];

      const trainers = [];
      for (let i = 0; i < trainersStore.length; i++) {
        const trainer = {
          value: trainersStore[i].id,
          label: trainersStore[i].name
        };

        trainers.push(trainer);
      }

      return trainers;
    },
    mergeMomentSelection() {
      const mergeMoments = this.$store.getters['training/mergeableMoments'];

      const moments = [];
      for (let i = 0; i < mergeMoments.length; i++) {
        const moment = {
          value: mergeMoments[i].id,
          label: mergeMoments[i].calendarTitle
        };

        moments.push(moment);
      }

      return moments;
    },
    tabList() {
      if (this.trainingStatus >= 2) {
        return ['Trainer', 'Opmerking'];
      } else {
        const mergeableMoments = this.$store.getters[
          'training/mergeableMoments'
        ];
        if (mergeableMoments.length === 0) {
          return ['Trainer', 'Opmerking'];
        } else {
          return ['Trainer', 'Opmerking', 'Samenvoegen'];
        }
      }
    }
  },
  methods: {
    async loadTrainers() {
      this.isLoading = true;

      try {
        await this.$store.dispatch('training/loadTrainers');
      } catch (err) {
        this.error =
          err.message ||
          'Er trad een probleem op bij het ophalen van de trainerslijst!';
      }

      this.isLoading = false;
    },
    async loadMergeableMoments() {
      this.isLoading = true;

      try {
        await this.$store.dispatch('training/loadMergeableMoments', {
          trainingId: this.trainingid,
          eventId: this.id
        });
      } catch (err) {
        this.error =
          err.message ||
          'Er trad een probleem op bij het ophalen van de beschikbare samenvoegbare momenten!';
      }

      this.isLoading = false;
    },
    closeForm() {
      this.$emit('close-training');
    },
    submitForm(e) {
      e.preventDefault();
      if (!this.trainerId) {
        alert('Geef de trainer op a.u.b.');
        return;
      }

      if (this.tabIndex == 3 && (!this.mergeId || this.mergeId <= 0)) {
        alert('Geef de training op waarmee je wenst samen te voegen a.u.b.');
        return;
      }

      const formData = {
        eventId: this.id,
        trainerId: this.trainerId,
        assistentId: this.assistentId,
        assistent2Id: this.assistent2Id,
        assistent3Id: this.assistent3Id,
        assistent4Id: this.assistent4Id,
        assistent5Id: this.assistent5Id,
        status: this.tabIndex == 3 ? 8 : this.trainingStatus,
        trainingMergeId: this.mergeId,
        remark: this.remark
      };

      this.$emit('save-training', formData);
    },
    tabChange(payload) {
      this.tabIndex = payload.tabIndex;
    },
    deleteCancellation() {
      if (confirm('De annulatie van dit trainingsmoment ongedaan maken?')) {
        const currentDate = new Date();
        const trainingDate = new Date(this.starttime);
        // console.log(currentDate);
        // console.log(trainingDate);

        if (trainingDate < currentDate) {
          this.trainingStatus = 0; // Niet gestart
        } else {
          this.trainingStatus = 1; // Te bevestigen
        }
      }
    },
    deleteMerge() {
      if (confirm('De samenvoeging van dit trainingsmoment ongedaan maken?')) {
        const currentDate = new Date();
        const trainingDate = new Date(this.starttime);
        // console.log(currentDate);
        // console.log(trainngDate);

        this.mergeId = 0;

        if (trainingDate < currentDate) {
          this.trainingStatus = 0; // Niet gestart
        } else {
          this.trainingStatus = 1; // Te bevestigen
        }
      }
    },
    deleteConfirmation() {
      if (confirm('De bevestiging van dit trainingsmoment ongedaan maken?')) {
        const currentDate = new Date();
        const trainingDate = new Date(this.starttime);
        // console.log(currentDate);
        // console.log(trainingDate);

        if (trainingDate < currentDate) {
          this.trainingStatus = 0; // Niet gestart
        } else {
          this.trainingStatus = 1; // Te bevestigen
        }
      }
    }
  },
  watch: {
    mergeId(val) {
      if (val && val > 0) {
        const mergeMoments = this.$store.getters['training/mergeableMoments'];

        mergeMoments.forEach(moment => {
          if (moment.id == val) {
            this.mergeDescription = moment.description;
          }
        });
      } else {
        this.mergeDescription = null;
      }
    }
  },
  mounted() {
    this.trainerId = this.trainerid;
    this.assistentId = this.assistentid;
    this.assistent2Id = this.assistent2id;
    this.assistent3Id = this.assistent3id;
    this.assistent4Id = this.assistent4id;
    this.assistent5Id = this.assistent5id;
    this.trainingStatus = this.trainingstatus;
    this.mergeId = this.trainingmergeid;
    this.remark = this.training_remark;

    this.loadTrainers();

    if (this.trainingStatus < 2) {
      this.loadMergeableMoments();
    }

    if (this.trainingStatus == 8 && this.mergeId && this.mergeId > 0) {
      this.loadMergeableMoments().then(() => {
        const mergeMoments = this.$store.getters['training/mergeableMoments'];
        mergeMoments.forEach(moment => {
          if (moment.id == this.mergeId) {
            this.mergeTitle = moment.title;
            this.mergeDescription = moment.description;
          }
        });
      });
    }
  }
};
</script>

<style src="@vueform/multiselect/themes/default.css"></style>
<style>
:root {
  --ms-font-size: 0.9rem;
  --ms-line-height: 0.85;

  --ms-option-bg-selected: #4dabf7;
  --ms-option-bg-selected-pointed: #4dabf7;
  --ms-option-font-size: 0.9rem;
}
</style>

<style scoped>
.form-control {
  margin: 0.5rem 0;
}

input,
textarea {
  display: block;
  width: 100%;
  height: 100%;
  border: 1px solid var(--var-edit-bordercolor);
  font: inherit;
}

input:focus,
textarea:focus {
  background-color: var(--var-edit-color-focused);
  outline: none;
  border-color: var(--var-edit-bordercolor-focused);
}

.invalid label {
  color: red;
}

.invalid input,
.invalid textarea {
  border: 1px solid red;
}

.info {
  padding-bottom: 15px;
  font-style: italic;
}

.boxed {
  overflow: scroll;
  overflow-x: hidden;
  height: 250px;
  margin-left: 5px;
}

.merge {
  margin-bottom: 10px;
}

.status {
  margin-bottom: 10px;
  padding: 5px;
  border-radius: 10px;
}

.confirmed {
  background-color: var(--var-training-confirmed);
}

.merged {
  background-color: var(--var-training-merged);
}

.mergeinfo {
  line-height: 1.2;
}

.cancelled {
  background-color: var(--var-training-cancelled);
}

.clickable {
  cursor: pointer;
}
</style>
