<template>
  <div>
    <li @click="setPresence" :class="clickclass">
      <div>
        <span class="present"
          ><i class="fas fa-user-check" v-if="traineePresence == 1"></i
        ></span>
        <span class="notpresent"
          ><i class="fas fa-user-slash" v-if="traineePresence == 0"></i
        ></span>
      </div>
      <div class="trainee">
        {{ membername }}
        <div class="group">{{ traininggroup }}</div>
        <div class="missed" v-if="nrofeventsago > 0">
          Training(en) gemist: {{ nrofeventsago }}
        </div>
      </div>
    </li>
  </div>
</template>

<script>
export default {
  props: [
    'eventid',
    'memberid',
    'membername',
    'presence',
    'traininggroup',
    'nrofeventsago',
    'trainingstatus'
  ],
  emits: ['set-presence'],
  data() {
    return {
      traineePresence: -1
    };
  },
  computed: {
    clickclass() {
      return this.trainingstatus < 2 ? '' : 'confirmed';
    }
  },
  methods: {
    setPresence() {
      if (this.trainingstatus < 2) {
        if (this.traineePresence <= 0) {
          this.traineePresence = 1;
        } else {
          this.traineePresence = 0;
        }
        this.$emit('set-presence', {
          memberId: this.memberid,
          presence: this.traineePresence
        });
      }
    }
  },
  mounted() {
    this.traineePresence = this.presence;
  }
};
</script>

<style scoped>
li {
  list-style: none;
  font-size: 32px;
  font-weight: bold;
  margin: 0.5rem 0;
  border: 1px solid var(--var-font-color);
  border-radius: 12px;
  padding: 1rem;
  cursor: pointer;
  display: flex;
}

.confirmed {
  cursor: auto;
}

.present {
  color: var(--var-selected);
}

.notpresent {
  color: var(--var-unselected);
}

.trainee {
  margin-top: -10px;
  padding-left: 15px;
  line-height: 1.3;
}

.group {
  font-size: 18px;
  font-weight: normal;
}

.missed {
  font-size: 18px;
  font-weight: normal;
  font-style: italic;
}
</style>
