<template>
  <form @submit.prevent="closeForm">
    <div class="warning">
      <i class="fas fa-exclamation-triangle"></i>&nbsp;OPGELET: deze informatie
      is louter informatief! De terreinreservatie zelf bevat uiteraard de enige
      correcte speeldata!
    </div>
    <div class="form-control">
      <label for="playdate">(Geplande) speeldatum</label>
      <input
        type="date"
        id="playdate"
        v-model.trim="playDate"
        onkeypress="return event.keyCode != 13;"
      />
      <label for="playtime">Tijdstip</label>
      <input
        type="time"
        id="playtime"
        v-model.trim="playTime"
        onkeypress="return event.keyCode != 13;"
      />
      <hr />
      <label for="terrain">Terrein</label>
      <input
        type="text"
        id="terrain"
        v-model.trim="terrain"
        onkeypress="return event.keyCode != 13;"
      />
      <hr />

      <label for="remarks">Heb je aan- of opmerkingen</label>
      <textarea id="remarks" v-model.trim="remarks" rows="3" />
    </div>
    <div>
      <base-button @click="submitForm" mode="smallbold">Registreer</base-button>
      <base-button @click="closeForm" mode="smallbold">Sluit</base-button>
    </div>
  </form>
</template>

<script>
export default {
  props: ['id'],
  emits: ['save-playdate', 'close-playdate-form'],
  data() {
    return {
      playDate: null,
      playTime: null,
      terrain: null,
      remarks: null
    };
  },
  methods: {
    closeForm() {
      this.$emit('close-playdate-form');
    },
    submitForm() {
      // const currentMember = this.$store.getters['memberId'];
      const formData = {
        pouleId: this.id,
        playDate: this.playDate,
        playTime: this.playTime,
        terrain: this.terrain,
        remarks: this.remarks
      };

      this.$emit('save-playdate', formData);
    }
  },
  mounted() {
    const poules = this.$store.getters['leagues/poules'];

    for (let i = 0; i < poules.length; i++) {
      if (poules[i].pouleId == this.id) {
        this.terrain = poules[i].playTerrain;
        this.remarks = poules[i].playRemarks;
        this.playDate = poules[i].playDate;
        // console.log(this.playDate);
        this.playTime = poules[i].playTime;
      }
    }
  }
};
</script>

<style scoped>
.form-control {
  margin: 0.5rem 0;
}

label {
  font-weight: bold;
  display: block;
  margin-bottom: 0.5rem;
}

input[type='checkbox'] + label {
  font-weight: normal;
  display: inline;
  margin: 0 0 0 0.5rem;
}

input,
textarea {
  display: block;
  width: 100%;
  border: 1px solid var(--var-edit-bordercolor);
  font: inherit;
}

input:focus,
textarea:focus {
  background-color: var(--var-edit-color-focused);
  outline: none;
  border-color: var(--var-edit-bordercolor-focused);
}

input[type='checkbox'] {
  display: inline;
  width: auto;
  border: none;
}

input[type='checkbox']:focus {
  outline: var(--var-edit-bordercolor-focused) solid 1px;
}

h3 {
  margin: 0.5rem 0;
  font-size: 1rem;
}

.invalid label {
  color: red;
}

.invalid input,
.invalid textarea {
  border: 1px solid red;
}

.warning {
  color: var(--var-error);
}
</style>
