<template>
  <li>
    <h5>
      <i class="fas fa-mars" v-if="isMale"></i>
      <i class="fas fa-venus" v-if="isFemale"></i>
      {{ membername }}
      &nbsp;
    </h5>
    <hr />
    <div v-if="!!address">
      <i class="fas fa-map-marker-alt"></i>&nbsp;<strong>{{ address }}</strong>
    </div>
    <div v-if="validEmail">
      <i class="fas fa-envelope-square"></i>&nbsp;<a :href="mailto"
        ><strong>{{ email }}</strong></a
      >
    </div>
    <div v-if="!!telephone">
      <i class="fas fa-phone"></i>&nbsp;<a :href="phoneto"
        ><strong>{{ telephone }}</strong></a
      >
    </div>
    <div v-if="!!mobile">
      <i class="fas fa-mobile-alt"></i>&nbsp;<a :href="mobileto"
        ><strong>{{ mobile }}</strong></a
      >
    </div>
    <div v-if="!!actuallevel && !!actualpoule">
      <i class="far fa-address-card"></i>&nbsp;Huidige poule:
      <strong>{{ actuallevel }}/{{ actualpoule }}</strong>
    </div>
    <div v-if="!!ranking">
      <i class="fas fa-chart-line"></i>&nbsp;Klassement:
      <strong>{{ ranking }}</strong>
    </div>
    <hr />
    <base-button
      v-if="!!actuallevel && !!actualpoule"
      class="small"
      @click="showDetails"
      ><i class="fas fa-trophy"></i>&nbsp;Toon resultaten</base-button
    >
  </li>
</template>

<script>
export default {
  props: [
    'id',
    'memberid',
    'membername',
    'gender',
    'address',
    'telephone',
    'mobile',
    'email',
    'ranking',
    'actuallevel',
    'actualpoule',
    'subscriptiondate',
    'status',
    'statusdescription'
  ],
  computed: {
    validEmail() {
      if (this.email.includes('@')) {
        return true;
      }

      return false;
    },
    mailto() {
      return 'mailto:' + this.email;
    },
    phoneto() {
      var nr = this.telephone;
      nr = nr.replace(/\D/g, '');
      return 'tel:' + nr;
    },
    mobileto() {
      var nr = this.mobile;
      nr = nr.replace(/\D/g, '');
      return 'tel:' + nr;
    },
    isMale() {
      if (this.gender === 'M') {
        return true;
      }

      return false;
    },
    isFemale() {
      if (this.gender === 'F') {
        return true;
      }

      return false;
    }
  },
  methods: {
    showDetails() {
      this.$router.push('/league/' + this.memberid);
    }
  }
};
</script>

<style scoped>
li {
  margin: 1rem 0;
  border: 1px solid #424242;
  border-radius: 12px;
  padding: 1rem;
}

h3 {
  font-size: 1.5rem;
}

h3,
h4 {
  margin: 0.5rem 0;
}

div {
  margin: 0rem 0;
}

.traineeremarks {
  margin-top: 10px;
  font-size: 16px;
  font-style: italic;
}

.objectives {
  display: flex;
}

.objective1 {
  background-color: var(--var-objective-1);
  margin-left: 10px;
}

.objective2 {
  background-color: var(--var-objective-2);
  margin-left: 10px;
}

.objective3 {
  background-color: var(--var-objective-3);
  margin-left: 10px;
}

.objective4 {
  background-color: var(--var-objective-4);
  margin-left: 10px;
}

.objective5 {
  background-color: var(--var-objective-5);
  margin-left: 10px;
}
</style>
