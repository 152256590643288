<template>
  <base-dialog :show="presenceDialogVisible" :fixed="true">
    <template v-slot:header>
      <h4>
        <i class="far fa-calendar-check"></i>
        Jouw aanwezigheid {{ campdate }}
      </h4>
    </template>
    <template v-slot:default>
      <trainer-presence-form
        :ids="ids"
        :campdate="campdate"
        :title="title"
        :presence="presence"
        :transport="transport"
        :remarks="remarks"
        @close-presence="hidePresenceDialog"
        @register-presence="registerPresence"
      ></trainer-presence-form>
    </template>
  </base-dialog>
  <li class="precenseinfo">
    <h5 @click="showPresenceDialog">
      <span class="present"
        ><i class="fas fa-user-check" v-if="isPresent"></i
      ></span>
      <span class="notpresent"
        ><i class="fas fa-user-slash" v-if="!isPresent"></i
      ></span>
      <span class="present" v-if="isPresent && transportByCar">
        <i class="fas fa-car"></i>
      </span>
      <span class="present" v-if="isPresent && transportByBike">
        <i class="fas fa-bicycle"></i>
      </span>
      {{ campdate }} - {{ title }}
    </h5>
    <div v-if="remarks" @click="showPresenceDialog">
      {{ remarks }}
    </div>
  </li>
</template>

<script>
import TrainerPresenceForm from '../../components/sportscamps/TrainerPresenceForm.vue';

export default {
  components: {
    TrainerPresenceForm
  },
  props: [
    'ids',
    'campdate',
    'title',
    'responsable',
    'presence',
    'transport',
    'remarks',
    'registrationdate'
  ],
  data() {
    return {
      presenceDialogVisible: false
    };
  },
  computed: {
    isPresent() {
      return this.presence && this.presence == 1;
    },
    transportByCar() {
      // console.log(this.transport);
      return this.transport && this.transport == 1;
    },
    transportByBike() {
      // console.log(this.transport);
      return this.transport && this.transport == 2;
    }
  },
  methods: {
    showPresenceDialog() {
      this.presenceDialogVisible = true;
    },
    hidePresenceDialog() {
      this.presenceDialogVisible = false;
    },
    async registerPresence(payload) {
      // console.log(payload);
      try {
        await this.$store.dispatch('sportscamps/setTrainerPresence', {
          ids: payload.ids,
          presence: payload.presence,
          transport: payload.transport,
          remarks: payload.remarks
        });

        this.presenceDialogVisible = false;
      } catch (err) {
        this.error =
          err.message ||
          'Er trad een probleem op bij het registreren van je aanwezigheid!';
      }
    }
  }
};
</script>

<style scoped>
li {
  margin: 1rem 0;
  border: 1px solid #424242;
  border-radius: 12px;
  padding: 1rem;
}

h3 {
  font-size: 1.5rem;
}

h3,
h4 {
  margin: 0.5rem 0;
}

div {
  margin: 0rem 0;
}

.precenseinfo {
  cursor: pointer;
}
.present {
  color: rgb(65, 209, 65);
}

.notpresent {
  color: rgb(207, 58, 58);
}
</style>
