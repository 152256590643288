<template>
  <base-popup :show="isLoading" :fixed="true" title="Even geduld aub...">
    <div>
      <base-spinner></base-spinner>
    </div>
  </base-popup>
  <base-popup :show="!!error" title="Er trad een fout op!" @close="handleError">
    <p>{{ error }}</p>
  </base-popup>
  <base-popup
    :show="iCalFormSentIsVisible"
    title="Het e-mailbericht werd verstuurd"
    awesomeicon="far fa-envelope"
    @close="hideICalFormSent"
  >
    <template v-slot:default>
      <div>
        Het e-mailbericht werd verstuurd aan <strong>{{ email }}</strong
        >.
      </div>
      <div class="mailinfo">
        <em
          >Eventjes geduld wel, het kan even duren alvorens het bericht in je
          mailbox toekomt!<br />
          Vergeet ook niet even bij je spamberichten te zien!</em
        >
      </div>
    </template>
  </base-popup>
  <div>
    Verstuur een e-mailbericht met de afspraakgegevens aan
    <strong>{{ email }}</strong>
  </div>
  <div class="form-control">
    <div>
      <base-button mode="smallbold" @click="sendIcalFileForMeeting"
        >Enkel voor de ontmoeting
        <div v-html="datedescription"></div
      ></base-button>
    </div>
    <div>
      <base-button mode="smallbold" @click="sendIcalFileForTeam"
        >Voor alle ontmoetingen<br />
        van de ploeg<br />{{ teamdescription }}</base-button
      >
    </div>
  </div>
</template>

<script>
export default {
  props: [
    'id',
    'teamid',
    'teamdescription',
    'weekdescription',
    'datedescription'
  ],
  emits: ['close-form'],
  data() {
    return {
      email: null,
      isLoading: false,
      error: null,
      iCalFormSentIsVisible: false
    };
  },
  methods: {
    handleError() {
      this.error = null;
    },
    async sendIcalFileForMeeting() {
      if (
        confirm(
          'Wens je een e-mailbericht met de afspraakgegevens voor deze ontmoeting?\n\n' +
            '(Je kan deze datum dan eenvoudig aan je agenda toevoegen)'
        )
      ) {
        try {
          this.isLoading = true;

          // Send iCalfiles
          await this.$store.dispatch('teams/sendIcalEventFile', {
            teamcalendarId: this.id
          });

          this.iCalFormSentIsVisible = true;
        } catch (err) {
          this.error =
            err.message ||
            'Er trad een probleem op bij het versturen van de gegevens!';
        }

        this.isLoading = false;
      }
    },
    async sendIcalFileForTeam() {
      if (
        confirm(
          `Wens je e-mailbericht(en) met de (komende) afspraakdata voor de ploeg ${this.teamdescription}?\n\n(Je kan deze afspraakdata dan eenvoudig aan je agenda toevoegen)`
        )
      ) {
        try {
          this.isLoading = true;

          // Send iCalfiles
          await this.$store.dispatch('teams/sendIcalEventFile', {
            teamId: this.teamid
          });

          this.iCalFormSentIsVisible = true;
        } catch (err) {
          this.error =
            err.message ||
            'Er trad een probleem op bij het versturen van de gegevens!';
        }

        this.isLoading = false;
      }
    },
    closeForm() {
      this.$emit('close-form');
    },
    hideICalFormSent() {
      this.iCalFormSentIsVisible = false;
      this.$emit('close-form');
    }
  },
  mounted() {
    this.email = this.$store.getters['email'];
  }
};
</script>

<style scoped>
.form-control {
  margin: 0.5rem 0;
  display: flex;
}
</style>
