export default {
  setTeams(state, payload) {
    state.teams = payload;
  },
  setTeamCompositions(state, payload) {
    state.teamCompositions = payload;
  },
  setCompositionLogs(state, payload) {
    state.compositionLogs = payload;
  },
  setAvailableTeamPlayers(state, payload) {
    state.availableTeamPlayers = payload;
  },
  setFetchTimestamp(state) {
    state.lastFetch = new Date().getTime();
  }
};
