<template>
  <section>
    <base-card>
      <h2>Pagina niet gevonden</h2>
      <p>
        Deze pagina kon niet gevonden worden!
      </p>
      <p>
      <router-link to="/">Home</router-link>.
      </p>
    </base-card>
  </section>
</template>