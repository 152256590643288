<template>
  <base-card>
    <div class="selection">
      <input type="radio" v-model="selection" value="1">Thema
      <input type="radio" v-model="selection" value="2">Materiaal
      <input type="radio" v-model="selection" value="3">Thema+materiaal
      <input type="radio" v-model="selection" value="4">Trainers
    </div>
    <div v-if="showSearchTerm">
      <h6>Zoek</h6>
      <base-search @search="updateSearch" :search-term="searchTerm"></base-search>
    </div>
  </base-card>
</template>

<script>
export default {
  props: ['showSearchTerm'],
  emits: ['change-filter'],
  data() {
    return {
      searchTerm: '',
      selection: '3'
    }
  },
  methods: {
    updateSearch(updatedSearchTerm) {
      this.searchTerm = updatedSearchTerm;
      const filter = {
        searchTerm : this.searchTerm,
        selection : this.selection
      }
      this.$emit('change-filter', filter);
    },
    updateSelection() {
      const filter = {
        searchTerm : this.searchTerm,
        selection : this.selection
      }
      this.$emit('change-filter', filter);
    }
  },
  watch: {
    selection() {
      const filter = {
        searchTerm : this.searchTerm,
        selection : this.selection
      }
      this.$emit('change-filter', filter);
    }
  }
};
</script>

<style scoped>
h2 {
  margin: 0.5rem 0;
}

.selection {
  font-size: 110%;
  display: flex;
}

input[type=radio] {  
  border: 0px;
  width: 10%;
  height: 1.5em;
}
</style>
