export default {
  setLeagues(state, payload) {
    state.leagues = payload;
  },
  setLeagueId(state, payload) {
    state.leagueId = payload;
  },
  setLevels(state, payload) {
    state.levels = payload;
  },
  setSeries(state, payload) {
    state.series = payload;
  },
  setPoules(state, payload) {
    state.poules = payload;
  },
  setRankings(state, payload) {
    state.rankings = payload;
  },
  setParticipations(state, payload) {
    state.participations = payload;
  },
  setSubscriptions(state, payload) {
    state.subscriptions = payload;
  },
  setPlayerResults(state, payload) {
    state.playerResults = payload;
  },
  setPouleInformation(state, payload) {
    state.pouleInformation = payload;
  },
  setPoulePlayers(state, payload) {
    state.poulePlayers = payload;
  },
  setPouleMatches(state, payload) {
    state.pouleMatches = payload;
  },
  setLeagueStatistics(state, payload) {
    state.leagueStatistics = payload;
  }
};
