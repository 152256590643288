<template>
  <div
    :class="{
      'flex space-x-4': variant === 'horizontal'
    }"
  >
    <ul
      :class="{
        flex: variant === 'vertical'
      }"
    >
      <li v-for="(tab, index) in tabList" :key="index">
        <label
          :for="`tab${tabId}${index}`"
          v-html="caption(index)"
          :class="{
            selected: index + 1 === activeTab || activeTab == null,
            unselected: index + 1 !== activeTab,
            small: fontsize == 'small',
            fontbold: bold == 'true'
          }"
        />
        <input
          :id="`tab${tabId}${index}`"
          type="radio"
          :name="`tab${index}`"
          :value="index + 1"
          v-model="activeTab"
        />
      </li>
    </ul>
    <hr />

    <template v-for="(tab, index) in tabList">
      <div :key="index" v-if="index + 1 === activeTab">
        <slot :name="`tabPanel-${tabId}${index + 1}`" />
      </div>
    </template>
  </div>
</template>

<script>
export default {
  props: {
    tabId: {
      type: String,
      default: ''
    },
    tabList: {
      type: Array,
      required: true
    },
    awesomeIconList: {
      type: Array
    },
    tabCaptionType: {
      type: String
    },
    variant: {
      type: String,
      required: false,
      default: () => 'vertical',
      validator: value => ['horizontal', 'vertical'].includes(value)
    },
    fontsize: {
      type: String,
      required: false,
      default: 'normal'
    },
    bold: {
      type: String,
      required: false,
      default: 'true'
    }
  },
  emits: ['tab-change'],
  data() {
    return {
      activeTab: 1
    };
  },
  methods: {
    caption(index) {
      let c = '';
      if (
        this.awesomeIconList &&
        this.awesomeIconList.length >= index &&
        (!this.tabCaptionType ||
          this.tabCaptionType == 'icons' ||
          this.tabCaptionType == 'iconsandtext')
      ) {
        c = '<i class="' + this.awesomeIconList[index] + '"></i>&nbsp;';
      }

      if (
        !this.tabCaptionType ||
        this.tabCaptionType == 'text' ||
        this.tabCaptionType == 'iconsandtext'
      ) {
        c = c + this.tabList[index];
      }

      return c;
    }
  },
  watch: {
    activeTab: function(val) {
      // console.log(val);
      this.$emit('tab-change', {
        tabIndex: val
      });
    }
  }
};
</script>

<style scoped>
.flex {
  display: flex;
  width: 100%;
  /* overflow: scroll;
  overflow-y: hidden;
  height: 75px; */
  list-style-type: none;
  margin: 0px 0 0px 0px;
  padding: 0;
}

li {
  float: left;
  margin: 0 5px 0 0;
}

label {
  padding: 5px;
  cursor: pointer;
  color: var(--var-font-color);
  border-style: solid;
  border-color: var(--var-font-color);
  border-width: 1px;
  -webkit-appearance: button;
  /* WebKit */
  -moz-appearance: button;
  /* Mozilla */
  -o-appearance: button;
  /* Opera */
  -ms-appearance: button;
  /* Internet Explorer */
  appearance: button;
  /* CSS3 */
}

label:hover {
  background: var(--var-button-background-focused);
}

input {
  visibility: hidden;
}

.selected {
  background: var(--var-button-background-focused);
}

.unselected {
  background-color: var(--var-button-background);
}

.small {
  font-size: 16px;
}

.fontbold {
  font-weight: bold;
}
</style>
