<template>
  <div>
    <slot></slot>
    <base-dialog
      :show="!!error"
      title="Er trad een fout op!"
      @close="handleError"
    >
      <p>{{ error }}</p>
    </base-dialog>
    <section>
      <presence-filter
        :moments="sportsCampMoments"
        title="Aanmelden - groep & moment"
        @set-moment="setSportsCampMoment"
      ></presence-filter>
    </section>
    <div v-if="isLoading || isEventSelected">
      <section>
        <base-card>
          <div v-if="isLoading">
            <base-spinner></base-spinner>
          </div>
          <div v-if="hasSportsCampPresences">
            <div class="nrofsubscriptions">
              Totaal aantal aangemeld: {{ nrOfPresences }} /
              {{ nrOfSubscriptions }}
              <base-button mode="small" @click="loadPresences"
                ><i class="fas fa-sync-alt"></i
              ></base-button>
            </div>
            <sports-camp-presence-item
              v-for="presence in filteredPresences"
              :key="presence.id"
              :checkinout="0"
              :subscriptionid="presence.subscriptionId"
              :subscriptionname="presence.subscriptionName"
              :gender="presence.gender"
              :email="presence.email"
              :email2="presence.email2"
              :telephone="presence.telephone"
              :telephone2="presence.telephone2"
              :isbirthdaytoday="presence.isBirthdayToday"
              :parents="presence.parents"
              :presence="presence.presence"
              :checkedout="presence.checkedOut"
              @set-presence="setPresence"
            ></sports-camp-presence-item>
            <div class="nrofsubscriptions">
              Totaal aantal aangemeld: {{ nrOfPresences }} /
              {{ nrOfSubscriptions }}
              <base-button mode="small" @click="loadPresences"
                ><i class="fas fa-sync-alt"></i
              ></base-button>
            </div>
          </div>
        </base-card>
      </section>
    </div>
  </div>
</template>

<script>
import SportsCampPresenceItem from '../../components/sportscamps/SportsCampPresenceItem.vue';
import PresenceFilter from '../../components/presences/PresenceFilter.vue';

export default {
  components: {
    SportsCampPresenceItem,
    PresenceFilter
  },
  data() {
    return {
      isLoading: false,
      error: null,
      eventId: 0
    };
  },
  computed: {
    sportsCampMoments() {
      const momentsList = this.$store.getters['sportscamps/sportsCampMoments'];
      const moments = [{ id: 0, name: 'Selecteer groep & moment' }];
      momentsList.forEach(moment => {
        moments.push({ id: moment.eventId, name: moment.campTitleAndPeriod });
      });

      return moments;
    },
    hasSportsCampPresences() {
      const presencesList = this.$store.getters['sportscamps/presences'];
      // console.log(presencesList);

      return !this.isLoading && presencesList && presencesList.length > 0;
    },
    filteredPresences() {
      const presencesList = this.$store.getters['sportscamps/presences'];

      return presencesList;
    },
    isEventSelected() {
      if (this.eventId > 0) {
        return true;
      }

      return false;
    },
    nrOfSubscriptions() {
      const presencesList = this.$store.getters['sportscamps/presences'];

      return presencesList.length;
    },
    nrOfPresences() {
      let nrOfPresent = 0;
      const presencesList = this.$store.getters['sportscamps/presences'];

      presencesList.forEach(presence => {
        if (presence.presence == '1') {
          nrOfPresent++;
        }
      });

      return nrOfPresent;
    }
  },
  methods: {
    async loadSportsCampMoments() {
      this.isLoading = true;

      try {
        const trainerId = this.$store.getters.trainerId;
        await this.$store.dispatch('sportscamps/loadSportsCampMoments', {
          trainerId: trainerId,
          forPlanning: false
        });
      } catch (err) {
        this.error =
          err.message ||
          'Er trad een probleem op bij het ophalen van de sportkampmomenten!';
      }

      this.isLoading = false;

      // Close navigation
      if (this.$store.getters.isNavOpen) {
        this.$store.dispatch('toggleNav');
      }
    },
    async loadPresences() {
      this.isLoading = true;

      try {
        await this.$store.dispatch('sportscamps/loadPresences', {
          eventId: this.eventId,
          checkOut: 0
        });
      } catch (err) {
        this.error =
          err.message ||
          'Er trad een probleem op bij het ophalen van de aanwezigheidslijst!';
      }

      this.isLoading = false;
    },
    setSportsCampMoment(moment) {
      if (moment) {
        this.eventId = moment.id;
      } else {
        this.eventId = 0;
      }

      if (this.eventId > 0) {
        this.loadPresences();
      } else {
        this.$store.dispatch('sportscamps/clearPresences');
      }
    },
    async setPresence(presenceData) {
      try {
        await this.$store.dispatch('sportscamps/updateCheckIn', {
          eventId: this.eventId,
          subscriptionId: presenceData.subscriptionId,
          present: presenceData.present
        });
      } catch (err) {
        this.error =
          err.message ||
          'Er trad een probleem op bij het aanpassen van de aanwezigheid!';
      }
    },
    handleError() {
      this.error = null;
    }
  },
  created() {
    this.loadSportsCampMoments();
  }
};
</script>

<style scoped>
ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.nrofsubscriptions {
  font-size: 24px;
  font-weight: bold;
}

.controls {
  display: flex;
  justify-content: space-between;
}
</style>
