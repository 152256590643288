<template>
  <base-button @click="setTeam" :class="selectedClass">
    {{ team }}
  </base-button>
</template>

<script>
export default {
  props: ['team', 'selectedteam'],
  emits: ['set-team'],
  computed: {
    selectedClass() {
      if (this.selectedteam && this.selectedteam == this.team) {
        return 'selected';
      } else {
        return 'unselected';
      }
    }
  },
  methods: {
    setTeam() {
      this.$emit('set-team', {
        team: this.team
      });
    }
  }
};
</script>

<style scoped>
.unselected {
  background-color: var(--var-button-background);
  color: var(--var-font-color);
  padding: 0rem 0.1rem;
  border: 0.3px solid var(--var-font-color);
  border-radius: 0px;
  font-size: 18px;
  font-weight: normal;
}

.selected {
  background-color: var(--var-button-background-focused);
  color: var(--var-font-color);
  padding: 0rem 0.1rem;
  border: 0.3px solid var(--var-font-color);
  border-radius: 0px;
  font-size: 18px;
  font-weight: normal;
}
</style>
