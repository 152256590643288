<template>
  <div>
    <slot></slot>
    <base-dialog
      :show="!!error"
      title="Er trad een fout op bij het ophalen van het reglement!"
      @close="handleError"
    >
      <p>{{ error }}</p>
    </base-dialog>
    <section>
      <base-card>
        <div v-if="isLoading">
          <base-spinner></base-spinner>
        </div>
        <div v-else>
          <h3>{{ leagueTitle }}</h3>
          <div v-html="regulation" class="regulations"></div>
        </div>
      </base-card>
    </section>
  </div>
</template>

<script>
export default {
  data() {
    return {
      isLoading: false,
      error: null
    };
  },
  computed: {
    leagueTitle() {
      const leagues = this.$store.getters['leagues/leagues'];
      const leagueId = this.$store.getters['leagues/leagueId'];

      for (let i = 0; i < leagues.length; i++) {
        if (leagues[i].leagueId == leagueId) {
          return leagues[i].description;
        }
      }

      return '';
    },
    regulation() {
      const leagues = this.$store.getters['leagues/leagues'];
      const leagueId = this.$store.getters['leagues/leagueId'];

      for (let i = 0; i < leagues.length; i++) {
        if (leagues[i].leagueId == leagueId) {
          return leagues[i].information;
        }
      }

      return '';
    }
  },
  methods: {
    handleError() {
      this.error = null;
    }
  },
  created() {
    // Close navigation
    if (this.$store.getters.isNavOpen) {
      this.$store.dispatch('toggleNav');
    }
  }
};
</script>

<style scoped>
.regulations {
  font-size: 16px;
}

.controls {
  display: flex;
  justify-content: space-between;
}
</style>
