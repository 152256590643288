export default {
  async loadPresences(context, payload) {
    let url = context.rootState.apiUrl + 'presence_list.php';

    const token = context.rootState.token;
    const response = await fetch(url, {
      method: 'POST',
      body: JSON.stringify({
        jwt: token,
        event_id: payload.eventId
      })
    });

    const responseData = await response.json();
    // console.log(responseData);

    if (!response.ok) {
      const error = new Error(responseData.message || 'Fout bij ophalen van de aanwezigheidslijst!');
      throw error;
    }

    const presences = [];

    for (const key in responseData) {
      const presence = {
        id: key,
        eventMemberId: responseData[key].eventmember_id,
        memberId: responseData[key].member_id,
        memberName: responseData[key].membername,
        email: responseData[key].email,
        telephone: responseData[key].telephone,
        mobile: responseData[key].mobile,
        playerMoments: responseData[key].playermoments,
        presence: responseData[key].presence,
        occasional: responseData[key].occasional
      };
      presences.push(presence);
    }

    context.commit('setPresences', presences);
  },
  clearPresences(context) {
    const presences = [];
    context.commit('setPresences', presences);
  },
  async updatePresence(context, payload) {
    let url = context.rootState.apiUrl + 'presence_update.php';
    let presence = '1';
    if (payload.present === false) {
      presence = '0';
    }

    const token = context.rootState.token;
    const response = await fetch(url, {
      method: 'POST',
      body: JSON.stringify({
        jwt: token,
        event_id: payload.eventId,
        member_id: payload.memberId,
        presence: presence
      })
    });

    const responseData = await response.json();
    // console.log(responseData);

    if (!response.ok) {
      const error = new Error(responseData.message || 'Fout bij het aanpassen van de aanwezigheid!');
      throw error;
    }

    // Update presences-item in array
    const presences = context.state.presences;

    presences.forEach(pres => {
      if (pres.memberId === payload.memberId) {
        pres.presence = presence;
      }
    })

    context.commit('setPresences', presences);
  }
};