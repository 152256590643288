<template>
  <div>
    <slot></slot>
    <base-dialog
      :show="!!error"
      title="Er trad een fout op!"
      @close="handleError"
    >
      <p>{{ error }}</p>
    </base-dialog>
    <section>
      <presence-filter
        :moments="playerMoments"
        title="Speelmoment"
        @set-moment="setPlayerMoment"
      ></presence-filter>
    </section>
    <div v-if="isLoading || isEventSelected">
      <section>
        <base-card>
          <div v-if="isLoading">
            <base-spinner></base-spinner>
          </div>
          <div v-if="!isLoading && isEventSelected" class="occasional">
            <h3>Aanwezigheden</h3>
            <div class="info">
              Indien de speler niet in de onderstaande lijst te vinden is, kan
              je de (occasioneel) aanwezige speler hier opzoeken en toevoegen.
            </div>
            <multiselect
              v-model="memberToAdd"
              :options="memberSelection"
              :searchable="true"
              placeholder="Zoek speler"
              ref="member"
              class="memberselection"
            ></multiselect>
            <base-button
              @click="addMember"
              class="smallbold memberselection"
              v-if="memberToAdd"
              >Voeg (occasionele) speler toe</base-button
            >
          </div>
          <div v-if="hasPresences">
            <presence-item
              v-for="presence in filteredPresences"
              :key="presence.id"
              :memberid="presence.memberId"
              :membername="presence.memberName"
              :presence="presence.presence"
              :occasional="presence.occasional"
              @set-presence="setPresence"
            ></presence-item>
            <div class="nrofplayers">Totaal aantal: {{ nrOfPresences }}</div>
            <!--          <div class="controls">
            <base-button mode="flat" @click="loadPresences"
              >Vernieuwen</base-button
            >
          </div> -->
          </div>
        </base-card>
      </section>
    </div>
  </div>
</template>

<script>
import PresenceItem from '../../components/presences/PresenceItem.vue';
import PresenceFilter from '../../components/presences/PresenceFilter.vue';
import Multiselect from '@vueform/multiselect';

export default {
  components: {
    PresenceItem,
    PresenceFilter,
    Multiselect
  },
  data() {
    return {
      isLoading: false,
      error: null,
      eventId: 0,
      memberToAdd: null
    };
  },
  computed: {
    playerMoments() {
      const momentsList = this.$store.getters['playermoments/playerMoments'];
      const moments = [{ id: 0, name: 'Selecteer speelmoment' }];
      momentsList.forEach(moment => {
        moments.push({ id: moment.eventId, name: moment.moment });
      });

      return moments;
    },
    hasPresences() {
      const presencesList = this.$store.getters['presences/presences'];

      return !this.isLoading && presencesList && presencesList.length > 0;
    },
    filteredPresences() {
      const presencesList = this.$store.getters['presences/presences'];

      return presencesList;
    },
    isEventSelected() {
      if (this.eventId > 0) {
        return true;
      }

      return false;
    },
    nrOfPresences() {
      let nrOfPresent = 0;
      const presencesList = this.$store.getters['presences/presences'];

      presencesList.forEach(presence => {
        if (presence.presence == '1') {
          nrOfPresent++;
        }
      });

      return nrOfPresent;
    },
    memberSelection() {
      const membersStore = this.$store.getters['members/members'];

      const members = [];
      for (let i = 0; i < membersStore.length; i++) {
        const member = {
          value: membersStore[i].memberId,
          label: membersStore[i].memberName
        };

        members.push(member);
      }

      return members;
    }
  },
  methods: {
    async loadPlayerMoments() {
      this.isLoading = true;

      try {
        const memberId = this.$store.getters.memberId;
        await this.$store.dispatch('playermoments/loadPlayerMoments', {
          memberId: memberId
        });
      } catch (err) {
        this.error =
          err.message ||
          'Er trad een probleem op bij het ophalen van de speelmomenten!';
      }

      this.isLoading = false;

      // Close navigation
      if (this.$store.getters.isNavOpen) {
        this.$store.dispatch('toggleNav');
      }
    },
    async loadMembers() {
      this.isLoading = true;

      try {
        await this.$store.dispatch('members/loadMembers', {
          forceRefresh: false
        });
      } catch (err) {
        this.error =
          err.message ||
          'Er trad een probleem op bij het ophalen van de ledenfiches!';
      }

      this.isLoading = false;
    },
    async loadPresences() {
      this.isLoading = true;

      try {
        await this.$store.dispatch('presences/loadPresences', {
          eventId: this.eventId
        });
      } catch (err) {
        this.error =
          err.message ||
          'Er trad een probleem op bij het ophalen van de aanwezigheidslijst!';
      }

      this.isLoading = false;
    },
    setPlayerMoment(moment) {
      if (moment) {
        this.eventId = moment.id;
      } else {
        this.eventId = 0;
      }

      // console.log(this.eventId);

      if (this.eventId > 0) {
        this.loadPresences();
      } else {
        this.$store.dispatch('presences/clearPresences');
      }
    },
    async setPresence(presenceData) {
      try {
        await this.$store.dispatch('presences/updatePresence', {
          eventId: this.eventId,
          memberId: presenceData.memberId,
          present: presenceData.present
        });
      } catch (err) {
        this.error =
          err.message ||
          'Er trad een probleem op bij het aanpassen van de aanwezigheid!';
      }
    },
    async addMember() {
      if (this.memberToAdd > 0) {
        try {
          await this.$store.dispatch('presences/updatePresence', {
            eventId: this.eventId,
            memberId: this.memberToAdd,
            present: true
          });
        } catch (err) {
          this.error =
            err.message ||
            'Er trad een probleem op bij het aanpassen van de aanwezigheid!';
        }

        this.memberToAdd = null;
        this.loadPresences();
      }
    },
    handleError() {
      this.error = null;
    }
  },
  created() {
    this.loadPlayerMoments();
    this.loadMembers();
  }
};
</script>

<style src="@vueform/multiselect/themes/default.css"></style>
<style>
:root {
  --ms-font-size: 0.9rem;
  --ms-line-height: 0.85;

  --ms-option-bg-selected: #4dabf7;
  --ms-option-bg-selected-pointed: #4dabf7;
  --ms-option-font-size: 0.9rem;
}
</style>

<style scoped>
ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.nrofplayers {
  font-size: 24px;
  font-weight: bold;
}

.controls {
  display: flex;
  justify-content: space-between;
}

.info {
  line-height: 1.2;
}

.occasional {
  width: 100%;
}

.memberselection {
  margin-top: 5px;
}
</style>
