<template>
  <div>
    <li @click="selectMember" :class="selectedClass">
      <div v-if="selected">
        {{ membername }}&nbsp;<i class="fas fa-check"></i>
      </div>
      <div v-else>
        {{ membername }}
      </div>
    </li>
  </div>
</template>

<script>
export default {
  props: [
    'memberid',
    'membername',
    'selected'
  ],
  emits: ['set-member'],
  data() {
    return {
    };
  },
  computed: {
    isSelected() {
      return !!this.selected;
    },
    selectedClass() {
      if (this.isSelected) {
        return "selected";
      } 

      return "notselected";
    }
  },
  methods: {
    selectMember() {
      this.$emit('set-member', {
        memberId: this.memberid
      });
    }
  },
};
</script>

<style scoped>
li {
  list-style: none;
  font-size: 32px;
  font-weight: bold;
  margin: 1rem 0;
  border: 1px solid #424242;
  border-radius: 12px;
  padding: 1rem;
  cursor: pointer;
}

.selected {
  background-color: var(--var-button-background-focused);
}

.notselected {
  background-color: var(--var-button-background);
}
</style>
