export default {
  hasTeamCompositions(state) {
    return state.teamCompositions && state.teamCompositions.length > 0;
  },
  teams(state) {
    return state.teams;
  },
  teamCompositions(state) {
    return state.teamCompositions;
  },
  compositionLogs(state) {
    return state.compositionLogs;
  },
  availableTeamPlayers(state) {
    return state.availableTeamPlayers;
  },
  shouldUpdate(state) {
    const lastFetch = state.lastFetch;
    if (!lastFetch) {
      return true;
    }
    const currentTimeStamp = new Date().getTime();
    return (currentTimeStamp - lastFetch) / 1000 > 60;
  }
};
