<template>
  <div>
    <base-popup
      :show="!!error"
      title="Fout/waarschuwing"
      awesomeicon="fas fa-exclamation-triangle"
      @close="handleErrorAndClose"
    >
      <p class="error">{{ error }}</p>
    </base-popup>
    <base-popup
      :show="registered"
      title="GDPR-voorkeuren geregistreerd"
      @close="closeGdpr"
    >
      <p>
        Je voorkeuren werden geregistreerd en doorgestuurd naar de
        verantwoordelijke van het ledenbeheer.
      </p>
    </base-popup>
    <section>
      <base-card>
        <div v-if="isLoading">
          <base-spinner></base-spinner>
        </div>
        <div v-else>
          <h3>Mijn GDPR-voorkeuren</h3>
          <hr />
          <div class="boxed">
            <span class="form-check form-switch">
              <label for="explicitAllowance" class="checkboxtext"
                >Ik verleen expliciete GDPR-toelating</label
              >
              <input
                class="form-check-input"
                type="checkbox"
                id="explicitAllowance"
                :checked="explicitChecked"
                @change="setExplicitAllowance"
              />
            </span>
            <span class="form-check form-switch" v-if="explicitChecked">
              <label for="mailings" class="checkboxtext"
                >De club mag me e-mailberichten sturen</label
              >
              <input
                class="form-check-input"
                type="checkbox"
                id="mailings"
                :checked="mailingsChecked"
                @change="setMailings"
              />
            </span>
            <span class="form-check form-switch" v-if="explicitChecked">
              <label for="smses" class="checkboxtext"
                >De club mag me SMS-berichten sturen</label
              >
              <input
                class="form-check-input"
                type="checkbox"
                id="smses"
                :checked="smsesChecked"
                @change="setSmses"
              />
            </span>
            <span class="form-check form-switch" v-if="explicitChecked">
              <label for="birthday" class="checkboxtext"
                >Mijn verjaardag mag gepubliceerd worden</label
              >
              <input
                class="form-check-input"
                type="checkbox"
                id="birthday"
                :checked="birthdayChecked"
                @change="setBirthday"
              />
            </span>
            <span class="form-check form-switch" v-if="explicitChecked">
              <label for="photo" class="checkboxtext"
                >Mijn foto mag gepubliceerd worden</label
              >
              <input
                class="form-check-input"
                type="checkbox"
                id="birthday"
                :checked="photoChecked"
                @change="setPhoto"
              />
            </span>
          </div>
          <hr />
          <div>
            <i class="fas fa-info-circle"></i>&nbsp;&nbsp;Meer uitleg over GDPR
            vind je
            <a href="https://www.ledenfiches.be/?page_id=572" target="_blank"
              >hier</a
            >
          </div>
          <hr />
          <div class="buttons">
            <base-button @click="setGdpr" mode="smallbold"
              >Mijn voorkeuren opslaan</base-button
            >
            <base-button @click="closeGdpr" mode="smallbold">Sluit</base-button>
          </div>
        </div>
      </base-card>
    </section>
  </div>
</template>

<script>
export default {
  components: {},
  data() {
    return {
      isLoading: false,
      explicitAllowance: true,
      mailings: true,
      smses: true,
      birthday: true,
      photo: true,
      error: null,
      registered: false
    };
  },
  computed: {
    explicitChecked() {
      return this.explicitAllowance;
    },
    mailingsChecked() {
      return this.mailings;
    },
    smsesChecked() {
      return this.smses;
    },
    birthdayChecked() {
      return this.birthday;
    },
    photoChecked() {
      return this.photo;
    }
  },
  methods: {
    async loadGdpr() {
      this.isLoading = true;

      try {
        await this.$store.dispatch('loadGdpr', {}).then(() => {
          this.explicitAllowance =
            this.$store.getters['gdprExplicitAllowance'] == 1;
          this.mailings = this.$store.getters['gdprMailings'] == 1;
          this.smses = this.$store.getters['gdprSmses'] == 1;
          this.birthday = this.$store.getters['gdprBirthday'] == 1;
          this.photo = this.$store.getters['gdprPhoto'] == 1;
        });
      } catch (err) {
        this.error =
          err.message ||
          'Er trad een probleem op bij het ophalen van je GDPR-instellingen!';
      }

      this.isLoading = false;

      // Close navigation
      if (this.$store.getters.isNavOpen) {
        this.$store.dispatch('toggleNav');
      }
    },
    setExplicitAllowance(event) {
      this.explicitAllowance = event.target.checked;
    },
    setMailings(event) {
      this.mailings = event.target.checked;
    },
    setSmses(event) {
      this.smses = event.target.checked;
    },
    setBirthday(event) {
      this.birthday = event.target.checked;
    },
    setPhoto(event) {
      this.photo = event.target.checked;
    },
    async setGdpr() {
      try {
        let changed = false;

        if (
          this.explicitAllowance !=
          (this.$store.getters['gdprExplicitAllowance'] == 1)
        ) {
          changed = true;
        }
        if (this.mailings != (this.$store.getters['gdprMailings'] == 1)) {
          changed = true;
        }
        if (this.smses != (this.$store.getters['gdprSmses'] == 1)) {
          changed = true;
        }
        if (this.birthday != (this.$store.getters['gdprBirthday'] == 1)) {
          changed = true;
        }
        if (this.photo != (this.$store.getters['gdprPhoto'] == 1)) {
          changed = true;
        }

        if (changed == true) {
          await this.$store
            .dispatch('setGdpr', {
              gdprExplicitAllowance: this.explicitAllowance,
              gdprMailings: this.mailings,
              gdprSmses: this.smses,
              gdprBirthday: this.birthday,
              gdprPhoto: this.photo
            })
            .then(() => {
              this.registered = true;
            });
        } else {
          this.registered = true;
        }
      } catch (err) {
        this.error =
          err.message ||
          'Er trad een probleem op bij het doorvoeren van je GDPR-voorkeuren!';
        // console.error(this.error);
      }
    },
    closeGdpr() {
      this.$router.replace('/home');
    },
    handleError() {
      this.error = null;
    },
    handleErrorAndClose() {
      this.error = null;
    }
  },
  created() {
    this.loadGdpr();
  }
};
</script>

<style scoped>
.buttons {
  margin-top: 10px;
}
</style>
