<template>
  <div>
    <slot></slot>
    <base-popup
      :show="!!error"
      title="Er trad een fout op bij het ophalen van de ploegopstellingen!"
      @close="handleError"
    >
      <p>{{ error }}</p>
    </base-popup>
    <section>
      <team-composition-filter
        @change-filter="setFilter"
        @refresh="loadTeamCompositions(true)"
      ></team-composition-filter>
    </section>
    <section>
      <base-card>
        <div v-if="isLoading">
          <base-spinner></base-spinner>
        </div>
        <ul v-else-if="hasTeamCompositions">
          <team-item
            v-for="teamComposition in filteredTeamCompositions"
            :key="teamComposition.id"
            :id="teamComposition.id"
            :playid="teamComposition.playId"
            :playdate="teamComposition.playDate"
            :playtime="teamComposition.playTime"
            :datedescription="teamComposition.dateDescription"
            :weekdescription="teamComposition.weekDescription"
            :teamid="teamComposition.teamId"
            :team="teamComposition.team"
            :teamdescription="teamComposition.teamDescription"
            :meeting="teamComposition.meeting"
            :division="teamComposition.division"
            :nrofplayersperteam="teamComposition.nrOfPlayersPerTeam"
            :startplayerindex="teamComposition.startPlayerIndex"
            :captain="teamComposition.captain"
            :composition="teamComposition.composition"
            :error="teamComposition.error"
            :members="teamComposition.members"
            :reserves="teamComposition.reserves"
            :intermediatescore="teamComposition.intermediateScore"
            :score="teamComposition.score"
            :won="teamComposition.won"
            :homeout="teamComposition.homeout"
            :address="teamComposition.address"
            :frenoyurl="teamComposition.frenoyUrl"
            :googlemapsurl="teamComposition.googleMapsUrl"
            :driverid="teamComposition.driverId"
            :driver="teamComposition.driver"
            :driverremarks="teamComposition.driverRemarks"
            :confirmedmembers="teamComposition.confirmedMembers"
            :candrive="teamComposition.canDrive"
            :report="teamComposition.report"
          ></team-item>
        </ul>
        <h5 v-else>Geen ploegopstellingen</h5>
        <base-button mode="smallbold" @click="loadTeamCompositions(true)"
          >Vernieuwen</base-button
        >
      </base-card>
    </section>
  </div>
</template>

<script>
import TeamItem from '../../components/teams/TeamItem.vue';
import TeamCompositionFilter from '../../components/teams/TeamCompositionFilter.vue';

export default {
  components: {
    TeamItem,
    TeamCompositionFilter
  },
  data() {
    return {
      //      refreshTimer: null,
      isLoading: false,
      error: null,
      activeFilters: {
        coming: true,
        done: false,
        mine: false,
        onlyMyTeams: false,
        team: ''
      }
    };
  },
  computed: {
    hasTeamCompositions() {
      const currentFilters = this.activeFilters;
      const currentDate = new Date();
      let presentDate = new Date();
      presentDate.setDate(presentDate.getDate() - 3);
      const currentMember = this.$store.getters['memberId'];
      const compositionList = this.$store.getters['teams/teamCompositions'];
      const myTeams = this.$store.getters['myTeams'];

      return (
        !this.isLoading &&
        compositionList.filter(composition => {
          const compositionDate = new Date(composition.playDate);
          const members = ',' + composition.members + ',';

          if (
            currentFilters.onlyMyTeams &&
            myTeams.indexOf(composition.teamDescription) < 0
          ) {
            return false;
          }

          if (
            !currentFilters.onlyMyTeams &&
            currentFilters.team &&
            currentFilters.team != composition.teamDescription
          ) {
            return false;
          }

          if (currentFilters.mine && members.indexOf(currentMember) < 0) {
            return false;
          }

          if (currentFilters.coming && compositionDate >= presentDate) {
            return true;
          }

          if (currentFilters.done && compositionDate < currentDate) {
            return true;
          }

          return false;
        }).length > 0
      );
    },
    filteredTeamCompositions() {
      const currentFilters = this.activeFilters;
      const currentDate = new Date();
      let presentDate = new Date();
      presentDate.setDate(presentDate.getDate() - 3);
      const currentMember = this.$store.getters['memberId'];
      const compositionList = this.$store.getters['teams/teamCompositions'];
      const myTeams = this.$store.getters['myTeams'];

      return compositionList.filter(composition => {
        const compositionDate = new Date(composition.playDate);
        const members = ',' + composition.members + ',';

        if (
          currentFilters.onlyMyTeams &&
          myTeams.indexOf(composition.teamDescription) < 0
        ) {
          return false;
        }

        if (
          !currentFilters.onlyMyTeams &&
          currentFilters.team &&
          currentFilters.team != composition.teamDescription
        ) {
          return false;
        }

        if (currentFilters.mine && members.indexOf(currentMember) < 0) {
          return false;
        }

        if (currentFilters.coming && compositionDate >= presentDate) {
          return true;
        }

        if (currentFilters.done && compositionDate < currentDate) {
          return true;
        }

        return false;
      });
    }
  },
  methods: {
    async loadTeamCompositions(refresh = false) {
      this.isLoading = true;

      try {
        await this.$store.dispatch('teams/loadTeamCompositions', {
          forceRefresh: refresh
        });
      } catch (err) {
        this.error =
          err.message ||
          'Er trad een probleem op bij het ophalen van de ploegopstellingen!';
      }

      this.isLoading = false;

      // Close navigation
      if (this.$store.getters.isNavOpen) {
        this.$store.dispatch('toggleNav');
      }

      // Autorefresh om de 5"
      // const that = this;
      // this.refreshTimer = setInterval(function() {
      //   that.loadTeamCompositions(true);
      // }, 300000);
    },
    async loadAbsences() {
      this.isLoading = true;

      try {
        await this.$store.dispatch('members/loadAbsences');
      } catch (err) {
        this.error =
          err.message ||
          'Er trad een probleem op bij het ophalen van de afwezigheden!';
      }

      this.isLoading = false;
    },
    handleError() {
      this.error = null;
    },
    setFilter(filters) {
      this.activeFilters = filters;
    }
  },
  created() {
    this.loadTeamCompositions(true);
    this.loadAbsences();
  }
  // beforeUnmount() {
  //   if (this.refreshTimer) {
  //     clearInterval(this.refreshTimer);
  //   }
  // }
};
</script>

<style scoped>
ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.controls {
  display: flex;
  justify-content: space-between;
}
</style>
