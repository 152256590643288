<template>
  <div
    :class="{
      'flex space-x-4': variant === 'horizontal'
    }"
  >
    <ul
      :class="{
        flex: variant === 'vertical'
      }"
    >
      <li v-for="(option, index) in optionList" :key="index">
        <label
          :for="`opt${optionId}${index}`"
          v-html="caption(index)"
          :class="{
            selected: index + 1 === activeOption || activeOption == null,
            unselected: index + 1 !== activeOption,
            small: fontsize == 'small',
            fontbold: bold == 'true',
            primary: badgeColor(index) == 'primary',
            success: badgeColor(index) == 'success',
            neutral: badgeColor(index) == 'neutral',
            warning: badgeColor(index) == 'warning',
            danger: badgeColor(index) == 'danger'
          }"
        />
        <input
          :id="`opt${optionId}${index}`"
          type="radio"
          :name="`opt${index}`"
          :value="index + 1"
          v-model="activeOption"
        />
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  props: {
    optionId: {
      type: String,
      default: ''
    },
    optionList: {
      type: Array,
      required: true
    },
    defaultOption: {
      type: String
    },
    awesomeIconList: {
      type: Array
    },
    useAwesomeIcon: {
      type: String
    },
    badgeList: {
      type: Array
    },
    variant: {
      type: String,
      required: false,
      default: () => 'vertical',
      validator: value => ['horizontal', 'vertical'].includes(value)
    },
    fontsize: {
      type: String,
      required: false,
      default: 'normal'
    },
    bold: {
      type: String,
      required: false,
      default: 'true'
    }
  },
  emits: ['selection-change'],
  data() {
    return {
      activeOption: 1
    };
  },
  methods: {
    caption(index) {
      let c = this.optionList[index];

      if (
        this.useAwesomeIcon == 'true' &&
        this.awesomeIconList &&
        this.awesomeIconList.length >= index
      ) {
        c = '<i class="' + this.awesomeIconList[index] + '"></i>&nbsp;' + c;
      }

      return c;
    },
    badgeColor(index) {
      if (this.badgeList && this.badgeList.length >= index) {
        return this.badgeList[index];
      }
    }
  },
  watch: {
    activeOption: function(val) {
      // console.log(this.optionList[val - 1]);
      this.$emit('selection-change', this.optionList[val - 1]);
    }
  },
  created() {
    let i = 1;
    if (this.defaultOption && this.defaultOption != '') {
      this.optionList.forEach(option => {
        if (option == this.defaultOption) {
          this.activeOption = i;
        }
        i = i + 1;
      });
    }
  }
};
</script>

<style scoped>
.flex {
  display: flex;
  width: 100%;
  /* overflow: scroll;
  overflow-y: hidden;
  height: 75px; */
  list-style-type: none;
  margin: 0px 0 0px 0px;
  padding: 0;
}

li {
  float: left;
}

label {
  padding: 5px;
  cursor: pointer;
  color: var(--var-font-color);
  border-style: solid;
  border-color: var(--var-font-color);
  border-width: 1px;
  -webkit-appearance: button;
  /* WebKit */
  -moz-appearance: button;
  /* Mozilla */
  -o-appearance: button;
  /* Opera */
  -ms-appearance: button;
  /* Internet Explorer */
  appearance: button;
  /* CSS3 */
}

label:hover {
  background: var(--var-button-background-focused);
}

input {
  visibility: hidden;
}

.selected {
  background: var(--var-button-background-focused);
}

.unselected {
  background-color: var(--var-button-background);
}

.small {
  font-size: 16px;
}

.fontbold {
  font-weight: bold;
}

.primary {
  background-color: var(--var-sl-primary);
}

.success {
  background-color: var(--var-sl-success);
}

.neutral {
  background-color: var(--var-sl-neutral);
}

.warning {
  background-color: var(--var-sl-warning);
}

.danger {
  background-color: var(--var-sl-danger);
}
</style>
