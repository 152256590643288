<template>
  <base-dialog :show="individualResultsVisible" :fixed="true">
    <template v-slot:header>
      <h2>
        <div class="newbadge">NIEUW</div>
        <i class="fas fa-trophy"></i>
        Individuele resultaten
      </h2>
      <h4>Poulespelers {{ levelcode }}/{{ poule }}</h4>
    </template>
    <template v-slot:default>
      <league-individual-results-form
        :id="pouleid"
        @close-results="closeIndividualResults"
      ></league-individual-results-form>
    </template>
  </base-dialog>
  <li>
    <h4><i class="fas fa-boxes"></i>&nbsp;Poule {{ levelcode }}/{{ poule }}</h4>
    <div class="poule" v-html="pouleResults" @click="switchResultsView()"></div>
    <div class="individualresults" @click="showIndividualResults()">
      <div class="newbadge">NIEUW</div>
      <i class="fas fa-trophy"></i>&nbsp;Klik hier voor de individuele
      wedstrijdresultaten van deze poulespelers
    </div>
    <hr />
  </li>
</template>

<script>
import LeagueIndividualResultsForm from '../../components/leagues/LeagueIndividualResultsForm.vue';
export default {
  props: [
    'id',
    'pouleid',
    'levelcode',
    'poule',
    'pouleresults',
    'poulematches',
    'poulestatus',
    'poulestatusdescription'
  ],
  components: {
    LeagueIndividualResultsForm
  },
  data() {
    return {
      showMatches: false,
      individualResultsVisible: false
    };
  },
  computed: {
    pouleResults() {
      if (this.showMatches) {
        return this.poulematches;
      } else {
        return this.pouleresults;
      }
    }
  },
  methods: {
    switchResultsView() {
      this.showMatches = !this.showMatches;
    },
    showIndividualResults() {
      this.individualResultsVisible = true;
    },
    closeIndividualResults() {
      this.individualResultsVisible = false;
    }
  }
};
</script>

<style scoped>
li {
  list-style: none;
}

h3 {
  font-size: 1.5rem;
}

h3,
h4 {
  margin: 0.5rem 0;
}

div {
  margin: 0rem 0;
}
.poule {
  cursor: pointer;
}
.individualresults {
  font-size: 12px;
  font-weight: bold;
  cursor: pointer;
}
.newbadge,
.newbadge:hover {
  color: var(--var-font-color);
  background-color: var(--var-gold);
  padding: 3px 3px;
  border: 0px;
  border-radius: 30px;
  display: inline-block;
  font-size: 16px;
  font-weight: bold;
  margin-right: 0.5rem;
}
</style>
