export default {
  setSportsCampMoments(state, payload) {
    state.sportsCampMoments = payload;
  },
  setSubscriptions(state, payload) {
    state.subscriptions = payload;
  },
  setPresences(state, payload) {
    state.presences = payload;
  },
  setMaterialList(state, payload) {
    state.materialList = payload;
  },
  setTheme(state, payload) {
    state.theme = payload;
  },
  setMaterial(state, payload) {
    state.material = payload;
  },
  setPlanning(state, payload) {
    state.planning = payload;
  },
  setTrainers(state, payload) {
    state.trainers = payload;
  },
  setTrainerDayPlanning(state, payload) {
    state.trainerDayPlanning = payload;
  },
  setTrainerPresences(state, payload) {
    state.trainerPresences = payload;
  },
};
