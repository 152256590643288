<template>
  <header>
    <nav class="main-nav homebuttons">
      <burger></burger>
      <div class="homeheader" @click="goHome">
        &nbsp;<i class="fas fa-landmark"></i>
      </div>
    </nav>
    <div class="main-nav appheader">
      <h3>{{ appName }}</h3>
    </div>
    <sidebar>
      <ul class="sidebar-panel-nav">
        <div v-if="!loggedIn">
          <li>
            <router-link to="/login">Aanmelden</router-link>
          </li>
        </div>
        <div v-else>
          <div v-if="moreThanOneMember">
            <div v-if="!selectMemberMenuIsVisible">
              <h5 @click="toggleSelectMemberMenu">
                <i class="fas fa-angle-down"></i>&nbsp;Selecteer lid
              </h5>
            </div>
            <div v-else>
              <h5 @click="toggleSelectMemberMenu">
                <i class="fas fa-angle-up"></i>&nbsp;Selecteer lid
              </h5>
              <my-member-item
                v-for="member in myMembersList"
                :key="member.id"
                :memberid="member.memberId"
                :membername="member.memberName"
                :selected="member.selected"
                @set-member="selectMember"
              ></my-member-item>
            </div>
          </div>
          <div v-if="teamCompositionModuleActive">
            <div v-if="!associationMenuIsVisible">
              <h5 @click="toggleAssociationMenu">
                <i class="fas fa-angle-down"></i>&nbsp;{{ association }}
              </h5>
            </div>
            <div v-else>
              <h5 @click="toggleAssociationMenu">
                <i class="fas fa-angle-up"></i>&nbsp;{{ association }}
              </h5>
              <li v-if="isTableTennis">
                <router-link :to="resultRoute">Mijn resultaten</router-link>
              </li>
              <li>
                <router-link to="/statistics">Ploegen</router-link>
              </li>
              <li>
                <router-link to="/teams">Opstellingen</router-link>
              </li>
              <li v-if="isTableTennis">
                <router-link to="/weekdigest">Weekdigest</router-link>
              </li>
              <li v-if="isTableTennis">
                <router-link to="/sterktelijsten">Sterktelijsten</router-link>
              </li>
            </div>
          </div>
          <div v-if="hasLeague">
            <div v-if="!leagueMenuIsVisible">
              <h5 @click="toggleLeagueMenu">
                <i class="fas fa-angle-down"></i>&nbsp;League
              </h5>
            </div>
            <div v-else>
              <h5 @click="toggleLeagueMenu">
                <i class="fas fa-angle-up"></i>&nbsp;League
              </h5>
              <li>
                <router-link to="/home">Selecteer league</router-link>
              </li>
              <li v-if="!!leagueId">
                <router-link :to="leagueLink">Mijn league</router-link>
              </li>
              <li v-if="!!leagueId">
                <router-link to="/leaguesubscriptions"
                  >Inschrijvingen</router-link
                >
              </li>
              <li v-if="!!leagueId">
                <router-link to="/leagueregulations">Reglement</router-link>
              </li>
            </div>
          </div>
          <div v-if="trainingModuleActive && trainerId > 0">
            <div v-if="!trainingMenuIsVisible">
              <h5 @click="toggleTrainingMenu">
                <i class="fas fa-angle-down"></i>&nbsp;Trainingswerking
              </h5>
            </div>
            <div v-else>
              <h5 @click="toggleTrainingMenu">
                <i class="fas fa-angle-up"></i>&nbsp;Trainingswerking
              </h5>
              <!-- <li v-if="trainerId > 0">
              <router-link to="/home">Mijn trainees</router-link>
            </li> -->
              <li v-if="trainerId > 0">
                <router-link to="/trainingmoments/open"
                  >Aanwezigheden</router-link
                >
              </li>
              <li v-if="trainerId > 0">
                <router-link to="/members/trainees"
                  >Overzicht trainees</router-link
                >
              </li>
              <li v-if="trainerId > 0">
                <router-link to="/trainingmoments/all"
                  >Trainingsmomenten</router-link
                >
              </li>
            </div>
          </div>
          <div v-if="trainingGroups">
            <div v-if="!myTrainingMenuIsVisible">
              <h5 @click="toggleMyTrainingMenu">
                <i class="fas fa-angle-down"></i>&nbsp;Mijn trainingen
              </h5>
            </div>
            <div v-else>
              <h5 @click="toggleMyTrainingMenu">
                <i class="fas fa-angle-up"></i>&nbsp;Mijn trainingen
              </h5>
              <li>
                <router-link to="/traineetrainingmoments/reservation"
                  >Reserveren<br />Annuleren</router-link
                >
              </li>
              <li>
                <router-link to="/traineetrainingmoments/participated"
                  >Mijn deelnames</router-link
                >
              </li>
            </div>
          </div>
          <div v-if="sportsCampModuleActive && hasSportsCamp">
            <div v-if="!sportsCampMenuIsVisible">
              <h5 @click="toggleSportsCampMenu">
                <i class="fas fa-angle-down"></i>&nbsp;Sportkampen
              </h5>
            </div>
            <div v-else>
              <h5 @click="toggleSportsCampMenu">
                <i class="fas fa-angle-up"></i>&nbsp;Sportkampen
              </h5>
              <li>
                <router-link to="/home">Mijn kampdag</router-link>
              </li>
              <li>
                <router-link to="/trainerpresence"
                  >Mijn aanwezigheden</router-link
                >
              </li>
              <li>
                <router-link to="/sportscampplanning">Dagplanning</router-link>
              </li>
              <li>
                <router-link to="/sportscampcheckin"
                  >Gymnasten aanmelden</router-link
                >
              </li>
              <li>
                <router-link to="/sportscampcheckout"
                  >Gymnasten afmelden</router-link
                >
              </li>
              <li>
                <router-link to="/materiallist">Materiaal</router-link>
              </li>
              <li>
                <router-link to="/subscriptions">Inschrijvingen</router-link>
              </li>
            </div>
          </div>
          <div v-if="!generalMenuIsVisible">
            <h5 @click="toggleGeneralMenu">
              <i class="fas fa-angle-down"></i>&nbsp;Algemeen
            </h5>
          </div>
          <div v-else>
            <h5 @click="toggleGeneralMenu">
              <i class="fas fa-angle-up"></i>&nbsp;Algemeen
            </h5>
            <li>
              <router-link to="/members/members">Ledenfiches</router-link>
            </li>
            <li>
              <router-link to="/gdpr">Mijn GDPR-voorkeuren</router-link>
            </li>
            <li>
              <router-link to="/personalinformation"
                >Mijn persoonlijke gegevens</router-link
              >
            </li>
            <li v-if="!!frenoyPublicUrl && isTableTennis">
              <a :href="frenoyPublicUrl" target="_blank">Frenoy</a>
            </li>
            <li v-if="isGym">
              <a href="https://www.gymfed.be/" target="_blank">Gymfed</a>
            </li>
          </div>
          <div v-if="isTableTennis || isBadminton">
            <div v-if="!playerMomentMenuIsVisible">
              <h5 @click="togglePlayerMomentMenu">
                <i class="fas fa-angle-down"></i>&nbsp;Speelmomenten
              </h5>
            </div>
            <div v-else>
              <h5 @click="togglePlayerMomentMenu">
                <i class="fas fa-angle-up"></i>&nbsp;Speelmomenten
              </h5>
              <li>
                <router-link to="/reservations">Reservaties</router-link>
              </li>
              <li v-if="isResponsableForPlayerMoment">
                <router-link to="/presences">Aanwezigheden</router-link>
              </li>
            </div>
          </div>
          <hr />
          <li v-if="loggedIn">
            <router-link to="/logout">Afmelden</router-link>
          </li>
        </div>
        <li>
          <a href="https://www.ledenfiches.be" target="_blank">About</a>
        </li>
      </ul>
      <base-card class="login" v-if="loggedIn">
        <div>Aangemeld als:</div>
        <div>{{ firstName }} {{ lastName }}</div>
        <div>
          {{ clubCode }} - {{ clubName }}<br />
          {{ association }}
        </div>
      </base-card>
    </sidebar>
  </header>
  <div
    v-if="
      loggedIn && nameIsVisible && !isGym && !hasMessageOfTheDay && !isMobile
    "
    class="member"
    @click="toggleNameVisible"
  >
    Welkom {{ firstName }}!
  </div>
  <div
    v-if="loggedIn && hasMessageOfTheDay && messageIsVisible && !isMobile"
    class="messageoftheday"
    v-html="messageOfTheDay"
    @click="hideMessageOfTheDay"
  ></div>
</template>

<script>
import { ref, computed } from 'vue';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
import Burger from './../menu/Burger.vue';
import Sidebar from './../menu/Sidebar.vue';
import MyMemberItem from './MyMemberItem.vue';

export default {
  components: {
    Burger,
    Sidebar,
    MyMemberItem
  },
  setup() {
    let showName = ref(true);
    let showMessage = ref(true);
    let menuSelectMember = ref(true);
    let menuAssociation = ref(true);
    let menuLeague = ref(true);
    let menuTraining = ref(false);
    let menuMyTraining = ref(false);
    let menuSportsCamp = ref(false);
    let menuGeneral = ref(false);
    let menuPlayerMoment = ref(false);
    let menuLogout = ref(true);

    const store = useStore();
    const router = useRouter();

    const appName = computed(function() {
      return store.getters.appName;
    });

    const loggedIn = computed(function() {
      return store.getters.loggedIn;
    });

    const nameIsVisible = computed(function() {
      return showName.value;
    });

    const messageIsVisible = computed(function() {
      return showMessage.value;
    });

    const selectMemberMenuIsVisible = computed(function() {
      return menuSelectMember.value;
    });

    const associationMenuIsVisible = computed(function() {
      return menuAssociation.value;
    });

    const leagueMenuIsVisible = computed(function() {
      return menuLeague.value;
    });

    const sportsCampMenuIsVisible = computed(function() {
      return menuSportsCamp.value;
    });

    const trainingMenuIsVisible = computed(function() {
      return menuTraining.value;
    });

    const myTrainingMenuIsVisible = computed(function() {
      return menuMyTraining.value;
    });

    const teamCompositionModuleActive = computed(function() {
      // console.log(store.getters.teamCompositionModuleActive);
      return store.getters.teamCompositionModuleActive == 1 ? true : false;
    });

    const trainingModuleActive = computed(function() {
      // console.log(store.getters.trainingModuleActive);
      return store.getters.trainingModuleActive == 1 ? true : false;
    });

    const sportsCampModuleActive = computed(function() {
      return store.getters.sportsCampModuleActive == 1 ? true : false;
    });

    const trainerId = computed(function() {
      let id = store.getters.trainerId;
      if (!id || id <= 0) id = 0;
      return id;
    });

    const generalMenuIsVisible = computed(function() {
      return menuGeneral.value;
    });

    const playerMomentMenuIsVisible = computed(function() {
      return menuPlayerMoment.value;
    });

    const logoutMenuIsVisible = computed(function() {
      return menuLogout.value;
    });

    const firstName = computed(function() {
      return store.getters.firstName;
    });

    const lastName = computed(function() {
      return store.getters.lastName;
    });

    const clubName = computed(function() {
      return store.getters.clubName;
    });

    const clubCode = computed(function() {
      return store.getters.clubCode;
    });

    const association = computed(function() {
      return store.getters.association;
    });

    const isTableTennis = computed(function() {
      return store.getters.isTableTennis;
    });

    const isBadminton = computed(function() {
      return store.getters.isBadminton;
    });

    const isGym = computed(function() {
      return store.getters.isGym;
    });

    const isPadel = computed(function() {
      return store.getters.isPadel;
    });

    const hasSportsCamp = computed(function() {
      return store.getters.hasSportsCamp;
    });

    const hasLeague = computed(function() {
      return store.getters.hasLeague;
    });

    const leagueLink = computed(function() {
      const whom = store.getters['memberId'];
      return '/league/' + whom;
    });

    const frenoyPublicUrl = computed(function() {
      return store.getters.frenoyPublicUrl;
    });

    const isResponsableForPlayerMoment = computed(function() {
      const playerMoments = store.getters.playerMoments;
      if (playerMoments && playerMoments != '') {
        return true;
      }

      return false;
    });

    const isMobile = computed(function() {
      if (window.innerWidth < 540) {
        return true;
      }

      return false;
    });
    const hasMessageOfTheDay = computed(function() {
      const messageOfTheDay = store.getters.messageOfTheDay;
      return messageOfTheDay && messageOfTheDay != 'undefined' ? true : false;
    });

    const messageOfTheDay = computed(function() {
      return store.getters.messageOfTheDay;
    });

    function toggleNameVisible() {
      showName.value = !showName.value;
    }

    function hideMessageOfTheDay() {
      showMessage.value = !showMessage.value;
    }

    function toggleSelectMemberMenu() {
      menuSelectMember.value = !menuSelectMember.value;
    }

    function toggleAssociationMenu() {
      menuAssociation.value = !menuAssociation.value;
    }

    function toggleLeagueMenu() {
      menuLeague.value = !menuLeague.value;
    }

    function toggleGeneralMenu() {
      menuGeneral.value = !menuGeneral.value;
    }

    function toggleSportsCampMenu() {
      menuSportsCamp.value = !menuSportsCamp.value;
    }

    function toggleTrainingMenu() {
      menuTraining.value = !menuTraining.value;
    }

    function toggleMyTrainingMenu() {
      menuMyTraining.value = !menuMyTraining.value;
    }

    function togglePlayerMomentMenu() {
      menuPlayerMoment.value = !menuPlayerMoment.value;
    }

    function toggleLogoutMenu() {
      menuLogout.value = !menuLogout.value;
    }

    const moreThanOneMember = computed(function() {
      const members = store.getters['myMembers'];
      return members && members.length > 1 ? true : false;
    });

    const myMembersList = computed(function() {
      return store.getters['myMembers'];
    });

    const trainingGroups = computed(function() {
      return store.getters['trainingGroups'];
    });

    const resultRoute = computed(function() {
      const memberId = store.getters['memberId'];
      return '/results/' + memberId;
    });

    const leagueId = computed(function() {
      return store.getters['leagues/leagueId'];
    });

    function selectMember(selectedMember) {
      store.dispatch('setMemberId', selectedMember.memberId);

      if (store.getters.isTableTennis) {
        router.push('/results/' + selectedMember.memberId);
      } else {
        router.push('/home');
      }
    }

    function goHome() {
      showMessage.value = true;
      router.push('/home');
    }

    return {
      goHome,
      appName,
      loggedIn,
      isMobile,
      nameIsVisible,
      messageIsVisible,
      selectMemberMenuIsVisible,
      toggleSelectMemberMenu,
      associationMenuIsVisible,
      toggleAssociationMenu,
      leagueMenuIsVisible,
      toggleLeagueMenu,
      trainingMenuIsVisible,
      toggleTrainingMenu,
      myTrainingMenuIsVisible,
      toggleMyTrainingMenu,
      teamCompositionModuleActive,
      trainingModuleActive,
      sportsCampModuleActive,
      trainerId,
      sportsCampMenuIsVisible,
      toggleSportsCampMenu,
      generalMenuIsVisible,
      toggleGeneralMenu,
      playerMomentMenuIsVisible,
      togglePlayerMomentMenu,
      logoutMenuIsVisible,
      toggleLogoutMenu,
      firstName,
      lastName,
      clubName,
      clubCode,
      association,
      isTableTennis,
      isBadminton,
      isGym,
      isPadel,
      hasSportsCamp,
      frenoyPublicUrl,
      resultRoute,
      toggleNameVisible,
      hideMessageOfTheDay,
      isResponsableForPlayerMoment,
      hasMessageOfTheDay,
      messageOfTheDay,
      moreThanOneMember,
      myMembersList,
      selectMember,
      trainingGroups,
      hasLeague,
      leagueLink,
      leagueId
    };
  }
};
</script>

<style scoped>
html {
  height: 100%;
  overflow: hidden;
}

.main-nav {
  display: flex;
  justify-content: space-between;
  padding: 0.5rem 0.8rem;
}
.homebuttons {
  margin-right: 10px;
  position: absolute;
}

.appheader {
  margin-left: 120px;
  position: absolute;
}
ul.sidebar-panel-nav {
  list-style-type: none;
}

ul.sidebar-panel-nav > li > a {
  color: var(--var-font-color);
  text-decoration: none;
  font-size: 20px;
  display: block;
  padding-bottom: 0.5em;
}

header {
  width: 100%;
  height: 5rem;
  background-color: var(--var-button-background);
  display: flex;
  align-items: center;
  border: 2px solid var(--var-font-color);
  font-size: 32px;
  font-weight: bold;
  margin: 1rem auto;
  max-width: 40rem;
}

header a {
  text-decoration: none;
  color: var(--var-font-color);
  display: flex;
  padding: 0.25rem 0.5rem;
  margin-bottom: 5px;
  border: 1px solid var(--var-font-color);
  font-size: 20px;
}

a:active,
a.router-link-active {
  border: 1px solid var(--var-font-color);
}

a:hover {
  background-color: var(--var-button-background-focused);
}

.homeheader {
  cursor: pointer;
}
.homeheader a {
  text-decoration: none;
  color: var(--var-font-color);
  display: inline;
  padding: 0rem 0rem;
  margin-bottom: 0px;
  border: 0px;
  font-size: 35px;
}

.homeheader a:active,
a:router-link-active {
  border: 0px;
}

.homeheader a:hover {
  background-color: var(--var-button-background);
}

header ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

li {
  margin: 0 0.5rem;
}

h5 {
  border: 1px solid var(--var-font-color);
  border-radius: 12px;
  padding: 0.3rem;
  cursor: pointer;
}

.member {
  width: 100%;
  height: 2rem;
  background-color: var(--var-button-background);
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 12px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);
  font-size: 20px;
  font-weight: bold;
  margin: 2rem auto;
  max-width: 40rem;
  cursor: alias;
}

.messageoftheday {
  width: 100%;
  background-color: var(--var-button-background);
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 12px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);
  font-size: 20px;
  font-weight: bold;
  margin: 2rem auto;
  padding: 0.7rem;
  max-width: 40rem;
  cursor: alias;
}

.login {
  font-size: 16px;
}
</style>
