let timer;

export default {
  appConfig(context, payload) {
    context.commit('setAppConfig', payload);
  },
  async login(context, payload) {
    let url = context.rootState.apiUrl + 'login.php';

    const response = await fetch(url, {
      method: 'POST',
      body: JSON.stringify({
        login: payload.email,
        password: payload.password,
        application: 4,
        returnSecureToken: true
      })
    });

    const responseData = await response.json();
    // console.log(responseData);

    if (!response.ok) {
      const error = new Error(
        responseData.message || 'Fout tijdens het aanmelden.'
      );
      throw error;
    }

    const expiresIn = +responseData.expiresIn * 1000;
    const expirationDate = new Date().getTime() + expiresIn;

    // Disciplines
    const disciplines = [];

    for (const key in responseData.disciplines) {
      const discipline = {
        id: key,
        description: responseData.disciplines[key]
      };

      disciplines.push(discipline);
    }

    // Check API-URL in tryLogin!
    const sessionApiUrl = context.rootState.apiUrl;
    const data = {
      sessionApiUrl: sessionApiUrl,
      token: responseData.jwt,
      clubName: responseData.clubName,
      clubCode: responseData.clubCode,
      association: responseData.association,
      disciplines: disciplines,
      loginId: responseData.loginId,
      userId: responseData.userId,
      firstName: responseData.firstName,
      lastName: responseData.lastName,
      email: responseData.email,
      memberId: responseData.memberId,
      memberIds: responseData.memberIds,
      teamCompositionModuleActive: responseData.teamCompositionModule,
      trainingModuleActive: responseData.trainingModule,
      sportsCampModuleActive: responseData.sportsCampModule,
      trainerId: responseData.trainerId,
      trainerProfile: responseData.trainerProfile,
      additionalTraineeSelection: +responseData.additionalTraineeSelection,
      frenoyUrl: responseData.frenoyUrl,
      frenoyPublicUrl: responseData.frenoyPublicUrl,
      compositionsNeedRelease: responseData.compositionsNeedRelease,
      myTeams: responseData.teams,
      playerMoments: responseData.playerMoments,
      showPlayerMomentsInTheFuture: responseData.playerMomentsFuture,
      hasSportsCamp: responseData.hasSportsCamp,
      composeAsCaptain: responseData.composeAsCaptain,
      composeAnyTeam: responseData.composeAnyTeam,
      homePage: responseData.homePage,
      messageOfTheDay: responseData.messageOfTheDay,
      hasLeague: responseData.hasLeague,
      leaguePlayerMomentInfo:
        responseData.leaguePlayerMomentInfo == 1 ? true : false,
      enterAnyLeagueResult: responseData.enterAnyLeagueResult,
      isSubscribedForTheLeague: responseData.isSubscribedForTheLeague,
      canUnsubscribeFromLeague:
        responseData.canUnsubscribeFromLeague == 1 ? true : false,
      tokenExpiration: expirationDate
    };
    localStorage.setItem('MyTabNetApp', JSON.stringify(data));

    timer = setTimeout(function() {
      context.dispatch('autoLogout');
    }, expiresIn);

    // console.log(data);
    context.commit('setUser', data);
  },
  tryLogin(context) {
    const data = JSON.parse(localStorage.getItem('MyTabNetApp'));
    if (!data) return;

    // APIUrl different -> DO NOT LOGIN!
    const sessionApiUrl = context.rootState.apiUrl;
    // console.log('context.rootState.apiUrl: ' + context.rootState.apiUrl);
    // console.log('sessionApiUrl: ' + sessionApiUrl);
    // console.log('data.sessionApiUrl: ' + data.sessionApiUrl);
    if (data.sessionApiUrl != sessionApiUrl) {
      localStorage.removeItem('MyTabNetApp');
      return;
    }

    // Already expired? Clear localStorage
    const expiresIn = +data.tokenExpiration - new Date().getTime();
    if (expiresIn < 0) {
      localStorage.removeItem('MyTabNetApp');
      return;
    }

    if (!data.token || !data.loginId) return;

    timer = setTimeout(function() {
      context.dispatch('autoLogout');
    }, expiresIn);

    context.commit('setUser', data);
  },
  logout(context) {
    localStorage.removeItem('MyTabNetApp');

    clearTimeout(timer);

    context.commit('setUser', {
      token: null,
      clubName: null,
      clubCode: null,
      association: null,
      loginId: null,
      userId: null,
      firstName: null,
      lastName: null,
      email: null,
      memberId: null,
      memberIds: null,
      teamCompositionModuleActive: 0,
      trainingModuleActive: 0,
      sportsCampModuleActive: 0,
      trainerId: null,
      trainerProfile: null,
      frenoyUrl: null,
      frenoyPublicUrl: null,
      compositionsNeedRelease: null,
      myTeams: null,
      playerMoments: null,
      showPlayerMomentsInTheFuture: null,
      hasSportsCamp: null,
      composeAsCaptain: null,
      composeAnyTeam: null,
      homePage: 3,
      messageOfTheDay: null,
      hasLeague: null,
      leaguePlayerMomentInfo: null,
      enterAnyLeagueResult: null,
      isSubscribedForTheLeague: null,
      canUnsubscribeFromLeague: true
    });

    context.commit('setMyMembers', []);
  },
  autoLogout(context) {
    context.dispatch('logout');
    context.commit('setAutoLogout');
  },
  setIsNavOpen(context, payload) {
    context.commit('setIsNavOpen', payload);
  },
  toggleNav(context) {
    context.commit('setIsNavOpen', !context.state.isNavOpen);
  },
  async loadMyMembers(context) {
    let myMembers = context.getters.myMembers;

    if (myMembers.length === 0) {
      const memberId = context.getters.memberId;

      let url = context.rootState.apiUrl + 'member_list.php';

      const token = context.rootState.token;
      const memberIds = context.rootState.memberIds;
      // console.log(memberIds);
      const response = await fetch(url, {
        method: 'POST',
        body: JSON.stringify({
          jwt: token,
          memberids: memberIds
        })
      });

      const responseData = await response.json();
      // console.log(responseData);

      if (!response.ok) {
        const error = new Error(
          responseData.message ||
            'Fout bij ophalen van de fiches waartoe je met je aanmelding toegang hebt!'
        );
        throw error;
      }

      myMembers = [];
      for (const key in responseData) {
        const member = {
          id: key,
          memberId: responseData[key].id,
          memberName: responseData[key].membername,
          firstName: responseData[key].firstname,
          lastName: responseData[key].lastname,
          address: responseData[key].address,
          email: responseData[key].email,
          telephone: responseData[key].telephone,
          mobile: responseData[key].mobile,
          birthDate: responseData[key].birthdate,
          gender: responseData[key].gender,
          ranking: responseData[key].ranking,
          associationId: responseData[key].association_id,
          trainingGroups: responseData[key].traininggroups,
          selected: responseData[key].id == memberId ? true : false
        };
        // console.log(member);
        myMembers.push(member);
      }

      context.commit('setMyMembers', myMembers);
    }
  },
  setMemberId(context, payload) {
    const memberId = payload;
    const myMembers = context.getters.myMembers;
    if (myMembers.length > 0) {
      myMembers.forEach(member => {
        member.selected = member.memberId === payload ? true : false;
      });
    }

    context.commit('setMemberId', memberId);
  },
  async loadTeamConfirmationOptions(context) {
    let url = context.rootState.apiUrl + 'teamconfirmationoption_list.php';

    const token = context.rootState.token;

    // console.log(memberIds);
    const response = await fetch(url, {
      method: 'POST',
      body: JSON.stringify({
        jwt: token
      })
    });

    const responseData = await response.json();
    // console.log(responseData);

    if (!response.ok) {
      const error = new Error(
        responseData.message ||
          'Fout bij ophalen van de ploegopstellingsopties!'
      );
      throw error;
    }

    const teamConfirmationOptions = [];

    for (const key in responseData) {
      const teamConfirmationOption = {
        id: key,
        teamConfirmationOptionId: responseData[key].id,
        description: responseData[key].description,
        variant: responseData[key].variant,
        color: responseData[key].color,
        participate: responseData[key].participate,
        remarks: responseData[key].remarks
      };

      teamConfirmationOptions.push(teamConfirmationOption);
    }

    context.commit('setTeamConfirmationOptions', teamConfirmationOptions);
  },
  async loadGdpr(context) {
    const memberId = context.getters.memberId;
    let url = context.rootState.apiUrl + 'member_gdpr.php';

    const token = context.rootState.token;

    // console.log(memberIds);
    const response = await fetch(url, {
      method: 'POST',
      body: JSON.stringify({
        jwt: token,
        memberid: memberId
      })
    });

    const responseData = await response.json();

    if (!response.ok) {
      const error = new Error(
        responseData.message || 'Fout bij ophalen van de GDPR-instellingen!'
      );
      throw error;
    }

    const payload = {
      gdprExplicitAllowance: responseData[0].GdprExplicitAllowance,
      gdprMailings: responseData[0].GdprMailings,
      gdprSmses: responseData[0].GdprSmses,
      gdprBirthday: responseData[0].GdprBirthday,
      gdprPhoto: responseData[0].GdprPhoto
    };

    // console.log(payload);

    context.commit('setGdpr', payload);
  },
  async setGdpr(context, payload) {
    const memberId = context.getters.memberId;
    let url = context.rootState.apiUrl + 'member_gdpr_register.php';

    const token = context.rootState.token;

    // console.log(memberIds);
    const response = await fetch(url, {
      method: 'POST',
      body: JSON.stringify({
        jwt: token,
        memberid: memberId,
        GdprExplicitAllowance: payload.gdprExplicitAllowance,
        GdprMailings: payload.gdprMailings,
        GdprSmses: payload.gdprSmses,
        GdprBirthday: payload.gdprBirthday,
        GdprPhoto: payload.gdprPhoto
      })
    });

    const responseData = await response.json();

    if (!response.ok) {
      const error = new Error(
        responseData.message || 'Fout bij ophalen van de GDPR-instellingen!'
      );
      throw error;
    }

    payload = {
      gdprExplicitAllowance: responseData[0].GdprExplicitAllowance,
      gdprMailings: responseData[0].GdprMailings,
      gdprSmses: responseData[0].GdprSmses,
      gdprBirthday: responseData[0].GdprBirthday,
      gdprPhoto: responseData[0].GdprPhoto
    };

    // console.log(payload);

    context.commit('setGdpr', payload);
  },
  async loadPersonalInformation(context) {
    const memberId = context.getters.memberId;
    let url = context.rootState.apiUrl + 'member_info.php';

    const token = context.rootState.token;

    // console.log(memberIds);
    const response = await fetch(url, {
      method: 'POST',
      body: JSON.stringify({
        jwt: token,
        memberid: memberId
      })
    });

    const responseData = await response.json();
    // console.log(responseData);

    if (!response.ok) {
      const error = new Error(
        responseData.message || 'Fout bij ophalen van de persoonlijke gegevens!'
      );
      throw error;
    }

    const personalInformation = {
      lastName: responseData[0].lastname,
      firstName: responseData[0].firstname,
      address: responseData[0].address,
      zip: responseData[0].zip,
      city: responseData[0].city,
      birthdate: responseData[0].birthdate,
      telephone: responseData[0].telephone,
      mobile: responseData[0].mobile,
      email: responseData[0].email,
      ibanAccount: responseData[0].ibanaccount,
      financialInformation: responseData[0].financialinfo,
      associationId: responseData[0].association_id,
      ageCategory: responseData[0].agecategory,
      displayParentInformation:
        responseData[0].displayparentinformation == 1 ? true : false,
      fatherLastName: responseData[0].father_lastname,
      fatherFirstName: responseData[0].father_firstname,
      fatherTelephone: responseData[0].father_telephone,
      fatherMobile: responseData[0].father_mobile,
      fatherEmail: responseData[0].father_email,
      motherLastName: responseData[0].mother_lastname,
      motherFirstName: responseData[0].mother_firstname,
      motherTelephone: responseData[0].mother_telephone,
      motherMobile: responseData[0].mother_mobile,
      motherEmail: responseData[0].mother_email
    };

    context.commit('setPersonalInformation', personalInformation);
  },
  async setPersonalInformation(context, payload) {
    const memberId = context.getters.memberId;
    let url = context.rootState.apiUrl + 'member_info_register.php';

    const token = context.rootState.token;

    // console.log(memberIds);
    const response = await fetch(url, {
      method: 'POST',
      body: JSON.stringify({
        jwt: token,
        memberid: memberId,
        firstname: payload.firstName,
        lastname: payload.lastName,
        address: payload.address,
        zip: payload.zip,
        city: payload.city,
        birthdate: payload.birthdate,
        telephone: payload.telephone,
        mobile: payload.mobile,
        email: payload.email,
        ibanaccount: payload.ibanAccount,
        father_lastname: payload.fatherLastName,
        father_firstname: payload.fatherFirstName,
        father_telephone: payload.fatherTelephone,
        father_mobile: payload.fatherMobile,
        father_email: payload.fatherEmail,
        mother_lastname: payload.motherLastName,
        mother_firstname: payload.motherFirstName,
        mother_telephone: payload.motherTelephone,
        mother_mobile: payload.motherMobile,
        mother_email: payload.motherEmail
      })
    });

    const responseData = await response.json();
    // console.log(responseData);

    if (!response.ok) {
      const error = new Error(
        responseData.message || 'Fout bij ophalen van de persoonlijke gegevens!'
      );
      throw error;
    }

    const personalInformation = {
      lastName: responseData[0].lastname,
      firstName: responseData[0].firstname,
      address: responseData[0].address,
      zip: responseData[0].zip,
      city: responseData[0].city,
      birthdate: responseData[0].birthdate,
      telephone: responseData[0].telephone,
      mobile: responseData[0].mobile,
      email: responseData[0].email,
      ibanAccount: responseData[0].ibanaccount,
      financialInformation: responseData[0].financialinfo,
      associationId: responseData[0].association_id,
      ageCategory: responseData[0].agecategory,
      displayParentInformation:
        responseData[0].displayparentinformation == 1 ? true : false,
      fatherLastName: responseData[0].father_lastname,
      fatherFirstName: responseData[0].father_firstname,
      fatherTelephone: responseData[0].father_telephone,
      fatherMobile: responseData[0].father_mobile,
      fatherEmail: responseData[0].father_email,
      motherLastName: responseData[0].mother_lastname,
      motherFirstName: responseData[0].mother_firstname,
      motherTelephone: responseData[0].mother_telephone,
      motherMobile: responseData[0].mother_mobile,
      motherEmail: responseData[0].mother_email
    };

    context.commit('setPersonalInformation', personalInformation);
  },
  async loadFrenoyResults(context) {
    const memberId = context.getters.memberId;
    let url = context.rootState.apiUrl + 'frenoy_individualresults.php';
    // console.log(memberId);

    const token = context.rootState.token;
    const response = await fetch(url, {
      method: 'POST',
      body: JSON.stringify({
        jwt: token,
        memberid: memberId
      })
    });
    // console.log('RESPONSE ' + response);

    try {
      const responseData = await response.json();
      // console.log(responseData);

      if (!response.ok) {
        const error = new Error(
          responseData.message || 'Fout bij ophalen van de Frenoy-resultaten!'
        );
        throw error;
      }

      const frenoyResults = {
        ELOPoints: responseData.ELOPoints,
        ELODate: responseData.ELODate,
        BELPoints: responseData.BELPoints,
        BELDate: responseData.BELDate,
        BELPosPoints: responseData.BELPosPoints,
        BELPosDate: responseData.BELPosDate,
        Prognose: responseData.Prognose,
        ResultsAvailable: responseData.ResultsAvailable,
        RankingResults: responseData.RankingResults,
        DetailResults: responseData.DetailResults,
        WeekResults: responseData.WeekResults
      };

      // console.log(frenoyResults);

      context.commit('setFrenoyResults', frenoyResults);
    } catch (err) {
      console.log(
        'Er trad een probleem op bij het opvragen van de individuele resultaten!'
      );
    }
  },

  setSubscribedForTheLeague(context, payload) {
    context.commit('setSubscribedForTheLeague', payload);
  },

  async loadTransportTypes(context) {
    let transportTypes = context.transportTypes;
    if (transportTypes && transportTypes.length > 0) {
      return;
    }

    let url = context.rootState.apiUrl + 'transporttype_list.php';

    const token = context.rootState.token;
    const response = await fetch(url, {
      method: 'POST',
      body: JSON.stringify({
        jwt: token
      })
    });

    try {
      const responseData = await response.json();
      // console.log(responseData);

      if (!response.ok) {
        const error = new Error(
          responseData.message || 'Fout bij het ophalen van de transportwijzes!'
        );
        throw error;
      }

      transportTypes = [];
      for (const key in responseData) {
        const transportType = {
          id: key,
          typeId: responseData[key].id,
          description: responseData[key].description
        };
        // console.log(transportType);

        transportTypes.push(transportType);
      }

      context.commit('setTransportTypes', transportTypes);
    } catch (err) {
      console.log(
        'Er trad een probleem op bij het opvragen van de transportwijzes: ' +
          err.message
      );
    }
  }
};
