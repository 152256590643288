<template>
  <div>
    <slot></slot>
    <base-dialog
      :show="!!error"
      title="Er trad een fout op bij het ophalen van de ledenfiches!"
      @close="handleError"
    >
      <p>{{ error }}</p>
    </base-dialog>
    <section v-if="!isLoading">
      <member-filter @change-filter="setFilter"></member-filter>
    </section>
    <section>
      <base-card>
        <div v-if="isLoading">
          <base-spinner></base-spinner>
        </div>
        <ul v-else-if="hasMembers">
          <member-item
            v-for="member in filteredMembers"
            :key="member.id"
            :id="member.id"
            :membername="member.memberName"
            :address="member.address"
            :email="member.email"
            :telephone="member.telephone"
            :mobile="member.mobile"
            :birthdate="member.birthdate"
            :gender="member.gender"
            :ranking="member.ranking"
            :associationid="member.associationId"
            :traineeinfo="which == 'trainees' ? true : false"
            :traininggroups="member.trainingGroups"
            :traineeremark="member.traineeRemark"
            :achievedobjectivelistid="member.achievedObjectiveListId"
            :achievedobjectivelist="member.achievedObjectiveList"
            :currentobjectivelistid="member.currentObjectiveListId"
            :currentobjectivelist="member.currentObjectiveList"
          ></member-item>
        </ul>
        <h3 v-else>Geen ledenfiches</h3>
        <div class="controls">
          <base-button mode="flat" @click="loadMembers(true)"
            >Vernieuwen</base-button
          >
        </div>
      </base-card>
    </section>
  </div>
</template>

<script>
import MemberItem from '../../components/members/MemberItem.vue';
import MemberFilter from '../../components/members/MemberFilter.vue';

export default {
  components: {
    MemberItem,
    MemberFilter
  },
  data() {
    return {
      which: 'all',
      isLoading: false,
      error: null,
      searchTerm: ''
    };
  },
  computed: {
    filteredMembers() {
      const memberList = this.$store.getters['members/members'];

      return memberList.filter(member => {
        if (this.which == 'trainees' && !member.trainingGroups) {
          return false;
        }
        if (member.memberState != 1) {
          return false;
        }
        if (this.searchTerm) {
          return (
            member.memberName
              .toLowerCase()
              .includes(this.searchTerm.toLowerCase()) ||
            (member.email != null &&
              member.email
                .toLowerCase()
                .includes(this.searchTerm.toLowerCase())) ||
            (member.address != null &&
              member.address
                .toLowerCase()
                .includes(this.searchTerm.toLowerCase()))
          );
        }

        return true;
      });
    },
    hasMembers() {
      const memberList = this.filteredMembers;
      return memberList.length > 0 ? true : false;
    }
  },
  methods: {
    async loadMembers(refresh = false) {
      this.isLoading = true;

      try {
        await this.$store.dispatch('members/loadMembers', {
          forceRefresh: refresh
        });
      } catch (err) {
        this.error =
          err.message ||
          'Er trad een probleem op bij het ophalen van de ledenfiches!';
      }

      this.isLoading = false;

      // Close navigation
      if (this.$store.getters.isNavOpen) {
        this.$store.dispatch('toggleNav');
      }
    },
    handleError() {
      this.error = null;
    },
    setFilter(updatedSearchTerm) {
      this.searchTerm = updatedSearchTerm;
    }
  },
  created() {
    this.loadMembers(true);
  },
  mounted() {
    if (this.$route.params.which) {
      this.which = this.$route.params.which;
    }
  }
};
</script>

<style scoped>
ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.controls {
  display: flex;
  justify-content: space-between;
}
</style>
